import React, { useState, useEffect } from 'react'
import DoughnutComponent from './ticketcomponents/DoughnutComponent'
import CitiesChart from './ticketcomponents/CitiesChart'
// import IssueResolvingTime from './ticketcomponents/IssueResolvingTime'
import IsssueResolvingComponent from './ticketcomponents/IssueResolvingComponent'
const TicketAnalysis = () => {

  return (
    <>
      <div className="mt-3 d-flex flex-md-row flex-column">
        <div className="card col-xl-4 col-lg-12 col-md-12 col-12">
          <DoughnutComponent name="Issue Type" apiVar="issue-types" />
        </div>
        <div className="card col-xl-8 col-lg-12 col-md-12 col-12 mt-md-0 mt-3 ml-3">
          <CitiesChart />
        </div>
      </div>
      <div className="card col-xl-12 col-lg-12 col-md-12 col-12 mt-2">
        <IsssueResolvingComponent name="Issue Resolving Time" apiVar="issues-resolving-time" />
      </div>
      <div className="mt-3 d-flex flex-md-row flex-column" style={{minHeight:600}}>
        <div className="card col-xl-4 col-lg-12 col-md-12 col-12">
          <DoughnutComponent name="Issue Resolving Rating" apiVar="issue-resolving-ratings" />
        </div>
        <div className="card col-xl-4 col-lg-12 col-md-12 col-12 ms-2">
          <DoughnutComponent name="Issue Status" apiVar="issue-status" />
        </div>
        <div className="card col-xl-4 col-lg-12 col-md-12 col-12 ms-2">
          <DoughnutComponent name="Issue Channels" apiVar="channels-issues" />
        </div>
      </div>
      <div className="card col-xl-12 col-lg-12 col-md-12 col-12 mt-2">
        <IsssueResolvingComponent name="App No Use Feedback" apiVar="issues-resolving-time" />
      </div>
    </>
  )
}

export default TicketAnalysis