import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import Calendar from "./Calendar";
import moment from "moment";
import APIService from "../../services/APIService";
import { TabView, TabPanel } from "primereact/tabview";

function Raiting(props) {
  let windowWidth = window.innerWidth;
  const { DriverReviewsStats, RiderReviewsStats } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [captRating , setCaptRatingData]=useState({});
  const [riderRating,setRiderRatingData]=useState({});
  const [applyFilter,setApplyFilter] = useState(false)

  const getFilteredRatingCount = (source, key) => {
    return applyFilter ? source?.count?.[key] : RiderReviewsStats?.count?.[key];
  };


  const getFilteredDriverRating = (source, key) => {
    return applyFilter ? source?.count?.[key] : DriverReviewsStats?.count?.[key];
  };
  
  let badRatingRider = getFilteredRatingCount(riderRating, 'poor');
  let excellentRatingRider = getFilteredRatingCount(riderRating, 'excelent');
  let goodRatingRider = getFilteredRatingCount(riderRating, 'good');
  let bestRatingRider = getFilteredRatingCount(riderRating, 'best');
  let averageRatingRider = getFilteredRatingCount(riderRating, 'average');
  
  let badRatingDriver = getFilteredDriverRating(captRating, 'poor');
  let goodRatingDriver = getFilteredDriverRating(captRating, 'good');
  let bestRatingDriver = getFilteredDriverRating(captRating, 'best');
  let averageRatingDriver = getFilteredDriverRating(captRating, 'average');
  let excellentRatingDriver = getFilteredDriverRating(captRating, 'excelent');
  

  let chartOptions = {
    responsive: true,
    // maintainAspectRatio: false,
    type: "doughnut",
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: windowWidth > 992 ? 95 : 105,
    pointStyle: "circle",

    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
        position: windowWidth > 992 ? "bottom" : "bottom",
        labels: {
          boxWidth: 100,
          usePointStyle: true,
          fontSize: 60,
        },
      },
    },
  };
  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 380).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        const totalTrips=chart?.config?._config?.data?.datasets[0] &&chart?.config?._config?.data?.datasets[0]?.data?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        var text = `Total : ${totalTrips || 0}`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillStyle = "#828282";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  const getTimeRange=(range)=>{
    Promise.all([
      APIService.get(`admin/dashboard/ratting?type=custom&entity=rider&fromDate=${range.sDate} 00:00:00.000000&toDate=${range.eDate} 23:59:59.000000` ),
      APIService.get(`admin/dashboard/ratting?type=custom&entity=captain&fromDate=${range.sDate} 00:00:00.000000&toDate=${range.eDate} 23:59:59.000000`)
    ]).then(response =>{
      setApplyFilter(true)
      if(response[0].status==200){
          let data = response[0]?.data?.data ? response[0].data.data : ""
          setRiderRatingData(data)
        }
      if(response[1].status==200){
        let data = response[1].data.data ? response[1].data.data : ""
        setCaptRatingData(data)
      }
    }).catch(e => console.error(e))
  }
  const [riderRatingData, setRiderRating] = useState({
    datasets: [
      {
        data: [
          badRatingRider,
          bestRatingRider,
          averageRatingRider,
          goodRatingRider,
          excellentRatingRider,
          
        ],
        backgroundColor: [
          "#EB3D3D",
          "#13B542",
          "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        hoverBackgroundColor: [
          "#EB3D3D",
          "#13B542",
          "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        borderWidth: 0,
      },
    ],
  });
  const [driverRatingData, setDriverRating] = useState({
    datasets: [
      {
        data: [
          badRatingDriver,
            bestRatingDriver,
            averageRatingDriver,
            goodRatingDriver,
            excellentRatingDriver,
          
        ],
        backgroundColor: [
          "#EB3D3D",
          "#13B542",
          "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        hoverBackgroundColor: [
          "#EB3D3D",
          "#13B542",
          "#FFB065",
          "rgba(19, 165, 181, 1)",
          "rgba(112, 113, 191, 1)",
        ],
        borderWidth: 0,
      },
    ],
  });

  useEffect(() => {
    setRiderRating({
      datasets: [
        {
          data: [
            badRatingRider || 0.1,
            bestRatingRider,
            averageRatingRider,
            goodRatingRider,
            excellentRatingRider,
            
          ],
          backgroundColor: [
            badRatingRider ? "#EB3D3D" : '#E5E5E5',
            "#13B542",
            "#FFB065",
            "rgba(19, 165, 181, 1)",
            "rgba(112, 113, 191, 1)",
          ],
          hoverBackgroundColor: [
            badRatingRider ? "#EB3D3D" : '#E5E5E5',
            "#13B542",
            "#FFB065",
            "rgba(19, 165, 181, 1)",
            "rgba(112, 113, 191, 1)",
          ],
          borderWidth: 0,
        },
      ],
    });
    setDriverRating({
      datasets: [
        {
          data: [
            badRatingDriver || 0.1,
            bestRatingDriver,
            averageRatingDriver,
            goodRatingDriver,
            excellentRatingDriver,
            
          ],
          backgroundColor: [
            badRatingDriver ?  "#EB3D3D" : '#E5E5E5',
            "#13B542",
            "#FFB065",
            "rgba(19, 165, 181, 1)",
            "rgba(112, 113, 191, 1)",
          ],
          hoverBackgroundColor: [
            badRatingDriver ?  "#EB3D3D" : '#E5E5E5',
            "#13B542",
            "#FFB065",
            "rgba(19, 165, 181, 1)",
            "rgba(112, 113, 191, 1)",
          ],
          borderWidth: 0,
        },
      ],
    });
  }, [RiderReviewsStats, DriverReviewsStats,riderRating,captRating]);

  const getPercentage = (source, key) => {
    return applyFilter ? source?.percentage?.[key] || 0 : RiderReviewsStats?.percentage?.[key] || 0;
  };

  const getPercentageTemp = (source, key) => {
    return applyFilter ? source?.percentage?.[key] || 0 : DriverReviewsStats?.percentage?.[key] || 0;
  };
  

  return (
    <>
      <div className="section-rider cards-section h-100">
        <div className="d-flex justify-content-between text-center align-items-center position-relative">
          <h2 className="box-title">Ratings</h2>
          <Calendar handleTimeRange={getTimeRange} />
        </div>
        <TabView
          className="mt-2"
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header=" Riders">
            {" "}
            <div>
              <Doughnut
                type="doughnut"
                data={riderRatingData}
                options={chartOptions}
                plugins={plugins}

              
              />
             
            </div>
            <div className="flex-cbb justify-content-center mt-32">
    <div>
      <div className="percentage">
        <div className="green" style={{ background: "#7071BF" }}></div>
        <div className="percentext">Excellent:</div>
        <div className="percenPoint">
          {getPercentage(riderRating, 'excelent')}%
        </div>
      </div>
      <div className="percentage">
        <div className="green" style={{ background: "#13A5B5" }}></div>
        <div className="percentext ">Good:</div>
        <div className="percenPoint">
          {getPercentage(riderRating, 'good')}%
        </div>
      </div>
      <div className="percentage">
        <div className="green bg-red"></div>
        <div className="percentext ">Poor:</div>
        <div className="percenPoint">
          {getPercentage(riderRating, 'poor')}%
        </div>
      </div>
    </div>
    <div>
      <div className="percentage">
        <div className="green"></div>
        <div className="percentext ">Best:</div>
        <div className="percenPoint">
          {getPercentage(riderRating, 'best')}%
        </div>
      </div>
      <div className="percentage">
        <div className="green bg-orange" style={{ background: "#FFB065" }}></div>
        <div className="percentext ">Average:</div>
        <div className="percenPoint">
          {getPercentage(riderRating, 'average')}%
        </div>
      </div>
    </div>
  </div>
          
          </TabPanel>
          <TabPanel header=" Captains">
            <div>
              <Doughnut
                type="doughnut"
                data={driverRatingData}
                options={chartOptions}
                plugins={plugins}

        
              />

            
            </div>
            <div className="flex-cbb mt-32">
    <div>
      <div className="percentage">
        <div className="green" style={{ background: "#7071BF" }}></div>
        <div className="percentext">Excellent:</div>
        <div className="percenPoint">
          {getPercentageTemp(captRating, 'excelent')}%
        </div>
      </div>
      <div className="percentage ">
        <div className="green" style={{ background: "#13A5B5" }}></div>
        <div className="percentext">Good:</div>
        <div className="percenPoint">
          {getPercentageTemp(captRating, 'good')}%
        </div>
      </div>
      <div className="percentage ">
        <div className="green red"></div>
        <div className="percentext">Poor:</div>
        <div className="percenPoint">
          {getPercentageTemp(captRating, 'poor')}%
        </div>
      </div>
    </div>
    <div>
      <div className="percentage">
        <div className="green"></div>
        <div className="percentext">Best:</div>
        <div className="percenPoint">
          {getPercentageTemp(captRating, 'best')}%
        </div>
      </div>
      <div className="percentage">
        <div className="green" style={{ background: "#FFB065" }}></div>
        <div className="percentext">Average:</div>
        <div className="percenPoint">
          {getPercentageTemp(captRating, 'average')}%
        </div>
      </div>
    </div>
  </div>

            <div className="main-percent rPercen justify-content-center"></div>
          </TabPanel>
          <TabPanel header="Pickups">Coming Soon</TabPanel>
        </TabView>
      </div>
    </>
  );
}

export default Raiting;
