import React from "react";
import { Button, Modal } from "react-bootstrap";
let DeleteModal = (props) => {
  const handleSubmit =()=>{
    props.clickYes();
    props.handleCloseDelete();
}
  return (
    <>
      <Modal
        dialogClassName="delete-modal-width"
        show={props.showDelete}
        onHide={props.handleCloseDelete}
        centered
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title className="modal-title" style={{textTransform:"unset"}}>
            <div className="text-center">
              <img
                alt="icon"
                src={process.env.PUBLIC_URL + "/images/deleteModal.png"}
              />
            </div>
            <div className="delete-modal-message my-3">
              Are you sure you want to delete?
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="padding-header-footer">
          <Button
            variant="danger"
            className="btn-delete save-button mb-3 border-radius-15"
            onClick={handleSubmit}
          >
            Delete
          </Button>
          <Button
            variant="secondary"
            className="btn-save-add save-button mb-3 border-radius-15"
            onClick={props.handleCloseDelete}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(DeleteModal);
