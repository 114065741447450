import React, { useState } from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import { Form, Field, ErrorMessage } from "formik";
import "../../sass/DriverDeatils.sass";
import riderAvtSec from "../../assest/images/placeHoderImage.png";
import UserLog from "./UserLog";
import APIService from "../../services/APIService";
import { toast } from "react-toastify";
import { useEffect } from "react";

function ManagementDetailsTab() {
  const [data, setData] = useState(null);
  const [adminID, setAdminID] = useState(null);

  const [updateModal, setUpdateModal] = useState(false);

  const rules = ['Master Controls', 'User Management', 'Products Management', 'Tickets', 'IT Operations', 'Sales & Marketing', 'Communication']

  let getManagementDetails = (uuid) => {
    APIService.get(
      `/admin/admin-details-from-id/${uuid}`
    ).then((response) => {
      if (response?.data?.statusCode === 200) {
        // toast.success("Admin Data Fetched");
        setData(response?.data?.data);
      } else {
        toast.error("Unable to Find Admin Details!");
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  const [fullName, setFullName] = useState(data?.fullName);
  const [email, setEmail] = useState(data?.email);

  let updateAdminDetails = (formData) => {
    setUpdateModal(false);
    
    APIService.patch(
      `/admin/subadmin/${adminID}`,
      { 
        fullName: fullName,
        email: email
      }
    ).then((response) => {
      if (response?.data?.statusCode === 200) {
        toast.success("Admin Details Updated!");
        getManagementDetails(adminID);
      } else {
        toast.error("Unable to Update Admin!");
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  let updateAdminBlock = (adminID) => {
    APIService.patch(
      `/admin/subadmin/${adminID}`,
      { status: !data?.status }
    ).then((response) => {
      if (response?.data?.statusCode === 200) {
        toast.success("Admin Block Status Updated!");
        getManagementDetails(adminID);
      } else {
        toast.error("Unable to Block/Un-Block Admin!");
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    const currentUrl = window.location.href;
    const parts = currentUrl.split('/');
    const uuid = parts[parts.length - 1];
    setAdminID(uuid);
    getManagementDetails(uuid);
  }, [])

  const profileinfo = [
    {
      label: 'Name',
      title: data?.fullName || '--'
    },
    {
      label: 'Department',
      title: data?.role?.subDepartment?.department?.name || '--'
    },
    {
      label: 'Sub Department',
      title: data?.role?.subDepartment?.name || '--'
    },
    {
      label: 'Position',
      title: data?.role?.title || '--'
    },
    {
      label: 'Level',
      title: data?.role?.level || '--'
    },
    {
      label: 'Mobile',
      title: data?.mobileNo || '--'
    },
    {
      label: 'Email',
      title: data?.email || '--'
    },
  ]

  useEffect(()=>{
    setFullName(data?.fullName)
    setEmail(data?.email)
  }, [data]);

  return (
    <div className="mt-3 driver-details">

      <>
        <Col xl={12} className="px-custom">
          <Row className="mt-2 driver-details px-1 row-flex">
            <Col xl={4}>
              <Row className="mt-2 driver-details px-1 row-flex">
                <Col xl={12} className="px-0">
                  <div className="d-box h-100 ">
                    <div className="d-flex my-3">
                      <div className="img-relative">
                        <img
                          src={data?.profileImageUrl || riderAvtSec}
                          alt="ride"
                          style={{
                            marginRight: "15px",
                            width: "84px",
                            height: "84px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                        />

                        <div
                          className="user-absolute-img"
                        //   onClick={() => setEditProfile(true)}
                        >
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="15"
                              cy="15"
                              r="13.5"
                              fill="#13B542"
                              stroke="white"
                              stroke-width="3"
                            />
                            <path
                              d="M20.7137 12.2795C20.7142 12.2042 20.6998 12.1297 20.6713 12.0601C20.6429 11.9905 20.601 11.9271 20.548 11.8737L18.1252 9.45088C18.0718 9.39792 18.0084 9.35602 17.9388 9.32758C17.8692 9.29915 17.7947 9.28473 17.7195 9.28517C17.6442 9.28473 17.5697 9.29915 17.5001 9.32758C17.4305 9.35602 17.3671 9.39792 17.3137 9.45088L15.6966 11.068L9.45088 17.3137C9.39792 17.3671 9.35602 17.4305 9.32758 17.5001C9.29915 17.5697 9.28473 17.6442 9.28517 17.7195V20.1423C9.28517 20.2939 9.34537 20.4392 9.45253 20.5464C9.5597 20.6535 9.70504 20.7137 9.85659 20.7137H12.2795C12.3594 20.7181 12.4394 20.7056 12.5142 20.677C12.589 20.6485 12.657 20.6045 12.7137 20.548L18.9252 14.3023L20.548 12.7137C20.6002 12.6584 20.6427 12.5946 20.6737 12.5252C20.6792 12.4796 20.6792 12.4336 20.6737 12.388C20.6764 12.3614 20.6764 12.3346 20.6737 12.308L20.7137 12.2795ZM12.0452 19.5709H10.428V17.9537L16.1023 12.2795L17.7195 13.8966L12.0452 19.5709ZM18.5252 13.0909L16.908 11.4737L17.7195 10.668L19.3309 12.2795L18.5252 13.0909Z"
                              fill="#F4F4F4"
                            />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <div className="detail-list-1 d-block">
                          <div className="detail-list-1-name">
                            {data?.fullName || 'Full Name'}
                          </div>
                          <div className="d-flex">
                            <div className="detail-list-1-id">#{data?.mobileNo || 'xxxxxxxxxx'}</div>
                            <span className="status-text">{data ? (data?.lastAccessTime ? data?.lastAccessTime : 'Offline') : '-------'}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="kyc-info">
                      {profileinfo?.map((profile, id) => {
                        return (
                          <div key={id}>
                            <div className="d-flex align-items-center justify-content-between mt-3">
                              <div>
                                {profile.label}
                              </div>
                              <div className="text-dark font-weight-bold">{profile.title}</div>
                            </div>
                          </div>
                        )
                      })
                      }
                    </div>

                    <div className="d-flex align-items-center gap-3 mt-3">
                      <button className="btn btn-success" onClick={()=>{setUpdateModal(true)}}>Edit</button>
                      <button className="btn btn-danger">Delete</button>
                      <button className="btn btn-warning"
                        style={data ? (data?.status ? { background: '#FFB065' } : { background: '#dc3545', color: '#fff' }) : ({ background: '#FFB065' })}
                        onClick={() => updateAdminBlock(adminID)}>
                        {data ? (data?.status ? 'Block' : 'UnBlock') : ('-----')}
                      </button>
                    </div>
                  </div>
                </Col>


              </Row>
            </Col>
            <Col xl={8}>
              <Row className="mt-2 driver-details px-1 row-flex">
                <Col xl={12} className="px-0">
                  <div className="d-box">
                    <div className="d-flex mb-3">
                      <h2 className="box-title"> Rules </h2>
                    </div>
                    <div className="my-4">
                      {rules.map((rule) => {
                        return (
                          <>
                            <div className="d-flex align-items-center justify-content-between mt-3">
                              <div className="text-dark font-weight-bold">
                                {rule}
                              </div>
                              <div className="d-flex flex-grow-1 mx-1" style={{ border: '1px solid #F4F4F4' }}></div>
                              <div className=" text-success font-weight-bolder d-flex align-items-center justify-content-center" style={{ fontSize: '24px', width: '20px', height: '20px', borderRadius: '50%', background: 'rgba(6, 176, 4, 0.3)' }}>+</div>
                            </div>

                          </>
                        )
                      })}

                    </div>
                    <button className="btn btn-success my-3">Update</button>
                  </div>
                </Col>
              </Row>

            </Col>
            <Col xl={12}>
              <Row className="mt-2 driver-details px-1 row-flex">
                <div className="d-box">
                  <UserLog externalId={data?.id || 'd51feb3c-e730'} />
                </div>
              </Row>
            </Col>
          </Row>
        </Col>{" "}
      </>

      <Modal
        className="modal-promotion"
        show={updateModal}
        size="md"
        onHide={()=>{setUpdateModal(false)}}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Formik
          initialValues={{ 
            name: data?.fullName,
            email: data?.email,
           }}
          // validationSchema={
          //   selectedOption.value === 1
          //     ? validationSchemaAmount
          //     : validationSchemaPercentage
          // }
          onSubmit={updateAdminDetails}
        >
          {({ handleBlur, setFieldValue, values }) => {
            return (
              <Form>
                <Modal.Header className="flex-modal-header">
                  <Modal.Title className="modal-title">
                    Edit Admin Details
                  </Modal.Title>
                  <div class="divclose" 
                  onClick={()=>{setUpdateModal(false)}}
                  >
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body className="mt-24">
                  <Row className="justify-content-center">
                    <Col md={12}>
                      <div className="form-group form-label-group mb-4">
                        <Field
                          id="name"
                          type="text"
                          name="name"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Full Name"
                          value={fullName}
                          onChange={(e) => {
                            setFullName(e.target.value);
                          }}
                        />
                        <label htmlFor="name">Full Name</label>
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    
                    <Col md={12}>
                      <div className="form-group form-label-group mb-4">
                        <Field
                          id="email"
                          type="email"
                          name="email"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                        <label htmlFor="email">Email</label>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    
                    {/* <Col md={12}>
                      <div className="form-group form-label-group mb-4">
                        <Field
                          id="mobile"
                          type="number"
                          name="mobile"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Mobile"
                          value={data?.mobileNo}
                          // onChange={(e) => {
                          //   setEmailValue(e.target.value);
                          // }}
                        />
                        <label htmlFor="mobile">Mobile</label>
                        <ErrorMessage
                          name="mobile"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col> */}

                    <Col md={12} className="mt-3">
                      {/* {validNumber | emailValidity ? ( */}
                        <Button
                          variant="primary"
                          type="submit"
                          className="btn-save btn-block "
                        >
                          Update
                        </Button>
                      {/* ) : (
                        <Button
                          disabled
                          variant="primary"
                          type="submit"
                          className="btn-save btn-block "
                        >
                          Update
                        </Button>
                      )} */}
                    </Col>
                  </Row>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  )
}

export default ManagementDetailsTab