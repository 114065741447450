import React , {useState} from "react";
import { Tab, Tabs } from "react-bootstrap";
import OwnaCar from "../components/Inventory/OwnaCar";
import DriverReject from "../components/Cancelation/DriverReject";
import DriverCancel from "../components/Cancelation/DriverCancel";
import RiderCancel from "../components/Cancelation/RiderCancel";

let Cancelation = () => {
  let [selectTab, setSelectedTab] = useState("DriverReject");
  return (
    <>
      <div className="mt-4">
      {/* <div className="d-flex justify-content-between">
          <span className="title">Car Details</span>
        </div> */}
        <Tabs
          id="controlled-tab-example"
          className="mt-30 position-relative"
          activeKey={selectTab}
          onSelect={(tab) => {
            setSelectedTab(tab);
          }}
        >
          
          <Tab eventKey="DriverReject" title="Driver Reject">
            {selectTab === "DriverReject" && (
            <DriverReject/>
            )}
          </Tab>
          <Tab eventKey="DriverCancel" title="Driver Cancel">
            {selectTab === "DriverCancel" && (
              <DriverCancel/>
            )}
          </Tab>
          <Tab eventKey="RiderCancel" title="Rider Cancel">
            {selectTab === "RiderCancel" && (
              <RiderCancel/>
            )}
          </Tab>
          </Tabs>
      </div>
    </>
  );
};

export default React.memo(Cancelation);