export const getCurrentLocation = async () => {
  try {
    // Function to get the user's current position (latitude and longitude)
    const getPosition = () => {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        } else {
          reject("Geolocation is not supported by this browser.");
        }
      });
    };

    // Get user's current position (latitude and longitude)
    const position = await getPosition();
    const { latitude, longitude } = position.coords;

    // Use Google Maps Geocoding API to get location details
    const apiKey = "YOUR_GOOGLE_MAPS_API_KEY";
    const response = await fetch(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
    );
    const data = await response.json();
    // Extract country and city information from the API response
    let country = "N/A";
    let city = "N/A";
    if (data) {
      // Get the user's local time and date
      const timestamp = Date.now();
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const formattedTime = new Date(timestamp).toLocaleTimeString([], {
        timeZone,
      });
      const formattedDate = new Date(timestamp).toLocaleDateString([], {
        timeZone,
      });

      // Return the current location information
      return {
        time: formattedTime,
        date: formattedDate,
        country: data?.countryName,
        city: data?.city,
      }
    }
    else {
      return {
        time: "",
        date: "",
        country: "",
        city: "",
      }
    }
  } catch (error) {
    console.error("Error getting location information:", error);
    // Return default values in case of error
    return {
      time: "",
      date: "",
      country: "",
      city: "",
    }
  }
}