import React from 'react'
import { Doughnut } from 'react-chartjs-2';

const DoughnutCMS = ({ IssueTypeData }) => {

  let total = 0;
  const color = ['#13B542', '#EF4242', '#7071BF', '#13A5B5', '#FFB065', '#F1F1F1']

  let Coloredata = [];
IssueTypeData && Object.keys(IssueTypeData).map((a, i) => Coloredata.push({
  name: a,
  color: color[i],
  count: IssueTypeData[a],
}))
IssueTypeData && Object.keys(IssueTypeData).map(a => total += IssueTypeData[a])
// IssueTypeData && total =
const data = {
  labels: IssueTypeData && Object.keys(IssueTypeData),
  datasets: [
    {
      data: IssueTypeData && Object.values(IssueTypeData),
      backgroundColor: Coloredata.map(a => a.color),
      hoverBackgroundColor: Coloredata.map(a => a.color),
      // hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
    }
  ],
};
  let chartOptions = {
    responsive: true,
    // maintainAspectRatio: false,
    type: "doughnut",
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: window.innerWidth > 992 ? 105 : 105,
    pointStyle: "circle",

    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
        // position: windowWidth > 992 ? "bottom" : "bottom",
        labels: {
          boxWidth: 100,
          usePointStyle: true,
          fontSize: 60,
        },
      },
    },
  };
  const emptyData = {
    labels: ["empty"],
    datasets: [
      {
        data: [0.1],
        backgroundColor: ["#F1F1F1"],
        hoverBackgroundColor:  ["#F1F1F1"],
      },
    ],
  },
    getPercent = (total, no) => ((no / total) * 100).toFixed(0)
    console.log('----------------------------',data)

  return (<div style={{ height: "100%" }}>
    <Doughnut data={data.labels !== undefined && data.labels?.length !== 0 && total !== 0 ? data : emptyData} options={chartOptions} />
    <div className="flex-cbb justify-content-between d-flex flex-wrap mt-32">
      {/* <div> */}
      {Coloredata.map(color =>
        <div className="percentage" style={{ width: "45%" }} >
          <div className="green" style={{
            background: color.color
          }}></div>
          <div className="percentext ">{color.name}:</div>
          <div className="percenPoint">
            {isNaN(getPercent(total, color.count)) ? 0 : getPercent(total, color.count)}%
          </div>
        </div>
      )}
      {/* </div> */}
    </div>
  </div>)

}

export default DoughnutCMS