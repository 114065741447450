import React , {useState} from "react";
import DriverListingTable from "../components/Drivers/DriverListingTable";
import { Tab, Tabs } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import IncompleteDrivers from "../components/Drivers/IncompleteDrivers";
import { toast } from "react-toastify";
import refresh from '../components/Dashboard_2/images/refresh.svg'
import APIService from "../services/APIService";
import RiderListingTable from "../components/Riders/RiderListingTable";
import InAppRider from "../components/Riders/InAppRider";
import WhatsappRider from "../components/Riders/WhatsappRider";
import SubscriptionRider from "../components/Riders/SubscriptionRider";


let TotalRiders = () => {
  let [selectTab, setSelectedTab] = useState("All Riders");
  return (
    <>
      <div style={{margin:'15px 0px'}}>
    
        <Tabs
          id="controlled-tab-example"
          className="mt-30 position-relative"
          activeKey={selectTab}
          onSelect={(tab) => {
            setSelectedTab(tab);
          }}
        >
          
          <Tab eventKey="All Riders" title="All Riders">
            {selectTab === "All Riders" && (
              <RiderListingTable />
            )}
          </Tab>

          <Tab eventKey="In App Riders" title="In App Riders">
            {selectTab === "In App Riders" && (
              <InAppRider />
            )}
          </Tab>
          <Tab eventKey="Whatsapp Riders" title="Whatsapp Riders">
            {selectTab === "Whatsapp Riders" && (
              <WhatsappRider />
            )}
          </Tab>
          <Tab eventKey="Subscription Riders" title="Subscription Riders">
            {selectTab === "Subscription Riders" && (
              <SubscriptionRider />
            )}
          </Tab>
          
          </Tabs>
        {/* <DriverListingTable /> */}
      </div>
    </>
  );
};

export default React.memo(TotalRiders);