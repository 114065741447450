import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { classNames } from "react-select/dist/index-ea9e225d.cjs.prod";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import { _baseURL, secret_key,_apiURL } from "../../services/APIService";
import Calendar from "../Dashboard_2/Calendar";
import APIService from "../../services/APIService";
import axios from "axios";
function CancelReason({customerId}) {
 const currentDate =  moment().format('YYYY-MM-DD');
  const [chartData ,setChartData] = useState(false);
  const [reasonList,setReasonsList] = useState(null)
  const [reasonCount,setReasonCount] = useState(null)
  const [cancelReasonsData,setCancelReasonsData] =useState( []);
  const [cancelReasons,setCancelReasons] =useState( {
    datasets: [
      {
          data: cancelReasonsData,
          backgroundColor: ["#EB3D3D", "#13B542","#13A5B5","#FFB065","#F1F1F1","#000000","#7071BF" ],
          hoverBackgroundColor: ["#EB3D3D", "#13B542","#13A5B5","#FFB065","#F1F1F1","#000000","#7071BF"],
          borderWidth: 0,
      },
    ],
  });
  console.log('cancelReasonsData',cancelReasonsData,cancelReasons)
  const reasonNames = [
    'Personal Emergency',
    'Passenger No Show',
    'Passenger Is Far',
    'Passenger Unreachable',
    'Issue With My Car',
    'Application is Hanging',
    'Other',
  ];

  const captainCancelReason = async ()=>{
    // const apiURL = {{base_url_admin}}/user-cancel-reasons?userId=966110000303&fromDate=2022-08-11 23:59:59.000000&toDate=2023-12-12 23:59:59.000000
    APIService.get(`admin/user-cancel-reasons?userId=${customerId}`)
    .then((response) => {
      if (response.data && response.data.statusCode === 200) {
        const resons = response.data.data;
        const captainsReasons = resons.filter((reason) =>{
          return reason?.reasonType==2;
        })
        const updatedReasons = captainsReasons.map((reason) => {
          if (!reason.count) {
            reason.count = 0;
          }
          return reason;
        });
        console.log('captain reasons ',updatedReasons)
        const updatedReasonsData = updatedReasons?.map(item => (item?.count))
        setCancelReasonsData(updatedReasonsData)


        

      }
    })
    .catch((error) => {
      console.log(error);
    });
  }




 


  useEffect(()=>{
  captainCancelReason();
  },[])
  const getTimeRange=(range)=>{
    const {sDate , eDate } = range;
    {
      // const apiURL = {{base_url_admin}}/user-cancel-reasons?userId=966110000303&fromDate=2022-08-11 23:59:59.000000&toDate=2023-12-12 23:59:59.000000
      APIService.get(`admin/user-cancel-reasons?userId=${customerId}&type=custom&fromDate=${sDate} 00:00:00.000000&toDate=${eDate} 23:59:59.000000`)
      .then((response) => {
        if (response.data && response.data.statusCode === 200) {
          const resons = response.data.data;
          const captainsReasons = resons.filter((reason) =>{
            return (window.location.pathname.includes('driver') ? reason?.reasonType==2 : reason?.reasonType==3) ;
          })
          const updatedReasons = captainsReasons.map((reason) => {
            if (!reason.count) {
              reason.count = 0;
            }
            return reason;
          });
          console.log('captain reasons ',updatedReasons)
          const updatedReasonsData = updatedReasons?.map(item => (item?.count))
          setCancelReasonsData(updatedReasonsData)
  
  
          
  
        }
      })
    }}
  const  windowWidth = window.innerWidth,
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    type: "doughnut",
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: windowWidth > 992 ? 95 : 105,
    pointStyle: "circle",

    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
        position: windowWidth > 992 ? "bottom" : "bottom",
        labels: {
          boxWidth: 100,
          usePointStyle: true,
          fontSize: 60,
        },
      },
    },
  };
  let emptyTrips = {
    datasets: [
      {
        data: [0.1],
        backgroundColor: ["rgba(241, 241, 241, 1)"],
        hoverBackgroundColor: ["rgba(241, 241, 241, 1)"],
        borderWidth: 0,
      },
    ],
  };


  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 380).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        
        // Access the chart data to calculate the total
        
        var totalValue = chart.data.datasets.reduce(function (total, dataset) {
          return total + dataset.data.reduce(function (subTotal, value) {
            return Number(subTotal) + Number(value);
          }, 0);
        }, 0);
  
        var text = "Total: " + totalValue,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillStyle = "#828282";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

 
  useEffect(() => {
    setCancelReasons( {
      datasets: [
        {
          data: cancelReasonsData,
          backgroundColor: ["#EB3D3D", "#13B542","#13A5B5","#FFB065","#F1F1F1","#000000","#7071BF" ],
          hoverBackgroundColor: ["#EB3D3D", "#13B542","#13A5B5","#FFB065","#F1F1F1","#000000","#7071BF"],
          borderWidth: 0,
        },
      ],
    })
  }, [JSON.stringify(cancelReasonsData)]);


  const chartHeadings =[
    {
        firstHeading:'Personal Emergency',
        firstColor:'#7071BF',
        secondHeading:'Passenger No Show',
        secondColor:'#13B542'
    },
    {
        firstHeading:'Passenger Is Far',
        firstColor:'#13A5B5',
        secondHeading:'Passenger Unreachable',
        secondColor:'#FFB065'
    },
    {
        firstHeading:'Issue With My Car',
        firstColor:'#EB3D3D',
        secondHeading:'Application is hanging',
        secondColor:'#000000'
    },
    {
        firstHeading:'Other',
        firstColor:'#F1F1F1'
    }
  ]
  useEffect(()=>{
    if(cancelReasonsData?.filter(item => item > 0)?.length < 1){
      setChartData(true)
    }
  },[JSON.stringify(cancelReasonsData)])
  return (
    <>
      <div className="section-rider col-lg-12 section-captain h-100">
        <div className="card-nav mb-16">
          <h2 className="box-title text-wrap">Cancelation Reason</h2>
          <div>
            <div className="dropdown">
              <Calendar handleTimeRange={getTimeRange} />
            </div>
          </div>
        </div>
        

          {!chartData ? (
              <Doughnut
                data={emptyTrips}
                width={null}
                height={windowWidth > 992 ? 150 : null}
                options={chartOptions}
                plugins={plugins}
              />
            ) : (
              <Doughnut
                type="doughnut"
                width={null}
                height={windowWidth > 992 ? 150 : null}
                data={cancelReasons}
                plugins={plugins}
                options={chartOptions}
              />
            )}

            <div className="info-section">
              <div className="row">
                <div className="container-fluid">
                  <div className="main-percent line-main"> 
                    {chartHeadings?.map((chart)=>{
                        return(
                            <>
                            
                    <div className="percentage cc-percent justify-content-start">
                      <div className="d-flex align-items-center" style={{width:'45%'}}>
                        <div className="green-ball" style={{background:`${chart.firstColor} `}}></div>
                        <div className="percentext flex-grow-1 text-start" style={{lineHeight:'1.3'}}>{chart.firstHeading}</div>
                      </div>
                      <div>
                        {chart.secondColor&&
                        <div className="percenPoint">
                        <div className="d-flex align-items-center">
                        <div className="green-ball" style={{background:`${chart.secondColor}`}}></div>
                        <div className="percentext flex-grow-1 text-start" style={{lineHeight:'1.3'}}>{chart.secondHeading}</div>
                      </div>
                        </div>
                        }
                      </div>
                    </div>
                            </>
                        )
                    })}

                  </div>
                </div>
              </div>
            </div>
            <div>
             
            </div>

            <div className="main-percent rPercen justify-content-center"></div>
    
      </div>
    </>
  );
}

export default CancelReason;
