import { Tab } from "bootstrap";
import React, { useState } from "react";
import { Tabs } from "react-bootstrap";
import { Link, useParams ,useHistory } from "react-router-dom";
import CustomizedFareTab from "./CustomizedFareTab";
import FareSettingTab from "./FareSettingTab";

let FareConfigationDetailsTab = (props) => {
  let history = useHistory();
  let urlParams = useParams(),
    [tab, setTab] = useState(urlParams.tab),
    permissions = JSON.parse(atob(localStorage.getItem("access")));
  return (
    <div className="rider-listing">
        <span onClick={() => history.goBack()} style={{ cursor: "pointer" }}>
          <i className="fa fa-chevron-left back-arrow"></i>
        </span>
      <span className="title">Fare Calculations</span>
      <Tabs
        id="controlled-tab-example"
        className="mt-30"
        activeKey={tab}
        onSelect={(k) => {
          props.history.push(
            "/fare-conf/" + k + "/" + urlParams.id + props.location.search
          );
          setTab(k);
        }}
      >
        {permissions.find(a => a.includes('/fare-conf/standard')) && <Tab eventKey="standard" title="Standard Rates">
          {!urlParams.tab ||
            (urlParams.tab === "standard" && <FareSettingTab />)}
        </Tab>}
        {permissions.find(a => a.includes('/fare-conf/customized')) && <Tab eventKey="customized" title="Customized Rates">
          {urlParams.tab === "customized" && <CustomizedFareTab />}
        </Tab>}

      </Tabs>
    </div>
  );
};
export default React.memo(FareConfigationDetailsTab);
