import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Calendar4 } from "react-bootstrap-icons";
import { Calendar } from "primereact/calendar";
import Select from "react-select";
import APIService from "../../services/APIService";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { MultiSelect } from "primereact/multiselect";
import DeleteModal from "../Common/DeleteModal";
import ChangeStatusModal from "../Common/ChangeStatusModal";


const colourStyles = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? "#fff"
          : isFocused
            ? "white"
            : undefined,
      color: isDisabled ? "white" : isSelected ? "#13B542" : "#000000",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },
},
statusOptions = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
],
 receiverOptions =[
  {value:1,label:"Rider"},
  {value:2,label:"Driver"},
  {value:3,label:"Admin"},
  {value:4,label:"Other"}
],
  sendVia = [
    { label: 'Whatsapp', value: 'whatsapp' },
    { label: 'Email', value: 'email' },
    { label: 'SMS', value: 'sms' },
    { label: "In App", value: "push" },
  ],
  languageOptions = [
    { label: 'English', value: 'english' },
    { label: 'Urdu', value: 'urdu' },
    { label: 'Arabic', value: 'arabic' },
    { label: "Hindi", value: "hindi" },
  ];

let EmailNotificationListing = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize));
  let [templates, setTemplates] = useState(null);
  let [selectedNotifications, setSelectedNotifications] = useState(null);
  let [globalFilter, setGlobalFilter] = useState(null);
  let [selectedStatus, setSelectedStatus] = useState(null);
  let [show, setShow] = useState(false);
  let [showDelete, setShowDelete] = useState(false);
  let [showChange,setShowChange] = useState(false);
  let [isCalendarOpen, setIsCalendarOpen] = useState(false);
  let [showFilter, setShowFilter] = useState(false);
  let [selectedUserType, setSelectedUserType] = useState(null);
  let [date, setDate] = useState(null);
  let [multipleSelected, setMultipleSelected] = useState(null);
  let [language, setLanguage] = useState(null);
  let [rowData, setRowData] = useState(null);
  const [formValue, setFormValues] = useState(null);
  const [updateModal,setUpdateModal] = useState(false);
  const [lazyParams, setLazyParams] = useState({
    filters: {},
    sort: {
      field: "createdAt",
      order: -1,
    },
    take: 25,
    skip: 0,
    keyword: "",
    totalRecords: 0,
  });
  let [updateData, setUpdateData] = useState({
    name: '',
    content: '',
    usertype: '',
    title: '',
    sendViaValue: [],
    scheduleTime: '',
    language: []
  });
  let permissions = JSON.parse(atob(localStorage.getItem("access"))),
    currentRoutePermissions = permissions.filter(a => a.includes('/notification/notification-push/')),
    updatePermission = currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'update'),
    dt = useRef(null),
    receivers = ["Rider", "Driver", "Admin", "Other"],
    [receiversFilter, setReceiversFilter] = useState([]),
    [receiver, setReceiver] = useState(null),
    getTemplates = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.get("admin/templates/email")
        .then((response) => {
          let receiversData = [];
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          if (response.status === 200) {
            let notificationData = response.data.data.map((template) => {
              receiversData.push(receivers[template.receiver - 1]);
              template.status = template.status ? "Active" : "Inactive";
              template.receiver = receivers[template.receiver - 1];
              return template;
            });
            receiversData.length &&
              receiversData.sort(function (a, b) {
                return receivers.indexOf(a) - receivers.indexOf(b);
              });
            let unique = receiversData.filter(
              (item, i, ar) => ar.indexOf(item) === i
            );
            setReceiversFilter([...unique, ...receiversFilter]);
            setTemplates(notificationData);
          }
        })
        .catch(() => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    };


    useEffect(() => {
      getTemplates();
    }, []);

  let statuses = ["Active", "Inactive"],
    renderHeader = () => {
      return (
        <div className="table-header">
          <span>
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
                showFilter ? (
                  <>
                    <img src="/images/filter-icon.svg" alt="" />
                    <span className="span-filter mx-12">Filters</span>
                    <i className="fa fa-caret-up "></i>
                  </>
                ) : (
                  <>
                    <img src="/images/filter-icon.svg" alt="" />
                    <span className="span-filter mx-12">Filters</span>
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                        fill="#828282"
                      />
                    </svg>
                  </>
                )}
            </button>
          </span>
          <span className="p-input-icon-left w-100">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              onInput={(e) => setGlobalFilter(e.target.value)}
            />
          </span>
          {/* <span>
            <button onClick={() => setShow(true)} className="btn btn-primary float-right mb-1 ml-12"  >
              +Add
            </button>
          </span> */}
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      dt.current.reset();
    },
    validationSchema = Yup.object().shape({
      title: Yup.string().required("Please enter title"),
      usertype: Yup.string().required("Please select a userType"),
      content: Yup.string().required("Please enter content"),
      sendViaValue: Yup.array().min(1, "Select atleast one option"),
      language: Yup.array().min(1, "Select atleast one option"),
    }),
    actionTemplate = (rowData) => {
      return (
        <div className="button p-table-button">
          {updatePermission ? <button onClick={() => handleDelete(rowData)} className="btn btn-danger">Delete</button>
            : <span>No Permission</span>}
        </div>
      );
    },
    updateNotification = (e, id) => {
      APIService.patch(`admin/templates/push-status/${id}`, {
        status: e.target.checked,
      })
        .then((response) => {
          if (response && response.data.statusCode === 200) {
            toast.success("Updated Successfully");
          }
        })
        .catch((error) => {
          toast.error("Something went wrong.");
        });
    },
    activeActionTemplate = (rowData) => {
      return (
        <>
        {rowData.status=='Active'?
        <span className="status-text" style={{
          backgroundColor: '#13B542',cursor:'pointer'
        }} onClick={()=>handleChange(rowData)}>{rowData.status}</span>
        
        :<span className="status-text bg-danger" style={{cursor:'pointer'}} onClick={()=>handleChange(rowData)} >{rowData.status}</span>
        
        }
        </>
      );
    },
    calendarRef = useRef(null),
    handleCalendarHide = () => setIsCalendarOpen(false),
    handleClose = () => setShow(false),
    handleDelete = (rowData) => {
      setRowData(rowData)
      setShowDelete(true)
    },
    handleChange = (rowData)=>{
      setRowData(rowData)
      setShowChange(true);
    },
    handleCloseChange=()=>setShowChange(false),
    handleCloseDelete = () => setShowDelete(false),
    handleSubmit = (fields, props) => {
    },
    removeNotification = () => {
      APIService.delete(`admin/templates/email/${rowData.id}`)
        .then((response) => {
          if (response.data.statusCode === 200) {
          
              getTemplates();
          
              toast.success("Updated Successfully");
            }
          })
          .catch((error) => {
            toast.error("Something went wrong.");
          });
    },
    changeStatus = ()=>{
      APIService.patch(`admin/templates/email-status/${rowData.id}`, {
        status:rowData.status=='Active'?false:true
      })
        .then((response) => {
          if (response.data.statusCode === 200) {
            getTemplates();
            toast.success("Updated Successfully");
          }
        })
        .catch((error) => {
          toast.error("Something went wrong.");
        });
      

    }

        // notification updated data 
        const renderNotification=(rowData)=>{
          const handleUpdateClick = () => {
            setFormValues({
              id: rowData.id,
              name: rowData?.templateName,
              arabicName: rowData?.templateNameArabic,
              title: rowData?.subject,
              arabicTitle: rowData?.subjectArabic,
              message: rowData?.body,
              arabicMessage:rowData?.bodyArabic,
              receiver: rowData?.receiver,
              status: rowData?.status,
            });
            setUpdateModal(true);
          };
          return(
            <button className="btn btn-success" onClick={handleUpdateClick}>Update</button>
          )
        } 
    
        // update modal properties 
        const onHide = () => {
          // setModalValues(initialModal)
          setUpdateModal(false)
        }
    
        const handleUpdate = async (values) => {
          console.log('email value ',values)
          setUpdateModal(false);
    
          APIService.patch(`admin/templates/email/${values.id}`, values)
            .then((response) => {
              if (response.data.statusCode === 200) {
                getTemplates();
                toast.success("Updated Successfully");
              }
            })
            .catch((error) => {
              toast.error("Something went wrong.");
            });
    
        }

        const getStatusValue = (statusLabel) => {
          const statusValue =  statusOptions.find((option) => option.label === statusLabel)?.value;
          return statusValue
        };
        
        const getReceiverValue = (receiverLabel) => {
          const receiverValue = receiverOptions.find((option) => option.label === receiverLabel)?.value;
          return receiverValue
        };
       
    

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={templates}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          // globalFilter={globalFilter}
          // selection={selectedNotifications}
          // onSelectionChange={(e) => setSelectedNotifications(e.value)}
          paginator
          rows={25}
          emptyMessage="No Notification(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          rowsPerPageOptions={[10, 25, 50]}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            style={{ fontSize: "12px", width: "270px" }}
            header="Title"
            field="templateName"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by title"
          />
          <Column
            style={{ fontSize: "12px", width: "300px" }}
            header="Content"
            field="message"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by content"
          />
          <Column
            style={{ fontSize: "12px", width: "120px" }}
            header="Receiver"
            field="receiver"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by receiver"
          // filterElement={
          //   <Dropdown
          //     value={receiver}
          //     options={receiversFilter}
          //     onChange={(e) => {
          //       dt.current.filter(e.value, "receiver", "equals");
          //       setReceiver(e.value);
          //     }}
          //     placeholder="Select a Receiver"
          //     className="p-column-filter"
          //     showClear
          //   />
          // }
          />
          <Column
            style={{ fontSize: "12px", width: "300px" }}
            header="Channel"
            field="channel"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by status"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Status"
            field="status"
            sortable
            filter={showFilter}
            filterElement={
              <Dropdown
                value={selectedStatus}
                options={statuses}
                onChange={(e) => {
                  dt.current.filter(e.value, "status", "equals");
                  setSelectedStatus(e.value);
                }}
                placeholder="Status"
                className="p-column-filter"
                showClear
              />
            }
            body={activeActionTemplate}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            header="Action"
            headerClassName="text-center"
            body={renderNotification}
            sortable
          />
          {/* <Column
            className="text-center"
            style={{ fontSize: "12px", width: "100px" }}
            header="Action"
            headerClassName="text-center"
            body={actionTemplate}
            sortable
          /> */}
        </DataTable>
      </div>
      <Modal
        className="modal-promotion"
        show={show}
        size="lg"
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Formik
          initialValues={{ ...updateData }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({ handleChange, handleBlur, setFieldValue, values }) => {
            return (
              <Form>
                <Modal.Header>
                  <Modal.Title className="modal-title">
                    Add/Update Notification
                  </Modal.Title>
                  <div className="divclose" onClick={handleClose}>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body className="mt-24">
                  <Row>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="title"
                          type="text"
                          name="title"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Title"
                          onChange={(e) => handleChange('title')(e.target.value.trimStart())}
                        // value={title}
                        // onMouseDown={e=>valchange(true)}
                        // onChange={(e) => setFieldValue("title", e.value)}
                        />
                        <label htmlFor="title">Title</label>
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col className="field" md={6}>
                      <span className="p-float-label">
                        <MultiSelect
                          name="language"
                          placeholder="Select Language"
                          className="shadow-none height-60"
                          value={language}
                          options={languageOptions}
                          onChange={(e) => {
                            setFieldValue("language", e.value)
                            setLanguage(e.value)
                          }}
                          onBlur={() => {
                            handleBlur({ target: { name: "language" } });
                          }}
                        />
                        <label htmlFor="language">Select Language</label>
                        <ErrorMessage
                          name="language"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </span>
                    </Col>
                    <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Target Reciever"
                          // isClearable
                          name="usertype"
                          styles={colourStyles}
                          value={selectedUserType}
                          onBlur={() => {
                            handleBlur({ target: { name: "usertype" } });
                          }}
                          onChange={(e) => {
                            if (e) {
                              setFieldValue("usertype", e.value);
                              setSelectedUserType(e);
                            }
                          }}
                          options={receiverOptions}
                        />
                        <label>Target Reciever</label>
                        <ErrorMessage
                          name="usertype"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col className="field" md={6}>
                      <span className="p-float-label">
                        <MultiSelect
                          name="sendViaValue"
                          placeholder="Channel"
                          className="shadow-none height-60"
                          value={multipleSelected}
                          options={sendVia}
                          onChange={(e) => {
                            setFieldValue("sendViaValue", e.value)
                            setMultipleSelected(e.value)
                          }}
                          onBlur={() => {
                            handleBlur({ target: { name: "sendViaValue" } });
                          }}
                        />
                        <label htmlFor="sendViaValue">Channel</label>
                        <ErrorMessage
                          name="sendViaValue"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </span>
                    </Col>
                    <Col md={6}>
                      <div className="mb-4 form-group form-label-group">
                        <Calendar
                          appendTo={null}
                          minDate={new Date()}
                          showOnFocus={isCalendarOpen}
                          onHide={handleCalendarHide}
                          maxDate={values.expiredAt ? values.expiredAt : ""}
                          placeholder="Date & Time"
                          onBlur={() => {
                            handleBlur({ target: { name: "scheduleTime" } });
                          }}
                          value={updateData?.scheduleTime?.length > 0 ? updateData?.scheduleTime : date}
                          onChange={(e) => {
                            if (e) {
                              setFieldValue("scheduleTime", e.value);
                              setDate(e?.value)
                            }
                          }}
                          extendsTo={null}
                          ref={calendarRef}
                          onFocus={() => setTimeout(() => {
                            calendarRef.current.showOverlay()
                          }, 100)}
                          name="scheduleTime"
                          dateFormat="dd/mm/yy"
                          className="p-column-filter"
                          showTime
                          hourFormat="12"
                        />
                        <span className="input-box-icon">
                          <Calendar4 />
                        </span>
                        <ErrorMessage
                          name="scheduleTime"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="content"
                          type="text"
                          as="textarea"
                          name="content"
                          className="shadow-none form-control modal-input br-10 height-60"
                          placeholder="Content"
                          onChange={(e) => handleChange('content')(e.target.value.trimStart())}
                        //  onMouseDown={e=>valchange(true)}
                        />
                        <label htmlFor="content">Content</label>

                        <ErrorMessage
                          name="content"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>  
                    <Row
                      className="justify-content-center">
                      <Col md={6}>
                        <Button
                          variant="primary"
                          type="submit"
                          className="btn-save "
                        >
                          Send
                        </Button>
                      </Col>
                    </Row>
                  </Row>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>
      <DeleteModal
        clickYes={removeNotification}
        showDelete={showDelete}
        handleCloseDelete={handleCloseDelete}
      />
      <ChangeStatusModal
      clickYes={changeStatus}
      showChange ={showChange}
      handleCloseStatus={handleCloseChange}
      />
       {/* update notifications modal  */}
       <Modal show={updateModal} onHide={() => setUpdateModal(false)} size="lg" centered>
  <Modal.Header>
    <Modal.Title className="modal-title mb-4">Update Notification</Modal.Title>
    <div className="divclose" onClick={onHide}>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </Modal.Header>
  <Modal.Body>
    <Formik
      initialValues={{
        id:formValue?.id,
        name: formValue?.name,
        arabicName: formValue?.arabicName,
        title: formValue?.title,
        arabicTitle: formValue?.arabicTitle,
        message: formValue?.message,
        arabicMessage: formValue?.arabicMessage,
        receiver: getReceiverValue(formValue?.receiver),
        status: getStatusValue(formValue?.status), 
      }}
      validationSchema={Yup.object({
        name: Yup.string()
        .required('Name is required'),
      arabicName: Yup.string()
        .required('Name (Arabic) is required'),
      title: Yup.string()
        .required('Title is required'),
      arabicTitle: Yup.string()
        .required('Title (Arabic) is required'),
      message: Yup.string()
        .required('Message is required'),
      arabicMessage: Yup.string()
        .required('Message (Arabic) is required'),
      receiver: Yup.string()
        .required('Receiver is required'),
      status: Yup.string()
        .required('Status is required'),
      })}
      onSubmit={(values) => {
        const updatedFields = {
          templateName: values.name,
          subject: values?.title,
          body: values?.message,
          id:values.id,
        };

        if (values?.arabicName !== formValue?.arabicName) {
          updatedFields.templateNameArabic = values?.arabicName;
        }

        if (values?.arabicTitle !== formValue?.arabicTitle) {
          updatedFields.subjectArabic = values?.arabicTitle;
        } 

        if (values?.arabicMessage !== formValue?.arabicMessage) {
          updatedFields.bodyArabic = values?.arabicMessage;
        } 

  const receiverValue = getReceiverValue(formValue?.receiver)
  const statusValue = getStatusValue(formValue?.status)
  if (values.receiver !== receiverValue) {
    updatedFields.receiver = values.receiver;
  }
  if (values.status !== statusValue) {
    updatedFields.status = values.status;
  }

  handleUpdate(updatedFields);
      }}
    >
      {(formik) => (
        <Form>
          <Row>
            <Col md={6}>
            <div className="form-group form-label-group">
                          <Field
                            id="name"
                            type="text"
                            name="name"
                            className="shadow-none form-control modal-input br-10"
                            
                          />
                          <label htmlFor="name">Template Name</label>
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
            </Col>

            <Col md={6}>
            <div className="form-group form-label-group">
                          <Field
                            id="arabicName"
                            type="text"
                            name="arabicName"
                            className="shadow-none form-control modal-input br-10"
                            
                          />
                          <label htmlFor="arabicName">Template Name (Arabic)</label>
                          <ErrorMessage
                            name="arabicName"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
            </Col>
          </Row>
          <Row>
          <Col md={6}>
            <div className="form-group form-label-group">
                          <Field
                            id="title"
                            type="text"
                            name="title"
                            className="shadow-none form-control modal-input br-10"
                            
                          />
                          <label htmlFor="title">Email Subject</label>
                          <ErrorMessage
                            name="title"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
            </Col>
            <Col md={6}>
            <div className="form-group form-label-group">
                          <Field
                            id="arabicTitle"
                            type="text"
                            name="arabicTitle"
                            className="shadow-none form-control modal-input br-10"
                            
                          />
                          <label htmlFor="arabicTitle">Email Subject (Arabic)</label>
                          <ErrorMessage
                            name="arabicTitle"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
            </Col>
          </Row>
          <Row>
          <Col md={6}>
            <div className="form-group form-label-group">
                          <Field
                          as="textarea"
                            id="message"
                            type="text"
                            name="message"
                            className="shadow-none form-control modal-input br-10"
                            
                          />
                          <label htmlFor="message">Content</label>
                          <ErrorMessage
                            name="message"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
            </Col>
            <Col md={6}>
            <div className="form-group form-label-group">
                          <Field
                          as="textarea"
                            id="arabicMessage"
                            type="text"
                            name="arabicMessage"
                            className="shadow-none form-control modal-input br-10"
                            
                          />
                          <label htmlFor="arabicMessage">Content (Arabic)</label>
                          <ErrorMessage
                            name="arabicMessage"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
            </Col>
          </Row>

          <Row>
          <Col md={6}>
              <div className="mb-4 form-label-group">
                <label htmlFor="receiver">Receiver</label>
                <Select
                  id="receiver"
                  name="receiver"
                  className="height-50 br-10 bg-color-select"
                  options={receiverOptions}
                  placeholder="Select a Receiver"
                  value={receiverOptions.find(option => option.value === formik.values.receiver)}
                  onChange={(selectedOption) => {
                    formik.setFieldValue('receiver', selectedOption.value);
                  }}
                />
                <ErrorMessage
                  name="receiver"
                  component="div"
                  className="invalid-feedback text-danger"
                />
              </div>
            </Col>

            <Col md={6}>
              <div className="mb-4 form-label-group">
                <label htmlFor="status">Status</label>
                <Select
                  id="status"
                  name="status"
                  className="height-50 br-10 bg-color-select"
                  options={statusOptions}
                  placeholder="Select a status"
                  value={statusOptions.find(option => option.value === formik.values.status)}
                  onChange={(selectedOption) => {
                    formik.setFieldValue('status', selectedOption.value);
                  }}
                />
                <ErrorMessage
                  name="status"
                  component="div"
                  className="invalid-feedback text-danger"
                />
              </div>
            </Col>
          </Row>
          <Row>
          <Col md={12}>
              <button
                className="btn btn-success "
                style={{ height: '58px',width:'100%' }}
                type="submit"
              >
                Update
              </button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  </Modal.Body>
</Modal>
    </div>
  );
};

export default React.memo(connect()(EmailNotificationListing));
