import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import APIService from "../../services/APIService";
import * as Yup from "yup";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import moment from "moment";
const list = [
  {
    transactionId: 1242728227,
    amount: 10,
    method: 'Card (**** 6007)',
    category: 'Captain',
    username: 'John Jacob',
    userId: 132681022,
  },
  {
    transactionId: 1242728227,
    amount: 10,
    method: 'Card (**** 6007)',
    category: 'Captain',
    username: 'John Jacob',
    userId: 132681022,
  },
  {
    transactionId: 1242728227,
    amount: 10,
    method: 'Card (**** 6007)',
    category: 'Captain',
    username: 'John Jacob',
    userId: 132681022,
  },
  {
    transactionId: 1242728227,
    amount: 10,
    method: 'Card (**** 6007)',
    category: 'Captain',
    username: 'John Jacob',
    userId: 132681022,
  },
  {
    transactionId: 1242728227,
    amount: 10,
    method: 'Card (**** 6007)',
    category: 'Captain',
    username: 'John Jacob',
    userId: 132681022,
  }
]
let Refunds = (props) => {
  let [refundList, setRefundList] = useState([]),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [show, setShow] = useState(false),
    [showFilter, setShowFilter] = useState(false),
    [initialValues, setInitialValues] = useState({
      transactionNo: null,
      maximum: null,
      minimum: null,
      amount: null,
    }),
    dt = useRef(null),
    [lazyParams, setLazyParams] = useState({
      filters: {
        entityType: 4,
      },
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalCount: 0,
    }),
    getRefundList = () => {
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      // setRefundList(list)
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.post("admin/user-transactions", filterParams)
        .then((response) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          if (response.status === 201) {
            lazyParams.totalCount = response.data.data.totalCount;
            setLazyParams(lazyParams);
            // setTotalEarning(response.data.data.totalEarnings.toFixed(2));
            setRefundList(response.data.data.transactions);
          }
        })
        .catch(() =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    };
  useEffect(() => {
    getRefundList();
  }, []);
  let renderHeader = () => {
    return (
      <div className="table-header">
        <span className="text-nowrap">
          <button
            className="btn btn-primary show_filter_btn"
            onClick={() => toggleFilter()}
          >
            {windowWidth ? (
              showFilter ? (
                <span>
                  <i className="fa fa-filter"></i>
                  <i className="fa fa-caret-up ml-10"></i>
                </span>
              ) : (
                <span>
                  <i className="fa fa-filter"></i>
                  <i className="fa fa-caret-down ml-10"></i>
                </span>
              )
            ) : // <i className="fa fa-filter"></i>
              showFilter ? (
                <>
                  <img src="/images/filter-icon.svg" alt="" srcSet="" />
                  <span className="span-filter mx-12">Filters</span>
                  <i className="fa fa-caret-up  "></i>
                </>
              ) : (
                <>
                  <img src="/images/filter-icon.svg" alt="" srcSet="" />
                  <span className="span-filter mx-12">Filters</span>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                      fill="#828282"
                    />
                  </svg>
                </>
              )}
          </button>
        </span>
        <span className="p-input-icon-left text-nowrap">
          <i className="pi pi-search" />
          <InputText
            type="search"
            placeholder="Search"
            onInput={(e) => {
              lazyParams = {
                ...lazyParams,
                skip: 0,
                take: 25,
                keyword: e.target.value,
              };
              setLazyParams(lazyParams);
              getRefundList();
            }}
          />
        </span>
        <span className="text-nowrap">
          <button className="btn btn-primary float-right mb-1 ml-12">Export Report </button>
        </span>
        <span className="text-nowrap">
          <button onClick={() => setShow(true)} className="btn btn-primary float-right mb-1 ml-12">Initiate Refund </button>
        </span>
      </div>
    );
  },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalCount: 0,
      });
      getRefundList();
    },
    manageRefund = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getRefundList();
    },
    actionTemplate = (rowData) => {
      return (
        <div className="button p-table-button">
          <button className="btn btn-success">Refund</button>
        </div>
      );
    },
    handleShow = () => setShow(false),
    modalData = [
      { label: 'Maximum', value: 'maximum' },
      { label: 'Minimum', value: 'minimum' },
      { label: 'Amount', value: 'amount' },
    ],
    handleSubmit = (fields, props) => {
      console.log("submit:", fields)
    },
    validationSchema = Yup.object().shape({
      transactionNo: Yup.number().required("Please enter transaction id"),
      maximum: Yup.number().required("Please select a maxinum"),
      minimum: Yup.number().required("Please enter minimum"),
      amount: Yup.number().required("Please enter amount"),
    });
    const limitizeId = (data) => {
      let result = data.slice(0, 8);
      return result;
    };
    function getStatusText(source) {
      switch (source) {
        case source:
          return 'Internal Wallet';
        case source:
          return 'Click Pay';
        case source:
          return 'Tabby Pay';
        case source:
          return 'Tamara Pay';
        case source:
          return 'Tap Payment';
        case source:
          return 'Promotion';
        default:
          return 'Unknown';
      }
    };
    function getStatus(number) {
      const statusDict = {
        1: 'PENDING',
        2: 'COMPLETED',
        3: 'CANCELLED',
        4: 'REFUNDED',
        5: 'FAILED'
      };
    
      return statusDict[number] || 'Invalid Status';
    }
  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={refundList}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Topup found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          lazy={true}
          totalRecords={lazyParams.totalCount}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageRefund}
          onSort={manageRefund}
          onFilter={manageRefund}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="createdAt"
            header="Created At"
            sortable
            body={(d) => (
              <span>{moment(d.createdAt).format("YYYY-MM-DD")}</span>
            )}
            filter={showFilter}
            filterPlaceholder="Search by amount"
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="entityId"
            header="Entity Id"
            body={(d) => <span>{d.entityId}</span>}
            sortable
          />

          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            field="sourceRef"
            header="Source Ref"
            body={(d) => <span>{d.sourceRef}</span>}
            sortable
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            field="source"
            body={(d) => <span>{`${getStatusText(d.source)}`}</span>}
            header="Source"
            sortable
          />

          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            field="receiverId"
            header="Receiver Id"
            body={(d) => (
              <span>
                {" "}
                <Link
                  to={`/rider-details/details/${d.receiverId}/ticket`.trim()}
                  className="dataFnt-size"
                  style={{ color: "rgba(19, 181, 66, 1)" }}
                >
                  <span className="dataFnt-size" style={{ color: "#13B542" }}>
                    {d.receiverId}
                  </span>
                </Link>
              </span>
            )}
          />

          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="transactionAmount"
            header="Transaction Amount"
            sortable
            body={(d) => <span>{`${d.transactionAmount} SAR`}</span>}
            filter={showFilter}
            filterPlaceholder="Search by amount"
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="status"
            header="Status"
            sortable
            body={(d) => <span>{`${getStatus(d.status)}`}</span>}
            filter={showFilter}
            filterPlaceholder="Search by amount"
          />
        </DataTable>
      </div>
      <Modal show={show} onHide={handleShow} centered size="md">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => {
            return (
              <Form>
                <Modal.Header>
                  <Modal.Title className="modal-title">
                    Initiate Refund
                  </Modal.Title>
                  <div className="divclose" onClick={handleShow}>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body className="mt-24">
                  <Row>
                    <Col md={12}>
                      <div className="form-group form-label-group">
                        <Field
                          id="transactionNo"
                          type="Number"
                          name="transactionNo"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Transaction No."
                          onChange={(e) =>
                            setFieldValue("transactionNo", e.target.value)
                          }
                        />
                        <label htmlFor="transactionNo">Transaction No.</label>
                        <ErrorMessage
                          name="transactionNo"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    {modalData.map((d) => (
                      <Col md={12}>
                        <div className="form-group form-label-group position-relative">
                          <Field
                            id={d.value}
                            type="Number"
                            name={d.value}
                            className="shadow-none form-control modal-input br-10"
                            placeholder={d.label}
                            onChange={(e) =>
                              setFieldValue(d.value, e.target.value)
                            }
                          />
                          <label htmlFor={d.value}>{d.label}</label>
                          <span
                            className="position-absolute top-0 end-0 p-2 rounded font-weight-bold"
                            style={{
                              marginTop: "10px",
                              color: "#13B542",
                              background: "#E3F6E8",
                              marginRight: "10px",
                            }}
                          >
                            SAR.
                          </span>
                          <ErrorMessage
                            name={d.value}
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                    ))}
                    <Row>
                      <Col md={12}>
                        <Button
                          variant="primary"
                          type="submit"
                          className="btn-save "
                        >
                          Refund
                        </Button>
                      </Col>
                    </Row>
                  </Row>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

export default React.memo(connect()(Refunds));
