import { Col, Row } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import Select, { StylesConfig } from "react-select";
import { _baseURL } from "../services/APIService";
import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import APIService from "../services/APIService";
import { ProgressSpinner } from 'primereact/progressspinner';




const colourStyles: StylesConfig<ColourOption, true> = {
    // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? "#ffffff"
            : isFocused
              ? "white"
              : undefined,
        color: isDisabled ? "white" : isSelected ? "#28a745" : "#000000",
        cursor: isDisabled ? "not-allowed" : "default",
  
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : "white"
            : undefined,
        },
      };
    },
  };
export const Admins =() =>{
  const secret_key = process.env.REACT_APP_SECRET_KEY;
  const _saveEmployee = _baseURL + "/api/v1/employee/save?secret_key=" + secret_key;

  const [admins, setAdmins] = useState([]);
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [usersData, setUsersData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [saveNmore, setSaveNmore] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [roles, setRoles] = useState([]);
  const [savedUser, setSavedUser] = useState([]);
  const [adminName, setAdminName] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [updateData, setUpdateData] = useState({
    email: '',
    fullName: '',
    status: true,
    role: '',
  });
   


  useEffect(() => {
    getOnlineStatus();
    const intervalId = setInterval(getOnlineStatus, 10000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    try {
      const response = await APIService.get('admin/role');
      if (response.status === 200) {
        const roles = response.data.data
          .filter((role) => role.status)
          .map((role) => ({
            value: role.id,
            label: selectedRole?.value === role.id ? `${role.title} ✔` : role.title,
          }));
        setRoles(roles);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const createOrUpdateAdmin = async () => {
    try {
      const onlyUser = {
        fullName: adminName,
        email: adminEmail,
        role: selectedRole?.value,
        country: selectedCountry,
        status: selectedStatus?.value,
      };

      const response = !updateData || !updateData.id
        ? await APIService.post('admin/subadmin', savedUser.length === 0 ? onlyUser : savedUser[0])
        : await APIService.patch('admin/subadmin/' + updateData.id, savedUser[0]);

      if (response.data.statusCode === 200) {
        toast.success(updateData.id ? 'Admin updated successfully' : 'Admin created successfully');
        if (!updateData.id) {
          saveEmployee(response?.data?.data?.id);
          setShow(false);
        }
        if (!saveNmore) {
          handleClose();
        }
      } else {
        toast.error(updateData.id ? 'Failed to update Admin' : 'Failed to create Admin');
      }
      emptyStates();
    } catch (error) {
      console.error('Error creating/updating admin:', error);
    }
  };

  const handleClose = () => {
    setShow(false);
    emptyStates();
  };

  const saveAndAddMore = () => {
    setSaveNmore(true);
    setSavedUser([
      ...savedUser,
      {
        fullName: adminName,
        email: adminEmail,
        role: selectedRole.value,
        country: selectedCountry.value,
        status: selectedStatus.value,
      },
    ]);
    emptyStates();
  };

  const saveEmployee = async (dep_id) => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          name: adminName,
          secret_key,
          company_id: 1,
          email: adminEmail,
          subdepartment_id: 5,
          country: selectedCountry?.value,
          status: selectedStatus?.value,
          id: dep_id,
        }),
      };
      const response = await fetch(_saveEmployee, requestOptions);
      const final_data = await response.json();
    } catch (error) {
      console.error('Error saving employee:', error);
    }
    finally{
getOnlineStatus();
    }
  };

  const emptyStates = () => {
    setAdminName('');
    setAdminEmail('');
    setSelectedRole(null);
    setSelectedCountry(null);
    getRoles();
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter email in proper format e.g. johndoes@hotmail.com')
      .required('Please enter your registered email'),
    fullName: Yup.string().required('Please enter name'),
    role: Yup.string().nullable().required('Select Role'),
    status: Yup.string().required('Select Status'),
  });

  const statusOptions = [	
    { value: true, label: "Active" },	
    { value: false, label: "Inactive" },	
  ];
  
  const compareId = (userId) => {
    const idMappings = {
      'd74ae344-001d-11ec-9a03-0242ac130003': 'Dispatcher Admin',
      'd74ae222-001d-11ec-9a03-0242ac130003': 'Emergency Admin',
      'd74ae40c-001d-11ec-9a03-0242ac130003': 'Sub Admin',
      'd74adb1a-001d-11ec-9a03-0242ac130003': 'Admin',
      'd74adb1a-001d-11ec-9a03-0242ac130004': 'Admin',
    };
    return idMappings[userId] || '-';
  };

  const compareStatus = (stats) => (stats ? 'Active' : 'InActive');

  const handleCloseUpdate = () => setShowUpdate(false);

  const getAdmins = async () => {
    try {
      const response = await APIService.post('admin/subadmin/list', {
        limit: 100,
        offset: 0,
        sortBy: 'fullName',
        sortType: 'asc',
      });

      if (response.data && response.data.statusCode === 200) {
        const admins = response.data.data.results
          .map((admin) => {
            admin.status = admin.status ? 'Active' : 'Inactive';
            admin.roleName = admin.role?.title || '-';
            admin.roleId = admin.role?.id || '-';
            return admin;
          })
          .sort((admin, admin2) => new Date(admin2.createdAt) - new Date(admin.createdAt));

        setAdmins(admins);
      }
    } catch (error) {
      console.error('Error fetching admins:', error);
    }
  };

  const countryOptions = [{ value: 'Saudi Arabia', label: 'Saudi Arabia' }];

  const removeFun = (index) => {	
    const filteredUsers = savedUser.filter((user, idx) => {	
      return idx !== index	
    })	
    setSavedUser(filteredUsers)	
  };

  const getOnlineStatus = async () => {
    try {
      setLoading(true);
      const response = await APIService.post('admin/subadmin/list');

      if (response?.data?.statusCode === 200) {
        const now = new Date();
        const nowUTC = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
        const UpdatedUsersData = response?.data?.data?.results?.map((user) => {
          if (user.lastAccessedAt) {
            const lastAccessedTime = new Date(user.lastAccessedAt);
            const lastAccessedTimeUTC = new Date(lastAccessedTime.getTime() + lastAccessedTime.getTimezoneOffset() * 60000);
            const timeDifferenceInSeconds = (nowUTC - lastAccessedTimeUTC) / 1000;

            user.onlineStatus = timeDifferenceInSeconds < 120;
          } else {
            user.onlineStatus = false;
          }

          return user;
        });

        setUsersData(UpdatedUsersData);
      }
    } catch (error) {
      console.error('Error fetching online status:', error);
    }finally{
        setLoading(false);
    }
  };
  
    return(<>
    <div className="col-12 bottomSpacer">
  <div className="row">
    {/* <Cards topCard={topCard} /> */}
    <div className="mainDiv">
      {usersData?.map((item, idx) => (
        <div key={idx} className="imgBox mainBox flex-col">
          <div>
            <div className={item?.onlineStatus ? "greenDot" : "greyDot"}></div>
            <img
              width={48}
              height={48}
              src={
                item?.profileImage
                  ? `https://objectstorage.me-jeddah-1.oraclecloud.com/n/axhzhj71rohd/b/bucket-20221107-1745/o/admin/${item?.profileImage}`
                  : "/images/profile.svg"
              }
              alt=""
            />
          </div>
          <div className="overflowElipsis">
            <span className="font-weight-bold" title={item?.fullName}>{item?.fullName}</span>
          </div>
        </div>
      ))}
      <div
        onClick={() => setShow(true)}
        className="addBox mainBox flex-col"
        style={{ cursor: 'pointer' }}
      >
        <div className="plusSign" style={{ color: '#13B542' }}>
          +
        </div>
        <span style={{ color: '#13B542' }} className="font-weight-bold">
          Add New
        </span>
      </div>
    </div>
  </div>
</div>

        <Modal show={show} onHide={handleClose} centered size="lg">
        <Formik
          initialValues={{ ...updateData }}
          validationSchema={validationSchema}
          onSubmit={createOrUpdateAdmin}
        >
          {({ handleBlur, setFieldValue }) => {
            return (
              <Form>
                <Modal.Header>
                  <Modal.Title className="modal-title">
                    Add New User
                  </Modal.Title>
                  <div className="divclose" onClick={handleClose}>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body className="mt-24">
                  <Row className="row">
                    {(savedUser.length !== 0) && <div>
                      <Col md={12}>
                        <h3 className="modal-sub-heading">Saved User</h3>
                      </Col>
                      {savedUser.map((user, index) => {
                        console.log(user)
                        return (
                          <Col md={12} className="user-block-height">
                            <div className="user-block">
                              <div className="d-flex justify-content-between block-container">
                                <div>
                                  <p className="user-block-heading">Name</p>
                                  <p className="user-block-content">{user.fullName}</p>
                                </div>
                                <div>
                                  <p className="user-block-heading">
                                    Email Address
                                  </p>
                                  <p className="user-block-content">
                                    {user.email}
                                  </p>
                                </div>
                                <div>
                                  <p className="user-block-heading">Role</p>
                                  <p className="user-block-content">
                                    {compareId(user.role)}
                                  </p>
                                </div>
                                <div>
                                  <p className="user-block-heading">Country</p>
                                  <p className="user-block-content">{user.country}</p>
                                </div>
                                <div>
                                  <p className="user-block-heading">Status</p>
                                  <p className="user-block-content">{compareStatus(user.status)}</p>
                                </div>
                              </div>
                            </div>
                            <div className="text-right w-100">
                              <div onClick={() => removeFun(index)} style={{ color: "red", cursor: "pointer" }} className="text-decoration-underline">Remove</div>
                            </div>
                          </Col>
                        )
                      })}

                    </div>}
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="fullName"
                          type="text"
                          name="fullName"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="name"
                          value={adminName}
                          onChange={(e) => {
                            setAdminName(e.target.value)
                            setFieldValue("fullName", e.target?.value)
                          }}
                        />
                        <label htmlFor="fullName">Name</label>
                        <ErrorMessage
                          name="fullName"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="email"
                          type="email"
                          name="email"
                          maxlength="60"
                          className="shadow-none form-control modal-input br-10 "
                          placeholder="email"
                          value={adminEmail}
                          onChange={(e) => {
                            setAdminEmail(e.target.value);
                            setFieldValue("email", e.target?.value)
                          }
                          }
                        />
                        <label htmlFor="email">Email</label>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Select Role"
                          name="role"
                          styles={colourStyles}
                          onBlur={() => {
                            handleBlur({ target: { name: "role" } });
                          }}
                          onChange={(e) => {
                            console.log("ee", e)
                            let selectLabel = e.label
                            // let selectLabel =
                            //   e.label.props.children[0].props.children.replace(
                            //     "✔",
                            //     ""
                            //   );
                            if (e) {
                              console.log(e)
                              setFieldValue("role", e.value);
                              setSelectedRole({
                                label: selectLabel,
                                value: e.value,
                              });
                              getRoles(e);
                            }
                          }}
                          options={roles}
                          value={selectedRole}
                        />
                        <label htmlFor="">Role</label>
                        <ErrorMessage
                          name="role"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Select Country"
                          // isClearable
                          name="country"
                          styles={colourStyles}
                          id="country"
                          onBlur={() => {
                            handleBlur({ target: { name: "country" } });
                          }}
                          onChange={(e) => {
                            if (e) {
                              setFieldValue("country", e.value);
                              setSelectedCountry(e);
                            }
                          }}
                          value={selectedCountry}
                          options={countryOptions}
                        />
                        <label htmlFor="">Country</label>
                        {(!selectedCountry || !selectedCountry?.value) && (
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Select Status"
                          // isClearable
                          name="status"
                          styles={colourStyles}
                          id="status"
                          onBlur={() => {
                            handleBlur({ target: { name: "status" } });
                          }}
                          onChange={(e) => {
                            if (e) {
                              setFieldValue("status", e.value);
                              setSelectedStatus(e);
                            }
                          }}
                          value={selectedStatus}
                          options={statusOptions}
                        />
                        <label htmlFor="">Status</label>
                        {(!selectedStatus || !selectedStatus?.value) && (
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        )}
                      </div>
                    </Col>
                    <Col md={12} className="d-flex">
                      <Col md={6}>
                        <Button
                          variant="success"
                          // type="submit"
                          className="btn-save save-button mb-3 btn btn-block"
                          onClick={saveAndAddMore}
                        >
                          Save & Add more
                        </Button>
                      </Col>
                      <Col md={6}>
                        <Button
                          variant="success"
                          className="btn-save save-button mb-3 btn  btn-block"
                          // type="submit"
                          onClick={createOrUpdateAdmin}
                        >
                          Save
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>
      </>
    )
}
export default Admins;
