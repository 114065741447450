import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { connect, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { CalendarRange } from "react-bootstrap-icons";
import moment from "moment";
import { Link } from "react-router-dom";
import retry from "../../src/components/Dashboard_2/images/retry.svg";
import APIService from "../services/APIService";
// import InfoCard from "../../InfoCard";

let Tabby = (props) => {
  const tab = window.location.pathname.split('/')[2]
  let [earnings, setEarnings] = useState([]),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [showFilter, setShowFilter] = useState(false),
    [totalEarning, setTotalEarning] = useState(0),
    // [totalServiceTax, setTotalServiceTax] = useState(0),
    [createdAt, setCreatedAt] = useState(null),
    [isCalendarOpen, setIsCalendarOpen] = useState(false),
    [loading, setLoading] = useState(false),
    [taxId, setTaxId] = useState(''),
    [status, setStatus] = useState(null),
    dt = useRef(null),
    [lazyParams, setLazyParams] = useState({
      filters: {
        entityType: 1,
      },
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalCount: 0,
    }),

    getEarnings = () => {
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.post("admin/accounting-tabby-transactions", filterParams)
        .then((response) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          if ((response.status === 201 || response.status == 200) ) {
            lazyParams.totalCount = response.data.data?.length;
            setLazyParams(lazyParams);
            // setTotalEarning(response.data.data.totalEarnings.toFixed(2));
            setEarnings(response.data.data);
          }
        })
        .catch(() =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    };
    console.log('earnings',earnings)
  useEffect(() => {
    getEarnings();
  }, [JSON.stringify(earnings)]);

  const spinIcon = () => {
    return (
      <div style={{ width: '1.2rem', height: '1.2rem' }} class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    )
  };

  let renderHeader = () => {
    return (
      <div className="table-header">
        <span>
          <button
            className="btn btn-primary show_filter_btn"
            onClick={() => toggleFilter()}
          >
            {windowWidth ? (
              showFilter ? (
                <span>
                  <i className="fa fa-filter"></i>
                  <i className="fa fa-caret-up ml-10"></i>
                </span>
              ) : (
                <span>
                  <i className="fa fa-filter"></i>
                  <i className="fa fa-caret-down ml-10"></i>
                </span>
              )
            ) : // <i className="fa fa-filter"></i>
              showFilter ? (
                <>
                  <img src="/images/filter-icon.svg" alt="" srcSet="" />
                  <span className="span-filter mx-12">Filters</span>
                  <i className="fa fa-caret-up  "></i>
                </>
              ) : (
                <>
                  <img src="/images/filter-icon.svg" alt="" srcSet="" />
                  <span className="span-filter mx-12">Filters</span>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                      fill="#828282"
                    />
                  </svg>
                </>
              )}
          </button>
        </span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            placeholder="Search"
            onInput={(e) => {
              lazyParams = {
                ...lazyParams,
                skip: 0,
                take: 25,
                keyword: e.target.value,
              };
              setLazyParams(lazyParams);
              getEarnings();
            }}
          />
        </span>
        {tab === 'reconciliation' && <span>
          <button className="btn btn-primary float-right mb-1 ml-12">Export</button>
        </span>}
      </div>
    );
  },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setCreatedAt([]);
      setStatus(null);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalCount: 0,
      });
      getEarnings();
    },
    manageDriverEarning = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getEarnings();
    };
  const limitizeId = (data) => {
    let result = data.slice(0, 8);
    return result;
  }

  const retryFun = async (driver) => {
    setTaxId(driver?.id);
    setLoading(true);
    APIService.get(`/admin/alinma-transactions/retry/${driver.id}`)
      .then((res) => {
        setLoading(false);
        getEarnings();

      })
  }
  let calendarRef = useRef(null)
  const handleCalendarHide = () => setIsCalendarOpen(false),
    info = [
      { title: 'Total RiDE TopUp', value: '1,938' },
      { title: 'Today’s RiDE TopUp', value: '1,820' },
    ];
  return (
    <div className="datatable-doc-demo mt-3">
      {/* <InfoCard info={info} /> */}
      <div className="card">
        <DataTable
          ref={dt}
          value={earnings}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Topup found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          lazy={true}
          totalRecords={lazyParams.totalCount}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageDriverEarning}
          onSort={manageDriverEarning}
          onFilter={manageDriverEarning}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
            <Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            header=" id"
            field="id"
            sortable
            filter={false}
            filterPlaceholder="Search Id"
            body={(driver) => {
              return (
                driver.id && (
                  <span className="dataFnt-size" style={{ color: "#13B542" }}>
                    {limitizeId(driver?.id)}
                  </span>
                )
              );
            }}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            header="User Id"
            field="userId"
            sortable
            filter={false}
            filterPlaceholder="Search User Id"
            body={(driver) => {
              return (
                driver.userId && (
                  <span className="dataFnt-size" style={{ color: "#13B542" }}>
                     {driver?.userId ? <Link
              to={(`/rider-details/details/${driver.userId}/ticket`).trim()}
                className="dataFnt-size"
                style={{ color: "rgba(19, 181, 66, 1)" }}
              >
                
    
                <span className="dataFnt-size" style={{ color: "#13B542" }}>
                {(driver?.userId)}                </span>
              </Link> : <span>-</span> }   
                  </span>
                )
              );
            }}
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "230px" }}
            field="createdAt"
            header="Created At"
            body={(earning) =>
              moment(earning.createdAt).format("DD/MM/YYYY hh:mm A")
            }
            sortable
            filter={showFilter}
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  id="range"
                  className="mr-4 mb-2 w-100"
                  placeholder="Select date range"
                  dateFormat="dd/mm/yy"
                  value={createdAt}
                  hideOnDateTimeSelect={true}
                  onChange={(e) => {
                    if (e.value) {
                      setCreatedAt(e.value);
                      if (e.value[0] && e.value[1]) {
                        lazyParams.filters["createdAt"] = [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ];

                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getEarnings();
                        }, 500);
                      }
                    } else {
                      setCreatedAt([]);
                      delete lazyParams.filters["createdAt"];
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getEarnings();
                      }, 500);
                    }
                  }}
                  showOnFocus={isCalendarOpen}
                  onHide={handleCalendarHide}
                  ref={calendarRef}
                  onFocus={() => setTimeout(() => {
                    calendarRef.current.showOverlay()
                  }, 100)}
                  selectionMode="range"
                  showButtonBar
                  readOnlyInput
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />

          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="cartAmount"
            header="Cart Amount"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by cart amount"
          />
           <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="tax"
            header="Tax"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by tax"
          />
           <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="fee"
            header="Fee"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by fee"
          />
             <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="creditAmount"
            header="Credit Amount"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by credit amount"
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="currency"
            header="Cart currency"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by cart currency"
          />
           <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="type"
            header="Type"
            body={(driver) => {
              return (
                driver.type &&
                (driver?.type == 1 ? (
                  <span className="status-text-pending">TopUp</span>
                ) : driver?.type == 2 ? (
                  <span className="status-text">Subscription</span>
                ) : (
<>-</>                ))
              );
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by status"
          />
           <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="paymentMethod"
            header="Payment Method"
            body={(driver) => {
              return (
                driver.paymentMethod &&
                (driver?.paymentMethod == 1 ? (
                  <span className="status-text-pending">Pay in installment</span>
                ) : driver?.paymentMethod == 2 ? (
                  <span className="status-text">Pay later</span>
                ) : (
<>-</>                ))
              );
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by status"
          />
           <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="paymentStatus"
            header="Payment Status"
            body={(driver) => {
              return (
                driver.paymentStatus &&
               <>{driver.paymentStatus}</>
              );
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by status"
          />
        </DataTable>
      </div>
    </div >
  );
};

export default React.memo(connect()(Tabby));
