import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { CalendarRange } from "react-bootstrap-icons";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import Rating from "react-rating";
import APIService, { googleMapApiKey } from "../../services/APIService";
import rideSource from "../../assest/images/ride-app-source.svg";
import whatsappSource from "../../assest/images/ride-app-source.svg";
import riderAvtSec from "../../assest/images/placeHoderImage.png";
import { CSVLink } from "react-csv";
import Geocode from "react-geocode";
let OfflineCaptains = (props) => {
  const customFunction = (value, filter) => {
    return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0;
  };
  Geocode.setApiKey("AIzaSyC3ervqmme-m7QTtK-0PJfZzrcyHuyvldg");
  Geocode.enableDebug();
  let [selectedStatus, setSelectedStatus] = useState(null),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [showFilter, setShowFilter] = useState(false),
    [fillRider, setFillRider] = useState([]),
    [subStatus, setSubStatus] = useState(null),
    [downlaodCsv, setDownloadCsv] = useState([]),
    CSVdownloadRef = useRef(null),
    dt = useRef(null),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    [lazyParams, setLazyParams] = useState({
      filters: {
        "driverModeSwitch": true
      },
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    [createdAt, setCreatedAt] = useState([]),
    [drivers, setDrivers] = useState([]),
    setDriversData = async (res) => {
      const newResponse = await Promise.all(res.map(async (a) => {
        const address = await getAddress(a)
        return { ...a, addressLine: address }
      }))
      console.log('offline address: ' , newResponse)
      setDrivers(newResponse)
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
    },
    getDrivers = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      APIService.post("admin/offline-captains-heatmap")
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);
            const Response = response.data.data.captains;
            var filterResponse = Response.map((item) => {
              return (
                item.approved === true ? item.userStatus = 'Active' : item.userStatus == false ? item.userStatus = 'Inactive' : 'Locked',
                item.carLicenceType == 1 ? item.carLicenceType = 'Ride' : 'Ride',
                item.createdAt = moment(item.createdAt).format("DD/MM/YYYY - hh:mm A")
                // item.kycStatus === 1?item.kycStatus='Yes':item.kycStatus===0?item.kycStatus='No':''
              )
            })
            setFillRider(filterResponse)
            setDriversData(Response)
          }
        })
        .catch(() =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    },
    manageDrivers = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            if (filter === "totalTrips") {
              lazyParams.filters[filter] = Number(event.filters[filter].value);
            } else {
              lazyParams.filters[filter] = event.filters[filter].value;
            }
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getDrivers();
    },
    statusOptions = [
      { label: "Active", value: 1 },
      { label: "Inactive", value: 2 },
    ],
    headers = [
      { label: 'ID', key: 'externalId' },
      { label: 'Full Name', key: 'driverName' },
      { label: 'Service', key: 'Service' },
      { label: 'Rating', key: 'overallRating' },
      { label: 'Loyailty Points', key: 'LoyaltyPoints' },
      { label: 'Moblie No', key: 'mobileNo' },
      { label: 'Data & Time', key: 'createdAt' },
      { label: 'Total Trips', key: 'totalTrips' },
      { label: 'KYC', key: 'KYC' },
      { label: 'Status', key: 'userStatus' }
    ],
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="toggle-filter">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
                showFilter ? (
                  <>
                    <img src="/images/filter-icon.svg" alt="" srcSet="" />
                    <span className="span-filter mx-12">Filters</span>
                    <i className="fa fa-caret-up  "></i>
                  </>
                ) : (
                  <>
                    <img src="/images/filter-icon.svg" alt="" srcSet="" />
                    <span className="span-filter mx-12">Filters</span>
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                        fill="#828282"
                      />
                    </svg>
                  </>
                )}
            </button>
          </span>
          <span className="p-input-icon-left p-captain d-flex align-items-center">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getDrivers();
              }}
            />
          </span>
          <CSVLink
            data={downlaodCsv}
            headers={headers}
            filename='Captains.csv'
            target='_blank'
            ref={CSVdownloadRef}
          />
          <button className="btn btn-primary float-right mb-1 ml-12" onClick={() => downloadCsvFile()} >
            Export
          </button>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      setCreatedAt([]);
      setSubStatus(null);
      getDrivers();
    };

  useEffect(() => {
    getDrivers();
  }, []);



  // get captain table data in csv 
  const downloadCsvFile = () => {
    APIService.post("admin/captains")
      .then((response) => {
        if (response.data && response.data.statusCode === 200) {


          const Response = response.data.data.captains;
          var filterResponse = Response.map((item) => {
            return (
              item.approved === true ? item.userStatus = 'Active' : item.userStatus == false ? item.userStatus = 'Inactive' : 'Locked',
              item.carLicenceType == 1 ? item.carLicenceType = 'Ride' : 'Ride',
              item.createdAt = moment(item.createdAt).format("DD/MM/YYYY - hh:mm A")
              // item.kycStatus === 1?item.kycStatus='Yes':item.kycStatus===0?item.kycStatus='No':''
            )
          })


          setDownloadCsv(Response);

          setTimeout(() => {

            CSVdownloadRef.current.link.click();
          }, 500)

        }
      })
  }

  const getAddress = async (driver) => {
    try {
      const response = await Geocode.fromLatLng(driver.latitude, driver.longitude)
      // return <div>{response?.results[0]?.formatted_address}</div>
      return response?.results[0]?.formatted_address
    }
    catch (e) { console.log("err", e) }
  }
  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={drivers??drivers}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Driver(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          totalRecords={lazyParams.totalRecords}
          lazy={true}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageDrivers}
          onSort={manageDrivers}
          onFilter={manageDrivers}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "250px" }}
            header="ID"
            field="externalId"
            body={(driver) => {
              return (
                <div className="text-left">
                  {driver?.profileImage ? (
                    <img
                      src={`${driver?.profileImage}`}
                      alt="ride"
                      style={{
                        marginRight: "15px",
                        width: "48px",
                        height: "48px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <img
                      src={riderAvtSec}
                      alt="ride"
                      style={{
                        marginRight: "15px",
                        width: "48px",
                        height: "48px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                  <Link
                    to={"/driver-details/details/" + driver.id}
                    style={{ color: "#13B542" }}
                  >
                    {driver.externalId}
                  </Link>
                </div>
              );
            }}
            sortable
            filter={showFilter}
            filterMatchMode="startsWith"
            filterPlaceholder="Search by id"
          />

          <Column
            className="text-left"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "180px" }}
            header="Full name"
            field="driverName"
            sortable
            filter={showFilter}
            filterMatchMode="custom"
            filterFunction={customFunction}
            filterPlaceholder="Search by name"
          />

          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "180px" }}
            header="Service"
            field="Service"
            body={(driver)=>{
              return <div>Own a Car</div>
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Services"
          />

          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "180px" }}
            header="Driver"
            field="LoyaltyPoints"
            body={(driver)=>{
              return <div>Captain</div>
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Loyalty points"
          />

          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "180px" }}
            header="Last Live Location"
            field="addressLine"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by mobile"
          />
        </DataTable>
      </div >
    </div >
  );
};

export default React.memo(connect()(withRouter(OfflineCaptains)));
