
import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { CalendarRange } from "react-bootstrap-icons";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import Rating from "react-rating";
import APIService from "../../services/APIService";
import rideSource from "../../assest/images/ride-app-source.svg";
import whatsappSource from "../../assest/images/ride-app-source.svg";
import riderAvtSec from "../../assest/images/placeHoderImage.png";
import { CSVLink } from "react-csv";
import DriverInfobox from "./DriverInfoBox";

let OwnACarListingTable = (props) => {
  const customFunction = (value, filter) => {
    return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0;
  };
  let [selectedStatus, setSelectedStatus] = useState(null),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [showFilter, setShowFilter] = useState(false),
    [fillRider, setFillRider] = useState([]),
    [subStatus, setSubStatus] = useState(null),
    [isCalendarOpen, setIsCalendarOpen] = useState(false),
    [downlaodCsv, setDownloadCsv] = useState([]),
    CSVdownloadRef = useRef(null),
    dt = useRef(null),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    [createdAt, setCreatedAt] = useState([]),
    [drivers, setDrivers] = useState([]),
    getDrivers = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      APIService.post("admin/captains", filterParams)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);


            const Response = response.data.data.captains;
            var filterResponse = Response.map((item) => {
              return (
                item.approved === true ? item.userStatus = 'Active' : item.userStatus == false ? item.userStatus = 'Inactive' : 'Locked',
                item.carLicenceType == 1 ? item.carLicenceType = 'Ride' : 'Ride',
                item.createdAt = moment(item.createdAt).format("DD/MM/YYYY - hh:mm A")
                // item.kycStatus === 1?item.kycStatus='Yes':item.kycStatus===0?item.kycStatus='No':''



              )
            })

            setFillRider(filterResponse)
            setDrivers(Response);




          }
        })
        .catch(() =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    },
    manageDrivers = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            if (filter === "totalTrips") {
              lazyParams.filters[filter] = Number(event.filters[filter].value);
            } else {
              lazyParams.filters[filter] = event.filters[filter].value;
            }
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getDrivers();
    },
    statusOptions = [
      { label: "Active", value: 1 },
      { label: "Inactive", value: 2 },
    ],
    subStatusOptions = [
      {
        label: "Active",
        value: 1,
      },
      {
        label: "Overdue",
        value: 2,
      },
      {
        label: "Expired",
        value: 3,
      },
    ],
    headers = [
      { label: 'ID', key: 'externalId' },
      { label: 'Full Name', key: 'driverName' },
      { label: 'Service', key: 'Service' },
      { label: 'Rating', key: 'overallRating' },
      { label: 'Loyailty Points', key: 'LoyaltyPoints' },
      { label: 'Moblie No', key: 'mobileNo' },
      { label: 'Data & Time', key: 'createdAt' },
      { label: 'Total Trips', key: 'totalTrips' },
      { label: 'KYC', key: 'KYC' },
      { label: 'Status', key: 'userStatus' }
    ],
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="toggle-filter">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
                showFilter ? (
                  <>
                    <img src="/images/filter-icon.svg" alt="" srcSet="" />
                    <span className="span-filter mx-12">Filters</span>
                    <i className="fa fa-caret-up  "></i>
                  </>
                ) : (
                  <>
                    <img src="/images/filter-icon.svg" alt="" srcSet="" />
                    <span className="span-filter mx-12">Filters</span>
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                        fill="#828282"
                      />
                    </svg>
                  </>
                )}
            </button>
          </span>
          <span className="p-input-icon-left p-captain d-flex align-items-center">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getDrivers();
              }}
            />
          </span>
          <CSVLink
            data={downlaodCsv}
            headers={headers}
            filename='Captains.csv'
            target='_blank'
            ref={CSVdownloadRef}
          />
          <button className="btn btn-primary float-right mb-1 ml-12" onClick={() => downloadCsvFile()} >
            Export
          </button>
          <button
            className="btn btn-primary float-right mb-1 ml-12 text-nowrap"
         
          >
            {windowWidth ? <i className="fa fa-plus" /> : "+ Add "}
          </button>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      setCreatedAt([]);
      setSubStatus(null);
      getDrivers();
    };
    let ratingCount = (value) => {
      const statusText = ["-", "😣 Poor", "😔 Bad", "🙂 Normal", "😀 Good", "😊 Excellent"];
      return statusText[value] || "-";
    };
    
  // let ratingCount = (value) => {
  //   let statusText;
  //   switch (value) {
  //     case 1:
  //       statusText = "😣 Poor";
  //       break;
  //     case 2:
  //       statusText = "😔 Bad";
  //       break;
  //     case 3:
  //       statusText = "🙂 Normal";
  //       break;
  //     case 4:
  //       statusText = "😀 Good";
  //       break;
  //     case 5:
  //       statusText = "😊 Excellent";
  //       break;
  //     default:
  //       statusText = "-";
  //       break;
  //   }
  //   return statusText;
  // };
  useEffect(() => {
    getDrivers();
  }, []);



  // get captain table data in csv 
  const downloadCsvFile = () => {
    APIService.post("admin/captains")
      .then((response) => {
        if (response.data && response.data.statusCode === 200) {


          const Response = response.data.data.captains;
          var filterResponse = Response.map((item) => {
            return (
              item.approved === true ? item.userStatus = 'Active' : item.userStatus == false ? item.userStatus = 'Inactive' : 'Locked',
              item.carLicenceType == 1 ? item.carLicenceType = 'Ride' : 'Ride',
              item.createdAt = moment(item.createdAt).format("DD/MM/YYYY - hh:mm A")
              // item.kycStatus === 1?item.kycStatus='Yes':item.kycStatus===0?item.kycStatus='No':''



            )
          })


          setDownloadCsv(Response);

          setTimeout(() => {

            CSVdownloadRef.current.link.click();
          }, 500)

        }
      })
  }

  let calendarRef = useRef(null)
  const handleCalendarHide = () => setIsCalendarOpen(false);

  const [driverData,setDriverData]=useState(null);
  const [tripData,setTripData] = useState(null);
      const getDriversData=()=>{
          const fromDate = "2023-01-01 00:00:00.000000";
  let toDate = "2023-09-16 23:59:59.000000";
  
  // Create a moment object for today
  const today = moment();
  
  // Format the today date in the same format as toDate
  const formattedToday = today.format('YYYY-MM-DD ');
  
  // Replace the toDate with the formatted today date
  toDate = formattedToday;
          try{
            APIService.get(`admin/dashboard/wasl-approved-count?type=custom&entity=rider&fromDate=${fromDate} &toDate=${toDate} 23:59:59.000000`)
            .then((res)=>{
              if(res){
              if(res.status==200){
           
                let data = res?.data?.data ? res.data.data : ""
                setDriverData(data)
              }}
            }).catch((e)=>console.log(e))
          }catch(e){
              console.log(e)
            }
        }
        const getTripsData=()=>{
        
          try{
            APIService.get(`admin/dashboard/?type=year`)
            .then((res)=>{
              if(res){
              if(res.status==200){
           
                let data = res?.data?.data ? res.data.data : ""
                setTripData(data)
              }}
            }).catch((e)=>console.log(e))
          }catch(e){
              console.log(e)
            }
        }
  useEffect(()=>{
      getDriversData(); 
      getTripsData();
  },[])

  return (
    <>
    <div className="mt-3">
            <DriverInfobox data = {driverData} tripData={tripData}/>
          </div>
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={drivers}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Driver(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          totalRecords={lazyParams.totalRecords}
          lazy={true}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageDrivers}
          onSort={manageDrivers}
          onFilter={manageDrivers}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "250px" }}
            header="ID"
            field="externalId"
            body={(driver) => {
              return (
                <div className="text-left fw-bold">
                  {driver?.profileImage ? (
                    <img
                      src={`${driver?.profileImage}`}
                      alt="ride"
                      style={{
                        marginRight: "15px",
                        width: "48px",
                        height: "48px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <img
                      src={riderAvtSec}
                      alt="ride"
                      style={{
                        marginRight: "15px",
                        width: "48px",
                        height: "48px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                  <Link
                    to={"/driver-details/details/" + driver.id}
                    style={{ color: "#13B542", fontWeight: 'bold' }}
                  >
                    {driver.externalId}
                  </Link>
                </div>
              );
            }}
            sortable
            filter={showFilter}
            filterMatchMode="startsWith"
            filterPlaceholder="Search by id"
          />

          <Column
            className="text-left fw-bold"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "180px" }}
            header="Full name"
            field="driverName"
            sortable
            filter={showFilter}
            filterMatchMode="custom"
            filterFunction={customFunction}
            filterPlaceholder="Search by name"
          />

          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "180px" }}
            header="Service"
            field="Service"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Services"
          />

          <Column
            className="text-center fw-bold"
            style={{ fontSize: "12px", width: "180px" }}
            header="Rating"
            field="overallRating"
            body={
              (driver) => {
                if (driver.overallRating == 0) {
                  return "--";
                } else {
                  return ratingCount(Math.round(driver.overallRating));
                }
              }

            }
            sortable
            sortField="overallRating"
            filter={false}
          />

          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "180px" }}
            header="Loyalty points"
            field="LoyaltyPoints"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Loyalty points"
          />

          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "180px" }}
            header="Mobile No."
            field="mobileNo"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by mobile"
          />

          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "230px" }}
            header="Date & Time"
            field="createdAt"
            // body={(driver) =>
            //   moment(driver.createdAt).format("DD/MM/YYYY hh:mm A")
            // }
            sortable
            filter={showFilter}
            filterMatchMode="custom"
            filterElement={
              <div className="d-inline-flex position-relative w-100">
                <Calendar
                  id="range"
                  className="mr-4 mb-2 w-100"
                  placeholder="Select date range"
                  dateFormat="dd/mm/yy"
                  value={createdAt}
                  hideOnDateTimeSelect={true}
                  onChange={(e) => {
                    if (e.value) {
                      setCreatedAt(e.value);
                      if (e.value[0] && e.value[1]) {
                        lazyParams.filters["createdAt"] = [
                          moment(e.value[0]).format("YYYY-MM-DD"),
                          moment(e.value[1]).format("YYYY-MM-DD"),
                        ];
                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getDrivers();
                        }, 500);
                      }
                    } else {
                      delete lazyParams.filters["createdAt"];
                      setCreatedAt([]);
                      setLazyParams(lazyParams);
                      setTimeout(() => {
                        getDrivers();
                      }, 500);
                    }
                  }}
                  showOnFocus={isCalendarOpen}
                  onHide={handleCalendarHide}
                  ref={calendarRef}
                  onFocus={()=> setTimeout(() => {
                    calendarRef.current.showOverlay()
                  }, 100)}
                  selectionMode="range"
                  showButtonBar
                  readOnlyInput
                />
                <span className="input-box-daterange-icon">
                  <CalendarRange />
                </span>
              </div>
            }
          />

         <Column
            className="text-center fw-bold"
            style={{ fontSize: "12px", width: "135px" }}
            header="Mohsin"
            field="mohsin"
            sortable
            body={()=>{
              return(
                <>
            <div>Yes</div>
                </>
              )
            }}
            filter={showFilter}
            filterPlaceholder="Search by trips"
          />
           <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "135px" }}
            header="Tourist"
            field="tourist"
            body={()=>{
              return(
                <>
            <div>-</div>
                </>
              )
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by trips"
          />


          {/* <Column
            className="text-left"
            style={{ fontSize: "12px", width: "135px" }}
            header="Total Trips"
            field="totalTrips"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by trips"
          /> */}

          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "125px" }}
            header="KYC"
            field="KYC"
            body={(driver) => {
              return <div className={`${driver.KYC=='Yes'?'text-danger':'text-dark'}  font-weight-bold`}>Yes</div>
            }}
            sortable
            filter={showFilter}
            filterPlaceholder="Search by trips"
          />

          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "145px" }}
            field="userStatus"
            header="Status"
            body={(driver) => {
              return driver.approved ? (
                <span className="status-text">Active</span>
              ) : (
                <span className="status-text-inactive">Inactive</span>
              );
            }}
            sortable
            filter={showFilter}
            filterElement={
              <Dropdown
                value={selectedStatus}
                options={statusOptions}
                onChange={(event) => {
                  setSelectedStatus(event.value);
                  if (event.value === 1) {
                    lazyParams.filters["approved"] = true;
                  } else if (event.value === 2) {
                    lazyParams.filters["approved"] = false;
                  } else {
                    delete lazyParams.filters["approved"];
                  }
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getDrivers();
                  }, 500);
                }}
                showClear
                placeholder="Select Status"
                className="p-column-filter"
              />
            }
          />
          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "145px" }}
            field="carLicenceType"
            header="Source"
            body={(driver) => {
              return driver.approved ? (
                <img src={rideSource} alt="ride" className="nav_icon" />
              ) : (
                <img src={whatsappSource} alt="ride" className="nav_icon" />
              );
            }}
            filter={false}
          />
        </DataTable>
      </div >
    </div >
    </>
  );
};

export default React.memo(connect()(withRouter(OwnACarListingTable)));
