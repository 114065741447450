import React , {useState} from "react";
import DriverListingTable from "../components/Drivers/DriverListingTable";
import { Tab, Tabs } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import IncompleteDrivers from "../components/Drivers/IncompleteDrivers";
import { toast } from "react-toastify";
import refresh from '../components/Dashboard_2/images/refresh.svg'
import APIService from "../services/APIService";
import RiderListingTable from "../components/Riders/RiderListingTable";
import OwnACar from "../components/Drivers/OwnACar";
import RideARide from "../components/Drivers/RideARide";
import PickupsListing from "../components/Drivers/PickupsListing";


let TotalCaptains = () => {
  let [selectTab, setSelectedTab] = useState("All Captains");
  return (
    <>
      <div style={{margin:'15px 0px'}}>
    
        <Tabs
          id="controlled-tab-example"
          className="mt-30 position-relative"
          activeKey={selectTab}
          onSelect={(tab) => {
            setSelectedTab(tab);
          }}
        >
          
          <Tab eventKey="All Captains" title="All Captains">
            {selectTab === "All Captains" && (
              <DriverListingTable />
            )}
          </Tab>

          <Tab eventKey="I Own A Car" title="I Own A Car">
            {selectTab === "I Own A Car" && (
              <OwnACar />
            )}
          </Tab>
          <Tab eventKey="Ride A Ride" title="Ride A Ride">
            {selectTab === "Ride A Ride" && (
              <RideARide />
            )}
          </Tab>
          <Tab eventKey="Pickup" title="Pickup">
            {selectTab === "Pickup" && (
              <PickupsListing />
            )}
          </Tab>
          
          </Tabs>
        {/* <DriverListingTable /> */}
      </div>
    </>
  );
};

export default React.memo(TotalCaptains);