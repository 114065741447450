import React, { useState } from 'react'
// import PromotionsDatatable from './PromotionsDatatable'
import EscalationsDatatable from './EscalationsDatatable';
import { Button, Modal } from "react-bootstrap";
import {  Col, Row } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-select";
import PhoneInput from 'react-phone-number-input'

const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? "#ffffff"
            : isFocused
              ? "white"
              : undefined,
        color: isDisabled ? "white" : isSelected ? "#28a745" : "#000000",
        cursor: isDisabled ? "not-allowed" : "default",
  
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : "white"
            : undefined,
        },
      };
    },
  };


const Escalations = () => {

    const [show,setShow]=useState(false),
    [promotionsuccess,setPromotionSuccess]=useState(false),
    [editModal,setEditModal]=useState(false);
let userOptions = [{ value: 1, label: "Abdullah" },{ value: 2, label: "Ebrahim" }],
cityOptions =[{value:1,label:"Riyadh"},{value:2,label:"Mecca"}],
countryOptions=[{value:1,label:"Saudi Arabia"},{value:2,label:"Pakistan"}],
categoryOptions=[],
handleShow=()=>setShow(!show),
handleEditShow=()=>setEditModal(!editModal)

  return (
    <>
    <EscalationsDatatable 
     handleShow={handleShow}
     handleEditShow={handleEditShow}
     />
    <Modal show={show} onHide={handleShow} centered size="md">
          <Formik
            // initialValues={{ ...updateData }}
            // validationSchema={validationSchema}
            // onSubmit={createOrUpdateAdmin}
          >
            {({ handleBlur, setFieldValue }) => {
              return (
                <Form>
                  <Modal.Header>
                    <Modal.Title className="modal-title">
                      Add Rule
                    </Modal.Title>
                    <div className="divclose" onClick={handleShow}>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="mt-24">
                    <Row className="row">
                    <Col md={12}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Select"
                            // isClearable
                            name="managementuser"
                            styles={colourStyles}
                            id="managementuser"
                            onBlur={() => {
                              handleBlur({ target: { name: "managementuser" } });
                            }}
                            onChange={(e) => {
                            //   if (e) {
                            //     setFieldValue("country", e.value);
                            //     setSelectedCountry(e);
                            //   }
                            }}
                            // value={selectedCountry}
                            options={userOptions}
                          />
                          <label htmlFor="">Management User</label>
                          {/* {(!selectedCountry || !selectedCountry?.value) && (
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )} */}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Select"
                            // isClearable
                            name="city"
                            styles={colourStyles}
                            id="city"
                            onBlur={() => {
                              handleBlur({ target: { name: "city" } });
                            }}
                            onChange={(e) => {
                            //   if (e) {
                            //     setFieldValue("country", e.value);
                            //     setSelectedCountry(e);
                            //   }
                            }}
                            // value={selectedCountry}
                            options={cityOptions}
                          />
                          <label htmlFor="">City</label>
                          {/* {(!selectedCountry || !selectedCountry?.value) && (
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )} */}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Select"
                            // isClearable
                            name="country"
                            styles={colourStyles}
                            id="country"
                            onBlur={() => {
                              handleBlur({ target: { name: "country" } });
                            }}
                            onChange={(e) => {
                            //   if (e) {
                            //     setFieldValue("country", e.value);
                            //     setSelectedCountry(e);
                            //   }
                            }}
                            // value={selectedCountry}
                            options={countryOptions}
                          />
                          <label htmlFor="">Country</label>
                          {/* {(!selectedCountry || !selectedCountry?.value) && (
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )} */}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Select"
                            // isClearable
                            name="category"
                            styles={colourStyles}
                            id="category"
                            onBlur={() => {
                              handleBlur({ target: { name: "category" } });
                            }}
                            onChange={(e) => {
                            //   if (e) {
                            //     setFieldValue("country", e.value);
                            //     setSelectedCountry(e);
                            //   }
                            }}
                            // value={selectedCountry}
                            options={categoryOptions}
                          />
                          <label htmlFor="">Category</label>
                          {/* {(!selectedCountry || !selectedCountry?.value) && (
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )} */}
                        </div>
                      </Col>
                      <Col md={12}>
                      <PhoneInput
                            countryCallingCodeEditable={false}
                            className="d-flex mb-4"
                            international
                            defaultCountry="SA"
                            name="mobileNo"
                            required
                            // value={110001}
                            onChange={(e)=>console.log(e)}
                          />
                      </Col>
                        <Col md={12}>
                          <Button
                            variant="success"
                            className="btn-save save-button mb-3 btn  btn-block"
                            // type="submit"
                            // onClick={createOrUpdateAdmin}
                          >
                            Add
                          </Button>
                      </Col>
                    </Row>
                  </Modal.Body>
                </Form>
              );
            }}
          </Formik>
        </Modal>
        <Modal
            show={promotionsuccess}
            size="md"
            onHide={()=>setPromotionSuccess(false)}
            centered
            backdrop="static"
            keyboard={false}
            className="modal-height modal-vc"
          >
            <Formik
            // initialValues={{
            //   oldPassword: "",
            //   newPassword: "",
            //   confirmPassword: "",
            // }}
            // validationSchema={validationPasswordSchema}
            // onSubmit={changePassword}
            >
              {() => {
                return (
                  <Form>
                    <Modal.Header>
                      <Modal.Title className="modal-title"> </Modal.Title>
                      <div
                        className="divclose"
                        onClick={()=>setPromotionSuccess(false)}
                      >
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </Modal.Header>

                    <Modal.Body
                      className="customheight"
                      style={{
                        height: "492px !important",
                        minHeight: "492px !important",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Row>
                        <Col
                          xl={12}
                          className="text-center my-auto custom-success"
                        >
                          <svg
                            className="custom-svg"
                            viewBox="0 0 26 26"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              stroke="currentColor"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                className="circle"
                                d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"
                              />
                              <path
                                className="tick"
                                d="M6.5 13.5L10 17 l8.808621-8.308621"
                              />
                            </g>
                          </svg>
                          <h2 className="success-message mt-24">
                            Add New Promotion
                            <br />
                            Confirmed
                          </h2>
                        </Col>
                      </Row>
                    </Modal.Body>
                  </Form>
                );
              }}
            </Formik>
          </Modal>
          <Modal show={editModal} onHide={handleEditShow} centered size="md">
          <Formik
            // initialValues={{ ...updateData }}
            // validationSchema={validationSchema}
            // onSubmit={createOrUpdateAdmin}
          >
            {({ handleBlur, setFieldValue }) => {
              return (
                <Form>
                  <Modal.Header>
                    <Modal.Title className="modal-title">
                      Edit
                    </Modal.Title>
                    <div className="divclose" onClick={handleEditShow}>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="mt-24">
                    <Row className="row">
                    <Col md={12}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Select"
                            // isClearable
                            name="managementuser"
                            styles={colourStyles}
                            id="managementuser"
                            onBlur={() => {
                              handleBlur({ target: { name: "managementuser" } });
                            }}
                            onChange={(e) => {
                            //   if (e) {
                            //     setFieldValue("country", e.value);
                            //     setSelectedCountry(e);
                            //   }
                            }}
                            // value={selectedCountry}
                            options={userOptions}
                          />
                          <label htmlFor="">Another Management User</label>
                          {/* {(!selectedCountry || !selectedCountry?.value) && (
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )} */}
                        </div>
                      </Col>
                      <Col md={12}>
                      <PhoneInput
                            countryCallingCodeEditable={false}
                            className="d-flex mb-4"
                            international
                            defaultCountry="SA"
                            name="mobileNo"
                            required
                            // value={110001}
                            onChange={(e)=>console.log(e)}
                          />
                      </Col>
                        <Col md={12}>
                          <Button
                            variant="success"
                            className="btn-save save-button mb-3 btn  btn-block"
                            // type="submit"
                            // onClick={createOrUpdateAdmin}
                          >
                            Add
                          </Button>
                      </Col>
                    </Row>
                  </Modal.Body>
                </Form>
              );
            }}
          </Formik>
        </Modal>
    </>
  )
}

export default Escalations