import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import TripHistoryTab from "../components/Riders/TripHistoryTab";
import PaymentHistory from "../components/Riders/PaymentHistory";
import RiderDetailsTab from "../components/Riders/RiderDetailsTab";
import Spendings from "../components/Riders/Spendings";
import APIService from "../services/APIService";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import TicketHistory from "../components/Riders/TicketHistory";
import RideFinance from "../components/Riders/RideFinance";
import RideInsurence from "../components/Riders/RideInsurence";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

let RiderListingTableDetailPage = (props) => {
  let { id } = useParams(),
    [tab, setTab] = useState(null),
    history=useHistory(),
    [riderView, setRiderView] = useState([]),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    pathname = props.location.pathname,
    getRider = () => {
      if (props.match.params.tab) {
        setTab(props.match.params.tab);
      } else {
        setTab("details");
      }
      // if (props.match.params.tab === "details" || props.match.params.tab === "payment-history" || props.match.params.tab === "topup-history") {
        props.dispatch({ type: "TOGGLE_LOADER", payload: true });
        APIService.get(`admin/${id.length<20? 'rider-by-user-id' : 'riders'}/`+id.trim())
          .then((response) => {
            if (response.data && response.data.statusCode === 200) {
              setRiderView(response.data.data);
              props.dispatch({ type: "TOGGLE_LOADER", payload: false });
            }
          })
          .catch(() =>
            props.dispatch({ type: "TOGGLE_LOADER", payload: false })
          );
      // }
    };

  useEffect(() => {
    getRider();
  }, []);
  console.log('userrrrrrParams',useParams())

  return (
    <>
      <div className="rider-listing">
          <i className="fa fa-chevron-left back-arrow" onClick={() => history.goBack()} style={{ cursor: "pointer" }}></i>
        
        <span className="title">Riders</span>

        <Tabs
          id="controlled-tab-example"
          className="mt-30"
          activeKey={tab}
          onSelect={(tab) => {
            setTab(tab);
            props.history.push(`/rider-details/${tab}/${id}`);
          }}
        >
          {permissions.some(a => a.includes('/rider-details/details')) && <Tab eventKey="details" title="Rider Profile">
            {tab === "details" && (
              <RiderDetailsTab
                rideViewData={riderView}
                dispatch={props?.dispatch}
                riderId={id}
              />
            )}
          </Tab>}
          {permissions.some(a => a.includes('/rider-details/trip-history')) && <Tab eventKey="trip-history" title="Trip Details">
            {tab === "trip-history" && <TripHistoryTab />}
          </Tab>}
          {/* <Tab eventKey="upcoming-scheduled" title="Upcoming Scheduled">
            {tab === "upcoming-scheduled" && <UpcomingScheduledTab />}
          </Tab> */}
          {permissions.some(a => a.includes('/rider-details/topup-history')) && <Tab eventKey="topup-history" title="Ticket History">
            {tab === "topup-history" && (
              <TicketHistory riderView={riderView} />
            )}
          </Tab>}
          {permissions.some(a => a.includes('/rider-details/ride-finance')) && <Tab eventKey="ride-finance" title="Ride Finance">
            {tab === "ride-finance" &&<RideFinance/>}
          </Tab>}
          {permissions.some(a => a.includes('/rider-details/ride-insurance')) && <Tab eventKey="ride-insurance" title="Ride Insurance">
            {tab === "ride-insurance" && (
              <RideInsurence/>
            )}
          </Tab>}

        </Tabs>
      </div>
    </>
  );
};

export default React.memo(connect()(RiderListingTableDetailPage));
