import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import APIService from "../../services/APIService";
let TransectionHistory = (props) => {
  const [transactionData,setTransactionData] = useState(null);
  const {transactionDataId} =props;
  let [transaction, setTransaction] = useState([]),
    dt = useRef(null),
    [lazyParams, setLazyParams] = useState({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 5,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      
      const getTrasactionRecord = () => {
        const filters = {
          userId:`${transactionDataId}`,
        };
        try {
        APIService.post(`admin/user-transactions`,{
          filters:filters
        })
          .then(response => {
            if (response.status == 201) {
              let data = response?.data?.data?.transactions;
              // setCancelationReasonsData(data[0])
              console.log('transection ....',data)
              setTransactionData(data);  
              // setDailyTripsData(data)
            }
          })
      } catch (e) { console.log(e) }
    };

  useEffect(() => {
    getTrasactionRecord();
    
  }, []);
  
  
  
  
  
  // render entity type into filters 
  const entityTypeMapping = {
    1: 'Trip',
    2: 'Subscription',
    3: 'Topup',
    4: 'Refund',
    5: 'Promotion Topup',
  };
  
  const handleSelectChange = async (event) => {
    const selectedValue = event.target.value;

    const filters = {
      userId: `${transactionDataId}`,
    };
  
    if (selectedValue !== 'all') {
      filters.entityType = `${selectedValue}`;
    }

    try {
      APIService.post(`admin/user-transactions`,{
        filters:filters
      })
        .then(response => {
          if (response.status == 201) {
            let data = response?.data?.data?.transactions;
            // setCancelationReasonsData(data[0])
            setTransactionData(data);  
            // setDailyTripsData(data)
          }
        })
    } catch (e) { console.log(e) }
    
  };
  
  const renderSelectOptions = () => {
    return Object.entries(entityTypeMapping).map(([value, text]) => (
      <option key={value} value={value}>
        {text}
      </option>
    ));
  };
  
  
  
  
  
  const getStatusString = (statusNumber) => {
    let text, color;
    
    switch (statusNumber) {
      case 1:
        text = "Pending";
        color = "warning"; // Bootstrap warning class
        break;
        case 2:
          text = "Completed";
        color = "success"; // Bootstrap success class
        break;
      case 3:
        text = "CANCELLED"; // Note: Check if 'CANCELLED' should be uppercase
        color = "danger"; // Bootstrap danger class
        break;
      case 4:
        text = "Refunded";
        color = "info"; // Bootstrap info class
        break;
      case 5:
        text = "Failed";
        color = "danger"; // Bootstrap danger class
        break;
      default:
        text = "Unknown";
        color = "secondary"; // Bootstrap secondary class
    }
  
    return (
      <div className={`bg-${color} text-light text-nowrap px-2  rounded`}>
        {text}
      </div>
    );
  };
  
const redirectIdTemplate = (rowData)=>{
    return (
        <> 
<div className="font-weight-bold text-success pointer">
  {rowData.id ? `SM${rowData.id.slice(-4)}` : ""}
</div>        
        </>
    )
}


const renderTypes = (rowData) => {
  const getEntityType = (type) => {
    switch (type) {
      case 1:
        return { text: "Trip", color: "success" };
      case 2:
        return { text: "Subscription", color: "success" };
      case 3:
        return { text: "Topup", color: "success" };
      case 4:
        return { text: "Refund", color: "danger" };
      case 5:
        return { text: "Promotion Topup", color: "info" };
      default:
        return { text: "Unknown", color: "secondary" };
    }
  };

  const getSource = (source) => {
    switch (source) {
      case 1:
        return { text: "Internal Wallet", color: "dark" };
      case 2:
        return { text: "Click Pay", color: "dark" };
      case 3:
        return { text: "Tabby Pay", color: "dark" };
      case 4:
        return { text: "Tamara Pay", color: "dark" };
      case 5:
        return { text: "Tap Payment", color: "dark" };
      case 6:
        return { text: "Promotions", color: "dark" };
      default:
        return { text: "Unknown", color: "danger" };
    }
  };

  const entityTypeData = getEntityType(rowData.entityType);
  const sourceData = getSource(rowData.source);

  return (
    <>
      <div className="d-flex align-items-center justify-content-center flex-column">
        <div className={`text-capitalize font-weight-bold text-${sourceData.color}`}>
          {sourceData.text}
        </div>
        <div className={`font-weight-bold text-${entityTypeData.color}`}>
          {entityTypeData.text}
        </div>
      </div>
    </>
  );
};




const renderStatus = (rowData)=>{
    return (
        <>
       {getStatusString(rowData.status)}
        </>
    )
}

const renderDate = (rowData) => {
  const date = new Date(rowData.createdAt);
  if (isNaN(date)) {
    return <div className="text-center">Invalid Date</div>;
  }
  const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  return (
    <div className="text-center">{formattedDate}</div>
  );
};


const renderTime = (rowData) => {
  const date = new Date(rowData.createdAt);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedTime = `${hours > 12 ? hours - 12 : hours}:${minutes < 10 ? '0' : ''}${minutes} ${hours >= 12 ? 'PM' : 'AM'}`;
  
  return (
    <div className="text-center ">{formattedTime}</div>
  );
};


  return (
    <div className="datatable-doc-demo ">
      <div className="d-flex align-items-center mt-2">
      <h2 className="box-title mr-5">Transaction History </h2>
      <select name="Transaction" style={{ border: '1px solid lightgray', padding: '1px 30px', color: 'gray', borderRadius: '5px', cursor: 'pointer' }} onChange={handleSelectChange}>
  <option value="all">All</option>
  {renderSelectOptions()}
</select>
      </div>

      <div className="card mt-3">
        <DataTable
          ref={dt}
          value={transactionData}
          // header={header}
          className="p-datatable-customers"
          dataKey="id"
        //   globalFilter={globalFilter}
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Subscription(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[5, 10, 25]}
          totalRecords={transaction.length}
        //   lazy={true}
        //   first={lazyParams.skip}
          rows={lazyParams.take}
        //   onPage={manageActiveSubscription}
        //   onSort={manageActiveSubscription}
        //   onFilter={manageActiveSubscription}
        //   sortField={lazyParams.sort.field}
        //   sortOrder={lazyParams.sort.order}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Transaction ID"
            field="id"
            body={redirectIdTemplate}
          />
        <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Type"
            field="type"
            body={renderTypes}
          />
        <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Amount"
            field="transactionAmount"
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Date"
            field="date"
            body={renderDate}
          />
           <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Time"
            field="time"
            body={renderTime}
          />
        
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            header="Status"
            field="status"
            body={renderStatus}
          />
          

        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(TransectionHistory));
