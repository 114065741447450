import React, { useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';

const SelectOptions = (props) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionChange = (event) => {
    setSelectedOptions(event.value);
  };

  const options = props.data.map((option) => ({
    label: option.label,
    value: option.value,
  }));

  return (
    <div className='mr-3'>
      <MultiSelect
        className='me-4'
        value={selectedOptions}
        options={options}
        onChange={handleOptionChange}
        optionLabel='name'
        placeholder={props.name}
      />
    </div>
  );
};

export default SelectOptions;
