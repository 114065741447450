import React,{useState} from 'react'
import moment from "moment";
import refresh from "./images/refresh.svg";
import { useSelector } from 'react-redux';

const LocationNav = ({ refreshData }) => {
  const locationInfo = useSelector(s => s.location);
  const [locationError, setLocationError] = useState(false);
  const requestLocationAccess = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const date = new Date();
        const time = moment(date).format("h:mm A");
        // setLocationInfo({
        //   date: date.toDateString(),
        //   time,
        //   country: "CountryName", // Replace with actual country
        //   city: "CityName", // Replace with actual city
        // });
        setLocationError(false);
      },
      (error) => {
        setLocationError(true);
      }
    );
  };
  console.log('locationInfo',locationInfo)
  return (
    <div className="d-inline-flex flex-nowrap overflow-respons">
    {locationInfo?.city ? (
      <>
        <p className="nav-btn">
          <span style={{ marginLeft: "12px", cursor: "pointer" }}>
            {`${locationInfo.date} - ${locationInfo.time}`}
          </span>
        </p>{" "}
        <p style={{ marginLeft: "12px", cursor: "pointer" }} className="nav-btn">
          <span>{locationInfo.country}</span>
        </p>{" "}
        <p className="nav-btn" style={{ marginLeft: "12px", cursor: "pointer" }}>
          <span>{locationInfo.city}</span>
        </p>{" "}
        <p className="nav-btn" style={{ marginLeft: "12px", cursor: "pointer" }}>
          <span className='d-flex align-items-center' onClick={refreshData}>
            <img src={refresh} alt="" />
            <span style={{color:'#13B542'}}>Refresh</span>
          </span>
        </p>{" "}
      </>
    ) : (
      <p className="nav-btn text-none">
      <span style={{ marginLeft: "12px", cursor: "pointer" }}>
        {locationError
          ? "Failed to access location"
          : "You have not given location access to this site"}
      </span>
    </p>
    )}
  </div>
);
  
}

export default LocationNav
