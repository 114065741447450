import React, { useState, useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Select, { StylesConfig } from "react-select";
import SubscriptionsPackageListingTable from "../../components/Subscriptions/SubscriptionsPackageListingTable";
import DeleteModal from "../../components/Common/DeleteModal";
import APIService from "../../services/APIService";
import { connect, useSelector } from "react-redux";
import ActiveSubscriptionsListingTable from "./ActiveSubscriptionsListingTable";
import moment from "moment";
import { Paginator } from "primereact/paginator";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { CSVLink } from "react-csv";

const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? "#ffffff"
          : isFocused
            ? "white"
            : undefined,
      color: isDisabled ? "white" : isSelected ? "#13B542" : "#000000",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },
};
const data = {
  "1 day": 1,
  "2 days": 2,
  "3 days": 3,
  "4 days": 4,
  "5 days": 5,
  "6 days": 6,
  "1 week": 7,
  "2 weeks": 14,
  "3 weeks": 21,
  "1 month": 30,
  "2 months": 60,
  "3 months": 90,
  "6 months": 180,
  "1 year": 365,
  "2 years": 730,
  "3 years": 1095,
  "4 years": 1460,
  "5 years": 1825
};

const dataArray = [];

for (const key in data) {
  if (data.hasOwnProperty(key)) {
    dataArray.push({ label: key, value: data[key] });
  }
}
let statusOptions = [
  { value: 0, label: "Inactive" },
  { value: 1, label: "Active" },
],
  durationOptions = [
    { value: 1, label: "Monthly" },
    { value: 2, label: "Yearly" },
  ],
  assignToOptions = [
    { value: 1, label: "Own a Car" },
    // { value: 2, label: "Ride a Ride" },
    // { value: 3, label: "Rider" },
  ],
  categoryOptions = [
    { value: 1, label: "Orignal" },
    { value: 2, label: "..." },
  ],
  promoOptions = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ],
  SubscriptionsPackage = (props) => {
    const [basicFirst, setBasicFirst] = useState(0);
    const [basicRows, setBasicRows] = useState(10);
    const [filteredData, setFilteredData] = useState(null);
    let [windowWidth] = useState(useSelector((state) => state.windowSize)),
      [data, setData] = useState(null),
      [show, setShow] = useState(false),
      [downlaodCsv, setDownloadCsv] = useState([]),
      CSVdownloadRef = useRef(null),
      [showUpdate, setShowUpdate] = useState(false),
      [selectedCustomers, setSelectedCustomers] = useState(null),
      [showDelete, setShowDelete] = useState(false),
      [startDate, setStartDate] = useState([]),
      [showFilter, setShowFilter] = useState(false),
      [cabTypes, setCabTypes] = useState([]),
      [activeSubscriptions, setActiveSubscriptions] = useState([]),
      [lazyParams, setLazyParams] = useState({
        filters: {},
        sort: {
          field: "startDate",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      }),
      permissions = JSON.parse(atob(localStorage.getItem("access"))),
      currentRoutePermissions = permissions.filter(a => a.includes('/subscriptions/own-a-car/')),
      dt = useRef(null),
      [saveNmore, setSaveNmore] = useState(false),
      [selectedStatus, setSelectedStatus] = useState({
        value: 1,
        label: "Active",
      }),
      // [selectedPromoStatus, setSelectedPromoStatus] = useState({
      //   value: 1,
      //   label: "Yes",
      // }),
      [selectedDuration, setSelectedDuration] = useState({
        value: 1,
        label: "Monthly",
      }),
      [selectedAssign, setSelectedAssign] = useState({
        value: 1,
        label: "Own a Car",
      }),
      [selectedCategory, setSelectedCategory] = useState({
        value: '',
        label: "All",
      }),
      [updateData, setUpdateData] = useState({
        packageName: "",
        arabicPackageName: "",
        packageDescription: "",
        arabicPackageDescription: "",
        planType: 1,
        basePrice: "",
        status: 1,
        noOfDays:0,
      }),
      handleClose = () => setShow(false),
      handleShow = () => {
        setSelectedStatus({ value: 1, label: "Active" });
        setUpdateData({
          packageName: "",
          arabicPackageName: "",
          packageDescription: "",
          arabicPackageDescription: "",
          planType: 1,
          basePrice: "",
          status: 1,
          noOfDays:0,
        });
        setShow(true);
      },
      handleCloseUpdate = () => {
        setShowUpdate(false);
        setSelectedDuration({
          value: 1,
          label: "Monthly",
        });
      },
      
      handleShowUpdate = () => setShowUpdate(true),
      handleCloseDelete = () => setShowDelete(false),
      handleShowDelete = (rowData) => {
        setUpdateData(rowData);
        setShowDelete(true);
      };
    const onBasicPageChange = (event) => {
      setBasicFirst(event.first);
      setBasicRows(event.rows);

      const filtered = data?.slice(
        event.page * event.rows,
        event.page * event.rows + event.rows
      );
      setFilteredData(filtered);
    };
    console.log('sele',selectedCategory)
    useEffect(() => {
      getSubscriptionPackages();
    }, []);
    let createOrUpdateSubscription = (formInfo, props) => {
      formInfo.status = Number(selectedStatus.value);
      // formInfo.planType = Number(selectedDuration.value);
      if (!updateData || !updateData.id) {
        APIService.post("admin/subscription", formInfo)
          .then((response) => {
            if (response.data.statusCode === 200) {
              toast.success("Subscription Package created successfully");
              getSubscriptionPackages();
            } else {
              toast.error("Failed to create Subscription Package");
            }
            props.resetForm();
            setSelectedStatus({
              value: 1,
              label: "Active",
            });
            setSelectedDuration({
              value: 1,
              label: "Monthly",
            });
            setUpdateData({
              packageName: "",
              arabicPackageName: "",
              packageDescription: "",
              arabicPackageDescription: "",
              planType: 1,
              basePrice: "",
              status: 1,
              noOfDays:0,

            });
            if (!saveNmore) {
              handleClose();
            }
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            props.resetForm();
            setSelectedStatus({
              value: 1,
              label: "Active",
            });
            setSelectedDuration({
              value: 1,
              label: "Monthly",
            });
            setUpdateData({
              packageName: "",
              arabicPackageName: "",
              packageDescription: "",
              arabicPackageDescription: "",
              planType: 1,
              basePrice: "",
              status: 1,
            });
            if (!saveNmore) {
              handleClose();
            }
          });
      } else {
        APIService.patch("admin/subscription/" + updateData.id, formInfo)
          .then((response) => {
            if (response.data.statusCode === 200) {
              toast.success("Subscription Package updated successfully");
              getSubscriptionPackages();
            } else {
              toast.error("Failed to update Subscription Package");
            }
            handleCloseUpdate();
          })
          .catch((error) => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            handleCloseUpdate();
          });
      }
    };

    let validationSchema = Yup.object().shape({
      packageName: Yup.string().required("Please enter title"),
      planType: Yup.string().optional("Please select duration").nullable(),
      basePrice: Yup.number().required("Please enter price"),
      status: Yup.string().optional("Select Status").nullable(),
      // carMake: Yup.string().required("Please enter Car Maker"),
      // carModel: Yup.string().required("Please enter Car Model"),

      // AssignTo: Yup.string().required("Select Assign To"),
      // category: Yup.string().required("Select Category"),
    }),
      updateTableData = (rowData) => {
        setSelectedStatus({
          value: rowData.status.toLowerCase() === "active" ? 1 : 0,
          label: rowData.status,
        });
        setSelectedDuration({
          value: rowData.planType.toLowerCase() === "monthly" ? 1 : 2,
          label: rowData.planType,
        });
        setUpdateData(rowData);
        setShowUpdate(true);
      },

      // header for table csv 
      headers = [
        { label: 'ID', key: 'id' },
        { label: 'Package Name', key: 'packageName' },
        { label: 'Amount', key: 'finalPrice' },
        { label: 'Subs Validity', key: 'planType' },
        { label: 'Subscribers', key: 'totalSubscribers' },
        { label: 'Start Date', key: 'startDate' },
        { label: 'End Date', key: 'endDate' },
        { label: 'Status', key: 'status' }
      ],



      getSubscriptionPackages = () => {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.get("admin/subscription")
          .then((response) => {
            if (response.data && response.data.statusCode === 200) {
              let packages = response.data.data
                .map((subPackage) => {
                  subPackage.status = subPackage.status ? "Active" : "Inactive";
                  subPackage.planType =
                    subPackage.planType === 1 ? "Monthly" : "Yearly";
                  return subPackage;
                })
                .sort((subPackage, subPackage2) => {
                  return (
                    new Date(subPackage2.createdAt) -
                    new Date(subPackage.createdAt)
                  );
                });
              setData(packages);
              const filtered = packages?.slice(0, 10);
              setFilteredData(filtered);

              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            }
          })
          .catch(() => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      },
      getCabCategories = () => {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.get("/admin/cab-type/all")
          .then((response) => {
            if (response.data && response.data.statusCode === 200) {
              setCabTypes(response.data.data)
              let cabDetail = response.data.data
                .map((cab, index) => {
                  let cabItem = {
                    label: cab.name,
                    value: cab.id
                  }

                  return cabItem;
                })

                setCabTypes([{ label: 'All', value: '' }, ...cabDetail]);


              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            }

          })
          .catch(() => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      },
      removeData = () => {
        handleCloseDelete();
        APIService.delete("admin/subscription/" + updateData.id)
          .then((response) => {
            if (response.data && response.data.statusCode === 200) {
              getSubscriptionPackages();
              toast.success("Subscription Package deleted successfully");
            }
          })
          .catch(() => {
            toast.error("Failed to delete Subscription Package");
          });
      };
    const template = {
      layout:
        "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink ",
    };
    console.log('cananna',cabTypes)
    let renderHeader = () => {
      return (
        <div className="table-header">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                // getActiveSubscriptions();
              }}
            />
          </span>
          <span>
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                <i className="fa fa-filter"></i>
              ) : showFilter ? (
                "Hide Filter"
              ) : (
                "Show Filter"
              )}
            </button>
          </span>
        </div>
      );
    },
      header = renderHeader(),
      toggleFilter = () => {
        setShowFilter(!showFilter);
        setStartDate([]);
        setLazyParams({
          filters: {},
          sort: {
            field: "startDate",
            order: -1,
          },
          take: 25,
          skip: 0,
          keyword: "",
        });
      };
    useEffect(() => {
      getCabCategories();
    }, []);

    // get subscriptions data into csv file 
    const downloadCsvFile = () => {

      APIService.get("admin/subscription")
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            let packages = response.data.data
              .map((subPackage) => {
                subPackage.status = subPackage.status ? "Active" : "Inactive";
                subPackage.planType =
                  subPackage.planType === 1 ? "Monthly" : "Yearly";
                return subPackage;
              })
              .sort((subPackage, subPackage2) => {
                return (
                  new Date(subPackage2.createdAt) -
                  new Date(subPackage.createdAt)
                );
              });
            // setData(packages);
            const filtered = packages?.slice(0, 10);

            setDownloadCsv(filtered);
            setTimeout(() => {

              CSVdownloadRef.current.link.click();
            }, 500)

            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          }
        })
        .catch(() => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });


    }
    return (
      <>
        {/* <div className="rider-listing"> */}
        {/* <span className="title">Subscription Package</span>
          <button
            className="btn btn-primary float-right mb-1"
            onClick={handleShow}
          >
            {windowWidth ? <i className="fa fa-plus"></i> : "Add +"}
          </button> */}
        {/* <SubscriptionsPackageListingTable
            data={data}
            handleShow={handleShow}
            handleShowUpdate={handleShowUpdate}
            handleShowDelete={handleShowDelete}
            updateTableData={updateTableData}
            isLoading={useSelector((state) => state.isGridLoader)}
          /> */}
        <div className="d-box-subscription mt-3">
          <div className="table-header">
            <span className="toggle-filter">
              <button
                className="btn btn-primary show_filter_btn"
                onClick={() => toggleFilter()}
              >
                {windowWidth ? (
                  showFilter ? (
                    <span>
                      <i className="fa fa-filter"></i>
                      <i className="fa fa-caret-up ml-10"></i>
                    </span>
                  ) : (
                    <span>
                      <i className="fa fa-filter"></i>
                      <i className="fa fa-caret-down ml-10"></i>
                    </span>
                  )
                ) : // <i className="fa fa-filter"></i>
                  showFilter ? (
                    <>
                      <img src="/images/filter-icon.svg" alt="" />

                      <span className="span-filter mx-12">Filters</span>
                      <i className="fa fa-caret-up"></i>
                    </>
                  ) : (
                    <>
                      <img src="/images/filter-icon.svg" alt="" />
                      <span className="span-filter mx-12">Filters</span>
                      <svg
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                          fill="#828282"
                        />
                      </svg>
                    </>
                  )}
              </button>
            </span>
            <span className="p-input-icon-left d-flex align-items-center">
              <i className="pi pi-search" />
              <InputText
                type="search"
                placeholder="Search"
                value={lazyParams.keyword}
                onInput={(e) => {
                  lazyParams = {
                    ...lazyParams,
                    skip: 0,
                    take: 25,
                    keyword: e.target.value,
                  };
                  setLazyParams(lazyParams);
                  // getActiveSubscriptions();
                }}
              />
            </span>
            {/* <span className="title">Subscription Package</span> */}
            <CSVLink
              data={downlaodCsv}
              headers={headers}
              filename='Subscriptions.csv'
              target='_blank'
              ref={CSVdownloadRef}
            />
            <button className="btn btn-primary float-right mb-1 ml-12" onClick={() => downloadCsvFile()}>
              Export
            </button>
            {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'add') && <button
              className="btn btn-primary float-right mb-1 ml-12"
              onClick={handleShow}
            >
              {windowWidth ? <i className="fa fa-plus"></i> : "+ Add"}
            </button>}
          </div>
          <div className="row mt-3">
            <div className="col-12 ">
              {/* <!-- Nav pills --> */}
              <ul className="nav nav-pills subscription-tabs">
                <li className="nav-item">
                  <a className="nav-link active" data-toggle="pill" href="#all">
                    All
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="pill" href="#all">
                    Active (24)
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="pill" href="#all">
                    Inactive (12)
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="pill" href="#all">
                    Pending (09)
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="pill" href="#all">
                    Waiting for approval (03)
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link " data-toggle="pill" href="#all">
                    Wating for review (02)
                  </a>
                </li>

                {/* <li className="nav-item">
              <a className="nav-link" data-toggle="pill" href="#active">
                Active
                
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="pill" href="#inactive">
                Inactive
               
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="pill" href="#pending">
                Pending
               
              </a>
            </li> */}
                {/* <li className="nav-item">
              <a className="nav-link" data-toggle="pill" href="#approval">
                Waiting for approval
               
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="pill" href="#review">
                Wating for review
               
              </a>
            </li> */}
              </ul>
              <div className="tab-content  ">
                <div className="tab-pane tab-pane-custom active" id="all">
                  <div className="row  mx-0">
                    {filteredData ? (
                      filteredData.map((item, index) => (
                        <div className="col-lg-6" key={index}>
                          <div className="card-custom">
                            <div className="row row-grid">
                              <div className="col-6 col-lg-3">
                                <p className="sub-heading"> Sr. No. </p>
                                <p className="sub-stats">{index + 1} </p>
                              </div>
                              <div className="col-6 col-lg-3">
                                <p className="sub-heading"> Description </p>
                                <p className="sub-stats"> {item.packageName}</p>
                              </div>
                              <div className="col-6 col-lg-3">
                                <p className="sub-heading"> Amount </p>
                                <p className="sub-stats">
                                  {" "}
                                  {item?.finalPrice} SAR.
                                </p>
                              </div>
                              <div className="col-6 col-lg-3 ">
                                <p className="sub-heading"> Subs. Validity </p>
                                <p className="sub-stats">{item.planType} </p>
                              </div>
                              <div className="col-6 col-lg-3 ">
                                <p className="sub-heading"> Subscribers </p>
                                <p className="sub-stats">
                                  {" "}
                                  {item.totalSubscribers}{" "}{'KMS'}
                                </p>
                              </div>
                              <div className="col-6 col-lg-3 ">
                                <p className="sub-heading"> Start date </p>
                                <p className="sub-stats">
                                  {" "}
                                  {item.createdAt
                                    ? moment(item.createdAt).format(
                                      "DD/MM/YYYY"
                                    )
                                    : "--"}
                                </p>
                              </div>
                              <div className="col-6 col-lg-3 ">
                                <p className="sub-heading"> End date </p>
                                <p className="sub-stats">
                                  {item.endDate
                                    ? moment(item.endDate).format("DD/MM/YYYY")
                                    : "--"}
                                </p>
                              </div>
                              <div className="col-6 col-lg-3 ">
                                <p className="sub-heading"> Status </p>

                                {item.status == "Active" ? (
                                  <span className="status-text">
                                    {" "}
                                    {item.status}
                                  </span>
                                ) : (
                                  <span className="status-text-inactive">
                                    {" "}
                                    {item.status}
                                  </span>
                                )}

                                {/* {item.status == 1 ? (
                                    <span className="status-text">Active</span>
                                  ) : (
                                    <span className="status-text-inactive">
                                      Inactive
                                    </span>
                                  )} */}
                              </div>
                            </div>
                            <div className="line-break"></div>
                            <div className="row justify-content-between">
                              <div className="col-md-7">
                                <p className="sub-heading">Promo/Discount </p>
                                <p className="sub-stats">
                                  {" "}
                                  --
                                  {/* {item.packageDescription} */}
                                  {/* 10% of Ride fare maximum for 3 rides{" "} */}
                                </p>
                              </div>
                              <div className="col-md-5 text-md-right my-auto">
                                <Link to={`/subscription-packages-detail/${item?.id}`} className="link-success">
                                  View Subscribers
                                </Link>
                                <div>
                                  {/* <button
                                    className="border-0 btn btn-theme-green "
                                    style={{
                                      backgroundColor: "#13B542 !important",
                                    }}
                                    tooltip="Edit"
                                    tooltipOptions={{ position: "bottom" }}
                                    onClick={() => {
                                      updateTableData(item);
                                    }}
                                  >
                                    <svg
                                      width="19"
                                      height="20"
                                      viewBox="0 0 19 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M3.914 14L14.056 3.85796L12.642 2.44396L2.5 12.586V14H3.914ZM4.743 16H0.5V11.757L11.935 0.321961C12.1225 0.13449 12.3768 0.0291748 12.642 0.0291748C12.9072 0.0291748 13.1615 0.13449 13.349 0.321961L16.178 3.15096C16.3655 3.33849 16.4708 3.5928 16.4708 3.85796C16.4708 4.12313 16.3655 4.37743 16.178 4.56496L4.743 16ZM0.5 18H18.5V20H0.5V18Z"
                                        fill="#ffffff"
                                      />
                                    </svg>
                                  </button> */}
                                  {/* <button
                                    className="ml-2 border-0 btn btn-theme-red"
                                    tooltip="Delete"
                                    tooltipOptions={{ position: "bottom" }}
                                    onClick={() => {
                                      handleShowDelete(item);
                                    }}
                                  >
                                    <svg
                                      width="21"
                                      height="20"
                                      viewBox="0 0 21 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M15.5 4H20.5V6H18.5V19C18.5 19.2652 18.3946 19.5196 18.2071 19.7071C18.0196 19.8946 17.7652 20 17.5 20H3.5C3.23478 20 2.98043 19.8946 2.79289 19.7071C2.60536 19.5196 2.5 19.2652 2.5 19V6H0.5V4H5.5V1C5.5 0.734784 5.60536 0.48043 5.79289 0.292893C5.98043 0.105357 6.23478 0 6.5 0H14.5C14.7652 0 15.0196 0.105357 15.2071 0.292893C15.3946 0.48043 15.5 0.734784 15.5 1V4ZM16.5 6H4.5V18H16.5V6ZM7.5 2V4H13.5V2H7.5Z"
                                        fill="#ffffff"
                                      />
                                    </svg>
                                  </button> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="loader">
                        <i className="fa fa-spinner"></i>
                      </div>
                    )}

                    {/* <div className="col-lg-6">
                  <div className="card-custom">
                    <div className="row row-grid">
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Sr. No. </p>
                        <p className="sub-stats"> 01 </p>
                      </div>
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Description </p>
                        <p className="sub-stats"> SM1000 </p>
                      </div>
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Amount </p>
                        <p className="sub-stats"> 199 SAR.</p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Subs. Validity </p>
                        <p className="sub-stats"> 3 Months</p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Total KMs </p>
                        <p className="sub-stats"> 10 KMs </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Start date </p>
                        <p className="sub-stats"> 24/09/2022 </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> End date </p>
                        <p className="sub-stats"> 30/09/2022 </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Status </p>
                        <a href="#" className="badge badge-success">
                          {" "}
                          Active{" "}
                        </a>
                      </div>
                    </div>
                    <div className="line-break"></div>
                    <div className="row justify-content-between">
                      <div className="col-md-7">
                        <p className="sub-heading"> Promo/Discount </p>
                        <p className="sub-stats">
                          {" "}
                          10% of Ride fare maximum for 3 rides{" "}
                        </p>
                      </div>
                      <div className="col-md-5 text-md-right my-auto">
                        <Link to="#" className="link-success">
                          View Subscribers
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card-custom">
                    <div className="row row-grid">
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Sr. No. </p>
                        <p className="sub-stats"> 01 </p>
                      </div>
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Description </p>
                        <p className="sub-stats"> SM1000 </p>
                      </div>
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Amount </p>
                        <p className="sub-stats"> 199 SAR.</p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Subs. Validity </p>
                        <p className="sub-stats"> 3 Months</p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Total KMs </p>
                        <p className="sub-stats"> 10 KMs </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Start date </p>
                        <p className="sub-stats"> 24/09/2022 </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> End date </p>
                        <p className="sub-stats"> 30/09/2022 </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Status </p>
                        <a href="#" className="badge badge-success">
                          {" "}
                          Active{" "}
                        </a>
                      </div>
                    </div>
                    <div className="line-break"></div>
                    <div className="row justify-content-between">
                      <div className="col-md-7">
                        <p className="sub-heading"> Promo/Discount </p>
                        <p className="sub-stats">
                          {" "}
                          10% of Ride fare maximum for 3 rides{" "}
                        </p>
                      </div>
                      <div className="col-md-5 text-md-right my-auto">
                        <Link to="#" className="link-success">
                          View Subscribers
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card-custom">
                    <div className="row row-grid">
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Sr. No. </p>
                        <p className="sub-stats"> 01 </p>
                      </div>
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Description </p>
                        <p className="sub-stats"> SM1000 </p>
                      </div>
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Amount </p>
                        <p className="sub-stats"> 199 SAR.</p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Subs. Validity </p>
                        <p className="sub-stats"> 3 Months</p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Total KMs </p>
                        <p className="sub-stats"> 10 KMs </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Start date </p>
                        <p className="sub-stats"> 24/09/2022 </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> End date </p>
                        <p className="sub-stats"> 30/09/2022 </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Status </p>
                        <a href="#" className="badge badge-success">
                          {" "}
                          Active{" "}
                        </a>
                      </div>
                    </div>
                    <div className="line-break"></div>
                    <div className="row justify-content-between">
                      <div className="col-md-7">
                        <p className="sub-heading"> Promo/Discount </p>
                        <p className="sub-stats">
                          {" "}
                          10% of Ride fare maximum for 3 rides{" "}
                        </p>
                      </div>
                      <div className="col-md-5 text-md-right my-auto">
                        <Link to="#" className="link-success">
                          View Subscribers
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card-custom">
                    <div className="row row-grid">
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Sr. No. </p>
                        <p className="sub-stats"> 01 </p>
                      </div>
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Description </p>
                        <p className="sub-stats"> SM1000 </p>
                      </div>
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Amount </p>
                        <p className="sub-stats"> 199 SAR.</p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Subs. Validity </p>
                        <p className="sub-stats"> 3 Months</p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Total KMs </p>
                        <p className="sub-stats"> 10 KMs </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Start date </p>
                        <p className="sub-stats"> 24/09/2022 </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> End date </p>
                        <p className="sub-stats"> 30/09/2022 </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Status </p>
                        <a href="#" className="badge badge-success">
                          {" "}
                          Active{" "}
                        </a>
                      </div>
                    </div>
                    <div className="line-break"></div>
                    <div className="row justify-content-between">
                      <div className="col-md-7">
                        <p className="sub-heading"> Promo/Discount </p>
                        <p className="sub-stats">
                          {" "}
                          10% of Ride fare maximum for 3 rides{" "}
                        </p>
                      </div>
                      <div className="col-md-5 text-md-right my-auto">
                        <Link to="#" className="link-success">
                          View Subscribers
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card-custom">
                    <div className="row row-grid">
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Sr. No. </p>
                        <p className="sub-stats"> 01 </p>
                      </div>
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Description </p>
                        <p className="sub-stats"> SM1000 </p>
                      </div>
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Amount </p>
                        <p className="sub-stats"> 199 SAR.</p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Subs. Validity </p>
                        <p className="sub-stats"> 3 Months</p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Total KMs </p>
                        <p className="sub-stats"> 10 KMs </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Start date </p>
                        <p className="sub-stats"> 24/09/2022 </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> End date </p>
                        <p className="sub-stats"> 30/09/2022 </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Status </p>
                        <a href="#" className="badge badge-success">
                          {" "}
                          Active{" "}
                        </a>
                      </div>
                    </div>
                    <div className="line-break"></div>
                    <div className="row justify-content-between">
                      <div className="col-md-7">
                        <p className="sub-heading"> Promo/Discount </p>
                        <p className="sub-stats">
                          {" "}
                          10% of Ride fare maximum for 3 rides{" "}
                        </p>
                      </div>
                      <div className="col-md-5 text-md-right my-auto">
                        <Link to="#" className="link-success">
                          View Subscribers
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card-custom">
                    <div className="row row-grid">
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Sr. No. </p>
                        <p className="sub-stats"> 01 </p>
                      </div>
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Description </p>
                        <p className="sub-stats"> SM1000 </p>
                      </div>
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Amount </p>
                        <p className="sub-stats"> 199 SAR.</p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Subs. Validity </p>
                        <p className="sub-stats"> 3 Months</p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Total KMs </p>
                        <p className="sub-stats"> 10 KMs </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Start date </p>
                        <p className="sub-stats"> 24/09/2022 </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> End date </p>
                        <p className="sub-stats"> 30/09/2022 </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Status </p>
                        <a href="#" className="badge badge-success">
                          {" "}
                          Active{" "}
                        </a>
                      </div>
                    </div>
                    <div className="line-break"></div>
                    <div className="row justify-content-between">
                      <div className="col-md-7">
                        <p className="sub-heading"> Promo/Discount </p>
                        <p className="sub-stats">
                          {" "}
                          10% of Ride fare maximum for 3 rides{" "}
                        </p>
                      </div>
                      <div className="col-md-5 text-md-right my-auto">
                        <Link to="#" className="link-success">
                          View Subscribers
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card-custom">
                    <div className="row row-grid">
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Sr. No. </p>
                        <p className="sub-stats"> 01 </p>
                      </div>
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Description </p>
                        <p className="sub-stats"> SM1000 </p>
                      </div>
                      <div className="col-6 col-lg-3">
                        <p className="sub-heading"> Amount </p>
                        <p className="sub-stats"> 199 SAR.</p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Subs. Validity </p>
                        <p className="sub-stats"> 3 Months</p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Total KMs </p>
                        <p className="sub-stats"> 10 KMs </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Start date </p>
                        <p className="sub-stats"> 24/09/2022 </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> End date </p>
                        <p className="sub-stats"> 30/09/2022 </p>
                      </div>
                      <div className="col-6 col-lg-3 ">
                        <p className="sub-heading"> Status </p>
                        <a href="#" className="badge badge-success">
                          {" "}
                          Active{" "}
                        </a>
                      </div>
                    </div>
                    <div className="line-break"></div>
                    <div className="row justify-content-between">
                      <div className="col-md-7">
                        <p className="sub-heading"> Promo/Discount </p>
                        <p className="sub-stats">
                          {" "}
                          10% of Ride fare maximum for 3 rides{" "}
                        </p>
                      </div>
                      <div className="col-md-5 text-md-right my-auto">
                        <Link to="#" className="link-success">
                          View Subscribers
                        </Link>
                      </div>
                    </div>
                  </div>
                </div> */}
                  </div>
                </div>
                <div className="tab-pane tab-pane-custom fade" id="active">
                  Active
                </div>
                <div className="tab-pane tab-pane-custom fade" id="inactive">
                  Inactive
                </div>
                <div className="tab-pane tab-pane-custom fade" id="pending">
                  Pending
                </div>
                <div className="tab-pane tab-pane-custom fade" id="approval">
                  Approval
                </div>
                <div className="tab-pane tab-pane-custom fade" id="review">
                  Review
                </div>
              </div>
            </div>
          </div>

          <Paginator
            ref={dt}
            template={template}
            // first={lazyParams.skip}
            first={basicFirst}
            rows={basicRows}
            value={data}
            totalRecords={data?.length}
            rowsPerPageOptions={[5, 10, 25, 50]}
            selection={selectedCustomers}
            onSelectionChange={(e) => setSelectedCustomers(e.value)}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            // onPageChange={manageActiveSubscription}
            onPageChange={onBasicPageChange}
          ></Paginator>
        </div>
        {/* </div> */}

        <Modal show={show} onHide={handleClose} centered size="lg">
          <Formik
            initialValues={updateData}
            validationSchema={validationSchema}
            onSubmit={createOrUpdateSubscription}
          >
            {({ handleBlur, setFieldValue }) => {
              return (
                <Form>
                  <Modal.Header>
                    <Modal.Title className="modal-title">
                      Add New Subscription
                    </Modal.Title>
                    <div className="divclose" onClick={handleClose}>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </Modal.Header>

                  <Modal.Body className="mt-24">
                    <Row>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="packageName"
                            type="text"
                            name="packageName"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="packageName"
                          />
                          <label htmlFor="packageName">Name</label>
                          <ErrorMessage
                            name="packageName"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="arabicPackageName"
                            type="text"
                            name="arabicPackageName"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="arabicPackageName"
                          />
                          <label htmlFor="arabicPackageName">Arabic Name</label>
                          <ErrorMessage
                            name="arabicPackageName"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="packageDescription"
                            type="text"
                            name="packageDescription"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="packageDescription"
                          />
                          <label htmlFor="packageDescription">Description</label>
                          <ErrorMessage
                            name="packageDescription"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="arabicPackageDescription"
                            type="text"
                            name="arabicPackageDescription"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="arabicPackageDescription"
                          />
                          <label htmlFor="arabicPackageDescription">Arabic Description</label>
                          <ErrorMessage
                            name="arabicPackageDescription"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Assign To"
                            name="AssignTo"
                            styles={colourStyles}
                            id="AssignTo"
                            onBlur={() => {
                              handleBlur({ target: { name: "AssignTo" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("AssignTo", e.value);
                                setSelectedAssign(e);
                              }
                            }}
                            // value={selectedAssign}
                            options={assignToOptions}
                          />
                          <label htmlFor="">Assign To</label>
                          <ErrorMessage
                            name="AssignTo"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                          {/* {(!selectedDuration || !selectedDuration?.value) && (
                            <ErrorMessage
                              name="AssignTo"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )} */}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Category"
                            name="category"
                            styles={colourStyles}
                            id="category"
                            // onBlur={() => {
                            //   handleBlur({ target: { name: "category" } });
                            // }}
                            onChange={(e) => {
                              console.log('eeeee',e)
                              if (e) {
                                setFieldValue("cabType", e.value);
                                setSelectedCategory(e?.value);
                              }
                            }}
                            value={selectedCategory?.value}
                            options={cabTypes}
                          />
                          <label htmlFor="">Category</label>
                          <ErrorMessage
                            name="category"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                          {/* {(!selectedDuration || !selectedDuration?.value) && (
                            <ErrorMessage
                              name="Category"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )} */}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="basePrice"
                            type="number"
                            name="basePrice"
                            className="shadow-none form-control modal-input br-10 "
                            placeholder="Amount"
                          />
                          <label htmlFor="basePrice">Amount</label>
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              position: "absolute",
                              right: "15px",
                              top: "12px",
                              fontWeight: "bold",
                              color: "#13B542",
                              Background: "#E3F6E8 !important",
                              padding: "9px",
                              width: "45px",
                              borderRadius: "6px",
                              textAlign: "center",
                            }}
                            className="bg-lightGreen"
                          >
                            SAR.
                          </div>
                          <ErrorMessage
                            name="basePrice"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      {/* <Col md={6}>
                      <Row>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="startDate"
                            type="date"
                            name="startDate"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="startDate"
                          />
                          <label htmlFor="startDate">Start Date</label>
                          <ErrorMessage
                            name="startDate"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col> 
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="endDate"
                            type="date"
                            name="endDate"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="endDate"
                          />
                          <label htmlFor="endDate">End Date</label>
                          <ErrorMessage
                            name="endDate"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col> 
                      </Row>
                      </Col> */}
                      <Col md={6}>
                        <Row>
                          <Col md={6} className="pl-0">
                            <div className="form-group form-label-group">
                              <Field
                                id="startDate"
                                type="date"
                                name="startDate"
                                className="shadow-none form-control modal-input br-10"
                                placeholder="startDate"
                              />
                              <label htmlFor="startDate">Start Date</label>
                              <ErrorMessage
                                name="startDate"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </div>
                          </Col>
                          <Col md={6} className="pr-0">
                            <div className="form-group form-label-group">
                              <Field
                                id="endDate"
                                type="date"
                                name="endDate"
                                className="shadow-none form-control modal-input br-10"
                                placeholder="endDate"
                              />
                              <label htmlFor="endDate">End Date</label>
                              <ErrorMessage
                                name="endDate"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Subscription Validity"
                            name="noOfDays"
                            styles={colourStyles}
                            id="noOfDays"
                            onBlur={() => {
                              handleBlur({ target: { name: "noOfDays" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("noOfDays", e.value);
                                setSelectedDuration(e);
                              }
                            }}
                            // value={selectedDuration}
                            options={dataArray}
                          />
                          <label htmlFor="">Subscription Validity</label>
                          {(!selectedDuration || !selectedDuration?.value) && (
                            <ErrorMessage
                              name="noOfDays"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )}
                        </div>
                      </Col>
               
                     {selectedAssign?.label !== 'Own a Car' && <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="carMake"
                            type="text"
                            name="carMake"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="carMake"
                          />
                          <label htmlFor="carMake">Car Make</label>
                          <ErrorMessage
                            name="carMake"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div></Col>}
                      
                      {selectedAssign?.label !== 'Own a Car' && <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="carModel"
                            type="text"
                            name="carModel"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Car Model"
                          />
                          <label htmlFor="carModel">Car Model</label>
                          <ErrorMessage
                            name="carModel"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>}
                     {selectedAssign?.label !== 'Own a Car' && <Col md={3}>
                        <div className="form-group form-label-group">
                          <Field
                            id="splitToRide"
                            type="number"
                            name="splitToRide"
                            className="shadow-none form-control modal-input br-10 "
                            placeholder="Split To Ride"
                          />
                          <label htmlFor="splitToRide">Split To Ride</label>
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              position: "absolute",
                              right: "15px",
                              top: "12px",
                              fontWeight: "bold",
                              color: "#13B542",
                              Background: "#E3F6E8 !important",
                              padding: "9px",
                              width: "45px",
                              borderRadius: "6px",
                              textAlign: "center",
                            }}
                            className="bg-lightGreen"
                          >
                            %
                          </div>
                          <ErrorMessage
                            name="splitToRide"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>}   
                     {selectedAssign?.label !== 'Own a Car' &&  <Col md={3}>
                        <div className="form-group form-label-group">
                          <Field
                            id="splitToCaptain"
                            type="number"
                            name="splitToCaptain"
                            className="shadow-none form-control modal-input br-10 "
                            placeholder="Amount"
                          />
                          <label htmlFor="splitToCaptain">Split To Captain</label>
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              position: "absolute",
                              right: "15px",
                              top: "12px",
                              fontWeight: "bold",
                              color: "#13B542",
                              Background: "#E3F6E8 !important",
                              padding: "9px",
                              width: "45px",
                              borderRadius: "6px",
                              textAlign: "center",
                            }}
                            className="bg-lightGreen"
                          >
                           %
                                                     </div>
                          <ErrorMessage
                            name="splitToCaptain"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>}
                      {/* <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="carMake"
                            type="text"
                            name="carMake"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="carMake"
                          />
                          <label htmlFor="carMake">Car Make</label>
                          <ErrorMessage
                            name="carMake"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="carModel"
                            type="text"
                            name="carModel"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="carModel"
                          />
                          <label htmlFor="carModel">Car Model</label>
                          <ErrorMessage
                            name="carModel"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                      <Row>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="splitRide"
                            type="number"
                            name="splitRide"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="splitRide"
                          />
                          <label htmlFor="splitRide">Split To Ride</label>
                          <div
                          style={{
                            display: 'block',
                            fontSize: "12px",
                            position: "absolute",
                            right: "15px",
                            top: "12px",
                            fontWeight: "bold",
                            color: "#13B542",
                            Background: "#E3F6E8 !important",
                            padding: '9px 2px',
                            width: '25px',
                            borderRadius:'6px',
                            textAlign: 'center'
                          }}
                          className="bg-lightGreen"
                        >
                           
                          % 
                        </div>
                         
                          <ErrorMessage
                            name="splitRide"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col> 
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="splitCaptain"
                            type="number"
                            name="splitCaptain"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="splitCaptain"
                          />
                          <label htmlFor="splitCaptain">Split To Captain</label>
                          <div
                          style={{
                            display: 'block',
                            fontSize: "12px",
                            position: "absolute",
                            right: "15px",
                            top: "12px",
                            fontWeight: "bold",
                            color: "#13B542",
                            Background: "#E3F6E8 !important",
                            padding: '9px 2px',
                            width: '25px',
                            borderRadius:'6px',
                            textAlign: 'center'
                          }}
                          className="bg-lightGreen"
                        >
                           
                          % 
                        </div>
                          <ErrorMessage
                            name="splitCaptain"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col> 
                      </Row>
                      </Col>
                      //change
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Promo Applicable?"
                            name="promo"
                            styles={colourStyles}
                            id="promo"
                            onBlur={() => {
                              handleBlur({ target: { name: "promo" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("promo", e.value);
                                setSelectedStatus(e);
                              }
                            }}
                            value={selectedStatus}
                            options={statusOptions}
                          />
                          <label> Promo Applicable </label>
                          {(!selectedStatus || !selectedStatus?.value) && (
                            <ErrorMessage
                              name="promo"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )}
                        </div>
                      </Col> */}
                      {/* <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="packageName"
                            type="text"
                            name="packageName"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="packageName"
                          />
                          <label htmlFor="packageName">Title</label>
                          <ErrorMessage
                            name="packageName"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col> */}

                      {/* <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Select Status"
                            name="status"
                            styles={colourStyles}
                            id="status"
                            onBlur={() => {
                              handleBlur({ target: { name: "status" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("status", e.value);
                                setSelectedStatus(e);
                              }
                            }}
                            value={selectedStatus}
                            options={statusOptions}
                          />
                          <label htmlFor="">Status</label>
                          {(!selectedStatus || !selectedStatus?.value) && (
                            <ErrorMessage
                              name="status"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )}
                        </div>
                      </Col> */}
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Promo Applicable?"
                            name="isPromoApplicable"
                            styles={colourStyles}
                            id="isPromoApplicable"
                            onBlur={() => {
                              handleBlur({ target: { name: "isPromoApplicable" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("isPromoApplicable", e.value);
                                setSelectedStatus(e);
                              }
                            }}
                            // value={selectedPromoStaus}
                            options={promoOptions}
                          />
                          <label> Promo Applicable? </label>

                          <ErrorMessage
                            name="isPromoApplicable"
                            component="div"
                            className="invalid-feedback text-danger"
                          />

                        </div>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col md={6}>
                        <Button
                          variant="primary"
                          type="submit"
                          className="btn-save save-button "
                          onClick={() => setSaveNmore(false)}
                        >
                          Create
                        </Button>
                      </Col>
                    </Row>
                  </Modal.Body>
                  {/* <Modal.Footer className="padding-header-footer">
                 
                    <Button
                      variant="secondary"
                      type="submit"
                      className="btn-save-add save-button mb-3 border-radius-15"
                      onClick={() => setSaveNmore(true)}
                    >
                      Save & Add more
                    </Button>
                    <Button
                      variant="light"
                      className="btn-cancel save-button mb-3 border-radius-15"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer> */}
                </Form>
              );
            }}
          </Formik>
        </Modal>

        <Modal show={showUpdate} onHide={handleCloseUpdate} centered size="lg">
          <Formik
            initialValues={{ ...updateData }}
            validationSchema={validationSchema}
            onSubmit={createOrUpdateSubscription}
          >
            {({ handleBlur, setFieldValue }) => {
              return (
                <Form>
                  <Modal.Header>
                    <Modal.Title className="modal-title">
                      Update Subscription Package
                    </Modal.Title>
                    <div className="divclose" onClick={handleClose}>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseUpdate}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </Modal.Header>

                  <Modal.Body className="mt-24">
                    <Row>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="packageName"
                            type="text"
                            name="packageName"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Package Name"
                          />
                          <label htmlFor="packageName">Name</label>
                          <ErrorMessage
                            name="packageName"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Assign To"
                            name="AssignTo"
                            styles={colourStyles}
                            id="AssignTo"
                            onBlur={() => {
                              handleBlur({ target: { name: "AssignTo" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("AssignTo", e.value);
                                setSelectedAssign(e);
                              }
                            }}
                            value={selectedAssign}
                            options={assignToOptions}
                          />
                          <label htmlFor="">Assign To</label>
                          {/* {(!selectedDuration || !selectedDuration?.value) && (
                            <ErrorMessage
                              name="AssignTo"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )} */}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Category"
                            name="Category"
                            styles={colourStyles}
                            id="Category"
                            onBlur={() => {
                              handleBlur({ target: { name: "Category" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("Category", e.value);
                                setSelectedCategory(e);
                              }
                            }}
                            value={selectedCategory}
                            options={cabTypes}
                          />
                          <label htmlFor="">Category</label>
                          {/* {(!selectedDuration || !selectedDuration?.value) && (
                            <ErrorMessage
                              name="Category"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )} */}
                        </div>
                      </Col>
                      <Col xl={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="basePrice"
                            type="number"
                            name="basePrice"
                            className="shadow-none form-control modal-input br-10 "
                            placeholder="Amount"
                          />
                          <label htmlFor="basePrice">Amount</label>
                          <div
                            style={{
                              display: "block",
                              fontSize: "12px",
                              position: "absolute",
                              right: "15px",
                              top: "12px",
                              fontWeight: "bold",
                              color: "#13B542",
                              Background: "#E3F6E8 !important",
                              padding: "9px",
                              width: "45px",
                              borderRadius: "6px",
                              textAlign: "center",
                            }}
                            className="bg-lightGreen"
                          >
                            SAR.
                          </div>
                          <ErrorMessage
                            name="basePrice"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col md={6} className="pl-0">
                            <div className="form-group form-label-group">
                              <Field
                                id="startDate"
                                type="date"
                                name="startDate"
                                className="shadow-none form-control modal-input br-10"
                                placeholder="startDate"
                              />
                              <label htmlFor="startDate">Start Date</label>
                              <ErrorMessage
                                name="startDate"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </div>
                          </Col>
                          <Col md={6} className="pr-0">
                            <div className="form-group form-label-group">
                              <Field
                                id="endDate"
                                type="date"
                                name="endDate"
                                className="shadow-none form-control modal-input br-10"
                                placeholder="endDate"
                              />
                              <label htmlFor="endDate">End Date</label>
                              <ErrorMessage
                                name="endDate"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6}>
                        <div className="mb-4  form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Subscription Validity"
                            name="noOfDays"
                            styles={colourStyles}
                            id="noOfDays"
                            onBlur={() => {
                              handleBlur({ target: { name: "noOfDays" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("noOfDays", e.value);
                                setSelectedDuration(e);
                              }
                            }}
                            value={selectedDuration}
                            options={durationOptions}
                          />
                          <label htmlFor="">Subscription Validity</label>

                          {(!selectedDuration || !selectedDuration?.value) && (
                            <ErrorMessage
                              name="noOfDays"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )}
                        </div>
                      </Col>

                      <Col xl={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="packageName"
                            type="text"
                            name="packageName"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="packageName"
                          />
                          <label htmlFor="packageName">Title</label>
                          <ErrorMessage
                            name="packageName"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      {/* <Col xl={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            placeholder="Select Status"
                            name="status"
                            styles={colourStyles}
                            id="status"
                            onBlur={() => {
                              handleBlur({ target: { name: "status" } });
                            }}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("status", e.value);
                                setSelectedStatus(e);
                              }
                            }}
                            value={selectedStatus}
                            options={statusOptions}
                          />
                          <label htmlFor=""> Status</label>
                          {(!selectedStatus || !selectedStatus?.value) && (
                            <ErrorMessage
                              name="status"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )}
                        </div>
                      </Col> */}
                    </Row>
                    <Row className="justify-content-center">
                      <Col xl={6}>
                        <Button
                          variant="primary"
                          className="btn-save save-button "
                          type="submit"
                        >
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Modal.Body>
                  {/* <Modal.Footer className="padding-header-footer">
                   
                    <Button
                      variant="light"
                      className="btn-cancel save-button mb-3 border-radius-15"
                      onClick={handleCloseUpdate}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer> */}
                </Form>
              );
            }}
          </Formik>
        </Modal>

        <DeleteModal
          showDelete={showDelete}
          clickYes={removeData}
          handleCloseDelete={handleCloseDelete}
        />
      </>
    );
  };

export default React.memo(connect()(SubscriptionsPackage));
