import React, { useState, useEffect } from "react";
import Calendar from "./Calendar";
import APIService from "../../services/APIService";
import moment from "moment";
function TotalCaptains(props) {
  const {
    activeDriversPercentage,
    waslApprovedCount,
    topCardDriver,
    locationDataset,
    rideNamesData,
  } = props;
  const [captainData, setCaptainData] = useState({});
  const [waslData, setWaslData] = useState({});
  const [applyFilter, setApplyFilter] = useState(false);
  const [locationData, setLocationsData] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [ridesNames, setRidesNames] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    id: "",
    name: "",
  });
  console.log(
    "rideNamesDatarideNamesDatarideNamesDatarideNamesData",
    rideNamesData
  );
  const [selectedCity, setSelectedCity] = useState({
    id: "",
    name: "",
  });

  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(ridesNames[0]?.name);
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const getTimeRange = (range) => {
    setStartDate(range.sDate);
    setEndDate(range.eDate);
  };

  useEffect(() => {
    if (rideNamesData) {
      setRidesNames(rideNamesData);
    }
  }, [JSON.stringify(rideNamesData)]);

  useEffect(() => {
    const buildApiUrl = () => {
      // Start building the URL with common parameters
      const baseUrl = `admin/dashboard/wasl-approved-count`;

      const params = {
        fromDate: startDate ? `${startDate} 00:00:00.000000` : "",
        toDate: endDate ? `${endDate} 23:59:59.000000` : "",
        countryId: selectedCountry?.id,
        cityId: selectedCity?.id,
        cabId: selectedValue,
        type: startDate && endDate ? "custom" : "",
      };

      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([_, value]) => value !== undefined && value !== null && value !== ""
        )
      );

      const queryString = new URLSearchParams(filteredParams).toString();

      return queryString ? `${baseUrl}?${queryString}` : baseUrl;
    };

    const fetchData = async () => {
      const url = buildApiUrl();
      if (url) {
        try {
          setApplyFilter(true);

          const response = await APIService.get(url);

          if (response && response.status === 200) {
            const data = response.data?.data || "";
            setWaslData(data);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, [endDate, selectedCountry, selectedCity, selectedValue]);
  useEffect(() => {
    if (locationDataset) {
      setLocationsData(locationDataset);
    }
  }, [JSON.stringify(locationDataset)]);
  const cardStyles = {
    background: `radial-gradient(
      50% 50% at 50% 50%,
      #BA65FF 0%,
      rgba(255, 176, 101, 0) 100%
    ),
    #ffffff`,
  };

  useEffect(() => {
    // Extract country options from the data
    const countries = locationData?.map((country) => ({
      id: country.id,
      name: country.countryName,
    }));
    setCountryOptions(countries);

    // Set the default selected country(
    if (countries?.length > 0) {
      setSelectedCountry(countries[0]);
    }

    // Set the default selected city if available
    const defaultCity = locationData?.length > 0 && locationData[0]?.city[0];
    if (defaultCity) {
      setSelectedCity({ id: "", name: "" });
    }
  }, [JSON.stringify(locationData)]);

  useEffect(() => {
    // Extract city options based on the selected country
    const selectedCountryData = locationData?.find(
      (country) => country.countryName === selectedCountry.name
    );
    if (selectedCountryData) {
      const cities = selectedCountryData?.city?.map((city) => ({
        id: city.id,
        name: city.cityName,
      }));
      setCityOptions(cities);

      // Set the default selected city
      setSelectedCity({});
    }
  }, [selectedCountry, JSON.stringify(locationData)]);

  const handleCountryChange = (e) => {
    const selectedCountryName = e.target.value;
    const selectedCountryData = countryOptions?.find(
      (country) => country.name === selectedCountryName
    );
    setSelectedCountry(selectedCountryData || {});
  };

  const handleCityChange = (e) => {
    const selectedCityName = e.target.value;
    const selectedCityData = cityOptions?.find(
      (city) => city.name === selectedCityName
    );
    setSelectedCity(selectedCityData || {});
  };
  return (
    <>
      <div className=" col-12 cards-section section-captain h-100">
        <div
          className="card-nav zoom-80"
          style={{ marginBottom: "15px", height: "auto" }}
        >
          <div>
            <ul className="nav nav-pills subscription-tabs mb-1">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="pill"
                  href="#captains"
                >
                  Captains
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="pill" href="#pickups">
                  Pickups
                </a>
              </li>
            </ul>
          </div>
          <div>
            <div className="dropdown justify-content-start flex-wrap gap-1">
              <div className="selectbox">
                <select
                  className="select w-100 outline-none border-0"
                  style={{
                    minWidth: "fit-content",
                    height: 35,
                    borderRadius: 5,
                    textTransform: "capitalize",
                  }}
                  value={selectedCountry.name}
                  onChange={handleCountryChange}
                >
                  <option key={""} value={""}>
                    All
                  </option>
                  {countryOptions?.map((country, index) => (
                    <option
                      style={{ textTransform: "capitalize" }}
                      key={index}
                      value={country.name}
                    >
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="selectbox">
                <select
                  className="select w-100 outline-none border-0"
                  style={{
                    minWidth: "fit-content",
                    height: 35,
                    borderRadius: 5,
                    textTransform: "capitalize",
                    maxWidth: "115px",
                  }}
                  value={selectedCity.name}
                  onChange={handleCityChange}
                >
                  <option key={""} value={""}>
                    All
                  </option>
                  {cityOptions?.map((city, index) => (
                    <option key={index} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="selectbox">
                <select
                  className="select w-100 outline-none border-0 "
                  style={{ width: "fit-content", height: 35 }}
                  value={selectedValue} // Set the selected value
                  onChange={handleSelectChange} // Handle changes
                >
                  <option key={""} value={""}>
                    All
                  </option>
                  {ridesNames?.map((ride) => (
                    <option key={ride.id} value={ride.id}>
                      {ride.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className="selectbox">
                <select
                  className="select outline-none border-0 "
                  style={{ width: "fit-content" ,height:35}}
                >
                  <option value="day">Male</option>
                </select>
              </div> */}
              <Calendar handleTimeRange={getTimeRange} />
            </div>
          </div>
        </div>

        <section className="tab-pane fade active show" id="captains">
          <div className="row">
            <div className=" col-lg-12 col-md-12 col-12">
              <h2
                className="box-title box-heading font-weight-bold"
                style={{ marginBottom: 10, fontSize: "24px" }}
              >
                Total Captains:{" "}
                {(waslData?.approved === undefined
                  ? waslApprovedCount?.approved
                  : waslData.approved) || 0}
              </h2>
              {/* <h2 className="box-title box-heading">Total Captains:{captainData?.count ? (captainData.count.active + captainData.count.inActive): topCardDriver?.count}</h2> */}
              <div className="card bg-light p-5 total-captainRespon" style={{border:'1px solid #F7F7F7',flexDirection:'row',justifyContent:'space-evenly',backgroundColor:'#F7F7F7'}}> 
              <div className="card-content">
                  <div className="graphs-section">
                    <div id="circle-1" style={cardStyles}>
                      <span>
                        {(waslData?.approved === undefined
                          ? waslApprovedCount?.approved
                          : waslData.approved) || 0}
                      </span>

                      <div id="circle-3">
                        {(waslData?.pending === undefined
                          ? waslApprovedCount?.pending
                          : waslData.pending) || 0}
                      </div>
                      <div id="circle-4" style={{ left: -15 }}>
                        {(waslData?.rejected === undefined
                          ? waslApprovedCount?.rejected
                          : waslData.rejected) || 0}
                      </div>
                    </div>
                  </div>

                  <div className="flex-cb mt-32 ">
                    <div className="d-flex align-items-center zoom-80">
                      <span
                        className="fig-name fw-bold"
                        style={{ color: "#000000" }}
                      >
                        WASL :{" "}
                      </span>
                    </div>
                    <div className="d-flex gap-3">
                      {" "}
                      <div className="d-flex align-items-center zoom-80">
                        <span className="circle-fig bg-purple"></span>
                        <span className="fig-name">Approved</span>
                      </div>
                      <div className="d-flex align-items-center zoom-80">
                        <span className="circle-fig bg-orange"></span>
                        <span className="fig-name">Pending</span>
                      </div>
                      <div className="d-flex align-items-center zoom-80">
                        <span className="circle-fig bg-red"></span>
                        <span className="fig-name">Rejected</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cardContent">
                  <div className="graphs-section">
                    <div id="circle-1">
                      <span>
                        {(waslData?.waslOnline === undefined
                          ? waslApprovedCount?.waslOnline
                          : waslData?.waslOnline) || 0}
                      </span>
                      {/* <span>{captainData?(captainData.Percentage?.activePercentage ? captainData.Percentage.activePercentage:0) : activeDriversPercentage?.Percentage?.activePercentage}</span> */}
                      <div>
                        {/* (waslData?.approved===undefined ?  waslApprovedCount?.approved : waslData.approved) */}
                        <div id="circle-2">
                          {waslData?.waslOffline === undefined
                            ? waslApprovedCount?.approved -
                                waslApprovedCount?.waslOnline || 0
                            : waslData?.waslOffline}
                        </div>
                        {/* <div id="circle-2">{captainData?(captainData.Percentage?.inActivePercentage ? captainData.Percentage.inActivePercentage:0)  : activeDriversPercentage?.Percentage?.inActivePercentage}</div> */}
                      </div>
                    </div>
                  </div>
                  <div className="flex-cb mt-32">
                    <div className="d-flex align-items-center zoom-80">
                      <span
                        className="fig-name fw-bold"
                        style={{ color: "#000000" }}
                      >
                        Captains:{" "}
                      </span>
                    </div>
                    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig"></span>
                      <span className="fig-name">Online</span>
                    </div>
                    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig bg-red"></span>
                      <span className="fig-name">Offline</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="tab-pane fade coming-soon" id="pickups">
          Coming soon
        </div>
      </div>
    </>
  );
}

export default TotalCaptains;
