import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
export const _apiURL  = process.env.REACT_APP_API_URL;
export const _baseURL  = process.env.REACT_APP_BASEURL;
export const secret_key = process.env.REACT_APP_SECRET_KEY;
export const googleMapApiKey = process.env.REACT_APP_MAP_API_KEY;
export const googleMapGeocodeKey = process.env.REACT_APP_MAP_GEOCODE_API_KEY;

let cancelToken = axios.CancelToken,
  cancel;

if (localStorage.getItem("token")) {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("token");
  axios.defaults.headers.common["sessionId"] = "3f2582d2906068f1";
}
axios.interceptors.request.use(
  (request) => {
    if (
      request.method === "post" &&
      request.data &&
      "skip" in request.data &&
      cancel
    ) {
      cancel();
    }
    request.cancelToken = new cancelToken(function executor(c) {
      cancel = c;
    });
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "login";
      } else if (error.response.status === 403) {
        window.location.pathname = "unauthorised";
      } else if (
        error.response.config.method !== "get" &&
        error.response.data
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

let APIService = axios;
export default APIService;
