import React , {useEffect, useState} from "react";
import { Tab, Tabs } from "react-bootstrap";
import TicketListing from "./TicketListing";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import TicketAnalysis from "./TicketAnalysis";
import OtpLogs from "../../pages/OtpLogs";

let DriverListing = (props) => {
  let [tab, setTab] = useState("activeSub"),
    getActiveTab = () => {
      if (props.match.params.tab) {
        setTab(props.match.params.tab);
      } else {
        setTab("active");
      }
    },
    permissions = JSON.parse(atob(localStorage.getItem("access")));

  useEffect(() => {
    getActiveTab();
  }, []);

  return (
    <>
      <div className="rider-listing">
      <div className="d-flex justify-content-between">
          <span className="title">Costumer Care</span>
        </div>
        <Tabs
          id="controlled-tab-example"
          className="mt-30 position-relative"
          activeKey={tab}
          onSelect={(tab) => {
            setTab(tab);
            props.history.push(`/tickets/${tab}`)
          }}
        >
          
          <Tab eventKey="tickets" title="Tickets">
            {tab === "tickets" && (
              <TicketListing/>
            )}
          </Tab>
          <Tab eventKey="analysis" title="Analysis">
            {tab === "analysis" && (
              <TicketAnalysis/>
                        )}
          </Tab>
          <Tab eventKey="otplogs" title="Otp Logs">
            {tab === "otplogs" && (
              <OtpLogs/>
            )}
          </Tab>
          </Tabs>
      </div>
    </>
  );
};

export default React.memo(DriverListing);