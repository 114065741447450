import React from "react";
import LocationNav from "./LocationNav";

function Navbar({ refreshData }) {

  return (
    <>
      <div className="navbarr">
        <div>
          <h1 className="header mt-0">Dashboard </h1>
        </div>
        <LocationNav refreshData={refreshData} />
      </div>
    </>
  );
}

export default Navbar;
