import React from "react";
import RidesListingTable from "../components/Rides/RidesListingTable";
import DriverListingTableDetailPage from "./DriverListingTableDetailPage";

let AllTrips = () => {
  return (
    <>
      {/* <div className="rider-listing"> */}
        {/* <span className="title">Trips</span> */}
        <RidesListingTable />
      {/* </div> */}
    </>
  );
};

export default React.memo(AllTrips);
