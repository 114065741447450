import HeatMapDisplay from "../HeatMap/HeatMapDisplay";
import OfflineCaptains from "../HeatMap/OfflineCaptains";
import TripSettingDetailPage from "./TripSettingDetailPage";
import Rides from "../../pages/Rides";
import FareConfigationListingPage from "../../pages/FareConfigationListingPage";
const RideHailing = ()=>{return(<Rides />) }
const Pickups = ()=> "Pickups"
export let tripTabs = ['all-trips', 'ride-hailing', 'pickups'];
export let HeatMapTabs = ["all" , "own-a-car","ontrip-captains","free-online-drivers","offline-captains"];
export let HeatMapData = [{
    title:"All",
    key:"all",
    Component: HeatMapDisplay,
    type:"all" 
  },{
    title:"Own A Car",
    key:"own-a-car",
    Component: HeatMapDisplay,
    type:"own-a-car" 
  },{
    title:"On Trip Captains",
    key:"ontrip-captains",
    Component: HeatMapDisplay,
    type:"ontrip-captains" 
  },{
    title:"Free Online Drivers",
    key:"free-online-drivers",
    Component: HeatMapDisplay,
    type:"free-online-drivers" 
  },{
    title:"Offline Captains",
    key:"offline-captains",
    Component: OfflineCaptains,
    type:"offline-captains" 
  }]
  export let tabsData = [{
    title:"All Trips",
    key:"all-trips",
    Component: Rides
  },{
    title:"Ride Hailing",
    key:"ride-hailing",
    Component: RideHailing
  },{
    title:"Pickups",
    key:"pickups",  
    Component: Pickups
  }
]
  export  let FareCalculationData = [{
      title:"All Trips",
      key:"all-trips",
      Component: FareConfigationListingPage
    },{
      title:"Ride Hailing",
      key:"ride-hailing",
      Component: RideHailing
    },{
      title:"Pickups",
      key:"pickups",  
      Component: Pickups
    }]
    
   export let tripSettingsData = [{
      title:"All Trips",
      key:"all-trips",
      Component: TripSettingDetailPage
    },{
      title:"Ride Hailing",
      key:"ride-hailing",
      Component: RideHailing
    },{
      title:"Pickups",
      key:"pickups",  
      Component: Pickups
    }]