import React , {useState} from "react";
import DriverListingTable from "../components/Drivers/DriverListingTable";
import { Button, Tab, Tabs } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import IncompleteDrivers from "../components/Drivers/IncompleteDrivers";
import { toast } from "react-toastify";
import refresh from '../components/Dashboard_2/images/refresh.svg'
import APIService from "../services/APIService";
import RiderListingTable from "../components/Riders/RiderListingTable";
import TotalCaptain from "./totalCaptain";
import TotalRiders from "./totalRiders";
import ProcessingListing from "../components/ProcessingRequest/ProcessingListing";
import ManagementListingTable from "../components/Management/ManagementListingTable";
import LocationNav from "../components/Dashboard_2/LocationNav";


let UserListing = () => {
  let [selectTab, setSelectedTab] = useState("Captains");
  const refreshData= () =>{
    console.log("refresh")
  }
  console.log("setSelectedTab",selectTab);
  return (
    <>
      <div className="rider-listing">
      <div className="d-flex justify-content-between">
          <span className="title">User Management</span>
          <div className="table-header">
          <div className="d-inline-flex flex-nowrap">
          <LocationNav refreshData={refreshData}/>
          <div className=" ml-3">{selectTab=='Riders'?<Button className="d-flex text-nowrap align-items-center">Initiate KYC for all</Button>:''}</div>
        </div>
          </div>
      
        </div>
        <Tabs
          id="controlled-tab-example"
          className="mt-30 position-relative"
          activeKey={selectTab}
          onSelect={(tab) => {
            setSelectedTab(tab);
          }}
        >

          
          <Tab eventKey="Captains" title="Captains">
            {selectTab === "Captains" && (
              <TotalCaptain />
            )}
          </Tab>

          <Tab eventKey="Riders" title="Riders">
            {selectTab === "Riders" && (
              <TotalRiders />
            )}
          </Tab>
          <Tab eventKey="Management" title="Management">
            {selectTab === "Management" && (
              <ManagementListingTable/>
            )}
          </Tab>
          <Tab eventKey="Authority Control" title="Processing Requests">
            {selectTab === "Authority Control" && (
              <ProcessingListing />
            )}
          </Tab>
          
          
          </Tabs>
        {/* <DriverListingTable /> */}
      </div>
    </>
  );
};

export default React.memo(UserListing);