import React, { useState, useEffect } from "react";
import cardIcon from "./images/cardIcon.svg";
import { Doughnut } from "react-chartjs-2";
import { classNames } from "react-select/dist/index-ea9e225d.cjs.prod";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import { _baseURL, secret_key } from "../../services/APIService";
import Calendar from "./Calendar";
import APIService from "../../services/APIService";
function CancelationReasonCard() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [cancelationReasonsData, setCancelationReasonsData] = useState({});
  const [updatedReasonsData, setUpdatedReasonsData] = useState(null);
  const currentDate = moment().format("YYYY-MM-DD");
  const [riderDataset, setRiderDataset] = useState({
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
        borderWidth: 0,
      },
    ],
  });
  const [driverDataset, setDriverDataset] = useState({
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
        borderWidth: 0,
      },
    ],
  });
  const getCancelatiosReasons = () => {
    try {
      APIService.get(`admin/user-cancel-reasons`).then((response) => {
        let data = response?.data?.data;

        if (activeIndex === 0 || activeIndex === 1) {
          const reasonTypeFilter = activeIndex === 0 ? 3 : 2;

          const updatedReasons = data?.map((reason) => {
            if (!reason.count) {
              reason.count = 0;
            }
            return reason;
          });

          const colors = [
            "#7071BF",
            "#13B542",
            "#13A5B5",
            "#FFB065",
            "#EB3D3D",
            "#000000",
            "#F1F1F1",
            "#F1F9F6",
          ];

          const filteredReasons = updatedReasons?.filter(
            (item) => item.reasonType === reasonTypeFilter
          );

          const updatedReasonsData = filteredReasons.map((obj, index) => ({
            ...obj,
            color: colors[index],
          }));

          setUpdatedReasonsData(updatedReasonsData);

          const updatedReasonsCounts = filteredReasons.map(
            (item) => item.count
          );
          setCancelationReasonsData(updatedReasonsCounts);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  console.log("CancelationReasonsData", cancelationReasonsData);
  useEffect(() => {
    getCancelatiosReasons();
  }, [activeIndex]);
  const getTimeRange = (range) => {
    const { sDate, eDate } = range;

    try {
      APIService.get(
        `admin/user-cancel-reasons?type=custom&fromDate=${sDate} 00:00:00.000000&toDate=${eDate} 23:59:59.000000`
      ).then((response) => {
        let data = response?.data?.data;

        if (activeIndex === 0 || activeIndex === 1) {
          const reasonTypeFilter = activeIndex === 0 ? 3 : 2;

          const updatedReasons = data?.map((reason) => {
            if (!reason.count) {
              reason.count = 0;
            }
            return reason;
          });

          const colors = [
            "#7071BF",
            "#13B542",
            "#13A5B5",
            "#FFB065",
            "#EB3D3D",
            "#000000",
            "#F1F1F1",
            "#F1F9F6",
          ];

          const filteredReasons = updatedReasons?.filter(
            (item) => item.reasonType === reasonTypeFilter
          );

          const updatedReasonsData = filteredReasons.map((obj, index) => ({
            ...obj,
            color: colors[index],
          }));

          setUpdatedReasonsData(updatedReasonsData);

          const updatedReasonsCounts = filteredReasons.map(
            (item) => item.count
          );
          setCancelationReasonsData(updatedReasonsCounts);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  const windowWidth = window.innerWidth,
    chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      type: "doughnut",
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      cutout: windowWidth > 992 ? 105 : 105,
      pointStyle: "circle",

      plugins: {
        tooltip: {
          enabled: true,
        },
        legend: {
          display: false,
          position: windowWidth > 992 ? "bottom" : "bottom",
          labels: {
            boxWidth: 100,
            usePointStyle: true,
            fontSize: 60,
          },
        },
      },
    };
  let emptyTrips = {
    datasets: [
      {
        data: [0.1],
        backgroundColor: ["rgba(241, 241, 241, 1)"],
        hoverBackgroundColor: ["rgba(241, 241, 241, 1)"],
        borderWidth: 0,
      },
    ],
  };
  // const secret_key = "xBJbW0S1OIFJl9bsjsdZ";
  const _getAllTicketsURL = `${_baseURL}/api/v1/ticket/get-status-wise-tickets?secret_key=${secret_key}&from_date=2022-10-29&to_date=${currentDate}&type=1`;

  const options = {
    plugins: {
      tooltip: {
        displayColors: false,
        backgroundColor: "#fff",
        fontWeight: "bold",
        bodyFontSize: "30",
        borderWidth: 1,
        padding: 12,
        yAlign: "bottom",

        callbacks: {
          label: function () {
            var label = "101";
            return label;
          },

          beforeLabel: function () {
            var beforelabel = "Open Tickets";
            return beforelabel;
          },
          afterLabel: function () {
            var afterlabel = "View All";
            return afterlabel;
          },
          labelTextColor: function (context) {
            return "#828282";
          },
        },
      },
    },
  };
  console.log("cancelationReasonsData?.total", cancelationReasonsData?.total);
  var total = cancelationReasonsData?.total;
  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 380).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        console.log("total", total);
        var text = `Total : ${Number(total) || 0}`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillStyle = "#828282";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  useEffect(() => {
    setRiderDataset({
      datasets: [
        {
          data: cancelationReasonsData,
          backgroundColor: [
            "#7071BF",
            "#13B542",
            "#13A5B5",
            "#FFB065",
            "#EB3D3D",
            "#000000",
            "#F1F1F1",
            "#F1F9F6",
          ],
          hoverBackgroundColor: [
            "#7071BF",
            "#13B542",
            "#13A5B5",
            "#FFB065",
            "#EB3D3D",
            "#000000",
            "#F1F1F1",
            "#F1F9F6",
          ],
          borderWidth: 0,
        },
      ],
    });
    setDriverDataset({
      datasets: [
        {
          data: cancelationReasonsData,
          backgroundColor: [
            "#7071BF",
            "#13B542",
            "#13A5B5",
            "#FFB065",
            "#EB3D3D",
            "#000000",
            "#F1F1F1",
            "#F1F9F6",
          ],
          hoverBackgroundColor: [
            "#7071BF",
            "#13B542",
            "#13A5B5",
            "#FFB065",
            "#EB3D3D",
            "#000000",
            "#F1F1F1",
            "#F1F9F6",
          ],
          borderWidth: 0,
        },
      ],
    });
  }, [JSON.stringify(cancelationReasonsData)]);

  return (
    <>
      <div className="section-rider col-lg-12 section-captain h-100">
        <div className="card-nav mb-16">
          <h2 className="box-title text-wrap font-weight-bold">
            Cancelation Reason
          </h2>
          <div>
            <div className="dropdown">
              <Calendar handleTimeRange={getTimeRange} />
            </div>
          </div>
        </div>
        <TabView
          className="mt-2"
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header=" Riders">
            {" "}
            {updatedReasonsData?.reduce((accumulator, currentObject) => {
              return Number(accumulator) + (Number(currentObject.count) || 0);
            }, 0) < 1 ? (
              <>
                <div
                  className="position-absolute"
                  style={{
                    zIndex: 99,
                    top: "45%",
                    right: "45%",
                    fontSize: 12,
                    color: "#828282",
                  }}
                >
                  {`${"Total"} : ${updatedReasonsData?.reduce(
                    (accumulator, currentObject) => {
                      return (
                        Number(accumulator) + (Number(currentObject.count) || 0)
                      );
                    },
                    0
                  )}`}{" "}
                </div>
                <Doughnut
                  data={emptyTrips}
                  width={null}
                  height={windowWidth > 992 ? 150 : null}
                  options={chartOptions}
                  // plugins={plugins}
                />
              </>
            ) : (
              <>
                <div
                  className="position-absolute"
                  style={{
                    zIndex: 99,
                    top: "45%",
                    right: "45%",
                    fontSize: 12,
                    color: "#828282",
                  }}
                >
                  {`${"Total"} : ${updatedReasonsData?.reduce(
                    (accumulator, currentObject) => {
                      return (
                        Number(accumulator) + (Number(currentObject.count) || 0)
                      );
                    },
                    0
                  )}`}
                </div>
                <Doughnut
                  type="doughnut"
                  width={null}
                  height={windowWidth > 992 ? 150 : null}
                  data={riderDataset}
                  // plugins={plugins}
                  options={chartOptions}
                />
              </>
            )}
            <div className="info-section mt-3">
              <div className="row">
                <div className="container-fluid">
                  <div
                    className="main-percent line-main flex-repons"
                    style={{ textAlign: "start" }}
                  >
                    {updatedReasonsData?.map((status, index) => (
                      <div
                        key={index}
                        className="percentage cc-percent width-respons"
                        style={{ width: "49%" }}
                      >
                        <div className="d-flex align-items-center">
                          <div
                            className={"green mt-0"}
                            style={{ backgroundColor: status.color }}
                          ></div>
                          <div className="percentext flex-grow-1">
                            {status.reason}
                          </div>
                        </div>
                        <div></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header=" Captains">
             {updatedReasonsData?.reduce((accumulator, currentObject) => {
              return Number(accumulator) + (Number(currentObject.count) || 0);
            }, 0) < 1 ? (
              <>
                <div
                  className="position-absolute"
                  style={{
                    zIndex: 99,
                    top: "45%",
                    right: "45%",
                    fontSize: 12,
                    color: "#828282",
                  }}
                >
                  {`${"Total"} : ${updatedReasonsData?.reduce(
                    (accumulator, currentObject) => {
                      return (
                        Number(accumulator) + (Number(currentObject.count) || 0)
                      );
                    },
                    0
                  )}`}{" "}
                </div>
                <Doughnut
                  data={emptyTrips}
                  width={null}
                  height={windowWidth > 992 ? 150 : null}
                  options={chartOptions}
                  // plugins={plugins}
                />
              </>
            ) : (
              <>
                <div
                  className="position-absolute"
                  style={{
                    zIndex: 99,
                    top: "45%",
                    right: "45%",
                    fontSize: 12,
                    color: "#828282",
                  }}
                >
                  {`${"Total"} : ${updatedReasonsData?.reduce(
                    (accumulator, currentObject) => {
                      return (
                        Number(accumulator) + (Number(currentObject.count) || 0)
                      );
                    },
                    0
                  )}`}
                </div>
                <Doughnut
                  type="doughnut"
                  width={null}
                  height={windowWidth > 992 ? 150 : null}
                  data={driverDataset}
                  // plugins={plugins}
                  options={chartOptions}
                />
              </>
            )}

            <div className="info-section mt-3">
              <div className="row">
                <div className="container-fluid">
                  <div
                    className="main-percent line-main"
                    style={{ textAlign: "start" }}
                  >
                    {updatedReasonsData?.map((status, index) => (
                      <div
                        key={index}
                        className="percentage cc-percent"
                        style={{ width: "50%" }}
                      >
                        <div className="d-flex">
                          <div
                            className={"green"}
                            style={{ backgroundColor: status.color }}
                          ></div>
                          <div className="percentext flex-grow-1">
                            {status.reason}
                          </div>
                        </div>
                        <div></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div></div>

            <div className="main-percent rPercen justify-content-center"></div>
          </TabPanel>
        </TabView>
      </div>
    </>
  );
}

export default CancelationReasonCard;
