import { Tab } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Tabs } from "react-bootstrap";
import AuditLog from "../components/Settings/AuditLog";
import Controls from "../components/Settings/Controls";
import OtherSettings from "../components/Settings/OtherSettings";
import TripSettings from "../components/Settings/TripSettings";
import VehicleAssignmentLising from "../components/Settings/VehicleAssignmentListing"
import TripSettingDetailPage from "../components/TripSetting/TripSettingDetailPage";
import VehicleListingPage from "./VehicleMakerListingPage"
import SLAConfig from "../../src/pages/SLAConfig"
import HighDemandZone from "./HighDemandZone";
import VehicleUnAssignedLising from "../components/Settings/UnAssignedVehicleListing"
import VehiclesListing from "../components/Settings/VehiclesListing";
import Inventory from "./Inventory";
import PromotionalCoupon from "./PromotionalCoupon";
import PromotionsMasterControl from "../components/Promotions/PromotionsMasterControl";
import Escalations from "../components/Escalations/Escalations";
import Cancelation from "./Cancelation";

let tripSettings = [
  "RIDE-A-RIDE-STATUS",
  "DRIVER_DECLINE_TIME_LIMIT",
  "DRIVER_END_TRIP_RANGE",
  "EHSAN_DONATION_FEE",
  "DRIVER_WAITING_TIME_LIMIT",
  "NOTIFY_TIME_SCHEDULED_TRIP",
  "RECENT_ADDRESSES_LIMIT",
  "RIDER_CANCEL_TIME_LIMIT",
  "SCHEDULE_TRIP_MAX_DAYS",
  "SCHEDULE_TRIP_TIME_RANGE",
  "SCHEDULED_TRIP_MIN_RANGE",
  "TRIP_DRIVER_SEARCH_LIMIT",
  "TRIP_DRIVER_SEARCH_RADIUS",
  "TRIP_TAX_PERCENTAGE",
  "CHANGE_DESTINATION_LIMIT_FOR_RIDER",
  "RIDER_TRIPS_LIMIT_WITHOUT_KYC",
  "ADMIN_RIDE_NOTIFICATION_EMAIL",
  "ADMIN_TRANSACTION_NOTIFY_EMAIL",
  "ADMIN_RIDE_NOTIFICATION_EMAIL",
  "DRIVER_DECLINE_TIME_LIMIT",
  "EMAIL_NOTIFICATION_FROM_EMAIL",
  "EMAIL_NOTIFICATION_FROM_NAME",
  "RECENT_ADDRESSES_LIMIT",
  "SCHEDULED_TRIP_MIN_TIME",
  "SUPPORT_TOLL_FREE_NO",
  "MAX_TRIP_REQUESTS_LIMIT_FOR_CANCELLED_TRIP",
  "MAX_DRIVERS_REQUEST_LIMIT_FOR_CANCELLED_TRIP",
  "MAX_TRIP_REQUESTS_LIMIT_FOR_REJECTED_TRIP",
  "MOT_AMOUNT",
]
let Settings = (props) => {
  let [tab, setTab] = useState("activeSub"),
    getActiveTab = () => {
      if (props.match.params.tab) {
        setTab(props.match.params.tab);
      } else {
        setTab("active");
      }
    },
    permissions = JSON.parse(atob(localStorage.getItem("access")));
 console.log('permissions', permissions);
  useEffect(() => {
    getActiveTab();
  }, []);
// console.log(permissions)
  return (
    <>
      <div className="rider-listing ">
        <span className="title">Master Controls</span>

        <Tabs
          id="controlled-tab-example"
          className="mt-30"
          activeKey={tab}
          onSelect={(tab) => {
            setTab(tab);
            props.history.push(`/master-control/${tab}`);
          }}
        >


          {permissions.find(a => a.includes('/master-control/terms-and-conditions')) &&
            <Tab eventKey="terms-and-conditions" title="Term & Conditions">
              {tab === "terms-and-conditions" && <SLAConfig type={"terms-and-conditions"}/>}
            </Tab>
          }
          {permissions.find(a => a.includes('/master-control/privacy-policy')) &&
            <Tab eventKey="privacy-policy" title="Privacy Policy">
              {tab === "privacy-policy" && <SLAConfig type={"privacy-policy"}/>}
            </Tab>
          }
          {permissions.find(a => a.includes('/master-control/inventory')) &&
  <Tab eventKey="inventory" title="Inventory">
    {tab === "inventory" && <Inventory />}
  </Tab>
}
{permissions.find(a => a.includes('/master-control/cancelation-reason')) &&
  <Tab eventKey="cancelation-reason" title="Cancelation Reason">
    {tab === "cancelation-reason" && <Cancelation />}
  </Tab>
}
{permissions.find(a => a.includes('/master-control/vehicle-makers')) &&
                <Tab eventKey="vehicle-makers" title="Vehicle Makers">
                  {tab === "vehicle-makers" && < VehicleListingPage />}
                  {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
                </Tab>
              }
                            {permissions.find(a => a.includes('/master-control/category-assignment')) &&
                <Tab eventKey="category-assignment" title="Car Assignment">
                  {tab === "category-assignment" && <VehiclesListing propsData={props} />}
                  {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
                </Tab>
              }
                        {permissions.find(a => a.includes('/master-control/promo-codes')) &&
            <Tab eventKey="promo-codes" title="Promo Codes">
              {tab === "promo-codes" && <PromotionalCoupon/>}
            </Tab>
          }
          {permissions.find(a => a.includes('/master-control/promotions')) &&
            <Tab eventKey="promotions" title="Promotions">
              {tab === "promotions" && <PromotionsMasterControl/>}
            </Tab>
          }
          {permissions.find(a => a.includes('/master-control/escalations')) &&
            <Tab eventKey="escalations" title="Escalations">
              {tab === "escalations" && <Escalations/>}
            </Tab>
          }

        </Tabs>
      </div>
    </>
  );
};

export default React.memo(Settings);
