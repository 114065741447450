import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import Calendar from "./Calendar";
import APIService from "../../services/APIService";
import moment from "moment";
function TotalRider(props) {
  const { activeRidersPercentage, topCardRider, locationDataset } = props;
  const [riderData, setRiderData] = useState({});
  const [applyFilter, setApplyFilter] = useState(false);
  const [locationData, setLocationsData] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedCountry, setSelectedCountry] = useState({
    id: "",
    name: "",
  });

  const [selectedCity, setSelectedCity] = useState({
    id: "",
    name: "",
  });

  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  let windowWidth = window.innerWidth;

  let activeRider = riderData?.count?.active;
  let inActiveRider = riderData?.count?.inActive;
  let riderCount = activeRidersPercentage?.activeRiderPercentage?.count;
  const getTimeRange = (range) => {
    setStartDate(range.sDate);
    setEndDate(range.eDate);
  };
  useEffect(() => {
    if (locationDataset) {
      setLocationsData(locationDataset);
    }
  }, [JSON.stringify(locationDataset)]);
  useEffect(() => {
    const buildApiUrl = () => {
      // Start building the URL with common parameters
      const baseUrl = `admin/dashboard/active-riders-percentage?entity=rider`;

      const params = {
        fromDate: startDate ? `${startDate} 00:00:00.000000` : "",
        toDate: endDate ? `${endDate} 23:59:59.000000` : "",
        countryId: selectedCountry?.id,
        cityId: selectedCity?.id,
        type: startDate && endDate ? "custom" : "",
      };

      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([_, value]) => value !== undefined && value !== null && value !== ""
        )
      );

      const queryString = new URLSearchParams(filteredParams).toString();

      return queryString ? `${baseUrl}&${queryString}` : baseUrl;
    };

    const fetchData = async () => {
      const url = buildApiUrl();
      console.log("yrl", url);
      if (url) {
        try {
          setApplyFilter(true);

          const response = await APIService.get(url);

          if (response && response.status === 200) {
            const data = response.data?.data || "";
            setRiderData(data);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, [endDate, selectedCountry, selectedCity]);

  const [dataResult, setDataResult] = useState({
    datasets: [
      {
        data: [
          activeRidersPercentage?.activeRiderPercentage?.count?.inActive,
          activeRidersPercentage?.activeRiderPercentage?.count?.active,
        ],
        backgroundColor: ["#EB3D3D", "#13B542"],
        hoverBackgroundColor: ["#EB3D3D", "#13B542"],
        borderWidth: 0,
      },
    ],
  });

  let chartOptions = {
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: windowWidth > 992 ? 95 : 95,
    pointStyle: "circle",
  };

  useEffect(() => {
    setDataResult({
      datasets: [
        {
          data: [
            applyFilter
              ? inActiveRider
              : activeRidersPercentage?.activeRiderPercentage?.count?.inActive,
            applyFilter
              ? activeRider
              : activeRidersPercentage?.activeRiderPercentage?.count?.active,
            // (applyFilter && inActiveRider == 0) ? 0.1: (inActiveRider)? inActiveRider: activeRidersPercentage?.count?.inActive,
            // (applyFilter && activeRider == 0) ? 0.1: (activeRider)? activeRider: activeRidersPercentage?.count?.active,
          ],
          backgroundColor: [
            inActiveRider == 0 ? "#e5e5e5" : "#EB3D3D",
            activeRider == 0 ? "#e5e5e5" : "#13B542",
          ],
          hoverBackgroundColor: [
            inActiveRider == 0 ? "#e5e5e5" : "#EB3D3D",
            activeRider == 0 ? "#e5e5e5" : "#13B542",
          ],
          borderWidth: 0,
        },
      ],
    });
  }, [topCardRider, activeRidersPercentage, riderData]);

  useEffect(() => {
    // Extract country options from the data
    const countries = locationData?.map((country) => ({
      id: country.id,
      name: country.countryName,
    }));
    setCountryOptions(countries);

    // Set the default selected country(
    if (countries?.length > 0) {
      setSelectedCountry(countries[0]);
    }

    // Set the default selected city if available
    const defaultCity = locationData?.length > 0 && locationData[0]?.city[0];
    if (defaultCity) {
      setSelectedCity({ id: "", name: "" });
    }
  }, [JSON.stringify(locationData)]);

  useEffect(() => {
    // Extract city options based on the selected country
    const selectedCountryData = locationData?.find(
      (country) => country.countryName === selectedCountry.name
    );
    if (selectedCountryData) {
      const cities = selectedCountryData?.city?.map((city) => ({
        id: city.id,
        name: city.cityName,
      }));
      setCityOptions(cities);

      // Set the default selected city
      setSelectedCity({});
    }
  }, [selectedCountry, JSON.stringify(locationData)]);

  const handleCountryChange = (e) => {
    const selectedCountryName = e.target.value;
    const selectedCountryData = countryOptions?.find(
      (country) => country.name === selectedCountryName
    );
    setSelectedCountry(selectedCountryData || {});
  };

  const handleCityChange = (e) => {
    const selectedCityName = e.target.value;
    const selectedCityData = cityOptions?.find(
      (city) => city.name === selectedCityName
    );
    setSelectedCity(selectedCityData || {});
  };

  return (
    <>
      <div className="section-rider cards-section h-100 zoom-90">
        <div className="card-nav " style={{ marginBottom: 55 }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
            }}
          >
            <div
              className="dropdown"
              style={{ justifyContent: "flex-end", marginBottom: 15 }}
            >
              <div className="selectbox">
                <select
                  className="select w-100 outline-none border-0"
                  style={{
                    minWidth: "fit-content",
                    height: 35,
                    borderRadius: 5,
                    textTransform: "capitalize",
                  }}
                  value={selectedCountry.name}
                  onChange={handleCountryChange}
                >
                  <option key={""} value={""}>
                    All
                  </option>
                  {countryOptions?.map((country, index) => (
                    <option
                      style={{ textTransform: "capitalize" }}
                      key={index}
                      value={country.name}
                    >
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="selectbox">
                <select
                  className="select w-100 outline-none border-0"
                  style={{
                    minWidth: "fit-content",
                    height: 35,
                    borderRadius: 5,
                    textTransform: "capitalize",
                    maxWidth: "115px",
                  }}
                  value={selectedCity.name}
                  onChange={handleCityChange}
                >
                  <option key={""} value={""}>
                    All
                  </option>
                  {cityOptions?.map((city, index) => (
                    <option key={index} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
              <Calendar handleTimeRange={getTimeRange} />
            </div>
            <h2
              className="box-title fw-bold font-weight-bold"
              style={{ marginBottom: 10, fontSize: "24px" }}
            >
              {" "}
              Total Riders :{" "}
              {!isNaN(
                (applyFilter ? activeRider : riderCount?.active) +
                  (applyFilter ? inActiveRider : riderCount?.inActive)
              )
                ? (applyFilter ? activeRider : riderCount?.active) +
                  (applyFilter ? inActiveRider : riderCount?.inActive)
                : 0}{" "}
            </h2>
          </div>
        </div>
        {/* <Doughnut
          type="doughnut"
          data={dataResult}

          options={chartOptions}
        /> */}
        <div
          className="card bg-light p-5"
          style={{
            border: "1px solid #F7F7F7",
            backgroundColor: "#F7F7F7",
            marginTop: 24,
          }}
        >
          <div>
            <div className="graphs-section" style={{ marginBottom: 75 }}>
              <div id="circle-1">
                <span>
                  {" "}
                  {(applyFilter
                    ? activeRider
                    : activeRidersPercentage?.activeRiderPercentage?.count
                        ?.active) || 0}
                </span>
                {/* <span>{captainData?(captainData.Percentage?.activePercentage ? captainData.Percentage.activePercentage:0) : activeDriversPercentage?.Percentage?.activePercentage}</span> */}
                <div>
                  {/* (waslData?.approved===undefined ?  waslApprovedCount?.approved : waslData.approved) */}
                  <div id="circle-2">
                    {(applyFilter
                      ? inActiveRider
                      : activeRidersPercentage?.activeRiderPercentage?.count
                          ?.inActive) || 0}
                  </div>
                  {/* <div id="circle-2">{captainData?(captainData.Percentage?.inActivePercentage ? captainData.Percentage.inActivePercentage:0)  : activeDriversPercentage?.Percentage?.inActivePercentage}</div> */}
                </div>
              </div>
            </div>
            <div className="flex-cb mt-32">
              <div className="d-flex align-items-center zoom-80">
                <span className="circle-fig"></span>
                <span className="fig-name">Online</span>
              </div>
              <div className="d-flex align-items-center zoom-80">
                <span className="circle-fig bg-red"></span>
                <span className="fig-name">Offline</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TotalRider;
