import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import Calendar from "./Calendar";
import APIService from "../../services/APIService";
import moment from "moment";
function Cashflow(props) {
  const { cashFlow } = props;
  console.log('cashFLl',cashFlow)
  const [cash_flow, setCashFlowData] = useState({});
  const [applyFilter,setApplyFilter] = useState(false)
  let windowWidth = window.innerWidth;
  const cashFlowData = cashFlow ? cashFlow[0] : null;
  const getFilteredValue = (value, applyFilter, originalValue) =>
  Math.round(applyFilter ? value || 0 : originalValue || 0);

  let cashFlowTopup = getFilteredValue(cash_flow.walletTopup, applyFilter, cashFlowData?.walletTopup);
  let cashFlowSubscription = getFilteredValue(cash_flow.subscription, applyFilter, cashFlowData?.subscription);
  let cashFlowTripFare = getFilteredValue(cash_flow.tripFare, applyFilter, cashFlowData?.tripFare);
  let cashFlowFee = getFilteredValue(cash_flow.fee, applyFilter, cashFlowData?.fee);
  let cashFlowVat = getFilteredValue(cash_flow.vat, applyFilter, cashFlowData?.vat);
  let cashFlowPayment = getFilteredValue(cash_flow.paymentToDrivers, applyFilter, cashFlowData?.paymentToDrivers);

  let chartOptions = {
    responsive: true,
    // maintainAspectRatio: false,
    type: "doughnut",
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: windowWidth > 992 ? 105 : 105,
    pointStyle: "circle",

    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
        position: windowWidth > 992 ? "bottom" : "bottom",
        labels: {
          boxWidth: 100,
          usePointStyle: true,
          fontSize: 60,
        },
      },
    },
  };
  const getTimeRange = (range) => {
    let obj = {
      type: "custom",
      fromDate: range.sDate,
      toDate: range.eDate,
    };
    try {
      setApplyFilter(true);
      APIService.get(`admin/dashboard/cash-flow?type=custom&entity=driver&fromDate=${range.sDate} 00:00:00.000000&toDate=${range.eDate} 23:59:59.000000`)
        .then((res) => {
          if (res.status == 200) {
            let data = res?.data?.data[0] ? res.data.data[0] : "";
            setCashFlowData(data);
          }
        })
        .catch((e) => {});
    } catch (e) {}
  };
console.log('cashFlowdata',cash_flow)
  const [dataResultInflow, setDataResultInflow] = useState({
    datasets: [
      {
        data: [cashFlowTopup || 0, cashFlowTripFare || 0, cashFlowSubscription || 0, 0],
        backgroundColor: ["#000000", "#7071BF", "#13A5B5", "#13B542"],
        hoverBackgroundColor: ["#000000", "#7071BF", "#13A5B5", "#13B542"],
        borderWidth: 0,
      },
    ],
  });

  const [dataResultoutflow, setDataResultoutflow] = useState({
    datasets: [
      {
        data: [cashFlowVat || 0, cashFlowPayment || 0, cashFlowFee || 0, 0],
        backgroundColor: ["#000000", "#7071BF", "#13A5B5", "#13B542"],
        hoverBackgroundColor: ["#000000", "#7071BF", "#13A5B5", "#13B542"],
        borderWidth: 0,
      },
    ],
  });

  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 380).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = "Inflows",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillStyle = "#828282";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  const plugins2 = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 380).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = "Outflows",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillStyle = "#828282";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];
console.log('dataResultInflow',dataResultInflow)
  useEffect(() => {
    setDataResultInflow({
      datasets: [
        {
          data: [cashFlowTopup || 0.1, cashFlowTripFare || 0, cashFlowSubscription || 0, 0],
          backgroundColor: [cashFlowVat ? "#000000" : '#E5E5E5', "#7071BF", "#13A5B5", "#13B542"],
          hoverBackgroundColor: [cashFlowVat ? "#000000" : '#E5E5E5', "#7071BF", "#13A5B5", "#13B542"],
          borderWidth: 0,
        },
      ],
    });
    setDataResultoutflow({
      datasets: [
        {
          data: [cashFlowVat || 0.1, cashFlowPayment || 0, cashFlowFee || 0, 0],
          backgroundColor: [cashFlowVat ? "#000000" : '#E5E5E5', "#7071BF", "#13A5B5", "#13B542"],
          hoverBackgroundColor: [cashFlowVat ? "#000000" : '#E5E5E5', "#7071BF", "#13A5B5", "#13B542"],
          borderWidth: 0,
        },
      ],
    });
  }, [cashFlow,JSON.stringify(cash_flow)]);
  return (
    <section className="rounded cards-section h-100">
      <div className="d-flex justify-content-between position-relative">
        <h2 className="box-title font-weight-bold">Cashflows</h2>
        <Calendar handleTimeRange={getTimeRange} />
      </div>

      <div className="row gy-3">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="chart mb-2 align-items-center text-center">
            <Doughnut
              type="doughnut"
              data={dataResultInflow}
              plugins={plugins}
              options={chartOptions}
            />
          </div>

          <div className="info-section">
            <div className="row">
              <div className="container-fluid">
                <div className="inner-box inner-box-dashboard">
                  <div className="flex-sb">
                    <div className="d-flex align-items-center">
                      <div className="green-box bg-dark"></div>
                      <div className="trips-title-label ml-2">Wallet topup</div>
                    </div>
                    <div className="amount-right w-25 text-left text-success" style={{display:'contents'}} >
                      SAR. {Math.round(cashFlowTopup)}
                    </div>
                  </div>
                  <div className="flex-sb">
                    <div className="d-flex align-items-center">
                      <div
                        className="green-box "
                        style={{ background: "#13A5B5" }}
                      ></div>
                      <div className="trips-title-label ml-2">Subscription</div>
                    </div>
                    <div className="amount-right w-25 text-left text-success" style={{display:'contents'}}>
                      SAR. {Math.round(cashFlowSubscription)}
                    </div>
                  </div>
                  <div className="flex-sb">
                    <div className="d-flex align-items-center">
                      <div className="green-box "></div>
                      <div className="trips-title-label ml-2">
                        {" "}
                        Ride a Ride(RiDE)
                      </div>
                    </div>
                    <div className="amount-right w-25 text-left text-success" style={{display:'contents'}}>
                      SAR. ------
                    </div>
                  </div>
                  <div className="flex-sb">
                    <div className="d-flex align-items-center">
                      <div
                        className="green-box "
                        style={{ background: "#7071bf" }}
                      ></div>
                      <div className="trips-title-label ml-2"> 
                        Trips Fare
                        <img src="/images/ant-design_info-circle-outlined.svg" class="ml-2" alt="" />
                      </div>
                    </div>
                    <div className="amount-right w-25 text-left text-success" style={{display:'contents'}}>
                      SAR. {Math.round(cashFlowTripFare)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* this is card one */}
        </div>

        {/* seccond card */}

        <div className="col-lg-6 col-md-6 col-12">
          <div className="chart mb-2 align-items-center text-center">
            <Doughnut
              type="doughnut"
              options={chartOptions}
              data={dataResultoutflow}
              plugins={plugins2}
            />
          </div>

          <div className="info-section">
            <div className="row">
              <div className="container-fluid">
                <div className="inner-box inner-box-dashboard">
                  <div className="flex-sb">
                    <div className="d-flex align-items-center">
                      <div className="green-box bg-dark"></div>
                      <div className="trips-title-label ml-2"> VAT</div>
                    </div>
                    <div className="amount-right w-25 text-left text-success" style={{display:'contents'}}>
                      SAR. {Math.round(cashFlowVat)}
                    </div>
                  </div>
                  <div className="flex-sb">
                    <div className="d-flex align-items-center">
                      <div
                        className="green-box"
                        style={{ background: "#13A5B5" }}
                      ></div>
                      <div className="trips-title-label ml-2"> Bank fee</div>
                    </div>
                    <div className="amount-right w-25 text-left text-success" style={{display:'contents'}}>
                      SAR. {Math.round(cashFlowFee)}
                    </div>
                  </div>
                  <div className="flex-sb">
                    <div className="d-flex align-items-center">
                      <div className="green-box"></div>
                      <div className="trips-title-label ml-2">
                        {" "}
                        Ride a ride(Captain)
                      </div>
                    </div>
                    <div className="amount-right w-25 text-left text-success" style={{display:'contents'}}>
                      SAR. -----
                    </div>
                  </div>
                  <div className="flex-sb">
                    <div className="d-flex align-items-center">
                      <div
                        className="green-box "
                        style={{ background: "#7071BF" }}
                      ></div>
                      <div className="trips-title-label ml-2">
                        {" "}
                        Payment to Captains
                      </div>
                    </div>
                    <div className="amount-right w-25 text-left text-success" style={{display:'contents'}}>
                      SAR. {Math.round(cashFlowPayment)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cashflow;
