import React from "react";
import { Button, Modal } from "react-bootstrap";
let ChangeStatus = (props) => {
    const handleSubmit =()=>{
        props.clickYes();
        props.handleCloseStatus();
    }
  return (
    <>
      <Modal
        dialogClassName="delete-modal-width"
        show={props.showChange}
        onHide={props.handleCloseStatus}
        centered
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title className="modal-title" style={{textTransform:"unset"}}>
            <div className="text-center">
              {/* <img
                alt="icon"
                src={process.env.PUBLIC_URL + "/images/deleteModal.png"}
              /> */}
              <i className="pi pi-file-edit" style={{ fontSize: '4rem',color:'green' }}></i>
            </div>
            <div className="delete-modal-message my-3">
              Are you sure you want to Change Status?
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="padding-header-footer">
          <Button
            variant="danger"
            className="btn-delete save-button mb-3 border-radius-15"
            onClick={handleSubmit}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="btn-save-add save-button mb-3 border-radius-15"
            onClick={props.handleCloseStatus}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(ChangeStatus);
