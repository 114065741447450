import React, { useState, useEffect } from "react";
import cardIcon from "./images/cardIcon.svg";
import { Doughnut } from "react-chartjs-2";
import { classNames } from "react-select/dist/index-ea9e225d.cjs.prod";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import { _baseURL, secret_key } from "../../services/APIService";
import Calendar from "./Calendar";
import APIService from "../../services/APIService";
function PaymentCard() {
  const [paymentData, setPaymentData] = useState({});
  const [paymentDataset,setPaymentDataset] =useState( {
    datasets: [
      {
        data: [paymentData?.cardSum?.total || 0,paymentData?.walletSum?.total || 0],
        backgroundColor: ["#EB3D3D", "#13B542"],
        hoverBackgroundColor: ["#EB3D3D", "#13B542"],
        borderWidth: 0,
      },
    ],
  });

  const getTimeRange=(range)=>{
    const {sDate , eDate } = range;
    // const startDate = moment(sDate , "DD/MM/YYYY").format('YYYY-MM-DD');
    // const endDate = moment(eDate , "DD/MM/YYYY").format('YYYY-MM-DD');
    APIService.get(`admin/payment-stats?start_date=${sDate}&end_date=${eDate}`)
    .then(res => setPaymentData(res.data.data))
    .catch(error => console.error(error))
    
  }
  const  windowWidth = window.innerWidth,
  chartOptions = {
    cutout: windowWidth > 992 ? 105 : 105,
    responsive: true,
    maintainAspectRatio: false,
    type: "doughnut",
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    pointStyle: "circle",

    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
        position: windowWidth > 992 ? "bottom" : "bottom",
        labels: {
          boxWidth: 100,
          usePointStyle: true,
          fontSize: 60,
        },
      },
    },
  };
  let emptyTrips = {
    datasets: [
      {
        data: [0.1],
        backgroundColor: ["rgba(241, 241, 241, 1)"],
        hoverBackgroundColor: ["rgba(241, 241, 241, 1)"],
        borderWidth: 0,
      },
    ],
  };

  const getPaymentData = () => {
    APIService.get("admin/payment-stats")
      .then((response) => {
        if (response.status === 200 ) {
          setPaymentData(response.data.data);
        }
      })
      .catch(() =>
        console.log("e")
      );
  };
  console.log('paymentData',paymentData)
  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 380).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        const totalPayments=chart?.config?._config?.data?.datasets[0] &&chart?.config?._config?.data?.datasets[0]?.data?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        var text = `Total : ${totalPayments > 0 ? Number(totalPayments)?.toFixed(2): 0}`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillStyle = "#828282";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  useEffect(() => {
    getPaymentData();
  }, []);
  useEffect(() => {
    setPaymentDataset( {
      datasets: [
        {
          data: [paymentData?.cardSum?.total || 0,paymentData?.walletSum?.total || 0],
          backgroundColor: ["#EB3D3D", "#13B542"],
          hoverBackgroundColor: ["#EB3D3D", "#13B542"],
          borderWidth: 0,
        },
      ],
    })
  }, [JSON.stringify(paymentData)]);

  return (
    <>
      <div className="section-rider col-lg-12 section-captain h-100">
      <div className="card-nav" style={{marginBottom:62}}>
          <h2 className="box-title text-wr.line-mainap font-weight-bold">Payment</h2>
          <div>
            <div className="dropdown">
              <Calendar handleTimeRange={getTimeRange} />
            </div>
          </div>
        </div>
        <div>
        
           {!paymentData?.walletSum?.total && !paymentData?.cardSum?.total
              ? (
              <Doughnut
                data={emptyTrips}
                width={null}
                // height={windowWidth > 992 ? 150 : null}
                options={chartOptions}
                plugins={plugins}
              />
              ) : (
                <Doughnut
                  type="doughnut"
                  width={null}
                  height={windowWidth > 992 ? 150 : null}
                  data={paymentDataset}
                  plugins={plugins}
                  options={chartOptions}
                />
              )}
            <div className="info-section" style={{marginTop:"2rem"}}>
              <div className="row">
                <div className="container-fluid">
                  <div className="main-percent line-main">
                    <div className="percentage cc-percent justify-content-center">
                      <div className="d-flex">
                        <div className="green mt-0"></div>
                        <div className="percentext flex-grow-1">Paid Via Wallet:</div>
                      </div>
                      <div>
                        <div className="percenPoint">
                          {(paymentData?.walletSum?.total)?.toFixed(2) ?? 0}
                        </div>
                      </div>
                    </div>

                    <div className="percentage cc-percent justify-content-center" >
                      <div className="d-flex">
                        <div className="red mt-0"></div>
                        <div className="percentext flex-grow-1">Paid Directly:</div>
                      </div>
                      <div>
                        <div className="percenPoint">
                        { (paymentData?.cardSum?.total)?.toFixed(2) ?? 0}
                        </div>
                      </div>
                    </div>

               
                  </div>
                </div>
              </div>
            </div> 
           
</div>
      </div>
    </>
  );
}

export default PaymentCard;
