import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import APIService from '../../services/APIService'

const header = [
  'Payments',
  'VAT',
  'RiDE Commissions',
  'Bank Payment Fees',
  'Payout',
  'Payout Fees',
  'Employer Commissions'
],
  title = {
    1: 'TOP_UP',
    2: 'SUBSCRIPTION',
    3: 'TRIP_AMOUNT',
    4: 'TAX',
    5: 'FEE',
    6: 'DRIVER_WITHDRAWAL',
  },
  despTitle = [
    'Account Number',
    'Target Account',
    'Bank Name',
    'Purpose',
    'Balance'
  ]

const profileinfo = [{
  label: 'Account Number',
  title: 'SA762728282672220'
},
{
  label: 'Bank Name',
  title: 'Inma Bank'
},
{
  label: 'Purpose',
  title: 'Title'
},
{
  label: 'Balance',
  title: 'SAR 5,000'
}
];
const DummyData ={
  "statusCode": 200,
  "data": {
      "main": {
          "accNum": "68204176823006",
          "currentBal": 26951.82,
          "ledgerBal": 26951.82,
          "availableBal": 26951.82
      },
      "topup": {
          "accNum": "68204176823003",
          "currentBal": 136607.03,
          "ledgerBal": 136607.03,
          "availableBal": 136607.03
      },
      "rideTopup": {
          "accNum": "68204176823005",
          "currentBal": 324.23,
          "ledgerBal": 324.23,
          "availableBal": 324.23
      },
      "vat": {
          "accNum": "68204176823001",
          "currentBal": 5254.01,
          "ledgerBal": 5254.01,
          "availableBal": 5254.01
      },
      "fee": {
          "accNum": "68204176823002",
          "currentBal": 2327.8,
          "ledgerBal": 2327.8,
          "availableBal": 2327.8
      },
      "subscription": {
          "accNum": "68204176823004",
          "currentBal": "error",
          "ledgerBal": "error",
          "availableBal": "error"
      }
  }
}
const All = (props) => {
  const [earnings, setEarnings] = useState([]),
    [lazyParams, setLazyParams] = useState({
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalCount: 0,
    }),
   
    
  
    getEarnings = () => {
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      // props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.get("admin/alinma-accounts/balance")
        .then((response) => {
          // props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          if (response.status === 200 && response?.data?.data?.length < 0) {
            lazyParams.totalCount = response?.data?.data.totalCount;
            setLazyParams(lazyParams);
            // setTotalEarning(response.data.data.totalEarnings.toFixed(2));
            const dataArray = Object.keys(response?.data?.data).map(key => ({
              type: key,
              ...response?.data?.data[key]
            }));
            setEarnings(dataArray);
          }else{
            const dataArrayDummy = Object.keys(DummyData?.data).map(key => ({
              type: key,
              ...DummyData?.data[key]
            }));
            
            console.log('dataArrayDummy',dataArrayDummy)
            setEarnings((dataArrayDummy));

          }
        })
        .catch(() =>
          console.log("e")
          // props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    };
  console.log('earnings', earnings)
  useEffect(() => {
    getEarnings();
  }, [JSON.stringify(earnings)]);
  return (
    <div className='my-2'>
      <Col xl={12} >
        <Row >
          {earnings.map((data, index) => (
            <Col key={index} md={4} className="mb-2"> {/* Use md={4} for 3 columns in each row */}
              <div className="kyc-info">
                <div className='text-dark font-weight-bolder'>{(title[data?.type])}</div>

                

                <React.Fragment>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div>{despTitle[0]}</div>
                    <div className="text-dark font-weight-bold">{data?.accNum}</div>
                  </div>
                  <div className='d-flex flex-grow-1 mt-2' style={{ border: '1px solid #F7F7F7' }}></div>
                </React.Fragment>

                <React.Fragment>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div>{despTitle[2]}</div>
                    <div className="text-dark font-weight-bold">Alinma</div>
                  </div>
                  <div className='d-flex flex-grow-1 mt-2' style={{ border: '1px solid #F7F7F7' }}></div>
                </React.Fragment>

                <React.Fragment>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div>{despTitle[3]}</div>
                    <div className="text-dark font-weight-bold capitilize">{(data?.type)}</div>
                  </div>
                  <div className='d-flex flex-grow-1 mt-2' style={{ border: '1px solid #F7F7F7' }}></div>
                </React.Fragment>

                <React.Fragment>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div>{despTitle[4]}</div>
                    <div className="text-dark font-weight-bold">{data?.currentBal}</div>
                  </div>
                  <div className='d-flex flex-grow-1 mt-2' style={{ border: '1px solid #F7F7F7' }}></div>
                </React.Fragment>
              </div>
            </Col>
          ))}
        </Row>
      </Col>
    </div >
  )
}

export default All
