import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import APIService from "../../services/APIService";
import moment from "moment";
import { barChartOptions,convertDataBasedOnRequirementForCancelledTrips } from "./linechartdata/linechartdata";

function CancelledTrips(props) {
  let [graphTripList, setGraphTripList] = useState([]),
    [graphCountList, setgraphCountList] = useState(null),
    [cancelledGraphData,setCancelledGraphData]=useState({}),
    [statusCounts,setStatusCounts]=useState({}),
    [timeRange, setTimeRange] = useState("week"),
    graphData = !props?.sendgraphTripData?.sendgraphTripList
      ? props?.sendgraphTripData
      : props?.sendgraphTripList?.sendgraphTripList;
  useEffect(()=>{
    setStatusCounts(graphCountList
      ? graphCountList
      : props?.sendgraphTripData?.cancelledTotalStats)
  },[props?.sendgraphTripData?.cancelledTotalStats])

  useEffect(() => {
    setTimeRange(props.timeRange);
    // manageTimeRange();
  }, [props.timeRange]);

  useEffect(()=>{
    // setLineGraphData(props?.sendgraphTripData?.graphList)
    let dataGraphList = props?.sendgraphTripData?.cancelledGraphList
    if(dataGraphList!==undefined){
      const convertedData = convertDataBasedOnRequirementForCancelledTrips(dataGraphList);
      setCancelledGraphData(convertedData);
    }
  },[props.sendgraphTripData])

  const [dateRange,setDateRange] =  useState(null);

  return (
    <div class="tab-pane fade active show" id="All">
      <div className="col-12">
        <h6 className=" graph-label left-text rounded text-capitalize" style={{left:-20}}>
          Trips
        </h6>
        {Object.keys(cancelledGraphData).length > 0 && (
          <Line data={cancelledGraphData} options={barChartOptions} />
        )}
        <h6 className=" graph-label-2 left-text rounded text-capitalize">
        {dateRange && `${moment(dateRange?.startDate).format('DD MMM YYYY')} - ${moment(dateRange?.endDate).format('DD MMM YYYY')}`}
        </h6>
      </div>

      <div className="col-12 graph-menu g-menu">
        <div className="main-percent line-main">
          <div className="percentage">
            <div className="green mt-0" style={{backgroundColor:'#13A5B5'}}></div>
            <div className="percentext">Total cancelled</div>
            <div className="percenPoint">{statusCounts?.cancelledByAdmin}</div>
          </div>

          <div className="percentage ">
            <div className="red mt-0" style={{backgroundColor:'#7071BF'}}></div>
            <div className="percentext">By riders</div>
            <div className="percenPoint">{statusCounts?.cancelledByRider}</div>
          </div>

          <div className="percentage">
            <div className="green  mt-0" style={{ background: "#62AE9A" }}></div>
            <div className="percentext">By captains</div>
            <div className="percenPoint">
              {statusCounts?.cancelledByCaptain}
            </div>
          </div>
        </div>

      </div>
      <div className="graph-menu"><div className="main-percent line-main mt-2">Click on any date to see more info.</div></div>

    </div>
  );
}

export default CancelledTrips;
