import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Tag } from 'primereact/tag';
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import APIService from "../../services/APIService";

let PromotionalCouponsListing = (props) => {
  const { handleShow } = props;
  let [selectedPromotions, setSelectedPromotions] = useState(null),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [globalFilter, setGlobalFilter] = useState(null),
    [downlaodCsv, setDownloadCsv] = useState([]),
    CSVdownloadRef = useRef(null),
    [selectedStatus, setSelectedStatus] = useState(null),
    [cabTypesList, setCabTypeList] = useState([]),
    [subscriptionList, setSubscriptionList] = useState([]),
    [showFilter, setShowFilter] = useState(false),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    currentRoutePermissions = permissions.filter(a => a.includes('/markeeting/promotions/')),
    dt = useRef(null),
    statuses = ["Active", "Inactive", "Expired"],

    headers = [
      { label: 'Name', key: 'code' },
      { label: 'Value', key: 'amount' },
      { label: 'Usage Limit', key: 'maximumTotalUsage' },
      { label: 'Cab Type', key: 'productIds' },
      { label: 'Total budget', key: 'totalBudget' },
      { label: 'Total Utilised', key: 'totalUtilised' },
      { label: 'Valid From', key: 'daysDiff' },
      { label: 'Valid To', key: 'daysDiff' },
      { label: 'Borne By', key: 'borne' },
      { label: 'Status', key: 'Status' },
      { label: 'Action', key: 'action' },
    ],
    renderHeader = () => {
      return (
        <>
          <div className="table-header">
            <span className="toggle-filter">
              <button
                className="btn btn-primary show_filter_btn"
                onClick={() => toggleFilter()}
              >
                {windowWidth ? (
                  showFilter ? (
                    <span>
                      <i className="fa fa-filter"></i>
                      <i className="fa fa-caret-up ml-10"></i>
                    </span>
                  ) : (
                    <span>
                      <i className="fa fa-filter"></i>
                      <i className="fa fa-caret-down ml-10"></i>
                    </span>
                  )
                ) : // <i className="fa fa-filter"></i>
                  showFilter ? (
                    <>
                      <img src="/images/filter-icon.svg" alt="" />

                      <span className="span-filter mx-12">Filters</span>
                      <i className="fa fa-caret-up"></i>
                    </>
                  ) : (
                    <>
                      <img src="/images/filter-icon.svg" alt="" />
                      <span className="span-filter mx-12">Filters</span>
                      <svg
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                          fill="#828282"
                        />
                      </svg>
                    </>
                  )}
              </button>
            </span>
            <span className="p-input-icon-left d-flex align-items-center">
              <i className="pi pi-search" />
              <InputText
                type="search"
                placeholder="Search"
                onInput={(e) => setGlobalFilter(e.target.value)}
              />
            </span>
            {/* <span className="title">Subscription Package</span> */}
            <CSVLink
              data={downlaodCsv}
              headers={headers}
              filename='Promotional.csv'
              target='_blank'
              ref={CSVdownloadRef}
            />
            <button
              className="btn btn-primary float-right mb-1 ml-12"
              onClick={() => downloadCsvFile()}
            >
              Export Report
            </button>
            {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'add') && <button
              className="btn btn-primary float-right mb-1 ml-12"
              onClick={handleShow}
            >
              {windowWidth ? <i className="fa fa-plus"></i> : "+ Add"}
            </button>}
          </div>

        </>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      dt.current.reset();
    },
    actionTemplate = (rowData) => {
      return (
        <div className="button p-table-button">
          <Button
            tooltip="Edit"
            tooltipOptions={{ position: "bottom" }}
            onClick={() => {
              props.handleShowUpdate();
              props.updateTableDate(rowData);
            }}
          >
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.914 14L14.056 3.85796L12.642 2.44396L2.5 12.586V14H3.914ZM4.743 16H0.5V11.757L11.935 0.321961C12.1225 0.13449 12.3768 0.0291748 12.642 0.0291748C12.9072 0.0291748 13.1615 0.13449 13.349 0.321961L16.178 3.15096C16.3655 3.33849 16.4708 3.5928 16.4708 3.85796C16.4708 4.12313 16.3655 4.37743 16.178 4.56496L4.743 16ZM0.5 18H18.5V20H0.5V18Z"
                fill="#03053D"
              />
            </svg>
          </Button>
          {/* <Button
            tooltip="Delete"
            tooltipOptions={{ position: "bottom" }}
            onClick={() => {
              props.handleShowDelete(rowData);
            }}
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5 4H20.5V6H18.5V19C18.5 19.2652 18.3946 19.5196 18.2071 19.7071C18.0196 19.8946 17.7652 20 17.5 20H3.5C3.23478 20 2.98043 19.8946 2.79289 19.7071C2.60536 19.5196 2.5 19.2652 2.5 19V6H0.5V4H5.5V1C5.5 0.734784 5.60536 0.48043 5.79289 0.292893C5.98043 0.105357 6.23478 0 6.5 0H14.5C14.7652 0 15.0196 0.105357 15.2071 0.292893C15.3946 0.48043 15.5 0.734784 15.5 1V4ZM16.5 6H4.5V18H16.5V6ZM7.5 2V4H13.5V2H7.5Z"
                fill="#03053D"
              />
            </svg>
          </Button> */}
        </div>
      );
    },
    getCabTypes = () => {
      APIService.get(`/admin/cab-type/all`).then((response) => {
        if (response?.status === 200) {
          response?.data?.data && setCabTypeList(response?.data?.data)
        }
      }).catch((e) => console.log(e));
    },
    getSubscriptionTypes = () => {
      APIService.get(`/admin/subscription`).then((response) => {
        if (response?.status === 200) {
          response?.data?.data && setSubscriptionList(response?.data?.data)
        }
      }).catch((e) => console.log(e));
    },
    getCabTag = (ids) => {
      let result = []
      cabTypesList.map(cab => ids.includes(cab.id) && result.push(cab.name))
      return result
    },
    getSubsTag = (ids) => {
      let result = []
      subscriptionList.map(cab => ids.includes(cab.id) && result.push(cab.packageName))
      return result
    }


  // get promotional code data into csv 
  const downloadCsvFile = () => {


    setDownloadCsv(props.promos);
    setTimeout(() => {

      CSVdownloadRef.current.link.click();
    }, 500)

  }

  useEffect(() => {
    getCabTypes()
    getSubscriptionTypes()
  }, [])
  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={props.promos}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          globalFilter={globalFilter}
          selection={selectedPromotions}
          onSelectionChange={(e) => setSelectedPromotions(e.value)}
          paginator
          rows={25}
          emptyMessage="No Promotion(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          loading={props.isLoading}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "200px" }}
            sortField="code"
            filterField="code"
            header="Name"
            field="code"
            sortable
            filter={showFilter}
            body={(promo) =>
              <Link 
                to={{ pathname: "/markeeting/promo-detail/" + promo.id, state: { codeName: promo.code } }}
                style={{ color: "#13B542", outline: 'none', fontWeight: '600' }}
              >{promo.code}</Link>
            }
            filterMatchMode="contains"
            filterPlaceholder="Search by coupon title"
          />

          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "160px" }}
            header="Value"
            field="amount"
            sortable
            body={(promo) => {
              if (promo.promoCodeType === 1) {
                return promo.amount.toFixed(2) + " SAR";
              } else if (promo.promoCodeType === 2) {
                return promo.amount + " %";
              } else {
                return "-";
              }
            }}
          />
          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "120px" }}
            header="Usage limit"
            field="maximumTotalUsage"
            sortable
            body={(promo) => promo.userUsage + "/" + promo.maximumTotalUsage}
          />
          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "250px" }}
            header="Cab Types"
            field="productIds"
            sortable
            body={(promo) =>
              promo.productIds ? getCabTag(promo.productIds.split(",")).length > 0 ? getCabTag(promo.productIds.split(",")).map((tag,index) =>
                <Tag key={index} className="mr-2 mb-2" value={tag} ></Tag>
              ) : <Tag value="none" ></Tag>
                : <Tag value="none" ></Tag>
            }
          />
          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "250px" }}
            header="Subscription Type"
            field="productIds"
            sortable
            body={(promo) =>
              promo.productIds ? getSubsTag(promo.productIds.split(",")).length > 0 ? getSubsTag(promo.productIds.split(",")).map((tag,index) =>
                <Tag key={index} className="mr-2 mb-2" value={tag} ></Tag>
              ) : <Tag value="none" ></Tag>
                : <Tag value="none" ></Tag>
            }
          />
          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "180px" }}
            header="Total Budget"
            field="totalBudget"
            sortable
            body={(promo) => promo.totalBudget + "  SAR."}
          />
          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "180px" }}
            header="Total Utilised"
            field="totalUtilised"
            sortable
            body={(promo) => promo.totalUtilised + "  SAR."}
          />
          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "180px" }}
            header="Min Amt. In Cart"
            field="minimumAmountInCart"
            sortable
            body={(promo) => promo.minimumAmountInCart + "  SAR."}
          />
          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "200px" }}
            header="Max Discount Amt."
            field="maximumDiscountAmount"
            sortable
            body={(promo) => promo.maximumDiscountAmount + "  SAR."}
          />
          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "180px" }}
            header="Max Usage/User"
            field="maximumUsagePerUser"
            sortable
            body={(promo) => promo.maximumUsagePerUser}
          />
          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "250px" }}
            header="Valid From"
            field="daysDiff"
            sortable
            body={(promo) => moment(promo.startAt).format("DD/MM/YYYY")}
            filter={false}
            filterMatchMode="contains"
            filterPlaceholder="Search by validity"
          />
          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "250px" }}
            header="Valid To"
            field="daysDiff"
            sortable
            body={(promo) => moment(promo.endAt).format("DD/MM/YYYY")}
            filter={false}
            filterMatchMode="contains"
            filterPlaceholder="Search by validity"
          />
          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "140px" }}
            header="Borne By"
            field="totalBudget"
            sortable
            body={(promo) => "Ride"}
          />

          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "130px" }}
            field="status"
            header="Status"
            sortable
            filter={showFilter}
            body={(promo) =>
              promo.status === "Active" ? (
                <span className="status-text">Active</span>
              ) :
                promo.status === "Expired" ?
                  <span className="status-text-pending">Expired</span>
                  : (
                    <span className="status-text-inactive">Inactive</span>
                  )
            }
            filterElement={
              <Dropdown
                value={selectedStatus}
                options={statuses}
                onChange={(e) => {
                  dt.current.filter(e.value, "status", "equals");
                  setSelectedStatus(e.value);
                }}
                placeholder="Status"
                className="p-column-filter"
                showClear
              />
            }
          />
          <Column
            className="text-left fw-bold"
            style={{ fontSize: "12px", width: "130px" }}
            field="action"
            header="Action"
            body={actionTemplate}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(PromotionalCouponsListing);
