import React, { useEffect, useState, useCallback, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Col, Row, Button } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import APIService from "../services/APIService";
import { connect } from "react-redux";
import { initial } from "lodash";


let SLAConfig = (props) => {
  let tabType = props?.type;
  let [CKConfig, setCKConfig] = useState({
      language: "ar",
      removeButtons: "PasteFromWord",
    }),
    [langConfig, setLangConfig] = useState([]),
    [selectTab, setSelectedTab] = useState('privacy-policy'),
    [initialValues, setInitialValues] = useState(null),
    languages = [
      {
        value: "en",
        label: "English",
      },
      {
        value: "ar",
        label: "العربية",
      },
      {
        value: "zh",
        label: "中文",
      },
      {
        value: "ur",
        label: "اردو",
      },
      {
        value: "fil",
        label: "Filipino",
      },
      {
        value: "ben",
        label: "বাংলা",
      },
      {
        value: "ind",
        label: "Indonasia",
      },
    ],
    manageLanguage = (event) => {
      setCKConfig({
        ...CKConfig,
        language: event?.target?.value || CKConfig.language,
      });
      setLangConfig([]);
      setInitialValues(null);
      getSLAConfig(event?.target?.value || CKConfig.language , tabType);
    },
    getSLAConfig = useCallback(
      (lang, chooseTab) => {
        props.dispatch({ type: "TOGGLE_LOADER", payload: true });
        APIService.get(`master/pages/${chooseTab}/${lang}`)
          .then((response) => {
            if (response.data && response.data.statusCode === 200) {
              if (response?.data && response?.data?.data?.data?.length) {
                setLangConfig(response.data.data.data);
                let initialValues = {};
                response.data.data.data.forEach((elConf) => {
                  initialValues[elConf.order + "-id"] = elConf.id;
                  initialValues[elConf.order + "-title"] = elConf.title;
                  initialValues[elConf.order + "-order"] = elConf.order;
                  initialValues[elConf.order + "-description"] =
                    elConf.description;
                  initialValues[elConf.order + "-field-order"] = elConf.order;
                });
                setInitialValues(initialValues);
                props.dispatch({ type: "TOGGLE_LOADER", payload: false });
              } else {
                setLangConfig([]);
                setInitialValues(null);
                props.dispatch({ type: "TOGGLE_LOADER", payload: false });
              }
            }
          })
          .catch((error) => {
            toast.error("Something went wong.");
            props.dispatch({ type: "TOGGLE_LOADER", payload: false });
          });
      },
      [props]
    ),
    // initialLoadData = useCallback(() => {
    //   setLangConfig([
    //     {
    //       language: CKConfig.language,
    //       title: "Demo",
    //       order: "0",
    //       description: "Sample Description",
    //       id: "",
    //     },
    //   ]);
    //   setInitialValues({
    //     "0-id": "",
    //     "0-description": "sample description",
    //     "0-order": "0",
    //     "0-title": "Demo",
    //   });
    // }, [CKConfig.language]),
    saveSLAConfig = (formValues) => {
      props.dispatch({ type: "TOGGLE_LOADER", payload: true });
      try {
        let totalSection = Object.keys(formValues).length / 5,
          postSLAConfig = [];
        for (let i = 0; i < totalSection; i++) {
          postSLAConfig[i] = !postSLAConfig[i] ? {} : postSLAConfig[i];

          postSLAConfig[i]["language"] = CKConfig.language;
          postSLAConfig[i]["id"] = formValues[i + "-id"] || "";
          postSLAConfig[i]["order"] = formValues[i + "-field-order"];
          postSLAConfig[i]["title"] = formValues[i + "-title"];
          postSLAConfig[i]["description"] = formValues[i + "-description"];
        }
        APIService.post(`master/pages/${selectTab}`, postSLAConfig)
          .then((response) => {
            if (response && response.data && response.data.statusCode === 200) {
              toast.success("Successfully updated.");
              props.dispatch({ type: "TOGGLE_LOADER", payload: true });
              setLangConfig([]);
              setInitialValues(null);
              setTimeout(function () {
                getSLAConfig(CKConfig.language,selectTab);
              }, 1500);
            }
          })
          .catch((error) => {
            toast.error("Something went wong.");
            props.dispatch({ type: "TOGGLE_LOADER", payload: false });
          });
      } catch (error) {
        props.dispatch({ type: "TOGGLE_LOADER", payload: false });
        toast.error("Something went wrong.");
      }
    };
  // getMaxOrder = useCallback((data) => {
  //   return data.reduce(function (prev, current) {
  //     return prev.order > current.order ? prev : current;
  //   });
  // }, []);
  // addMoreSection = useCallback(() => {
  //   let order = langConfig.length
  //     ? parseInt(getMaxOrder(langConfig).order) + 1
  //     : 0;
  //   setLangConfig([
  //     ...langConfig,
  //     {
  //       language: CKConfig.language,
  //       title: "Demo",
  //       order: order.toString(),
  //       description: "Sample Description",
  //       id: "",
  //     },
  //   ]);
  //   setInitialValues({
  //     ...initialValues,
  //     ...{
  //       [order + "-id"]: "",
  //       [order + "-description"]: "sample description",
  //       [order + "-order"]: order.toString(),
  //       [order + "-title"]: "Demo",
  //     },
  //   });
  // }, [CKConfig.language, getMaxOrder, initialValues, langConfig]);

  const firstLoad = useRef(false);
  // useEffect(() => {
    
  //   if (!firstLoad.current && window.location.pathname.includes('trip-settings')) {
  //     firstLoad.current = true;
  //     getSLAConfig(CKConfig.language,selectTab);
  //     console.log('1122------------------1122',window.location)
  //   }
  // }, [CKConfig.language, props]);

  useEffect(()=>{
    setSelectedTab(props?.type);
    // getSLAConfig(CKConfig.language , selectTab)
    manageLanguage();
  },[props])

  const validateOrder = useCallback(
    (value, fieldName) => {
      let totalSection = Object.keys(initialValues).length / 5,
        error;

      for (let i = 0; i < totalSection; i++) {
        if (
          i + "-field-order" !== fieldName &&
          initialValues[i + "-field-order"] === value
        ) {
          error = "Duplicate order not allowed.";
        }
      }
      return error;
    },
    [initialValues]
  );

  return (
    <>
      {/* <div className="rider-listing"> */}
        {/* <span className="title pl-2">SLA Config</span> */}
        <div className="datatable-doc-demo mt-3">
          <div className="card">
            <Row className="p-2 d-flex justify-content-between align-items-center">
              <Col md={12} className="d-flex justify-content-between">
                <div className="mt-30 d-flex flex-column px-4">
                  <div className="text-dark fw-bold">{props.type==="terms-and-conditions" ? "TERMS AND CONDITIONS" : "Privacy Policy"} </div>
                  <div>Change or modify the app privacy policy according to the needs and requirements.</div>
                {/* <Tabs
          id="controlled-tab-example"
          className="mt-30 position-relative"
          activeKey={selectTab}
          onSelect={(tab) => {
            setSelectedTab(tab);
          }}
        >
          <Tab eventKey="privacy-policy" title="Privacy Policy"></Tab>
          <Tab eventKey="terms-and-conditions" title="Terms and Conditions"></Tab>
          </Tabs> */}
                </div>
                <div className="position-absolute bottom-0 end-0 mr-20"> 
                <select
                  className="form-select"
                  value={CKConfig.language}
                  onChange={manageLanguage}
                >
                  {languages.map((lan) => (
                    <option key={lan.value} value={lan.value}>
                      {lan.label}
                    </option>
                  ))}
                </select>
                </div>
              </Col>
            </Row>
            <Row className="p-2">
              {initialValues && (
                <Formik
                  initialValues={{ ...initialValues }}
                  validationSchema={null}
                  onSubmit={saveSLAConfig}
                >
                  {({ setFieldValue }) => {
                    return (
                      <Form>
                        <div className="mt-2 p-2">
                          <div className="card">
                            <div className="card-header">
                              <h3>Main Header</h3>
                            </div>
                            {langConfig.map((lan) =>
                              !lan?.parentId ? (
                                <div key={lan.parentId} className="card-body">
                                  <Row className="mt-1 ">
                                    <Col md={12}>
                                      <div className="form-group form-label-group">
                                        <Field
                                          className="form-control"
                                          type="text"
                                          placeholder="Title"
                                          id={lan.order + "-title"}
                                          name={lan.order + "-title"}
                                          autoComplete="off"
                                        />
                                        <label htmlFor={lan.order + "-title"}>
                                          Title
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={12}>
                                      <div className="form-group form-label-group">
                                        <CKEditor
                                          editor={ClassicEditor}
                                          config={CKConfig}
                                          id={lan.order + "-description"}
                                          name={lan.order + "-description"}
                                          data={lan.description}
                                          onChange={(event, editor) => {
                                            setFieldValue(
                                              lan.order + "-description",
                                              editor.getData()
                                            );
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              ) : null
                            )}
                          </div>

                          <div className="card mt-4">
                            <div className="card-header">
                              <h2>Sections</h2>
                            </div>
                            <div className="card-body p-4">
                              {langConfig.map((lan) =>
                                lan.parentId ? (
                                  <div key={lan.id} className="card p-2 mt-2">
                                    <Row className="p-2">
                                      <Col md={12}>
                                        <h4>{lan.title}</h4>
                                      </Col>
                                    </Row>
                                    <Row className="mt-1">
                                      <Col md={6}>
                                        <div className="form-group form-label-group">
                                          <Field
                                            className="form-control"
                                            type="text"
                                            placeholder="Title"
                                            id={lan.order + "-title"}
                                            name={lan.order + "-title"}
                                            autoComplete="off"
                                          />
                                          <label htmlFor={lan.order + "-title"}>
                                            Title
                                          </label>
                                        </div>
                                      </Col>
                                      <Col md={6}>
                                        <div className="form-group form-label-group">
                                          <Field
                                            className="form-control"
                                            type="number"
                                            placeholder="Order"
                                            id={lan.order + "-order"}
                                            name={lan.order + "-field-order"}
                                            autoComplete="off"
                                            validate={(value) =>
                                              validateOrder(
                                                value,
                                                lan.order + "-field-order"
                                              )
                                            }
                                          />
                                          <label
                                            htmlFor={lan.order + "-field-order"}
                                          >
                                            Order
                                          </label>
                                          <ErrorMessage
                                            name={lan.order + "-field-order"}
                                            component="div"
                                            className="invalid-feedback text-danger"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={12}>
                                        <div className="form-group form-label-group">
                                          <CKEditor
                                            editor={ClassicEditor}
                                            config={CKConfig}
                                            id={lan.order + "-description"}
                                            name={lan.order + "-description"}
                                            data={lan.description}
                                            onChange={(event, editor) => {
                                              setFieldValue(
                                                lan.order + "-description",
                                                editor.getData()
                                              );
                                            }}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                ) : null
                              )}
                            </div>
                          </div>
                          <div
                            className="bg-white d-flex justify-content-end py-2"
                            style={{
                              position: "sticky",
                              zIndex: "999",
                              bottom: "0",
                              width: "100%",
                            }}
                          >
                            <Button
                              variant="primary"
                              type="submit"
                              className="btn border-radius-12"
                            >
                              <span className="btn-text">Save</span>
                            </Button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </Row>
          </div>
        </div>
      {/* </div> */}
    </>
  );
};

export default React.memo(connect()(SLAConfig));
