import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import ManagementDetailsTab from "../components/Management/ManagementDetailsTab";
import { connect } from "react-redux";


let ManagementListingTableDetailPage = (props) => {
  const  history = useHistory();
   
  return (
    <>
      <div className="rider-listing">
        <span onClick={() => history.goBack()} style={{ cursor: "pointer" }}>
          <i className="fa fa-chevron-left back-arrow"></i>
        </span>
        <span className="title">Management Details</span>
        <ManagementDetailsTab/>

      </div>
    </>
  );
};

export default React.memo(connect()(ManagementListingTableDetailPage));
