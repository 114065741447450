import React , {useState} from "react";
import DriverListingTable from "../components/Drivers/DriverListingTable";
import { Tab, Tabs } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import IncompleteDrivers from "../components/Drivers/IncompleteDrivers";
import { toast } from "react-toastify";
import APIService from "../services/APIService";

let initiateWaslForAll = () => {
  APIService.get("/admin/captain/check-all-wasl")
    .then((response) => {
      if (response.status === 200) {
        toast.success("Send request for initiate kyc");
      } else {
        toast.error("Failed request for initiate kyc");
      }
    })
    .catch((error) => {
      console.log("respond_qw", error.response);
    });
};

let DriverListing = () => {
  let [selectTab, setSelectedTab] = useState("completed");
  return (
    <>
      <div className="rider-listing">
      <div className="d-flex justify-content-between">
          <span className="title">Captains</span>
          <div className="table-header">
            <button className="btn btn-primary" onClick={initiateWaslForAll}>Recheck WASL</button>
          </div>
        </div>
        <Tabs
          id="controlled-tab-example"
          className="mt-30 position-relative"
          activeKey={selectTab}
          onSelect={(tab) => {
            setSelectedTab(tab);
          }}
        >
          
          <Tab eventKey="completed" title="Complete">
            {selectTab === "completed" && (
              <DriverListingTable />
            )}
          </Tab>
          <Tab eventKey="incompleted" title="Incomplete">
            {selectTab === "incompleted" && (
              // <div>COMING SOON</div>
              <IncompleteDrivers />
            )}
          </Tab>
          </Tabs>
        {/* <DriverListingTable /> */}
      </div>
    </>
  );
};

export default React.memo(DriverListing);