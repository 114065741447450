import React from 'react'

function DriverInfobox(props) {
  
  console.log('ssssssssssssssssss',props?.tripData?.topStats?.trip?.count)
    return (
    <>
    <div className='row'>

    <div className='col-lg-3 col-md-6 col-sm-12 '>
        <div className=' d-flex align-items-center justify-content-between p-2 bg-white' style={{border:'1px solid gray',borderRadius:'5px',height:'55px',outline:'1px solid green'}}>
            <div className=' font-weight-bold text-secondary'>Total Captains</div>
            <div className=' font-weight-bold text-success'>{props?.data?.waslOnline + props?.data?.waslOffline  || 0 }</div>
        </div>
    </div>
    <div className='col-lg-3 col-md-6 col-sm-12 '>
        <div className=' d-flex align-items-center justify-content-between p-2 bg-white' style={{border:'1px solid gray',borderRadius:'5px',height:'55px',outline:'1px solid green'}}>
            <div className=' font-weight-bold text-secondary'>Online Captains</div>
            <div className=' font-weight-bold text-success'>{props?.data?.waslOnline}</div>
        </div>
    </div>
    <div className='col-lg-3 col-md-6 col-sm-12 '>
        <div className=' d-flex align-items-center justify-content-between p-2 bg-white' style={{border:'1px solid gray',borderRadius:'5px',height:'55px',outline:'1px solid green'}}>
            <div className=' font-weight-bold text-secondary'>Offline Captains</div>
            <div className=' font-weight-bold text-success'>{props?.data?.waslOffline}</div>
        </div>
    </div>
    <div className='col-lg-3 col-md-6 col-sm-12 '>
        <div className=' d-flex align-items-center justify-content-between p-2 bg-white' style={{border:'1px solid gray',borderRadius:'5px',height:'55px',outline:'1px solid green'}}>
            <div className=' font-weight-bold text-secondary'>Made Trips Today</div>
            <div className=' font-weight-bold text-success'>{props?.tripData?.topStats?.trip?.count || 0}</div>
        </div>
    </div>
    </div>
    </>
  )
}

export default DriverInfobox