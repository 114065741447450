import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import APIService from "../../services/APIService";
import moment from "moment/moment";
import { toast } from "react-toastify";

let VehicleUnAssignedLising = (props) => {
  let [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [vehicles, setVehicles] = useState([]),
    [selectedVehicles, setSelectedVehicles] = useState(null),
    [globalFilter, setGlobalFilter] = useState(null),
    [showFilter, setShowFilter] = useState(false),
    [cabTypes,setCabTypes] = useState([]),
    [cabTypeList,setCabTypeList] = useState([]),
    dt = useRef(null),
    [selectedStatus, setSelectedStatus] = useState(null),
    statuses = ["Active", "Inactive"],
    [lazyParams, setLazyParams] = React.useState({
      filters: {},
      sort: {
        field: "vehicleMakerEnglish",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    getVehicles = () => {

        let totalCabs =[]
        let assignedCabs, unAssignedCabs
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.get("/admin/vehicle-model/all-unassigned", filterParams)
        .then((response) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          if (response.status === 200) {
            lazyParams.totalRecords = response.data.data.total;
            // setLazyParams(lazyParams);
            // unAssignedCabs = response.data.data.result

             setVehicles(response.data.data.result);
          }
        })
        .catch(() =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
       
    },
    updateData = (id, value) => {
   
        let findCab = cabTypes.filter(item => item.value === value.value)
        let findCabType = cabTypeList.filter(item => item.name == findCab[0]?.label)
       
        APIService.patch(`/admin/vehicle-model/${id}`, {
            cabTypeId: findCabType[0].id,
            cabTypeName : findCabType[0].name
          })
            .then((response) => {
              if (response && response.data.statusCode === 200) {
                toast.success("Updated Successfully");
                getVehicles();
              }
            })
            .catch((error) => {
              toast.error("Something went wrong.");
            });

        // code to update the data for the specific row based on the id
    },
    getCabCategories = () => {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
        APIService.get("/master/cab-type/all")
          .then((response) => {
            if (response.data && response.data.statusCode === 200) {
              setCabTypeList(response.data.data)
                 
              let cabDetail = response.data.data
                .map((cab,index) => {
                  let cabItem = {
                     label: cab.name,
                     value : index + 1,
                    //  name : cab.name,
                  
                  }
              
                  return cabItem;
                })
            
            setCabTypes(cabDetail)
       

              props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
            }
           
          })
          .catch(() => {
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          });
      },
    manageVehicles = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            lazyParams.filters[filter] = event.filters[filter].value;
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getVehicles();
    };

  useEffect(() => {
    getVehicles();
  }, []);
  let renderHeader = () => {
      return (
        <div className="table-header">
          {/* <span>
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
               showFilter ? (
                <span><i className="fa fa-filter"></i><i className="fa fa-caret-up ml-10" ></i></span>
              ) : (
                <span><i className="fa fa-filter"></i><i className="fa fa-caret-down ml-10"></i></span>
              )
                // <i className="fa fa-filter"></i>
              ) : showFilter ? (
                <><i className="fa fa-filter"></i> <span className="span-filter" >Filters</span><i className="fa fa-caret-up ml-100" ></i></>
              ) : (
                <><i className="fa fa-filter"></i> <span className="span-filter">Filters</span><i
                className="fa fa-caret-down ml-100"></i></>
              )}
            </button>
          </span> */}
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                getVehicles();
              }}
            />
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setLazyParams({
        filters: {},
        sort: {
          field: "modelYear",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      getVehicles();
    },
    vehicleTypePhotoBodyTemplate = (rowData) => {
      return (
        <React.Fragment>
          <img
            src={
              rowData?.vehicleImage
                ? rowData.vehicleImage
                : "showcase/demo/images/flag_placeholder.png"
            }
            width="50"
            height="50"
            onError={(e) =>
              (e.target.src = process.env.PUBLIC_URL + `/images/car.png`)
            }
            alt={rowData.vehicleImage}
            className="p-column-listing-photo vehicle-photo"
          />
        </React.Fragment>
      );
    };
    useEffect(() => {
        getCabCategories();
      }, []);
  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={vehicles}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          globalFilter={globalFilter}
          selection={selectedVehicles}
          onSelectionChange={(e) => setSelectedVehicles(e.value)}
          paginator
          emptyMessage="No Vehicle(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageVehicles}
          onSort={manageVehicles}
          onFilter={manageVehicles}
        >
        
          
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            sortField="cabName"
            filterField="cabName"
            header="ID"
             field="id"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by vehicle category"
          />
            <Column
            className="text-left"
            style={{ fontSize: "12px", width: "230px" }}
            header="Date & Time"
            field="createdAt"
            body={(driver) =>
              moment(driver.createdAt).format("DD/MM/YYYY hh:mm A")
            }
            sortable
            filter={showFilter}
            filterMatchMode="custom"
            // filterElement={
            //   <div className="d-inline-flex position-relative w-100">
              
            //     <span className="input-box-daterange-icon">
                 
            //     </span>
            //   </div>
            // }
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            // field="maker"
            header="Manufacture"
            filterPlaceholder="Search by vehicle maker"
            sortable
            filter={showFilter}
            body={(rowData) => (
               rowData?.maker?.maker
            )}
          />
         
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
           field="model"
            header="Model"
            filter={showFilter}
            filterPlaceholder="Search by vehicle model"
            sortable
          />
<Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            // field="maker"
            header="Category Type"
            filterPlaceholder="Search by vehicle maker"
            sortable
            filter={showFilter}
            body={(rowData) => (
               rowData?.cabTypeName
            )}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            header="Category"
            // field="majorColorEnglish"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by color"
             body={(rowData) => (
                
                <Dropdown 
                id="select" 
                // value={cabTypes[0].name}
                options={cabTypes}
                onChange={e => updateData(rowData.id, e)}
                 placeholder="Category"
            />
            //     <Dropdown
            //     value={selectedStatus}
            //     options={cabTypes}
            //     onChange={(e) => {
            //       dt.current.filter(e.value, "status", "equals");
            //       setSelectedStatus(e.value);
            //     }}
            //     placeholder="Category"
            //     // className="p-column-filter"
            //     showClear
            //   />
             
             )}
            />
            <Column
            className="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            // field="maker"
            header="Status"
            filterPlaceholder="Search by vehicle maker"
            sortable
            filter={showFilter}
            body={(rowData) => (
                rowData?.cabTypeId ? (
                    <span className="status-text">Assigned</span>
                  ) : (
                    <span className="status-text-inactive">
                     unAssigned
                    </span>
                  ))}
         
          />
         
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(compose(withRouter, connect())(VehicleUnAssignedLising));
