import React,{useState, useEffect} from "react";
import { Col, Row } from "react-bootstrap";
import { _baseURL } from "../../services/APIService";
import APIService from "../../services/APIService";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import riderAvtSec from "../../assest/images/placeHoderImage.png";

const SubscriptionDetails = ()=>{
  const [isMounted, setIsMounted] = useState(true);

  const [lazyParams, setLazyParams] = useState({
    filters: {
        subscriptionUsersListFilters: "905ea489-bad3-4c1b-a631-b3e9de796fb9"
    },
    sort: {
        field: {
            userId: "us.userId"
        },
        order: "string"
    },
    take: "10",
    skip: "0"
  });

  const [showFilter, setShowFilter] = useState(false);
  const sampleData = [
    { id: 1242728227, fullName: "John Doe", utilizedIn: "121 Trips", start: "24/09/22 - 11:30 PM", end: "24/09/22 - 11:30 PM" },
    { id: 1242728227, fullName: "John Doe", utilizedIn: "121 Trips", start: "24/09/22 - 11:30 PM", end: "24/09/22 - 11:30 PM" },
    { id: 1242728227, fullName: "John Doe", utilizedIn: "121 Trips", start: "24/09/22 - 11:30 PM", end: "24/09/22 - 11:30 PM" },
    { id: 1242728227, fullName: "John Doe", utilizedIn: "121 Trips", start: "24/09/22 - 11:30 PM", end: "24/09/22 - 11:30 PM" },
    { id: 1242728227, fullName: "John Doe", utilizedIn: "121 Trips", start: "24/09/22 - 11:30 PM", end: "24/09/22 - 11:30 PM" },
    { id: 1242728227, fullName: "John Doe", utilizedIn: "121 Trips", start: "24/09/22 - 11:30 PM", end: "24/09/22 - 11:30 PM" },
    { id: 1242728227, fullName: "John Doe", utilizedIn: "121 Trips", start: "24/09/22 - 11:30 PM", end: "24/09/22 - 11:30 PM" },
    { id: 1242728227, fullName: "John Doe", utilizedIn: "121 Trips", start: "24/09/22 - 11:30 PM", end: "24/09/22 - 11:30 PM" },
    { id: 1242728227, fullName: "John Doe", utilizedIn: "121 Trips", start: "24/09/22 - 11:30 PM", end: "24/09/22 - 11:30 PM" },
    { id: 1242728227, fullName: "John Doe", utilizedIn: "121 Trips", start: "24/09/22 - 11:30 PM", end: "24/09/22 - 11:30 PM" },
    { id: 1242728227, fullName: "John Doe", utilizedIn: "121 Trips", start: "24/09/22 - 11:30 PM", end: "24/09/22 - 11:30 PM" },
    { id: 1242728227, fullName: "John Doe", utilizedIn: "121 Trips", start: "24/09/22 - 11:30 PM", end: "24/09/22 - 11:30 PM" },
    // Add more sample data items here
  ];

  let [subsDetails, setSubsDetails] = useState([]), [subsUsers, setSubsUsers] = useState([]);

  let getSubsDetails = async () => {
    APIService.get(`admin/subscription/905ea489-bad3-4c1b-a631-b3e9de796fb9`)
    .then((response) => {
      if (response.data && response.data.statusCode === 200) {
        const logData1 = response.data.data;
        console.log(response)

        setSubsDetails(logData1)
        setLazyParams({ ...lazyParams, totalRecords: logData1.length });
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };
  let getSubsUsers = async () => {
    APIService.post(`admin/find-users-of-subscriptions`, {
      "filters": {
          "subscriptionUsersListFilters": "905ea489-bad3-4c1b-a631-b3e9de796fb9"
      },
      "sort": {
          "field": {
              "userId": "us.userId"
          },
          "order": "DESC"
      },
      "take": "10",
      "skip": "0"
    }).then((response) => {
      if (isMounted) {
      if (response.data && response.data.statusCode === 200) {
        const logData2 = response?.data?.data;
        console.log(logData2)

        setSubsUsers(logData2)
        setLazyParams({ ...lazyParams, totalRecords: logData2.length });
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };
  useEffect(() => {
    getSubsUsers();
    getSubsDetails();

    // return () => {
    //   setIsMounted(false);
    // };
  }, []);

  const redirectIdTemplate = (rowData) => {
    return (
      <div className="text-left fw-bold">
        {rowData?.profileImage ? (
          // <img
          //   src={`${rowData?.profileImage}`}
          //   alt="ride"
          //   style={{
          //     marginRight: "15px",
          //     width: "48px",
          //     height: "48px",
          //     borderRadius: "50%",
          //     objectFit: "cover",
          //   }}
          // />
          <img
            src={riderAvtSec}
            alt="ride"
            style={{
              marginRight: "15px",
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        ) : (
          <img
            src={riderAvtSec}
            alt="ride"
            style={{
              marginRight: "15px",
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        )}

        {rowData.id && (
          <Link
          to={"#"}
            className="dataFnt-size"
            style={{ color: "rgba(19, 181, 66, 1)" }}
          >
            <span className="dataFnt-size" style={{ color: "#13B542", fontWeight: 'bold' }}>
              {rowData.id ? rowData.id.split('-')[0] : '-'}
              {/* {rowData.id.split('-')[0]} */}
            </span>
          </Link>
        )}
      </div>
    );
  };
  return(
    <div className="rider-listing tickets-page">
          <span style={{ cursor: "pointer" }}>
            {
              <Link
                to={`/subscriptions/own-a-car`}
                className="dataFnt-size"
                style={{ color: "rgba(19, 181, 66, 1)" }}
              >
                <i className="fa fa-chevron-left back-arrow"></i>
              </Link>
            }
          </span>
          <span className="title">Subscriptions</span>
      <div className="inner-box mt-3">
      <Row className="p-4 d-box">
        <Col lg={4}>
          {/* <h5 style={{fontWeight:"bold"}}>CREATOR DETAILS</h5> */}
          <div className="flex-ticket flex-margin">
            <p className="ticket-heading">Sr no.</p>
            <p className="ticket-text">
              {subsDetails.serial ? subsDetails.serial : '-'}
            </p>
          </div>
          <div className="flex-ticket flex-margin">
            <p className="ticket-heading">Total KMs</p>
            <p className="ticket-text ">
              {subsDetails.distance ? subsDetails.distance : '-'}
            </p>
          </div>
          <div className="flex-ticket flex-margin">
            <p className="ticket-heading">Start</p>
            <p className="ticket-text ">
              {subsDetails.startDate ? subsDetails.startDate.split('T')[0] : '-'}
            </p>
          </div>

          {/* <div className="flex-ticket flex-margin">
            <p className="ticket-heading">Ticket type</p>
            <p className="ticket-text">
              {_getTicketCategory(ticketDetails.category_id)}
              ----                        
            </p>
          </div> */}
        </Col>
        <Col lg={4} className="border-custom">

          <div className="flex-ticket flex-margin">
            <p className="ticket-heading">Description</p>
            <p className="ticket-text">
              {/* {subsDetails.packageDescription} */}
              <span dangerouslySetInnerHTML={{ __html: subsDetails.packageDescription ? subsDetails.packageDescription.substr(0,46) : '-' }} />
            </p>
          </div>
          <div className="flex-ticket flex-margin">
            <p className="ticket-heading">Subs. Validity</p>
            <p className="ticket-text">
              {subsDetails.noOfDays ? subsDetails.noOfDays : '-'}
              {/* {ticketDetails?.last_ticket_assignment?.assigned_to
                ?.name || "Not Assigned Yet"} */}
            </p>
          </div>
          <div className="flex-ticket flex-margin">
            <p className="ticket-heading">End</p>
            <p className="ticket-text">
              {subsDetails.endDate ? subsDetails.endDate.split('T')[0] : '-'}
            </p>
          </div>
      
        </Col>
        <Col lg={4}>
        <div className="flex-ticket flex-margin">
            <p className="ticket-heading">Amount</p>
            <p className="ticket-text">
              {subsDetails.basePrice ? subsDetails.basePrice : '-'} SAR
            </p>
          </div>
          <div className="flex-ticket flex-margin">
            <p className="ticket-heading">Status</p>
            <p className="ticket-text">
              {subsDetails.status ? 'Active' : 'InActive'}
            </p>
          </div>
          <div className="flex-ticket flex-margin">
            <p className="ticket-heading">Promo/ Discount</p>
            <p className="ticket-text">
              {subsDetails.discount ? subsDetails.discount : '-'}
              {/* {ticketDetails?.customer?.name} */}
            </p>
          </div>
        </Col>
      </Row>
    </div>
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          className="p-datatable-customers"
          value={subsUsers}
          scrollable
          scrollHeight="470px"
          dataKey="id"
          rowHover
          paginator
          rows={25}
          emptyMessage={"No Subscribers(s) found" + isMounted}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
        >
           <Column
            className="text-center"
            style={{ fontSize: "12px", width: "250px", fontWeight: "bold" }}
            field="id"
            header="ID"
            body={redirectIdTemplate}
            sortable
            filter={showFilter}
            filterField="id"
            // filterElement={
            //   <InputText value={filterSearch} onChange={onSearchFilter}  placeholder="Search by id" />
            // }
            filterMatchMode="contains"
            filterPlaceholder="Search by ID"
          />
          <Column
            style={{ fontSize: "12px" , fontWeight: "bold"}}
            header="User ID"
            field="userId"
            body={(value)=>{return (
              <span>
                {value.userId ? value.userId : '-'}
              </span>
            )}}
            sortable
          />
          <Column
            style={{ fontSize: "12px" , fontWeight: "bold"}}
            header="Full Name"
            field="fullName"
            body={(value)=>{return (
              <span>
                {value.fullName ? value.fullName : '-'}
              </span>
            )}}
            sortable
          />
          <Column
            style={{ fontSize: "12px", fontWeight: "bold", color:'#13B542' }}
            header="Utilized In"
            field="utilizedIn"
            body={(value)=>{return (
              <span>
                {value.utilizedIn ? value.utilizedIn : '-'}
              </span>
            )}}
            sortable
          />
          <Column
            style={{ fontSize: "12px", fontWeight: "bold" }}
            header="Start"
            field="startDate"
            body={(value)=>{return (
              <span>
                {value.startDate ? value.startDate : '-'}
              </span>
            )}}
            sortable
          />
          <Column
            style={{ fontSize: "12px", fontWeight: "bold" }}
            header="End"
            field="endDate"
            body={(value)=>{return (
              <span>
                {value.endDate ? value.endDate : '-'}
              </span>
            )}}
            sortable
          />
        </DataTable>
      </div>
    </div>
</div>
)
};
export default SubscriptionDetails;
