import React,{useEffect, useState} from "react";
import { Card, Col, Row ,Modal,Button } from "react-bootstrap";
import logo from "../../assest/images/riderLogo.svg";
import riderAvtSec from "../../assest/images/placeHoderImage.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select, { StylesConfig } from "react-select";
import { toast } from "react-toastify";
import { Link, withRouter } from "react-router-dom";
import { _baseURL,secret_key } from "../../services/APIService";
import * as Yup from "yup";


let CaptainInfo = (props) => {
  const { captainInfo, status,userStatusDriver ,tripData} = props;
  const _saveTicketURL = _baseURL + "/api/v1/ticket/save";
  const _getCountryandCities = _baseURL + "/api/v1/countries";
  const colourStyles: StylesConfig<ColourOption, true> = {
    // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#fff"
          : isFocused
          ? "white"
          : undefined,
        color: isDisabled ? "white" : isSelected ? "#13B542" : "#000000",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : "white"
            : undefined,
        },
      };
    },
  };
  let ticketType = [
    { value: 1, label: "Ride Cancellation" },
    { value: 2, label: "Subscription Issue" },
    { value: 3, label: "Payment Not Received" },
  ],
  [showTicket, setShowTicket] = useState(false);
  const [submitting, isSubmitting] = useState(false)
  const [countriesCities, setCountriesCities] = useState([]);
  const [selectableCountry, setSelectableCountry] = useState([]);
   const [selectedCities, setSelectedCities] = useState([]);
   const [ticketOptionsFiltered, setTicketOptionsFiltered] = useState([]);
   const _getComplainTypes =
  _baseURL + "/api/v1/ticket/get-complaint-type?secret_key=" + secret_key;

const getTicketType = async () => {
  const response = await fetch(_getComplainTypes)
  const final_data = await response.json()
  setTicketOptionsFiltered(final_data?.data.map(a => {
    return { value: a.id, label: a.title }
  }))
};
const captainCategory = ['Registration', 'Passenger No Show', 'Vehicle Document Renewal', 'Trip', 'Payout', 'Other'];


let assignType = [
  { value: 1, label: "Rider" },
  // { value: 2, label: "Driver" },
];
let [ticketSelectionType, setSelectionType] = useState({
  value: 1,
  label: "Captain",
});
const handleCloseTicket = () => {
  setShowTicket(false);
  setSelectedCities([]);
};
let handleShowTicket = () => {
  setShowTicket(true);
};

useEffect(()=>{
  _getCountryandCitiesFunc();
  getTicketType();
 },[])
 let _getCountryandCitiesFunc = async () => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ secret_key: secret_key }),
  };
  const response = await fetch(_getCountryandCities, requestOptions);
  let final_data = await response.json();
  setCountriesCities(final_data.data)
  let countries = final_data.data.map((country) => (
    ({
      value: country.id, label: country.name
    })
  ))
  setSelectableCountry(countries);
}

const signUpSchma = Yup.object({
  ticket_type: Yup.string().required("Please select ticket type"),
  comment: Yup.string().required("Please enter comment"),
  city: Yup.string().required("Please select city"),
  country: Yup.string().required("Please select country"),
});
  const getStatusText = (value) => {
    let statusText;
    switch (value) {
      case 1:
        statusText = "Pending";
        break;
      case 2:
        statusText = "Accepted by Driver";
        break;
      case 3:
        statusText = "Rejected by Driver";
        break;
      case 4:
        statusText = "Cancelled by Driver";
        break;
      case 5:
        statusText = "Driver Arrived";
        break;
      case 6:
        statusText = "Cancelled by Rider";
        break;
      case 7:
        statusText = "Started";
        break;
      case 8:
        statusText = "Completed";
        break;
      case 9:
        statusText = "No Driver found";
        break;
      case 10:
        statusText = " Trip Expired";
        break;
      default:
        statusText = "-";
        break;
    }
    return statusText;
  };
  return (
    <>
      {status === 9 ? (
        <Card className="d-box mt-3">
          <div>
            <h2 className="box-title">Captain</h2>
          </div>
          <div
            className="status-text-inactive"
            style={{ textAlign: "center", marginTop: "135px" }}
          >
            {getStatusText(9)}
          </div>
        </Card>
      ) : status === 10 ? (
        <Card className="card-4 mt-3">
          <div>
            <h2 className="box-title">Captain</h2>
          </div>
          <div
            className="status-text-inactive"
            style={{ textAlign: "center", marginTop: "135px" }}
          >
            {getStatusText(10)}
          </div>
        </Card>
      ) : (
        <Card className="card-4 mt-3">
          <div style ={{display: "flex"}}>
          <h2 className="box-title">Captain</h2>
          {captainInfo?.userId && <button
            className="btn btn-success ml-3"
             onClick={handleShowTicket}
          >
            Create Ticket
          </button>}
        </div>
         <div>
           

          </div>
          <Row className="mt-24 mx-0">
            <Col xl={12} className="px-0">
              <div className="d-flex align-items-center">
              <div>
                {captainInfo?.profileImage ? (
                  <img
                    src={`${captainInfo?.profileImage}`}
                    alt="ride"
                    style={{
                      marginRight: "15px",
                      width: "84px",
                      height: "84px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img
                    src={riderAvtSec}
                    alt="ride"
                    style={{
                      marginRight: "15px",
                      width: "84px",
                      height: "84px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                )}
              </div>
              <div>
                <div className="title-text-detail">
                  <div className="detail-list-1-name">
                    {captainInfo?.firstName}
                  </div> 
                  <div className="d-flex align-items-baseline flex-wrap">
                  <div className="detail-list-1-id">
                  <Link
                    to={"/driver-details/details/" + captainInfo?.userId}
                    // style={{ color: "#13B542" }}
                  >
                    #{captainInfo?.userId}
                  </Link>
                    {/* {`#${rideViewData?.userId}`}#{captainInfo?.userId} */}
                  </div>
                  <div>
                  {userStatusDriver?.onlineStatus ? (
                    <span className="status-text">Online</span>
                  ) : (
                    <span className="status-text-inactive">Offline</span>
                  )}
                </div>
                  </div>
                </div> 
              </div>
              </div>
            </Col>
            
          </Row>
          <Row className="mx-0 mt-24 inner-box row">
            <Col md={12} className="px-0">
            <div className="card-mt-13">
                  <div className="card-label-13">Car plate no.</div>
                  <div className="card-label-14 text-dark font-weight-bolder arabic-fonts">
                    {captainInfo?.carPlateNo &&
                      captainInfo?.carPlateNo?.split("").join(" ")}
                  </div>
              </div>
              <div className="card-mt-13 mt-2">
                  <div className="card-label-13">Car license type</div>
                  <div className="card-label-14 text-dark font-weight-bolder">{captainInfo?.carLicenceType == 1  ? "PRIVATE" :captainInfo?.carLicenceType == 2 ? "PUBLIC_TRANSPORT" : "TAXI" }</div>
              </div>
              <div className="card-mt-13 mt-2">
                  <div className="card-label-13">Car sequence no.</div>
                  <div className="card-label-14 text-dark font-weight-bolder">
                    {" "}
                    {captainInfo?.carSequenceNo}
                  </div>
                </div>
                <div className="card-mt-13 mt-2">
                  <div className="card-label-13">Car category</div>
                  <div className="card-label-14 text-dark font-weight-bolder"> {tripData?.cab?.name}</div>
                </div>
                <div className="card-mt-13 mt-2">
                  <div className="card-label-13">Driving mode</div>
                  <div className="card-label-14 text-dark font-weight-bolder"> --</div>
                </div>
                <div className="card-mt-13 mt-2">
                  <div className="card-label-13">Is pickup captain?</div>
                  <div className="card-label-14 text-dark font-weight-bolder"> --</div>
                </div> 
            </Col>
          </Row>
        </Card>
      )}
         <Modal
            className="Create-Ticket"
            show={showTicket}
            onHide={handleCloseTicket}
            centered
            backdrop="static"
            keyboard={false}
          >
            <Formik
              initialValues={{
                ticket_type: "",
                name: "",
                phone: "",
                comment: "",
                assign_type: "",
                city:"",
                country:""
              }}
              validationSchema={signUpSchma}
              onSubmit={async (values) => {
                isSubmitting(true);
                let dept_id = values.ticket_type;
                let description = values.comment;
                let name = captainInfo?.fullName;
                let phone =captainInfo?.userId;
                let assign_type = ticketSelectionType.value;
                let city = values.city;
                let country = values.country;
                const requestOptions = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "Access-Control-Allow-Origin": "*",
                  },
                  body: JSON.stringify({
                    category_id: dept_id,
                    description: description,
                    secret_key: secret_key,
                    name: name,
                    contact_no: phone,
                    type: assign_type,
                    country_id: country,
                    city_id: city,
                    channels: '2'
                  }),
                };
                const response = await fetch(_saveTicketURL, requestOptions);
                let final_data = await response.json();
                if (final_data.success === true)
                  toast.success("Ticket created successfully!");
                else toast.error("Opsss! Ticket not created.");
                isSubmitting(false);
                handleCloseTicket();
              }}
            >
              {({ handleBlur, setFieldValue, values }) => {
                return (
                  <Form>
                    <Modal.Header>
                      <Modal.Title className="modal-title">
                        Create New Ticket
                      </Modal.Title>
                      <div className="divclose" onClick={handleCloseTicket}>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </Modal.Header>
                    <Modal.Body className="mt-24">
                      <Row>
                      <Col md={12}>
                      <div className="mb-3 form-label-group ">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Ticket Type"
                          name="ticket_type"
                          styles={colourStyles}
                          onBlur={() => {
                            handleBlur({ target: { name: "ticket_type" } });
                          }}
                          onChange={(selectedOption) =>
                            setFieldValue(
                              "ticket_type",
                              selectedOption.value
                            )
                          }
                          options={ticketOptionsFiltered?.filter(a => captainCategory.includes(a.label))}
                        />
                        <label>Ticket Type</label>
                        <ErrorMessage
                          name="ticket_type"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                        <Col md={12}>
                          <div className="mb-3 form-label-group ">
                            <Select
                              className="height-50 br-10 bg-color-select"
                              placeholder="Ticket Type"
                              name="assign_type"
                              styles={colourStyles}
                              onBlur={() => {
                                handleBlur({ target: { name: "assign_type" } });
                              }}
                              // onChange={async (selectedOption) =>
                              //   await setFieldValue(
                              //     "assign_type",
                              //     selectedOption.value
                              //   )
                              // }
                              disabled
                              // options={assignType}
                              // setFieldValue()
                              value={ticketSelectionType}
                            />
                            <label>User Type</label>
                            <ErrorMessage
                              name="assign_type"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="form-group form-label-group">
                            <Field
                              id="content"
                              type="text"
                              name="name"
                              value={captainInfo?.fullName}
                              disabled
                              className="shadow-none form-control modal-input br-10"
                              placeholder="Name"
                            />
                            <label htmlFor="content">Name</label>
                            <ErrorMessage
                              name="Name"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="form-group form-label-group">
                            <Field
                              id="content"
                              type="number"
                              name="phone"
                              value={captainInfo?.userId}
                              disabled
                              className="shadow-none form-control modal-input br-10"
                              placeholder="Phone"
                            />
                            <label htmlFor="content">User Id</label>
                            <ErrorMessage
                              name="Phone"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Col>

                        <Col md={12}>
                      <div className="mb-3 form-label-group ">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Select Country"
                          name="country"
                          styles={colourStyles}
                          onChange={(selectedOption) => {
                            countriesCities.forEach(item => item.id === selectedOption.value ? setSelectedCities(item.cities.map(data => ({ value: data.id, label: data.name }))) : "")
                            setFieldValue(
                              "country",
                              selectedOption.value
                            );
                          }
                          }
                          options={selectableCountry}
                        />
                        <label>Country</label>
                        <ErrorMessage
                          name="country"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="mb-3 form-label-group ">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Select City"
                          name="city"
                          styles={colourStyles}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              "city",
                              selectedOption.value
                            );
                          }
                          }
                          options={selectedCities}
                        />
                        <label>City</label>
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>



                        <Col md={12}>
                          <div className="form-group form-label-group">
                            <Field
                              id="content"
                              type="text"
                              as="textarea"
                              name="comment"
                              className="shadow-none form-control modal-input br-10"
                              placeholder="Name"
                            />
                            <label htmlFor="content">Comment</label>
                            <ErrorMessage
                              name="comment"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                        <Button
      disabled={submitting}
      variant="primary"
      type="submit"
      className="btn-save btn-block position-relative"
    >
      {submitting && (
        <span className="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
      )}
      Create Ticket
    </Button>
                        </Col>
                      </Row>
                    </Modal.Body>
                  </Form>
                );
              }}
            </Formik>
          </Modal>
    </>
  );
};

export default React.memo(CaptainInfo);
