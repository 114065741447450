import React, { useLayoutEffect, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes/Routes";
import { ProgressSpinner } from 'primereact/progressspinner';

import "react-toastify/dist/ReactToastify.css";
import "../src/assest/css/flags.css"
import "./App.sass";
import APIService from "./services/APIService";
import { getCurrentLocation } from "./utils";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch()
  const [load, setLoad] = useState(false),
    nonAuthenticatedPages = [
      "login",
      "forgot-password",
      "reset-password",
      "new-password",
      "checkout",
      "trip-invoice",
      "trip-arabic-invoice",
      "trip-both-invoices"
    ],
    isFirstLogin = localStorage.getItem("isFirstLogin"),
    isFirstLogin_allow = (isFirstLogin || isFirstLogin == 'true') ? true : false

  const getLocation = async () => {
    let result = await getCurrentLocation()
    dispatch({ type: "LOCATION", payload: result });
  }
  useLayoutEffect(() => {
    getLocation()
    const runApi = async () => {
      await APIService.get("admin/check-token")
      setLoad(true)
    }
    if (isFirstLogin_allow) setLoad(true)
    else if (nonAuthenticatedPages.indexOf(window.location.pathname.split("/")[1]) === -1) {
      runApi()
    }
    else {
      setLoad(true)
    }
  }, [])
  // useEffect(() => {
  //   // Check if geolocation is available
  //   if ('geolocation' in navigator) {
  //     // Check if user has denied location permission
  //     navigator.permissions.query({ name: 'geolocation' }).then((result) => {
  //       if (result.state === 'denied') {
  //         // Show a simple JavaScript alert
  //         alert('This website would like to access your location. Please grant location access to continue.');
  //       }
  //     });
  //   }
  // }, []);
  return (
    <>
      {load ? <BrowserRouter>
        <Routes />
      </BrowserRouter>
        : <div className="card flex justify-content-center " style={{ height: '100vh' }}>
          <ProgressSpinner />
        </div>
      }
    </>
  );
}

export default App;
