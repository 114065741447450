import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import moment from "moment";
import { CSVLink } from "react-csv";
import APIService from "../../services/APIService";

let PromotionsDatatable = (props) => {
  


  let  [downlaodCsv, setDownloadCsv] = useState([]),
    CSVdownloadRef = useRef(null),
    [showFilter, setShowFilter] = useState(false),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    currentRoutePermissions = permissions.filter(a => a.includes('/admin/user/')),
    dt = useRef(null),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    dummyData = [{
      promotionalId:6644123,
      category:"Discount",
      issueDate:"21/9/2023",
      issuedBy:"Ebrahim",
    },{
      promotionalId:6644124,
      category:"Discount",
      issueDate:"22/9/2023",
      issuedBy:"Abdullah",
    },{
      promotionalId:6644125,
      category:"Discount",
      issueDate:"23/9/2023",
      issuedBy:"Ebrahim",
    },{
      promotionalId:6644126,
      category:"Discount",
      issueDate:"24/9/2023",
      issuedBy:"Hassan",
    },{
      promotionalId:6644127,
      category:"Discount",
      issueDate:"25/9/2023",
      issuedBy:"Ali",
    },{
      promotionalId: 6644128,
      category:"Discount",
      issueDate:"26/9/2023",
      issuedBy:"Abdullah",
    }]
    ,
    headers = [
      { label: "Full Name", key: "fullName" },
      { label: "Role", key: "roleName" },
      { label: "Email", key: "email" },
      { label: "Last Access", key: "date" },
      { label: "Status", key: "status" },
    ],
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="toggle-filter" >
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
                showFilter ? (
                  <>
                    <img src="/images/filter-icon.svg" alt="" />
                    <span className="span-filter mx-12">Filters</span>
                    <i className="fa fa-caret-up "></i>
                  </>
                ) : (
                  <>
                    <img src="/images/filter-icon.svg" alt="" />
                    <span className="span-filter mx-12">Filters</span>
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                        fill="#828282"
                      />
                    </svg>
                  </>
                )}
            </button>
          </span>
          <span className="p-input-icon-left d-flex align-items-center ml-2" style={{height:48}}>
            <i className="pi pi-search " style={{marginTop:'-10px'}} />
            <InputText
              type="search"
              placeholder="Search"
              // onInput={(e) => setGlobalFilter(e.target.value)}
            />
          </span>
          <CSVLink
            data={downlaodCsv}
            headers={headers}
            filename="Sub Admin.csv"
            target="_blank"
            ref={CSVdownloadRef}
          />
          <button
            className="btn btn-primary mb-1 ml-12"
            style={{width:"150px"}}
            // onClick={() => downloadCsvFile()}
          >
            Export Report
          </button>
          {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'add') && <button
            className="btn btn-primary mb-1 ml-12"
            style={{width:"70px"}}
            onClick={props.handleShow}
          >
            {windowWidth ? <i className="fa fa-plus" /> : "+ Add"}
          </button>}
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      dt.current.reset();
      setShowFilter(!showFilter);
    },
    actionTemplate = (rowData) => {
      return (
        <>
        <Button label="Edit" className="mr-2"/>
        <Button label="Delete" className="p-button-danger"/>
        </>
      );
    },
    filterDate = (value, filter) => {
      return value &&
        filter &&
        moment(value).format("DD/MM/YYYY") ===
        moment(filter).format("DD/MM/YYYY")
        ? true
        : false;
    };

  // get subAdmins data into csv file
  const downloadCsvFile = () => {
    setDownloadCsv(props.admins);
    setTimeout(() => {
      CSVdownloadRef.current.link.click();
    }, 500);
  };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={props.promotionList}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          paginator
          rows={25}
          loading={props.isLoading}
          loadingIcon="fa fa-spinner"
          emptyMessage="No Promotions found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          scrollable
          scrollHeight="470px"
        >
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "140px",color:"#13B542" }}
            sortField="fullName"
            filterField="fullName"
            header="Promotion ID"
            field="id"
            body={(value)=>{return (
              <span>
                {value.id ? value.id.split('-')[0] : '-'}
              </span>
            )}}
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by ID"
            // body={(rowData) => {
            //   return (
            //     <span
            //       style={{
            //         color: "rgb(19, 181, 66)",
            //         textDecoration: " underline",
            //         cursor: "pointer",
            //         textTransfor: " capitalize",
            //       }}
            //     >
            //       {" "}
            //       {rowData.fullName}
            //     </span>
            //   );
            // }}
          />

          <Column
            headerClassName="text-center"
            className="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="createdAt"
            body={(value) => {          
              return (
                <span>
                  {value.createdAt ? ( value.createdAt.split('T')[0]) : ('-')}
                </span>
              );
            }}            
            header="Created At"
            sortable
            filter={showFilter}
          />
          <Column
            headerClassName="text-center"
            className="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="updatedAt"
            body={(value) => {          
              return (
                <span>
                  {value.updatedAt ? ( value.updatedAt.split('T')[0]) : ('-')}
                </span>
              );
            }}            
            header="Updated At"
            sortable
            filter={showFilter}
          />
          <Column
            headerClassName="text-center"
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            field="promotionCategory"
            body={(value) => {
              const categoryDescriptions = {
                1: 'Topup Cashback',
                2: 'Trip Cashback',
                3: 'Registration Cashback',
                4: 'Double Earn',
                5: 'Auto Promo Code',
                6: 'Referral',
              };            
              return (
                <span>
                  {value.promotionCategory ? (
                    categoryDescriptions[value.promotionCategory] || value.promotionCategory
                  ) : ('-')}
                </span>
              );
            }}            
            header="Category"
            sortable
            filter={showFilter}
          />
          <Column
            headerClassName="text-center"
            className="text-center"
            style={{ fontSize: "12px", width: "140px" }}
            field="message"
            body={(value) => {          
              return (
                <span>
                  {value.message ? (value.message) : ('-')}
                </span>
              );
            }}            
            header="Message"
            sortable
            filter={showFilter}
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            field="applicableFor"
            body={(value) => {
              const categoryDescriptions = {
                1: 'Rider',
                2: 'Driver',
                3: 'Both'
              };            
              return (
                <span>
                  {value.applicableFor ? (
                    categoryDescriptions[value.applicableFor] || value.applicableFor
                  ) : ('-')}
                </span>
              );
            }}       
            header="Applicable For"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by Applicable For"
          />
          <Column
            headerClassName="text-center"
            className="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="country"
            body={(value) => {          
              return (
                <span>
                  {value.country ? (value.country) : ('-')}
                </span>
              );
            }}            
            header="Country"
            sortable
            filter={showFilter}
          />
          <Column
            headerClassName="text-center"
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            field="amountType"
            body={(value) => {
              const categoryDescriptions = {
                1: 'Fixed',
                2: 'Ratio'
              };            
              return (
                <span>
                  {value.amountType ? (
                    categoryDescriptions[value.amountType] || value.amountType
                  ) : ('-')}
                </span>
              );
            }}  
            header="Amount Type"
            sortable
            filter={showFilter}
          />
          <Column
            headerClassName="text-center"
            className="text-center"
            style={{ fontSize: "12px", width: "140px" }}
            field="userUsage"
            body={(value) => {          
              return (
                <span>
                  {value.userUsage ? (value.userUsage) : ('-')}
                </span>
              );
            }}            
            header="User Usage"
            sortable
            filter={showFilter}
          />
          <Column
            headerClassName="text-center"
            className="text-center"
            style={{ fontSize: "12px", width: "130px" }}
            field="amount"
            body={(value) => {          
              return (
                <span>
                  {value.amount ? (value.amount) : ('-')}
                </span>
              );
            }}            
            header="Amount"
            sortable
            filter={showFilter}
          />
          <Column
            headerClassName="text-center"
            className="text-center"
            style={{ fontSize: "12px", width: "140px" }}
            field="totalBudget"
            body={(value) => {          
              return (
                <span>
                  {value.totalBudget ? (value.totalBudget) : ('-')}
                </span>
              );
            }}            
            header="Total Budget"
            sortable
            filter={showFilter}
          />
          <Column
            headerClassName="text-center"
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            field="totalUtilised"
            body={(value) => {          
              return (
                <span>
                  {value.totalUtilised ? (value.totalUtilised) : ('-')}
                </span>
              );
            }}            
            header="Total Utilised"
            sortable
            filter={showFilter}
          />
          <Column
            headerClassName="text-center"
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            field="minimumAmountInCart"
            body={(value) => {          
              return (
                <span>
                  {value.minimumAmountInCart ? (value.minimumAmountInCart) : ('-')}
                </span>
              );
            }}            
            header="Min Amount in Cart"
            sortable
            filter={showFilter}
          />
          <Column
            headerClassName="text-center"
            className="text-center"
            style={{ fontSize: "12px", width: "160px" }}
            field="requiredTrips"
            body={(value) => {          
              return (
                <span>
                  {value.requiredTrips ? (value.requiredTrips) : ('-')}
                </span>
              );
            }}            
            header="Required Trips"
            sortable
            filter={showFilter}
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "210px" }}
            field="maximumDiscountAmount"
            body={(value) => {          
              return (
                <span>
                  {value.maximumDiscountAmount ? (value.maximumDiscountAmount) : ('-')}
                </span>
              );
            }}            
            header="Max Discount Amount"
            sortable
            filter={showFilter}
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "170px" }}
            field="maximumTotalUsage"
            body={(value) => {          
              return (
                <span>
                  {value.maximumTotalUsage ? (value.maximumTotalUsage) : ('-')}
                </span>
              );
            }}            
            header="Max Total Usage"
            sortable
            filter={showFilter}
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            field="maximumUsagePerUser"
            body={(value) => {          
              return (
                <span>
                  {value.maximumUsagePerUser ? (value.maximumUsagePerUser) : ('-')}
                </span>
              );
            }}            
            header="Max Usage Per User"
            sortable
            filter={showFilter}
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "140px" }}
            field="startAt"
            body={(value)=>{return (
              <span>
                {value.startAt ? value.startAt.split('T')[0] : '-'}
              </span>
            )}}
            header="Start Date"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by Issue Date"
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "140px" }}
            field="endAt"
            body={(value)=>{return (
              <span>
                {value.endAt ? value.endAt.split('T')[0] : '-'}
              </span>
            )}}
            header="End Date"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by Issue Date"
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "140px" }}
            field="deletedAt"
            body={(value)=>{return (
              <span>
                {value.deletedAt ? value.deletedAt.split('T')[0] : '-'}
              </span>
            )}}
            header="Delete Date"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by Issue Date"
          />
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Action"
            body={actionTemplate}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(PromotionsDatatable);
