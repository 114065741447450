import React from "react";
import RiderListingTable from "../components/Riders/RiderListingTable";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import APIService from "../services/APIService";
import UserListing from "./userListing";
let UserManagement = (props) => {
//   let viewRiderData = (rowData) => {
//     props.history.push("/rider-details", {
//       riderId: rowData,
//     });
//   };

//   let initiateKycForAll = () => {
//     APIService.get("/admin/kyc-initiate-all")
//       .then((response) => {
//         if (response.status === 200) {
//           toast.success("Send request for initiate kyc");
//         } else {
//           toast.error("Failed request for initiate kyc");
//         }
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   };
  return (
    <>
      <div className="rider-listing ">
        
        <UserListing/>
        {/* <RiderListingTable viewRiderData={viewRiderData} /> */}
      </div>
    </>
  );
};

export default React.memo(connect()(UserManagement));
