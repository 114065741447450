// import React from 'react';
// import './SegmentedBarChart.css';
// const SegmentedBarChart = () => {
//     const categories = ["Registration","Passenger No Show","Vehicle Doc Renewal","Trip","Payout","Other"]
// const segmentData = [15, 20, 50];
// const colors = ['#13B542', '#FFB065', '#EF4242'];

// const segments = segmentData.map((value, index) => (
//     <div
//     key={index}
//     className="segment"
//     style={{ width: `${value}%`, backgroundColor: colors[index], borderTopRightRadius : segmentData.length-1=== index ? "10px" : "", borderBottomRightRadius : segmentData.length-1=== index ? "10px" : "" }}
//     ></div>
// ));

// return (
//     <div>
//     {categories.map((item)=>{
//         return(<>
//         <div className='d-flex'>
//         <div className='col-xl-2 col-lg-2 col-md-2 col-2 text-end text-align-center'>{item}</div>
//         <div className="col-xl-10 col-lg-10 col-md-10 col-10 segmented-bar mt-2" >{segments}</div>
//         </div>
//         </>
//         )
//     })}
//     </div>
// );
// };

// export default SegmentedBarChart;

import React from 'react';
import './SegmentedBarChart.css';

const data1 = [
  {
    "name": "Lahore",
    "PENDING": 9,
    "ASSIGNED": 0,
    "REJECTED": 0,
    "RESOLVED": 1,
    "INVALID": 0,
    "REVERT": 0
  },
  {
    "name": "Islamabad",
    "PENDING": 3,
    "ASSIGNED": 5,
    "REJECTED": 0,
    "RESOLVED": 2,
    "INVALID": 0,
    "REVERT": 0
  },
  {
    "name": "Faisalabad",
    "PENDING": 8,
    "ASSIGNED": 2,
    "REJECTED": 0,
    "RESOLVED": 0,
    "INVALID": 0,
    "REVERT": 0
  },
  {
    "name": "Karachi",
    "PENDING": 2,
    "ASSIGNED": 6,
    "REJECTED": 0,
    "RESOLVED": 2,
    "INVALID": 0,
    "REVERT": 0
  },
  {
    "name": "Peshawar",
    "PENDING": 3,
    "ASSIGNED": 4,
    "REJECTED": 0,
    "RESOLVED": 3,
    "INVALID": 0,
    "REVERT": 0
  }
]
const SegmentedBarChart = ({ data }) => {
  const colors = ['#13B542', '#FFB065', '#EF4242'];
  
  return (
    <>
      <div className="segmented-bar-container align-items-start d-flex mr-5 justify-content-evenly" style={{ width: "100%", minHeight: "470px" }}>
        {data.map((item, index) => (
          <div key={index} className="d-flex justify-between flex-column" style={{height:425}}>
            <div className="col-xl-10 col-lg-10 col-md-10 col-10 mt-2 mb-4 d-flex flex-column justify-content-end " style={{height:100}}>
  {["RESOLVED", "PENDING", "ASSIGNED"].map((status, index) => (
    item[status] > 0 && (
      <div
      className='charts-bar '
        key={index}
        // className={`segment${index === 2 ? ' third-child' : ''}`}
        style={{
          height: `calc(200px * ${item[status] / 10})`,
          backgroundColor: colors[index],
          width: "40px",
        }}
      ></div>
    )
  ))}
</div>

            <div className='mt-2 d-flex align-items-center justify-content-center' style={{writingMode:'vertical-lr',color:'#828282',transform:'rotate(180deg)'}}>{item.name}</div>
          </div>
        ))}
      </div>
      <div className='d-flex justify-content-center mt-2'>
        <div className="percentage me-3">
          <div className="green"></div>
          <div className="percentext ">Closed</div>
          <div className="percenPoint">

          </div>
        </div>
        <div className="percentage me-3">
          <div
            className="green bg-orange"
            style={{ background: "#FFB065" }}
          ></div>
          <div className="percentext ">Pending</div>
          <div className="percenPoint">

          </div>
        </div>
        <div className="percentage">
          <div className="green bg-red"></div>
          <div className="percentext ">Open</div>
          <div className="percenPoint">

          </div>
        </div>
      </div>
    </>
  );
};

export default SegmentedBarChart;
