import React, { useState, useEffect } from 'react'
import { secret_key, _baseURL } from '../../../services/APIService';
import { TabView, TabPanel } from 'primereact/tabview';
import Calendar from '../../Dashboard_2/Calendar';
// import DoughnutCMS from './Doughnut';
import BarChart from './BarChart';


let citiesChartURL = `${_baseURL}/api/v1/ticket/cities-issues`;

const CitiesChart = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [driverData, setDriverData] = useState([]);
  const [riderData, setRiderData] = useState([]);

  const CitiesChartAPI = async (updatedApi = null) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
          // "Timezone":timeUtc
        },
        body: JSON.stringify({
          secret_key: secret_key,
          countryId: 2
        }),
      };
      let result = await fetch(updatedApi ?? citiesChartURL, requestOptions);
      let response = await result.json();
      if (response?.success) {
        setDriverData(response.data?.driverCityIssues ?? [])
        setRiderData(response.data?.riderCityIssues ?? [])
      }
    } catch (e) {
      throw new Error(e);
    }
  }
  const getTimeRange = (range) => {
    let updatedApi = `${_baseURL}/api/v1/ticket/cities-issues?fromDate=${range.sDate}&toDate=${range.eDate}`;
    CitiesChartAPI(updatedApi);
  }
  useEffect(() => {
    CitiesChartAPI();
  }, [])
  return (
    <div className="section-rider cards-section">
      <div className="d-flex justify-content-between text-center align-items-center position-relative">
        <h2 className="box-title">Cities</h2>
        <Calendar handleTimeRange={getTimeRange} />
      </div>
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="Captain">
          {/* <DoughnutCMS/> */}
          <BarChart data={driverData} />
        </TabPanel>
        <TabPanel header="Rider">
          <BarChart data={riderData} />
          {/* <DoughnutCMS/> */}
        </TabPanel>
      </TabView>
    </div>
  )
}

export default CitiesChart