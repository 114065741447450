import React, { useEffect } from "react";
import { Doughnut } from "react-chartjs-2";

function PromoCard({
  backgroundColor,
  title,
  amount,
  totalCodes,
  data,
  centerText,
  barText,
}) {
  let windowWidth = window.innerWidth;


 
  console.log('data',data)
  let chartOptions = {
    responsive: true,
    // maintainAspectRatio: false,
    type: "doughnut",
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: windowWidth > 992 ? 95 : 105,
    pointStyle: "circle",

    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
        position: windowWidth > 992 ? "bottom" : "bottom",
        labels: {
          boxWidth: 100,
          usePointStyle: true,
          fontSize: 60,
        },
      },
    },
  };

  return (
    
    <>
      <div className="col-lg-4 col-md-6 col-12 mb-3 d-flex flex-column justify-content-between" >
        <div className="chart align-items-center text-center ">
        <div className="position-absolute" style={{zIndex:99,top:'30%',right:'45%',fontSize:12,color:'#828282'}}>
          {`${centerText} : ${data?.count || 0}`}
        </div>
                <Doughnut
          type="doughnut"
          data={data}
          options={chartOptions}
          className="relative"
        />
        </div>

        <div className="info-section mb-2">
          <div className="row">
            <div className="container-fluid">
              <div className="main-percent line-main">
                <div className="percentage cc-percent flex-wrap">
                  <div className="d-flex align-items-center text-nowrap">
                    <div
                      className="green mt-0"
                      style={{ background: `${backgroundColor}` }}
                    ></div>
                    <div className="percentext flex-grow-1">{title}</div>
                  </div>
                  <div>
                    <div className="percenPoint">
                      SAR. {amount ? amount : 0}
                    </div>
                  </div>
                </div>

                <div className="percentage cc-percent flex-wrap">
                  <div className="d-flex align-items-center text-nowrap">
                    <div
                      className="red mt-0"
                      style={{ background: "#F1F1F1" }}
                    ></div>
                    <div className="percentext flex-grow-1 unuse">
                      Total unutilized promo
                    </div>
                  </div>
                  <div>
                    <div className="percenPoint text-nowrap">
                      {totalCodes ? totalCodes : 0} {totalCodes > 1 ? 'Codes' : 'Code'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p>
          <u className="view-btn text-success text-right ">{barText}</u>
        </p>
      </div>
    </>
  );
}

export default PromoCard;
