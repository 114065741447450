import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Card, Col, Row, Modal, Button } from "react-bootstrap";
import driveractive from "../../assest/images/driveractive.png";
import PlaceHoderImage from "../../assest/images/placeHoderImage.png";
import Rating from "react-rating";
import "../../sass/DriverDeatils.sass";
import Select, { StylesConfig } from "react-select";
import { TabView, TabPanel } from "primereact/tabview";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import topup from "../../assest/images/topup.svg";
import countryLogo from "../../assest/images/countrylogo.svg";
import VisaLogo from "../../assest/images/visa-logo.png";
import CardLogo from "../../assest/images/card-logo.png";
import Chip from "../../assest/images/chip.svg";
import moment from "moment";
import riderAvtSec from "../../assest/images/placeHoderImage.png";
import carInfoIcon from "../../assest/images/carInfo.png";
import APIService from "../../services/APIService";
import { toast } from "react-toastify";
import noCardLogo from "../../assest/images/emptyCard.png";
import axios from "axios";
import cardIcon from "../Dashboard_2/images/cardIcon.svg"
import { Line } from "react-chartjs-2";
import { Justify } from "react-bootstrap-icons";
import logo from "../../assest/images/ride.svg";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "../Riders/NumberInput.css";
import { _baseURL } from "../../services/APIService";
import { secret_key } from "../../services/APIService";
import PickupSubscriptionTransactionsTab from "./PickupSubscriptionTransactionsTab";
import PickupEmptyTripsChart from "./PickupEmptyTripsChart";
import PickupsTopupHistoryChart from "./PickupTopupHistoryChart";
import PickupTrackDrivers from "./PickupTrackDrivers";
import PickupTotalTrips from "./PickupTotalTrips";
import CustomerCare from "./CustomerCare";
import PickupTopupHistoryChart from "./PickupTopupHistory";



let PickupDetailsTab = () => {
  const [isMore, setIsMore] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
   const [showProfile, setEditProfile] = useState(false);
  
  const [isStatus, setIsstatus] = useState(1);
  

  const profileDetail = () => {
    setIsMore(!isMore);
  };
  let ratingCount = (value) => {
    let statusText;
    switch (value) {
      case 1:
        statusText = "😣 Poor";
        break;
      case 2:
        statusText = "😔 Bad";
        break;
      case 3:
        statusText = "🙂 Normal";
        break;
      case 4:
        statusText = "😀 Good";
        break;
      case 5:
        statusText = "😊 Excellent";
        break;
      default:
        statusText = "-";
        break;
    }
    return statusText;
  };

  

  const data = {
    datasets: [
      {
        data: [12, 19],
        backgroundColor: ["#000000", "#00FF00"],
      },
    ],
  };

  

  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 380).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = "Lease To Own",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillStyle = "#828282";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  const plugins1 = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 380).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = "Trips To Cover",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillStyle = "#828282";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  // data1.datasets[0].label=false;

  return (
    <div className="mt-3 driver-details">
      <>
        <Col xl={12} className="px-custom">
          <Row className="mt-2 driver-details px-1 row-flex">
            <Col xl={8}>
              <Row className="mt-2 driver-details px-1 row-flex">
                <Col xl={12} className="px-0">
                  <div className="d-box h-100 ">
                    <Row className=" mx-0">
                      <Col lg={9} className="px-0">
                        <div className="d-flex">
                          <div className="img-relative">
                            <img
                              src={riderAvtSec}
                              alt="ride"
                              style={{
                                marginRight: "15px",
                                width: "84px",
                                height: "84px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                            <div
                              className="user-absolute-img"
                              onClick={() => setEditProfile(true)}
                            >
                              <svg
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="15"
                                  cy="15"
                                  r="13.5"
                                  fill="#13B542"
                                  stroke="white"
                                  stroke-width="3"
                                />
                                <path
                                  d="M20.7137 12.2795C20.7142 12.2042 20.6998 12.1297 20.6713 12.0601C20.6429 11.9905 20.601 11.9271 20.548 11.8737L18.1252 9.45088C18.0718 9.39792 18.0084 9.35602 17.9388 9.32758C17.8692 9.29915 17.7947 9.28473 17.7195 9.28517C17.6442 9.28473 17.5697 9.29915 17.5001 9.32758C17.4305 9.35602 17.3671 9.39792 17.3137 9.45088L15.6966 11.068L9.45088 17.3137C9.39792 17.3671 9.35602 17.4305 9.32758 17.5001C9.29915 17.5697 9.28473 17.6442 9.28517 17.7195V20.1423C9.28517 20.2939 9.34537 20.4392 9.45253 20.5464C9.5597 20.6535 9.70504 20.7137 9.85659 20.7137H12.2795C12.3594 20.7181 12.4394 20.7056 12.5142 20.677C12.589 20.6485 12.657 20.6045 12.7137 20.548L18.9252 14.3023L20.548 12.7137C20.6002 12.6584 20.6427 12.5946 20.6737 12.5252C20.6792 12.4796 20.6792 12.4336 20.6737 12.388C20.6764 12.3614 20.6764 12.3346 20.6737 12.308L20.7137 12.2795ZM12.0452 19.5709H10.428V17.9537L16.1023 12.2795L17.7195 13.8966L12.0452 19.5709ZM18.5252 13.0909L16.908 11.4737L17.7195 10.668L19.3309 12.2795L18.5252 13.0909Z"
                                  fill="#F4F4F4"
                                />
                              </svg>
                            </div>
                          </div>
                          <div>
                            <div className="detail-list-1">
                              <div className="detail-list-1-name">
                                Tom Holand
                              </div>
                              <div className="detail-list-1-id">#1245272916</div>

                              <div>

                                <span className="status-text">Online</span>

                              </div>
                            </div>
                            <div className="detail-list-2">
                              <div className="detail-list-2-name">
                                {ratingCount(
                                  Math.round(4)
                                )}
                              </div>
                              <div className="detail-list-1-id ml-3">
                                <div>
                                  <img src={topup} alt="ride" />
                                  <span className="topup-txt">
                                    {120.99} SAR.
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </Col>

                      <Col lg={3} className="px-0 my-auto">
                        <div className="text-right">
                          <img src={countryLogo} alt="ride" />
                          <span className="country-txt">Saudi Arabia</span>
                        </div>
                      </Col>
                    </Row>
                    <Row className="inner-box mx-0 row mt-24">
                      <Col md={6} className="border-right">
                        <Row className="mt-4">
                          <Col md={5} className="px-0">
                            <label className="profilelabel">
                              Loyalty points
                            </label>
                          </Col>
                          <Col md={7}>
                            <label className="profiletxt">1027 Pts</label>
                          </Col>
                        </Row>

                        <Row className="mt-4">
                          <Col md={5} className="px-0">
                            <label className="profilelabel">Is rider ?</label>
                          </Col>
                          <Col md={7}>
                            <label className="profiletxt">
                              Yes
                            </label>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col md={5} className="px-0">
                            <label className="profilelabel">
                              Is pickup captain?
                            </label>
                          </Col>
                          <Col md={7}>
                            <label className="profiletxt">No</label>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col md={5} className="px-0">
                            <label className="profilelabel">
                              Block / Unblock
                            </label>
                          </Col>
                          <Col md={7}>
                            <label className="profiletxt">
                              {" "}
                              <div className="custom-control custom-switch">
                                <input
                                  className="custom-control-input"
                                  // defaultChecked={!driver.approved}
                                  type="checkbox"
                                  id="customSwitch"
                                // onChange={handleChangeBlockStatus}
                                />
                                <label
                                  className="custom-control-label"
                                  style={{ cursor: "pointer" }}
                                  htmlFor="customSwitch"
                                ></label>
                              </div>
                            </label>
                          </Col>
                        </Row>

                      </Col>
                      <Col md={6} className="pl-5 pr-0">
                        <Row className="mt-4">
                          <Col md={5} className="px-0">
                            <label className="profilelabel">Mobile No.</label>
                          </Col>
                          <Col md={7}>
                            <label className="profiletxt">
                              +966 628292691
                            </label>
                          </Col>
                        </Row>

                        <Row className="mt-4">
                          <Col md={5} className="px-0">
                            <label className="profilelabel">
                              Email
                            </label>
                          </Col>
                          <Col md={7}>
                            <label
                              className="profiletxt"

                            >
                              Tomholand@ride.sa
                            </label>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col md={5} className="px-0">
                            <label className="profilelabel">Tickets created</label>
                          </Col>
                          <Col md={7}>
                            <label className="profiletxt text-uppercase" style={{ color: "#13B542" }}>
                              00
                            </label>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col md={5} className="px-0">
                            <label className="profilelabel">Source</label>
                          </Col>
                          <Col md={7}>
                            <label
                              className="profiletxt"
                              style={{
                                textTransform: "lowercase !important",
                              }}
                            >
                              RIDE
                            </label>
                          </Col>
                        </Row>

                      </Col>
                    </Row>
                    {isMore && (
                      <>
                        <Row className="inner-box mx-0 row mt-2">
                          <Col md={6} className="border-right pl-0">
                            <div className="data-listing">
                              <div className="profilelabel">Full name </div>
                              <div className="profiletxt">
                                Tom 
                              </div>
                            </div>
                            <div className="data-listing ">
                              <div className="profilelabel">Gender</div>
                              <div className="profiletxt">
                                Male
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profilelabel">DOB hijri </div>
                              <div className="profiletxt">
                                05 Feb 2023
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profilelabel">ID Expiry</div>
                              <div className="profiletxt">
                                05 Feb 2023
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profilelabel">
                                License type code
                              </div>
                              <div className="profiletxt">
                                000
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profilelabel">
                                License expiry date hijri
                              </div>
                              <div className="profiletxt">
                                4-04-1445
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profilelabel">
                                License expiry date gregorian
                              </div>
                              <div className="profiletxt">--</div>
                            </div>
                            <div className="data-listing">
                              <div className="profilelabel">Log ID</div>
                              <div className="profiletxt">
                                1607308268
                              </div>
                            </div>
                          </Col>
                          <Col md={6} className="pl-5 pr-0 arabic-fonts">
                            <div className="data-listing">
                              <div className="profiletxt">
                                7673
                              </div>
                              <div className="profilelabel">الاسم الكامل</div>
                            </div>
                            <div className="data-listing">
                              <div className="profiletxt">
                                Male
                              </div>
                              <div className="profilelabel">الجنس</div>
                            </div>
                            <div className="data-listing">
                              <div className="profiletxt">
                                26-02-1416
                              </div>
                              <div className="profilelabel">
                                تاريخ الميلاد
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profiletxt">
                                26-02-1416
                              </div>
                              <div className="profilelabel">
                                انتهاء الهوية
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profiletxt">
                                00
                              </div>
                              <div className="profilelabel">
                                License type code
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profiletxt">
                                607308268
                              </div>
                              <div className="profilelabel">
                                تاريخ انتهاء الرخصة
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profiletxt">
                                -- {/* /  {rideViewData.arabicFullName} */}
                              </div>
                              <div className="profilelabel">
                                تاريخ انتهاء الرخصة
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profiletxt">
                                607308268
                              </div>
                              <div className="profilelabel">Log ID</div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="inner-box mx-0 row mt-2">
                          <Col md={6} className="border-right pl-0">
                            <div className="data-listing">
                              <div className="profilelabel">
                                Owner full name{" "}
                              </div>
                              <div className="profiletxt">--</div>
                            </div>
                            <div className="data-listing">
                              <div className="profilelabel">Building no.</div>
                              <div className="profiletxt">
                                607308268
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profilelabel">Street name </div>
                              <div className="profiletxt">
                                420
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profilelabel">
                                Neighbourhood name
                              </div>
                              <div className="profiletxt">--</div>
                            </div>
                            <div className="data-listing">
                              <div className="profilelabel">Postal code</div>
                              <div className="profiletxt">
                                607308268
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profilelabel">
                                Additional code
                              </div>
                              <div className="profiletxt">
                                607308268
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profilelabel">Unit no.</div>
                              <div className="profiletxt">
                                607308268
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profilelabel">City</div>
                              <div className="profiletxt">
                                607308268
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profilelabel">
                                Location co-ordinates
                              </div>
                              <div className="profiletxt">
                                loctaion tracking ---
                              </div>
                            </div>
                          </Col>
                          <Col md={6} className="pl-5 pr-0 arabic-fonts">
                            <div className="data-listing">
                              <div className="profiletxt">--</div>
                              <div className="profilelabel">اسم المالك</div>
                            </div>
                            <div className="data-listing">
                              <div className="profiletxt">
                                607308268
                              </div>
                              <div className="profilelabel">رقم المبنى</div>
                            </div>
                            <div className="data-listing">
                              <div className="profiletxt">
                                -----
                              </div>
                              <div className="profilelabel">اسم الشارع</div>
                            </div>
                            <div className="data-listing">
                              <div className="profiletxt">--</div>
                              <div className="profilelabel">اسم الحي</div>
                            </div>
                            <div className="data-listing">
                              <div className="profiletxt">
                                -----
                              </div>
                              <div className="profilelabel">
                                الرمز البريدي
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profiletxt">
                                ---
                              </div>
                              <div className="profilelabel">
                                الرمز الإضافي
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profiletxt">
                                ---
                              </div>
                              <div className="profilelabel">رقم الوحدة</div>
                            </div>
                            <div className="data-listing">
                              <div className="profiletxt">
                                ---
                              </div>
                              <div className="profilelabel">المدينة</div>
                            </div>
                            <div className="data-listing">
                              <div
                                className="profilelabel"
                                style={{ color: "#000000" }}
                              >
                                --
                              </div>
                              <div
                                className="profiletxt"
                                style={{ color: "#828282" }}
                              >
                                احداثيات الموقع
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="inner-box mx-0 row mt-2">
                          <Col md={6} className="border-right  pl-0">
                            <div className="data-listing">
                              <div className="profilelabel">
                                Type of license plate
                              </div>
                              <div className="profiletxt">--</div>
                            </div>
                            <div className="data-listing">
                              <div className="profilelabel">Car type</div>
                              <div className="profiletxt">
                                Bugatti
                              </div>
                            </div>
                            <div className="data-listing">
                              <div className="profilelabel">Car color</div>
                              <div className="profiletxt">--</div>
                            </div>
                            <div className="data-listing">
                              <div className="profilelabel">Manufacturer</div>
                              <div className="profiletxt">--</div>
                            </div>
                          </Col>
                          <Col md={6} className="pl-5 pr-0 arabic-fonts">
                            <div className="data-listing">
                              <div className="profiletxt">--</div>
                              <div className="profilelabel">نوع اللوحة</div>
                            </div>
                            <div className="data-listing">
                              <div className="profiletxt">
                                Black
                              </div>
                              <div className="profilelabel">نوع المركبة</div>
                            </div>
                            <div className="data-listing">
                              <div className="profiletxt">
                                {" "}
                              </div>
                              <div className="profilelabel">لون المركبة</div>
                            </div>
                            <div className="data-listing">
                              <div className="profiletxt">
                                ----
                              </div>
                              <div className="profilelabel">
                                الشركة المصنعة
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div>
                        <button
                          className="btn btn-success"
                          // onClick={handleShowTicket}
                        >
                          Create Ticket
                        </button>
                      </div>
                      <div
                        className="show-more"
                        onClick={profileDetail}
                        style={{
                          fontFamily: "'Manrope', sans-serif !important",
                          cursor: "pointer",
                        }}
                      >
                        {isMore ? "Show Less" : "Show More"}
                      </div>
                    </div>
                  </div>
                </Col>

                {/* <Col xl={4} className="px-0 pl-0 pl-xl-2 mt-3 mt-xl-0">
              <TotalTrips
                activeDriverData={activeDriverData}
                captainData={driver}
              />
            </Col> */}
              </Row>
              <Row className="mt-2 driver-details px-1 row-flex">
                <Col xl={6} className="pl-0 pr-1">
                  <div className=" h-100 d-box">
                    {/* <TrackDrivers /> */}
                    <div className="d-flex justify-content-between align-items-baseline">
                      <h2 className="box-title">Saved Cards</h2>
                      <span className="link-custom">Add New Card</span>
                    </div>
                    <div className="col-12">
                      <div className="cash-card card-green">
                        <div className="row h-100">
                          <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="card-text">
                                <h4 className="card-name">Kotak AQUA</h4>
                                <span className="card-detail ">
                                  <img src={VisaLogo} alt="" />
                                  <span className="ml-1"> .... 6292</span>
                                </span>
                              </div>
                              <div className="card-logo">
                                <img className="img-fluid" src={CardLogo} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mt-auto">
                            <img className="img-fluid" src={Chip} alt="" />
                            <h4 className="card-user">Jim Brown</h4>
                          </div>
                        </div>
                      </div>
                      <div className="cash-card card-red">
                        <div className="row h-100">
                          <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="card-text">
                                <h4 className="card-name">Kotak AQUA</h4>
                                <span className="card-detail ">
                                  <img src={VisaLogo} alt="" />
                                  <span className="ml-1"> .... 6292</span>
                                </span>
                              </div>
                              <div className="card-logo">
                                <img className="img-fluid" src={CardLogo} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mt-auto">
                            <img className="img-fluid" src={Chip} alt="" />
                            <h4 className="card-user">Jim Brown</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "84px",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <img
                            src={noCardLogo}
                            alt="ride"
                            style={{
                              width: "137px",
                              height: "104px",
                              // borderRadius: "50%",
                            }}
                          />
                        </div>

                        <div
                          style={{
                            color: "#828282",
                            fontFamily: "'Manrope', sans-serif !important",
                            fontSize: "16px",
                            marginTop: "30px",
                          }}
                        >
                          No saved cards
                        </div>
                      </div> */}
                  </div>
                </Col>
                <Col xl={6} className="pl-1 pr-0 mt-2 mt-lg-0">
                  <div className="h-100 d-box">
                    <div className="d-flex mb-2">
                      <h2 className="box-title ">
                        {" "}
                        Car details{" "}
                        <span className="text-gray">(Ride-A-Ride)</span>{" "}
                      </h2>
                    </div>
                    <div className="kyc-info">
                      <div className=" d-flex align-items-center">
                        <div className="mr-4">
                          <img
                            className="img-fluid max-img"
                            src="image"
                            alt="ride"
                          />
                        </div>
                        <div>
                          <p className="stats mb-1 arabic-fonts">
                            Hyindai Azera (2023)
                            <span className="text-gray"></span>
                          </p>
                          <p className="grey-heading mb-0">Manual</p>
                        </div>
                      </div>
                      <div className="line-break"></div>
                      <div className=" d-flex align-items-center">
                        <div className="w-50">
                          <p className="grey-heading">Seq. no.</p>
                          <p className="stats"> 35245735817</p>
                        </div>
                        <div>
                          <p className="grey-heading">Plate no.</p>
                          <p className="stats arabic-fonts">
                            {" "}
                            3458 ZBA
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center mt-3">
                        <div className="w-50">
                          <p className="grey-heading">License (Exp.)</p>
                          <p className="stats text-red">
                            28 Feb 2023
                          </p>
                        </div>
                        <div>
                          <p className="grey-heading">Insurance (Exp.)</p>
                          <p className="stats"> 28 Feb 2030</p>
                        </div>
                      </div>
                      <div className="line-break"></div>
                      <div className=" d-flex align-items-center">
                        <div className="w-50">
                          <p className="grey-heading">Fuel type</p>
                          <p className="stats">Petrol</p>
                        </div>
                        <div>
                          <p className="grey-heading">Seating capacity</p>
                          <p className="stats">05</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center mt-3">
                        <div className="w-50">
                          <p className="grey-heading">No. of cylinder</p>
                          <p className="stats text-red">04</p>
                        </div>
                        <div>
                          <p className="grey-heading">Displacement (cc)</p>
                          <p className="stats">2498</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                {/* <Col xl={6} className="px-0 pl-0 pl-xl-2 mt-3 mt-xl-0">
                  <AppUsageChart activeRiderData={obj2} timeRange={timeRange} />
                </Col> */}
              </Row>

              {/* car deals section starting  */}
              <Row
                className="mt-2 driver-details bg-white px-0"
                style={{
                  height: "auto",
                  padding: "24px",
                  borderRadius: "6PX",
                  marginRight: "-11px",
                  marginLeft: "-11px",
                }}
              >
                <Col xl={12}>
                  <h2 className="box-title mb-4">Car Deal Details</h2>

                  <Row>
                    <Col xl={6}>
                      <Doughnut
                        type="doughnut"
                        data={data}
                        plugins={plugins}
                      />


                      <div class="mt-24 d-flex align-items-baseline justify-content-around flex-wrap">
                        <div style={{ display: "flex" }}>
                          <div
                            className="green-box"
                            style={{ backgroundColor: "#00FF00" }}
                          ></div>
                          <div className="ml-2">
                            <div className="trips-title-label">Total paid</div>
                            <div
                              className="trips-count text-dark font-weight-bolder"
                              style={{ color: "rgb(0, 0, 0)" }}
                            >
                              SAR. 799
                            </div>
                          </div>
                        </div>

                        <div style={{ display: "flex" }}>
                          <div
                            className="green-box"
                            style={{ backgroundColor: "#000000" }}
                          ></div>
                          <div className="ml-2">
                            <div className="trips-title-label">
                              Remaining amount
                            </div>
                            <div
                              className="trips-count text-dark font-weight-bolder"
                              style={{ color: "rgb(0, 0, 0)" }}
                            >
                              {" "}
                              SAR. 1200
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col xl={6}>
                      <Doughnut
                        type="doughnut"
                        data={data}
                        plugins={plugins1}
                      />


                      <div class="mt-24 d-flex align-items-baseline justify-content-around flex-wrap">
                        <div style={{ display: "flex" }}>
                          <div
                            className="green-box"
                            style={{ backgroundColor: "#00FF00" }}
                          ></div>
                          <div className="ml-2">
                            <div className="trips-title-label">
                              Total trips made
                            </div>
                            <div
                              className="trips-count text-dark font-weight-bolder"
                              style={{ color: "rgb(0, 0, 0)" }}
                            >
                              05
                            </div>
                          </div>
                        </div>

                        <div style={{ display: "flex" }}>
                          <div
                            className="green-box"
                            style={{ backgroundColor: "#000000" }}
                          ></div>
                          <div className="ml-2">
                            <div className="trips-title-label">Trips left</div>
                            <div
                              className="trips-count text-dark font-weight-bolder"
                              style={{ color: "rgb(0, 0, 0)" }}
                            >
                              112
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* captains summary section started from here */}

              <Row
                className="mt-2 driver-details px-1 row-flex"
                style={{ height: "auto" }}
              >
                <Col xl={12} className="px-0">

                  <PickupTopupHistoryChart />

                </Col>
              </Row>
              {/* captain summary section end '''''''''''' */}

              {/* <Row
                  className="mt-2 driver-details bg-white px-0"
                  style={{
                    height: "auto",
                    padding: "24px",
                    borderRadius: "6PX",
                    marginRight: "-11px",
                    marginLeft: "-11px",
                    overflow: "hidden",
                  }}
                >
                  <Col xl={12}>
                    <div style={{ height: "500px" }}>
                      <PickupsTopupHistoryChart
                        activeRiderData={obj}
                        timeRange={timeRange}
                        riderId={driver?.externalId}
                      />
                    </div>
                  </Col>
                </Row> */}
              {/* <Row className="mt-3 driver-details px-1 row-flex">
                <Col xl={12} className="px-0 pl-0  mt-3 mt-xl-0">
                  <div style={{ height: "400px" }}>
                    <TopupHistoryChart
                      activeRiderData={obj}
                      timeRange={timeRange}
                    />
                  </div>
                </Col>
              </Row> */}

              <Row className="mt-2 driver-details px-1 row-flex">
                <Col xl={12} className="px-0">
                  <Card className="py-3 h-100 card-121">
                    <PickupSubscriptionTransactionsTab
                    />
                  </Card>
                </Col>
              </Row>

              {/* total trips */}
              {/* <Col xl={4} className="px-0 pl-0 pl-xl-2 mt-3 mt-xl-0">
          <TotalTrips
            activeDriverData={activeDriverData}
            captainData={rideViewData}
          />
        </Col> */}
            </Col>
            <Col xl={4}>
              <Row className="mt-2 driver-details px-1 row-flex">
                <Col xl={12} className="px-0">
                  <div className="d-box">
                    <div className="d-flex mb-3">
                      <h2 className="box-title"> KYC Details </h2>
                      <span className="ml-3 status-text">
                        Completed
                      </span>
                    </div>

                    <div className="kyc-info">
                      KYC details completed successfully.
                    </div>


                    <div className="text-right mt-3">

                      <Link className="text-link" to="#">
                        Initiate KYC
                      </Link>

                    </div>

                  </div>
                </Col>
              </Row>
              <Row className="mt-2 driver-details px-1 row-flex">
                <Col xl={12} className="px-0">
                  <div className="d-box">
                    <div className="d-flex mb-2">
                      <h2 className="box-title "> WASL Status </h2>
                    </div>
                    <div className="kyc-info d-flex align-items-center">
                      <div style={{ width: "50%" }}>
                        <p className="grey-heading">Status</p>
                        {isStatus === 1 ? (
                          <p className="stats">
                            {" "}
                            <span className="circle-indicator circle-green"></span>{" "}
                            Active
                          </p>
                        ) : isStatus === 0 ? (
                          <p className="stats">
                            {" "}
                            <span className="circle-indicator circle-red"></span>{" "}
                            Inactive
                          </p>
                        ) : (
                          <p className="stats">
                            {" "}
                            <span className="circle-indicator circle-red"></span>{" "}
                            Rejected
                          </p>
                        )}
                      </div>

                      <div>
                        <p className="grey-heading">Expiry Date</p>
                        <p className="stats">
                          05 Feb 2023
                        </p>
                      </div>

                      <div></div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2 driver-details px-1 row-flex">
                <Col xl={12} className="px-0">
                  <div className="d-box">
                    <div className="d-flex mb-2">
                      <h2 className="box-title "> Contract Tajeer </h2>
                    </div>
                    <div className="kyc-info d-flex align-items-center">
                      <div style={{ width: "50%" }}>
                        <p className="grey-heading">Status</p>
                        <p className="stats">
                          {" "}
                          <span className="circle-indicator circle-pending"></span>{" "}
                          Pending
                        </p>
                      </div>
                      <div>
                        <p className="grey-heading">Signed?</p>
                        <p className="stats">No</p>
                      </div>
                      <div></div>
                    </div>
                    <div className="text-right mt-3">

                      <Link className="text-link" to="#">
                        Initiate TAMM OTP
                      </Link>

                    </div>

                  </div>
                </Col>
              </Row>

              <Row className="mt-2 driver-details px-1 row-flex">
                <Col xl={12} className="px-0">
                  <div className="d-box">
                    <div className="d-flex mb-3">
                      <h2 className="box-title"> Dakhli Details </h2>
                    </div>
                    <TabView
                      className="mt-2"
                      activeIndex={activeIndex}
                      onTabChange={(e) => setActiveIndex(e.index)}
                    >
                      <TabPanel header="GOSI">
                        <div className="tab-content mt-3 ">
                          <div
                            className="kyc-info tab-pane fade active show"
                            id="GOSI"
                            style={{ padding: "24px" }}
                          >
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ marginBottom: "24px" }}
                            >
                              <div>Basic wage</div>
                              <div className="text-dark font-weight-bold">
                                3,000 SAR.
                              </div>
                            </div>

                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ marginBottom: "24px" }}
                            >
                              <div>Housing Allowance</div>
                              <div className="text-dark font-weight-bold">
                                2,000 SAR.
                              </div>
                            </div>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ marginBottom: "24px" }}
                            >
                              <div>Other Allowance</div>
                              <div className="text-dark font-weight-bold">
                                500 SAR.
                              </div>
                            </div>
                            <div className="tab-content mt-3 ">
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ marginBottom: "24px" }}
                              >
                                <div>Employer Name</div>
                                <div className="text-dark font-weight-bold arabic-fonts">
                                  Tata moters
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ marginBottom: "24px" }}
                              >
                                <div>Working Months</div>
                                <div className="text-dark font-weight-bold">
                                  12 months
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                <div>Employment Status</div>
                                <div className="text-dark font-weight-bold">
                                  Employed
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel header="GOVT">
                        <div className="tab-content mt-3 ">
                          <div
                            className="kyc-info tab-pane fade active show"
                            id="GOVT"
                            style={{ padding: "24px" }}
                          >
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ marginBottom: "24px" }}
                            >
                              <div>Employee Name</div>
                              <div className="text-dark font-weight-bold">
                                DQ
                              </div>
                            </div>

                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ marginBottom: "24px" }}
                            >
                              <div>Agency Code</div>
                              <div className="text-dark font-weight-bold">
                                041001000000
                              </div>
                            </div>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ marginBottom: "24px" }}
                            >
                              <div>Agency Name</div>
                              <div className="text-dark font-weight-bold">
                                Moonbird
                              </div>
                            </div>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ marginBottom: "24px" }}
                            >
                              <div>Account no.</div>
                              <div
                                className="text-dark font-weight-bold"
                                style={{
                                  wordBreak: "break-all",
                                  marginLeft: "50px",
                                }}
                              >
                                SA935000000005236729523
                              </div>
                            </div>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ marginBottom: "24px" }}
                            >
                              <div>Bank Code</div>
                              <div className="text-dark font-weight-bold">
                                AAAL
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Bank Name</div>
                              <div className="text-dark font-weight-bold">
                                Saudi Hollandi Bank
                              </div>
                            </div>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ marginBottom: "24px" }}
                            >
                              <div>Employee Job No.</div>
                              <div className="text-dark font-weight-bold">
                                900aa
                              </div>
                            </div>

                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ marginBottom: "24px" }}
                            >
                              <div>Employee Job Title</div>
                              <div className="text-dark font-weight-bold">
                                Sr Test Eng
                              </div>
                            </div>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ marginBottom: "24px" }}
                            >
                              <div>Agency Emp. Date</div>
                              <div className="text-dark font-weight-bold">
                                2018-10-10
                              </div>
                            </div>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ marginBottom: "24px" }}
                            >
                              <div>Pay Month</div>
                              <div className="text-dark font-weight-bold">
                                202008
                              </div>
                            </div>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ marginBottom: "24px" }}
                            >
                              <div>Basic Salary</div>
                              <div className="text-dark font-weight-bold">
                                1,000 SAR.
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Total Allowance</div>
                              <div className="text-dark font-weight-bold">
                                1,00 SAR.
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Total Deductions</div>
                              <div className="text-dark font-weight-bold">
                                100 SAR.
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Net Salary</div>
                              <div className="text-dark font-weight-bold">
                                1,000 SAR.
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </TabView>
                  </div>
                </Col>

                {/* dakhi section end  */}
              </Row>
              <Row
                className="mt-2 driver-details px-1 row-flex"
                style={{ height: "auto" }}
              >
                <Col xl={12} className="px-0">

                  <PickupTotalTrips />
                  {/* <PickupEmptyTripsChart/> */}

                </Col>
              </Row>

              <Row
                className="mt-2 driver-details px-1 row-flex"
                style={{ height: "auto" }}
              >
                <Col xl={12} className="px-0">

                  <CustomerCare />

                </Col>
              </Row>
              <Row className="mt-2 driver-details px-1 row-flex">
                <Col xl={12} className="px-0">
                  <div className=" h-100 d-box">
                    <h2 className="box-title mb-4">Live Location</h2>
                    {/* <h6 className="box-title mb-4">Last updated Location  </h6> */}
                    {/* <h6 className="box-title mb-4">
                      05 Feb 2023
                      </h6> */}
                    <PickupTrackDrivers
                    />

                  </div>
                </Col>
              </Row>
              <Row className="mt-2 driver-details px-1 row-flex">
                <Col xl={12} className="px-0">
                  <div className="d-box">
                    <div className="d-flex mb-3">
                      <h2 className="box-title "> Other Reasons </h2>
                    </div>
                    <div className="kyc-info">
                      <span className="pr-3">
                        {" "}
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                        ></i>{" "}
                      </span>{" "}
                      Subscription has not been paid yet.
                    </div>
                    <div className="text-right mt-4"></div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
</Col>
                </>
    </div>
  );
};

export default React.memo(PickupDetailsTab);
