import moment from 'moment';
import React, { useEffect, useState } from 'react'
import APIService from '../../services/APIService';

function RiderInfobox(props) {

      return (
    <>
    <div className='row'>

    <div className='col-lg-4 col-md-6 col-sm-12 '>
        <div className=' d-flex align-items-center justify-content-between p-2 bg-white' style={{border:'1px solid gray',borderRadius:'5px',height:'55px',outline:'1px solid green'}}>
            <div className=' font-weight-bold text-secondary'>Total Riders</div>
            <div className=' font-weight-bold text-success'>{props?.data?.count.active + props?.data?.count.inActive || 0}</div>
        </div>
    </div>
    <div className='col-lg-4 col-md-6 col-sm-12 '>
        <div className=' d-flex align-items-center justify-content-between p-2 bg-white' style={{border:'1px solid gray',borderRadius:'5px',height:'55px',outline:'1px solid green'}}>
            <div className=' font-weight-bold text-secondary'>Online Riders</div>
            <div className=' font-weight-bold text-success'>{props?.data?.count.active}</div>
        </div>
    </div>
    <div className='col-lg-4 col-md-6 col-sm-12 '>
        <div className=' d-flex align-items-center justify-content-between p-2 bg-white' style={{border:'1px solid gray',borderRadius:'5px',height:'55px',outline:'1px solid green'}}>
            <div className=' font-weight-bold text-secondary'>Offline Riders</div>
            <div className=' font-weight-bold text-success'>{props?.data?.count.inActive}</div>
        </div>
    </div>
    </div>
    </>
  )
}

export default RiderInfobox