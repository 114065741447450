import React from 'react';
import './IssueResolvingTime.css';
import { Col, Row } from 'react-bootstrap';
const IssueResolvingTime = ({ comp, data }) => {
  const colors = ['#13B542', '#FFB065', '#EF4242','#EF4232'];
  // Function to generate segments for an item
  console.log('data',data)
function generateSegments(item, colors) {
  
  const segments = [
    { value: item.zeroToTwoHours, colorIndex: 0 },
    { value: item.TwoToFourHours, colorIndex: 1 },
    { value: item.FourToTenHours, colorIndex: 2},
    { value: item.aboveTenHours, colorIndex: 3 },
  ];

  return segments.map((segment, index) => {
    if (segment.value > 0) {
      return (
        <div
          key={index}
          className="segment charts-bar-issues"
          style={{
            width: `${segment.value}%`,
            backgroundColor: colors[segment.colorIndex],
          }}
        ></div>
      );
    }
    return null;
  });
}

// Map through the data array and generate the segments for each item


  return (
    <div>
      {(comp === "App No Use Feedback") ?
        <div>
          <div className="d-flex justify-content-center align-content-center fw-bold fs-4" style={{ marginTop: "100px", marginBottom: "100px", color: "#212529" }}>
            Coming soon
          </div>
        </div>
        :
        data.map((item, index) => (
          <div className='d-flex align-items-center' key={index}>
            <div className='col-xl-2 col-lg-2 col-md-2 col-2 text-end text-align-center'>{item.category}</div>
            <div className="col-xl-10 col-lg-10 col-md-10 col-10 segmented-bar mt-2 ">
              {generateSegments(item, colors)}
            </div>
          </div>
        ))
      }

      <div className='d-flex'>
        <div className='col-xl-2 col-lg-2 col-md-2 col-2 text-end text-align-center'>{ }</div>
        {/* <Row className='gx-3'>
          {Array(11).fill(0).map((a, i) => a + i).map(b => <Col className='col-xl-2 text-end text-align-center' >{b}</Col>)}
        </Row> */}
      </div>

      {comp === "Issue Resolving Time" &&
        <div className='d-flex justify-content-center mt-4'>
          <div className="percentage me-3">
            <div className="green"></div>
            <div className="percentext ">0-2 hours</div>
            {/* <div className="percenPoint">
                            30
                          </div> */}
          </div>
          <div className="percentage me-3">
            <div
              className="green bg-orange"
              style={{ background: "#FFB065" }}
            ></div>
            <div className="percentext ">2-4 hours</div>
            {/* <div className="percenPoint">
                            30
                          </div> */}
          </div>
          <div className="percentage">
            <div className="green bg-red"></div>
            <div className="percentext ">4-10 hours</div>
            {/* <div className="percenPoint">
                            30
                          </div> */}
          </div>
        </div>
      }
    </div>

  );
};

export default React.memo(IssueResolvingTime);


// import React from 'react';
// import './IssueResolvingTime.css';

// const SegmentedBarChart = () => {
//   const categories = ["Registration", "Passenger No Show", "Vehicle Doc Renewal", "Trip", "Payout", "Other"];
//   const segmentData = [15, 20, 50];
//   const colors = ['#13B542', '#FFB065', '#EF4242'];

//   const segments = segmentData.map((value, index) => (
//     <div
//       key={index}
//       className="segment"
//       style={{
//         height: `${(value / 100) * 300}px`,
//         backgroundColor: colors[index],
//         borderTopRightRadius:  index === 0 ? "10px" : "",
//         borderTopLeftRadius: index === 0 ? "10px" : "",
//         width: "40px",
//       }}
//     ></div>
//   ));

//   return (
//     <>
//     <div className="segmented-bar-container d-flex justify-content-between" style={{width:"100%"}}>
//       {categories.map((item, idx) => (
//         <div key={idx} className="d-flex justify-between flex-column">
//           <div className="col-xl-10 col-lg-10 col-md-10 col-10 mt-2 mb-4">{segments}</div>
//           <div className='mt-5 rotate-text'>Lahore</div>
//         </div>
//       ))}
//     </div>
//     <div className='d-flex justify-content-center'>
//     <div className="percentage me-3">
//                   <div className="green"></div>
//                   <div className="percentext ">Closed:</div>
//                   <div className="percenPoint">
//                     30
//                   </div>
//                 </div>
//                 <div className="percentage me-3">
//                   <div
//                     className="green bg-orange"
//                     style={{ background: "#FFB065" }}
//                   ></div>
//                   <div className="percentext ">Pending:</div>
//                   <div className="percenPoint">
//                     30
//                   </div>
//                 </div>
//                 <div className="percentage">
//                   <div className="green bg-red"></div>
//                   <div className="percentext ">Open:</div>
//                   <div className="percenPoint">
//                     30
//                   </div>
//                 </div>
//     </div>
//       </>
//   );
// };

// export default SegmentedBarChart;