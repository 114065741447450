import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Card, Col, Row, Modal, Button } from "react-bootstrap";
import driveractive from "../../assest/images/driveractive.png";
import PlaceHoderImage from "../../assest/images/placeHoderImage.png";
import Rating from "react-rating";
import "../../sass/DriverDeatils.sass";
import Select, { StylesConfig } from "react-select";
import { TabView, TabPanel } from "primereact/tabview";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import topup from "../../assest/images/topup.svg";
import countryLogo from "../../assest/images/countrylogo.svg";
import VisaLogo from "../../assest/images/visa-logo.png";
import CardLogo from "../../assest/images/card-logo.png";
import Chip from "../../assest/images/chip.svg";
import moment from "moment";
import riderAvtSec from "../../assest/images/placeHoderImage.png";
import carInfoIcon from "../../assest/images/carInfo.png";
import APIService from "../../services/APIService";
import { toast } from "react-toastify";
import noCardLogo from "../../assest/images/emptyCard.png";
import TotalTrips from "./TotalTripsChart";
import EmptyTotalTrips from "./EmptyTripsChart";
import RideCancelledSummary from "./RiderSubscriptionHistory";
import TrackDrivers from "./TrackDrivers";
import axios from "axios";
import DriverSubscriptionTransactionsTab from "./DriverSubscriptionTransactionsTab";
import cardIcon from "./icons/cardIcon.svg";
import { Line } from "react-chartjs-2";
import { Justify } from "react-bootstrap-icons";
import TopupHistoryChart from "./TopupHistoryChart";
import logo from "../../assest/images/ride.svg";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "../Riders/NumberInput.css";
import { _baseURL } from "../../services/APIService";
import { secret_key } from "../../services/APIService";
import DriverLog from "./DriverLog";
import CustomerCare from "./CustomerCare";
import CancelReason from "./CancelReason";



const obj = {
  graphList: [
    {
      key: "22",
      value: 5,
    },
    {
      key: "23",
      value: 10,
    },
    {
      key: "24",
      value: 15,
    },
    {
      key: "25",
      value: 20,
    },
    {
      key: "26",
      value: 25,
    },
    {
      key: "27",
      value: 0,
    },
    {
      key: "28",
      value: 0,
    },
    {
      key: "29",
      value: 45,
    },
  ],
  total: 2,
};
const _updateUserDetails = _baseURL + "/api/v1/employee/update-contact"
const _getComplainTypes =
  _baseURL + "/api/v1/ticket/get-complaint-type?secret_key=" + secret_key;
const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? "#ffffff"
          : isFocused
            ? "white"
            : undefined,
      color: isDisabled ? "white" : isSelected ? "#13B542" : "#000000",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },
};
let DriverDetailsTab = (props) => {
  let windowWidth = window.innerWidth,
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    type: "doughnut",
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: windowWidth > 992 ? 95 : 95,
    pointStyle: "circle",

    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
        position: windowWidth > 992 ? "bottom" : "bottom",
        labels: {
          boxWidth: 100,
          usePointStyle: true,
          fontSize: 60,
        },
      },
    },
  };

  let { driver, driverId, dispatch } = props;
  let timeRange = "week";
  const [isMore, setIsMore] = useState(false);
  const [showGosi, setShowGosi] = useState(false);
  const [bothData, setBothData] = useState(false);
  const [showGovt, setShowGovt] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [vehicleDetail, setVehicleDetail] = useState(null);
  const [userStatus, setUserStatus] = useState(null),
    [validNumber, setValidNumber] = useState(false),
    [emailValue, setEmailValue] = useState(""),
    [emailValidity, setEmailValidity] = useState(false),
    [emailUser, setEmailUser] = useState(""),
    [ticketList, setTicketList] = useState(null),
    [updateUserProfile, setUpdateUserProfile] = useState(null),
    [showProfile, setEditProfile] = useState(false),
    [ticketTypeOptions, setTicketTypeOptions] = useState([]),
    [mobleNo, setMoblNo] = useState("");

  const [isStatus, setIsstatus] = useState(driver?.isWASLApproved);
  const [checked, setChecked] = useState(false);
 
  let changeDriverStatusAPI = () => {
    if (!driver.approved) {
      APIService.patch("admin/approve-captain/" + driver.id, {})
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            driver.approved = true;
            driver.blockedReason = "";
            toast.success("Driver status updated successfully");
          } else {
            toast.error("Failed to update status");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      APIService.patch("admin/reject-captain/" + driver.id, {
        blockedReason: "behaviour issue",
      })
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            driver.approved = false;
            driver.blockedReason = "behaviour issue";

            toast.success("Driver status updated successfully");
          } else {
            toast.error("Failed to update status");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const handleChangeBlockStatus = () => {
    setChecked(!checked);
    changeDriverStatusAPI();
  };
  const onReinitializeStatus = () => {
    props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
    // filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
    APIService.get("admin/captain/wasl-initiat/" + driver.id)
      .then((response) => {
        if (response.data && response.data.statusCode === 200) {
          // const [isStatus, setIsstatus] = useState(driver?.isWASLApproved);

          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        }
      })
      .catch((error) => {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
      });
  }
  const _saveTicketURL = _baseURL + "/api/v1/ticket/save";
  const profileDetail = () => {
    setIsMore(!isMore);
  };
  let ratingCount = (value) => {
    let statusText;
    switch (value) {
      case 1:
        statusText = "😣 Poor";
        break;
      case 2:
        statusText = "😔 Bad";
        break;
      case 3:
        statusText = "🙂 Normal";
        break;
      case 4:
        statusText = "😀 Good";
        break;
      case 5:
        statusText = "😊 Excellent";
        break;
      default:
        statusText = "-";
        break;
    }
    return statusText;
  };

  const _getAllTicketsURL = _baseURL + "/api/v1/ticket/per-customer";
  let _getAllTickets = async () => {
    // props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
    // let filterParams = JSON.parse(JSON.stringify(lazyParams));
    // filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
    // sdelete filterParams["totalRecords"];
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        secret_key: secret_key,
        contact_no: driver?.mobileNo,
      }),
    };
    const response = await fetch(_getAllTicketsURL, requestOptions);
    let final_data = await response.json();
    if (final_data.data) {
      const sortedData = final_data?.data[0]?.tickets?.reverse();
      // const filtered = sortedData?.filter((word) => word.type == "2");
      setTicketList(sortedData);
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
    } else props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
  };

  const profileHandleClose = () => setEditProfile(false),
    profileHandleShow = () => {
      setEditProfile(true);
    };
  let ticketType = [
    { value: 1, label: "Ride Cancellation" },
    { value: 2, label: "Subscription Issue" },
    { value: 3, label: "Payment Not Received" },
  ],
    [showTicket, setShowTicket] = useState(false);

  let assignType = [
    { value: 1, label: "Rider" },
    // { value: 2, label: "Driver" },
  ];
  let [ticketSelectionType, setSelectionType] = useState({
    value: 2,
    label: "Driver",
  });
  let handleCloseTicket = () => setShowTicket(false);
  let handleShowTicket = () => {
    setShowTicket(true);
  };

  let totalTrips = {
    graphList: [
      {
        key: "completed",
        value: driver?.totalTrips,
      },
      {
        key: "cancelled",
        value: driver?.tripsCancelled,
      },
      {
        key: "Declined",
        value: driver?.tripslDeclined,
      },
    ],
    total: driver?.totalTrips + driver?.tripsCancelled,
  };

  let emptyTrips = {
    graphList: [
      {
        key: "completed",
        value: 10,
      },
    ],
    total: 12,
  };
  let [selectedOption, setSelectedOption] = useState(null);
  let [isDisable, setIsDisable] = useState(false);
  const [city, setCity] = useState("");
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [submitting, isSubmitting] = useState(false);
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [showData, setShowData] = useState(false);
  let [show, setShow] = useState(false);
  let handleClose = () => setShow(false);
  let topUpsDetail = () => {
    props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
    APIService.get("/admin/get-balance/" + driver?.externalId)
      .then((response) => {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        var result = response.data.data;
        setPaymentHistory(result);
      })
      .catch(() =>
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
      );
  };
  let carLicenceTyps = ["Private", "Public Transport", "Taxi"];
  if (driver?.ratingCounts && !driver.ratingCounts) {
    driver.ratingCounts = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    };
  }
  let driverOnlineStatus = () => {
    if (driver?.externalId) {
      APIService.get("admin/user/user-loc-and-status/" + driver?.externalId)
        .then((response) => {
          var result = response.data.data;
          setUserStatus(result);
        })
        .catch(() =>
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false })
        );
    }
  };
  let formValidation = Yup.object().shape({
    blockedReason: Yup.string()
      .trim("Please remove whitespaces")
      .strict(false)
      .required("Please enter reason")
      .nullable(),
  });
  let setSelect = () => {
    if (driver.approved) {
      setSelectedOption({ label: "Active", value: true });
    } else {
      setSelectedOption({ label: "Inactive", value: false });
    }
  };
  let handleShow = () => {
    setShow(true);
    setSelect();
  };

  let changeWaslSttus = () => {
    props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
    // filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
    APIService.get("admin/captain/change-wasl-status/" + driver.id)
      .then((response) => {
        if (response.data && response.data.statusCode === 200) {
          // const [isStatus, setIsstatus] = useState(driver?.isWASLApproved);
          if (isStatus === 1) {
            setIsstatus(0);
          } else {
            setIsstatus(1);
          }

          // setVehicleDetail(response.data.data);
          // lazyParams.totalRecords = response.data.data.totalCount;
          // setLazyParams(lazyParams);
          // setUpcomingSchedules(.trips);
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        }
      })
      .catch((error) => {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
      });
  };
  const onChangeStatus = () => {
    changeWaslSttus();
  };

  let activeDriverData = {
    graphList: [
      {
        key: "completed",
        value: driver?.totalTrips,
      },
      {
        key: "cancelled",
        value: driver?.tripCancelled,
      },
      {
        key: "Declined",
        value: driver?.tripCancelled,
      },
    ],
    total: driver?.totalTrips,
  };

  const driverDetail = driver?.additionalInfo
    ? JSON.parse(driver?.additionalInfo)
    : "";
  const otherDetail = driver?.otherDetails
    ? JSON.parse(driver?.otherDetails)
    : "";

  const handleValidatePhone = (value) => {
    if (value) {
      const isValid = isValidPhoneNumber(value);

      setValidNumber(isValid);
      // this.setState({ isValid });
    }
  };
  const validateSchema = Yup.object({
    email: Yup.string().email("invalid email format").required("Required!"),
  });

  validateSchema
    .isValid({
      email: emailValue,
    })
    .then((valid) => {
      setEmailValidity(valid);
    });

  let getTicketType = async () => {
    const response = await fetch(_getComplainTypes)
    const final_data = await response.json()
    setTicketTypeOptions(final_data?.data.map(a => {
      return { value: a.id, label: a.title }
    }))
  }
  useEffect(() => {
    getTicketType()
  }, [])
  useEffect(() => {
    handleValidatePhone(mobleNo);
  }, [mobleNo]);

  const handleOnChangeCountry = (value) => {
    // handleValidate(value);
    setMoblNo(value);
  };
  const updateInCms = async (contact_no) => {
    // console.log(driverId.userId)
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        ride_customer_id: driver?.externalId,
        contact_no,
        secret_key
      }),
    };
    const response = await fetch(_updateUserDetails, requestOptions)
    console.log(response)
  }
  const updateProfile = (userData) => {
    validNumber && (userData.mobileNo = mobleNo.slice(1, 13));
    emailValue && (userData.email = emailValue);
    userData.userId = driver?.externalId;
    userData.email = emailValue ? emailValue : null;
    APIService.post("admin/user/update-customer", userData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          toast.success("User Updated successfully");
          // setTimeout(() => {
          //   window.location.reload();
          // }, 500);
          updateInCms(userData.mobileNo)

          setEditProfile(false);
        } else {
          toast.error("Failed to update User");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // useEffect(() => {
  //   getSchedules();
  // }, [driver]);
  useEffect(() => {
    const status = driver?.isWASLApproved;
    setIsstatus(status);
  }, [driver]);
  useEffect(() => {
    topUpsDetail();
  }, [driver]);
  useEffect(() => { }, [driver]);
  useEffect(() => {
    let email = localStorage.getItem("email");
    setEmployeeEmail(email)
  }, [])

  const data = {
    datasets: [
      {
        data: [12, 19],
        backgroundColor: ["#000000", "#00FF00"],
      },
    ],
  };

  // line charts data
  const data1 = {
    labels: ["23", "24", "25", "26", "27", "28", "29"],
    datasets: [
      {
        data: [300, 400, 200, 600, 400],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  };


  useEffect(() => {
    console.log('callledddd')
    fetch(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${driver?.latitude}&longitude=${driver?.longitude}&localityLanguage=en`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log('data---------', data)
        setCity(data.city);
      });
  }, []);

  console.log('CityDisplay', city)
  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 380).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = "Lease To Own",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillStyle = "#828282";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  const plugins1 = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 380).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = "Trips To Cover",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillStyle = "#828282";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];
  const dakhliDetailGossi = otherDetail?.gosi?.employmentStatusInfo
    ? otherDetail.gosi.employmentStatusInfo[0]
    : null;
  // const dakhliDetailGovt = otherDetail?.gosi?.employmentStatusInfo ? otherDetail.gosi.employmentStatusInfo[0] : null
  const dakhliDetailGovt = otherDetail?.govt?.data
    ? otherDetail.govt.data[0]
    : null;

  useEffect(() => {
    driverOnlineStatus();
    var driverlocationApiInterval = setInterval(() => {
      driverOnlineStatus();
      if (!window.location?.href?.includes('driver-details')) {
        clearInterval(driverlocationApiInterval)
      }
    }, 10000);
  }, [driver]);
  useEffect(() => {
    _getAllTickets();
  }, [driver]);

  // data1.datasets[0].label=false;

  return (
    <div className="mt-3 driver-details">
      {driver ? (
        <>
          <Col xl={12} className="px-custom">
            <Row className="mt-2 driver-details px-1 row-flex">
              <Col xl={8}>
                <Row className="mt-2 driver-details px-1 row-flex">
                  <Col xl={12} className="px-0">
                    <div className="d-box h-100 ">
                      <Row className=" mx-0">
                        <Col lg={9} className="px-0">
                          <div className="d-flex">
                            <div className="img-relative">
                              {driver?.profileImage ? (
                                <>
                                  <img
                                    src={`${driver?.profileImage}`}
                                    alt="ride"
                                    style={{
                                      marginRight: "15px",
                                      width: "84px",
                                      height: "84px",
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                    }}
                                  />
                                  <div
                                    className="user-absolute-img"
                                    onClick={() => setEditProfile(true)}
                                  >
                                    <svg
                                      width="30"
                                      height="30"
                                      viewBox="0 0 30 30"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        cx="15"
                                        cy="15"
                                        r="13.5"
                                        fill="#13B542"
                                        stroke="white"
                                        stroke-width="3"
                                      />
                                      <path
                                        d="M20.7137 12.2795C20.7142 12.2042 20.6998 12.1297 20.6713 12.0601C20.6429 11.9905 20.601 11.9271 20.548 11.8737L18.1252 9.45088C18.0718 9.39792 18.0084 9.35602 17.9388 9.32758C17.8692 9.29915 17.7947 9.28473 17.7195 9.28517C17.6442 9.28473 17.5697 9.29915 17.5001 9.32758C17.4305 9.35602 17.3671 9.39792 17.3137 9.45088L15.6966 11.068L9.45088 17.3137C9.39792 17.3671 9.35602 17.4305 9.32758 17.5001C9.29915 17.5697 9.28473 17.6442 9.28517 17.7195V20.1423C9.28517 20.2939 9.34537 20.4392 9.45253 20.5464C9.5597 20.6535 9.70504 20.7137 9.85659 20.7137H12.2795C12.3594 20.7181 12.4394 20.7056 12.5142 20.677C12.589 20.6485 12.657 20.6045 12.7137 20.548L18.9252 14.3023L20.548 12.7137C20.6002 12.6584 20.6427 12.5946 20.6737 12.5252C20.6792 12.4796 20.6792 12.4336 20.6737 12.388C20.6764 12.3614 20.6764 12.3346 20.6737 12.308L20.7137 12.2795ZM12.0452 19.5709H10.428V17.9537L16.1023 12.2795L17.7195 13.8966L12.0452 19.5709ZM18.5252 13.0909L16.908 11.4737L17.7195 10.668L19.3309 12.2795L18.5252 13.0909Z"
                                        fill="#F4F4F4"
                                      />
                                    </svg>
                                  </div>
                                </>
                              ) : (
                                <img
                                  src={riderAvtSec}
                                  alt="ride"
                                  style={{
                                    marginRight: "15px",
                                    width: "84px",
                                    height: "84px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                              <div
                                className="user-absolute-img"
                                onClick={() => setEditProfile(true)}
                              >
                                <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="15"
                                    cy="15"
                                    r="13.5"
                                    fill="#13B542"
                                    stroke="white"
                                    stroke-width="3"
                                  />
                                  <path
                                    d="M20.7137 12.2795C20.7142 12.2042 20.6998 12.1297 20.6713 12.0601C20.6429 11.9905 20.601 11.9271 20.548 11.8737L18.1252 9.45088C18.0718 9.39792 18.0084 9.35602 17.9388 9.32758C17.8692 9.29915 17.7947 9.28473 17.7195 9.28517C17.6442 9.28473 17.5697 9.29915 17.5001 9.32758C17.4305 9.35602 17.3671 9.39792 17.3137 9.45088L15.6966 11.068L9.45088 17.3137C9.39792 17.3671 9.35602 17.4305 9.32758 17.5001C9.29915 17.5697 9.28473 17.6442 9.28517 17.7195V20.1423C9.28517 20.2939 9.34537 20.4392 9.45253 20.5464C9.5597 20.6535 9.70504 20.7137 9.85659 20.7137H12.2795C12.3594 20.7181 12.4394 20.7056 12.5142 20.677C12.589 20.6485 12.657 20.6045 12.7137 20.548L18.9252 14.3023L20.548 12.7137C20.6002 12.6584 20.6427 12.5946 20.6737 12.5252C20.6792 12.4796 20.6792 12.4336 20.6737 12.388C20.6764 12.3614 20.6764 12.3346 20.6737 12.308L20.7137 12.2795ZM12.0452 19.5709H10.428V17.9537L16.1023 12.2795L17.7195 13.8966L12.0452 19.5709ZM18.5252 13.0909L16.908 11.4737L17.7195 10.668L19.3309 12.2795L18.5252 13.0909Z"
                                    fill="#F4F4F4"
                                  />
                                </svg>
                              </div>
                            </div>
                            <div>
                              <div className="detail-list-1">
                                <div className="detail-list-1-name">
                                  {driver?.driverName}
                                </div>
                                <div className="detail-list-1-id">{`#${driver?.externalId}`}</div>

                                <div>
                                  {userStatus?.onlineStatus ? (
                                    <span className="status-text">Online</span>
                                  ) : (
                                    <span className="status-text-inactive">
                                      Offline
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="detail-list-2">
                                <div className="detail-list-2-name">
                                  {ratingCount(
                                    Math.round(driver.overallRating)
                                  )}
                                </div>
                                <div className="detail-list-1-id ml-3">
                                  <div>
                                    <img src={topup} alt="ride" />
                                    <span className="topup-txt">
                                      {driver?.totalEarned} SAR.
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col lg={3} className="px-0 my-auto">
                          <div className="text-right">
                            <img src={countryLogo} alt="ride" />
                            <span className="country-txt">Saudi Arabia</span>
                          </div>
                        </Col>
                      </Row>
                      <Row className="inner-box mx-0 row mt-24">
                        <Col md={6} className="border-right">
                          <Row className="mt-4">
                            <Col md={5} className="px-0">
                              <label className="profilelabel">
                                Loyalty points
                              </label>
                            </Col>
                            <Col md={7}>
                              <label className="profiletxt">--</label>
                            </Col>
                          </Row>

                          <Row className="mt-4">
                            <Col md={5} className="px-0">
                              <label className="profilelabel">Is rider ?</label>
                            </Col>
                            <Col md={7}>
                              <label className="profiletxt">
                                {driver.userType === 1 ? (
                                  "No"
                                ) : (
                                  <Link
                                    className="text-decoration-none "
                                    to="#"
                                    style={{ color: "#13B542" }}
                                  >
                                    Yes
                                  </Link>
                                )}
                              </label>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col md={5} className="px-0">
                              <label className="profilelabel">
                                Is pickup captain?
                              </label>
                            </Col>
                            <Col md={7}>
                              <label className="profiletxt">No</label>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col md={5} className="px-0">
                              <label className="profilelabel">
                                Block
                              </label>
                            </Col>
                            <Col md={7}>
                              <label className="profiletxt">
                                {" "}
                                <div className="custom-control custom-switch">
                                  <input
                                    className="custom-control-input"
                                    defaultChecked={!driver.approved}
                                    type="checkbox"
                                    id="customSwitch"
                                    onChange={handleChangeBlockStatus}
                                  />
                                  <label
                                    className="custom-control-label"
                                    style={{ cursor: "pointer" }}
                                    htmlFor="customSwitch"
                                  ></label>
                                </div>
                              </label>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col md={5} className="px-0">
                              <label className="profilelabel">IBAN</label>
                            </Col>
                            <Col md={7}>
                              <label
                                className="profiletxt"
                                style={{ wordBreak: "normal" }}
                              >
                                {driver?.iban}
                              </label>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col md={5} className="px-0">
                              <label className="profilelabel">City</label>
                            </Col>
                            <Col md={7}>
                              <label
                                className="profiletxt"
                                style={{ wordBreak: "normal" }}
                              >
                                {city || '-'}
                              </label>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={6} className="pl-5 pr-0">
                          <Row className="mt-4">
                            <Col md={5} className="px-0">
                              <label className="profilelabel">Mobile Number</label>
                            </Col>
                            <Col md={7}>
                              <label className="profiletxt">
                                {`+${driver?.mobileNo}`}
                              </label>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col md={5} className="px-0">
                              <label className="profilelabel">Email</label>
                            </Col>
                            <Col md={7}>
                              <label
                                className="profiletxt"
                                style={{
                                  textTransform: "lowercase !important",
                                }}
                              >
                                {driver?.emailId}
                              </label>
                            </Col>
                          </Row>

                          <Row className="mt-4">
                            <Col md={5} className="px-0">
                              <label className="profilelabel">
                                Tickets created
                              </label>
                            </Col>
                            <Col md={7}>
                              <label
                                className="profiletxt"
                                style={{ color: "#13B542" }}
                              >
                                {ticketList?.length<10?`0${ticketList?.length}`:ticketList?.length}
                              </label>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col md={5} className="px-0">
                              <label className="profilelabel">Source</label>
                            </Col>
                            <Col md={7}>
                              <label className="profiletxt text-uppercase">
                                Ride
                              </label>
                            </Col>
                          </Row>
                          
                          <Row className="mt-4">
                            <Col md={5} className="px-0">
                              <label className="profilelabel">
                                National ID
                              </label>
                            </Col>
                            <Col md={7}>
                              <label
                                className="profiletxt"
                                style={{
                                  textTransform: "lowercase !important",
                                }}
                              >
                                {driver?.driverNationalId}
                              </label>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {isMore && (
                        <>
                          <Row className="inner-box mx-0 row mt-2">
                            <Col md={6} className="border-right pl-0">
                              <div className="data-listing">
                                <div className="profilelabel">Full name </div>
                                <div className="profiletxt">
                                  {/* {rideViewData.arabicFullName} */}
                                  {`${driverDetail?.CitizenDLInfo?.englishFirstName?._text} ${driverDetail?.CitizenDLInfo?.englishSecondName?._text}  ${driverDetail?.CitizenDLInfo?.englishLastName?._text}`}
                                </div>
                              </div>
                              <div className="data-listing ">
                                <div className="profilelabel">Gender</div>
                                <div className="profiletxt">
                                  {/* {rideViewData.arabicFullName} */}
                                  {driverDetail?.CitizenDLInfo?.gender
                                    ?._text === "M"
                                    ? "Male"
                                    : "Female"}
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profilelabel">DOB hijri </div>
                                <div className="profiletxt">
                                  {/* {rideViewData.arabicFullName} */}
                                  {
                                    driverDetail?.CitizenDLInfo?.dateOfBirthH
                                      ?._text
                                  }
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profilelabel">ID Expiry</div>
                                <div className="profiletxt">
                                  {/* {rideViewData.arabicFullName} */}
                                  {
                                    driverDetail?.CitizenDLInfo?.idExpiryDate
                                      ?._text
                                  }
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profilelabel">
                                  License type code
                                </div>
                                <div className="profiletxt">
                                  {/* {rideViewData.arabicFullName} */}
                                  {
                                    driverDetail?.CitizenDLInfo
                                      ?.licenseListList[1]?.licnsTypeCode?._text
                                  }
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profilelabel">
                                  License expiry date hijri
                                </div>
                                <div className="profiletxt">
                                  {
                                    driverDetail?.CitizenDLInfo
                                      ?.licenseListList[1]?.licssExpiryDateH
                                      ?._text
                                  }
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profilelabel">
                                  License expiry date gregorian
                                </div>
                                <div className="profiletxt">--</div>
                              </div>
                              <div className="data-listing">
                                <div className="profilelabel">Log ID</div>
                                <div className="profiletxt">
                                  {driverDetail?.CitizenDLInfo?.logId?._text}
                                </div>
                              </div>
                            </Col>
                            <Col md={6} className="pl-5 pr-0 arabic-fonts">
                              <div className="data-listing">
                                <div className="profiletxt">
                                  {/* {rideViewData.arabicFullName} */}
                                  {`${driverDetail?.CitizenDLInfo?.firstName?._text} ${driverDetail?.CitizenDLInfo?.fatherName?._text}  ${driverDetail?.CitizenDLInfo?.familyName?._text}`}
                                </div>
                                <div className="profilelabel">الاسم الكامل</div>
                              </div>
                              <div className="data-listing">
                                <div className="profiletxt">
                                  {driverDetail?.CitizenDLInfo?.gender
                                    ?._text === "M"
                                    ? "Male"
                                    : "Female"}{" "}
                                  {/* {rideViewData.arabicFullName} */}
                                </div>
                                <div className="profilelabel">الجنس</div>
                              </div>
                              <div className="data-listing">
                                <div className="profiletxt">
                                  {
                                    driverDetail?.CitizenDLInfo?.dateOfBirthH
                                      ?._text
                                  }
                                  {/* {rideViewData.arabicFullName} */}
                                </div>
                                <div className="profilelabel">
                                  تاريخ الميلاد
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profiletxt">
                                  {
                                    driverDetail?.CitizenDLInfo?.idExpiryDate
                                      ?._text
                                  }
                                  {/* {rideViewData.arabicFullName} */}
                                </div>
                                <div className="profilelabel">
                                  انتهاء الهوية
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profiletxt">
                                  {
                                    driverDetail?.CitizenDLInfo
                                      ?.licenseListList[1]?.licnsTypeCode?._text
                                  }
                                  {/* {rideViewData.arabicFullName} */}
                                </div>
                                <div className="profilelabel">
                                  License type code
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profiletxt">
                                  {
                                    driverDetail?.CitizenDLInfo
                                      ?.licenseListList[1]?.licssExpiryDateH
                                      ?._text
                                  }{" "}
                                  {/* {rideViewData.arabicFullName} */}
                                </div>
                                <div className="profilelabel">
                                  تاريخ انتهاء الرخصة
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profiletxt">
                                  -- {/* /  {rideViewData.arabicFullName} */}
                                </div>
                                <div className="profilelabel">
                                  تاريخ انتهاء الرخصة
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profiletxt">
                                  {driverDetail?.CitizenDLInfo?.logId?._text}
                                  {/* {rideViewData.arabicFullName} */}
                                </div>
                                <div className="profilelabel">Log ID</div>
                              </div>
                            </Col>
                          </Row>
                          <Row className="inner-box mx-0 row mt-2">
                            <Col md={6} className="border-right pl-0">
                              <div className="data-listing">
                                <div className="profilelabel">
                                  Owner full name{" "}
                                </div>
                                <div className="profiletxt">--</div>
                              </div>
                              <div className="data-listing">
                                <div className="profilelabel">Building no.</div>
                                <div className="profiletxt">
                                  {
                                    driverDetail?.CitizenAddressInfoEnglish
                                      ?.buildingNumber?._text
                                  }
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profilelabel">Street name </div>
                                <div className="profiletxt">
                                  {
                                    driverDetail?.CitizenAddressInfoEnglish
                                      ?.streetName?._text
                                  }
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profilelabel">
                                  Neighbourhood name
                                </div>
                                <div className="profiletxt">--</div>
                              </div>
                              <div className="data-listing">
                                <div className="profilelabel">Postal code</div>
                                <div className="profiletxt">
                                  {
                                    driverDetail?.CitizenAddressInfoEnglish
                                      ?.postCode?._text
                                  }
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profilelabel">
                                  Additional code
                                </div>
                                <div className="profiletxt">
                                  {
                                    driverDetail?.CitizenAddressInfoEnglish
                                      ?.additionalNumber?._text
                                  }
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profilelabel">Unit no.</div>
                                <div className="profiletxt">
                                  {/* {rideViewData.arabicFullName} */}
                                  {
                                    driverDetail?.CitizenAddressInfoEnglish
                                      ?.unitNumber?._text
                                  }
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profilelabel">City</div>
                                <div className="profiletxt">
                                  {
                                    driverDetail?.CitizenAddressInfoEnglish
                                      ?.city?._text
                                  }
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profilelabel">
                                  Location co-ordinates
                                </div>
                                <div className="profiletxt">
                                  {
                                    driverDetail?.CitizenAddressInfoEnglish
                                      ?.locationCoordinates?._text
                                  }
                                </div>
                              </div>
                            </Col>
                            <Col md={6} className="pl-5 pr-0 arabic-fonts">
                              <div className="data-listing">
                                <div className="profiletxt">--</div>
                                <div className="profilelabel">اسم المالك</div>
                              </div>
                              <div className="data-listing">
                                <div className="profiletxt">
                                  {" "}
                                  {
                                    driverDetail?.CitizenAddressInfoEnglish
                                      ?.buildingNumber?._text
                                  }
                                </div>
                                <div className="profilelabel">رقم المبنى</div>
                              </div>
                              <div className="data-listing">
                                <div className="profiletxt">
                                  {/* {rideViewData.arabicFullName} */}
                                  {
                                    driverDetail?.CitizenAddressInfoArabic
                                      ?.streetName?._text
                                  }
                                </div>
                                <div className="profilelabel">اسم الشارع</div>
                              </div>
                              <div className="data-listing">
                                <div className="profiletxt">--</div>
                                <div className="profilelabel">اسم الحي</div>
                              </div>
                              <div className="data-listing">
                                <div className="profiletxt">
                                  {
                                    driverDetail?.CitizenAddressInfoEnglish
                                      ?.postCode?._text
                                  }
                                </div>
                                <div className="profilelabel">
                                  الرمز البريدي
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profiletxt">
                                  {
                                    driverDetail?.CitizenAddressInfoEnglish
                                      ?.additionalNumber?._text
                                  }
                                </div>
                                <div className="profilelabel">
                                  الرمز الإضافي
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profiletxt">
                                  {" "}
                                  {
                                    driverDetail?.CitizenAddressInfoEnglish
                                      ?.unitNumber?._text
                                  }
                                </div>
                                <div className="profilelabel">رقم الوحدة</div>
                              </div>
                              <div className="data-listing">
                                <div className="profiletxt">
                                  {
                                    driverDetail?.CitizenAddressInfoArabic?.city
                                      ?._text
                                  }
                                </div>
                                <div className="profilelabel">المدينة</div>
                              </div>
                              <div className="data-listing">
                                <div
                                  className="profilelabel"
                                  style={{ color: "#000000" }}
                                >
                                  {
                                    driverDetail?.CitizenAddressInfoEnglish
                                      ?.locationCoordinates?._text
                                  }
                                </div>
                                <div
                                  className="profiletxt"
                                  style={{ color: "#828282" }}
                                >
                                  احداثيات الموقع
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row className="inner-box mx-0 row mt-2">
                            <Col md={6} className="border-right  pl-0">
                              <div className="data-listing">
                                <div className="profilelabel">
                                  Type of license plate
                                </div>
                                <div className="profiletxt">--</div>
                              </div>
                              <div className="data-listing">
                                <div className="profilelabel">Car type</div>
                                <div className="profiletxt">
                                  {driver?.car?.plateTypeCode}
                                </div>
                              </div>
                              <div className="data-listing">
                                <div className="profilelabel">Car color</div>
                                <div className="profiletxt">--</div>
                              </div>
                              <div className="data-listing">
                                <div className="profilelabel">Manufacturer</div>
                                <div className="profiletxt">--</div>
                              </div>
                            </Col>
                            <Col md={6} className="pl-5 pr-0 arabic-fonts">
                              <div className="data-listing">
                                <div className="profiletxt">--</div>
                                <div className="profilelabel">نوع اللوحة</div>
                              </div>
                              <div className="data-listing">
                                <div className="profiletxt">
                                  {" "}
                                  {driver?.car?.plateTypeCode}
                                </div>
                                <div className="profilelabel">نوع المركبة</div>
                              </div>
                              <div className="data-listing">
                                <div className="profiletxt">
                                  {" "}
                                  {driver?.car?.majorColor}
                                </div>
                                <div className="profilelabel">لون المركبة</div>
                              </div>
                              <div className="data-listing">
                                <div className="profiletxt">
                                  {" "}
                                  {driver?.car?.vehicleMaker}
                                </div>
                                <div className="profilelabel">
                                  الشركة المصنعة
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                      <div className="d-flex justify-content-between align-items-center mt-3">
                        <div>
                          <button
                            className="btn btn-success"
                            onClick={handleShowTicket}
                          >
                            Create Ticket
                          </button>
                        </div>
                        <div
                          className="show-more"
                          onClick={profileDetail}
                          style={{
                            fontFamily: "'Manrope', sans-serif !important",
                            cursor: "pointer",
                          }}
                        >
                          {isMore ? "Show Less" : "Show More"}
                        </div>
                      </div>
                    </div>
                  </Col>

                  {/* <Col xl={4} className="px-0 pl-0 pl-xl-2 mt-3 mt-xl-0">
              <TotalTrips
                activeDriverData={activeDriverData}
                captainData={driver}
              />
            </Col> */}
                </Row>
                <Row className="mt-2 driver-details px-1 row-flex">
                  <Col xl={6} className="pl-0 pr-1">
                    <div className=" h-100 d-box">
                      {/* <TrackDrivers /> */}
                      <div className="d-flex justify-content-between align-items-baseline">
                        <h2 className="box-title">Saved Cards</h2>
                        <span className="link-custom">Add New Card</span>
                      </div>
                      {/* <div className="col-12">
                      <div className="cash-card card-green">
                        <div className="row h-100">
                          <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="card-text">
                                <h4 className="card-name">Kotak AQUA</h4>
                                <span className="card-detail ">
                                  <img src={VisaLogo} alt="" />
                                  <span className="ml-1"> .... 6292</span>
                                </span>
                              </div>
                              <div className="card-logo">
                                <img className="img-fluid" src={CardLogo} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mt-auto">
                            <img className="img-fluid" src={Chip} alt="" />
                            <h4 className="card-user">Jim Brown</h4>
                          </div>
                        </div>
                      </div>
                      <div className="cash-card card-red">
                        <div className="row h-100">
                          <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="card-text">
                                <h4 className="card-name">Kotak AQUA</h4>
                                <span className="card-detail ">
                                  <img src={VisaLogo} alt="" />
                                  <span className="ml-1"> .... 6292</span>
                                </span>
                              </div>
                              <div className="card-logo">
                                <img className="img-fluid" src={CardLogo} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mt-auto">
                            <img className="img-fluid" src={Chip} alt="" />
                            <h4 className="card-user">Jim Brown</h4>
                          </div>
                        </div>
                      </div>
                    </div> */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "84px",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <img
                            src={noCardLogo}
                            alt="ride"
                            style={{
                              width: "137px",
                              height: "104px",
                              // borderRadius: "50%",
                            }}
                          />
                        </div>

                        <div
                          style={{
                            color: "#828282",
                            fontFamily: "'Manrope', sans-serif !important",
                            fontSize: "16px",
                            marginTop: "30px",
                          }}
                        >
                          No saved cards
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl={6} className="pl-1 pr-0 mt-2 mt-lg-0">
                    <div className="h-100 d-box">
                      <div className="d-flex mb-2">
                        <h2 className="box-title ">
                          {" "}
                          Car details{" "}
                          <span className="text-gray">({driver?.cab?.name})</span>{" "}
                        </h2>
                      </div>
                      <div className="kyc-info">
                        <div className=" d-flex align-items-center">
                          <div className="mr-4">
                            <img
                              className="img-fluid max-img"
                              src={
                                driver?.car?.makerIcon
                                  ? driver?.car?.makerIcon
                                  : logo
                              }
                              alt="ride"
                            />
                          </div>
                          <div>
                            <p className="stats mb-1 arabic-fonts">
                              {driver?.car ? driver?.car?.vehicleMaker : "--"}{" "}
                              {driver?.car ? driver?.car?.vehicleModel : "--"}
                              <span className="text-gray">{`(${driver?.car ? driver?.car?.modelYear : "--"
                                })`}</span>
                            </p>
                            <p className="grey-heading mb-0">--</p>
                          </div>
                        </div>
                        <div className="line-break"></div>
                        <div className=" d-flex align-items-center">
                          <div className="w-50">
                            <p className="grey-heading">Seq. no.</p>
                            <p className="stats"> {driver?.carSequenceNo}</p>
                          </div>
                          <div>
                            <p className="grey-heading">Plate no.</p>
                            <p className="stats arabic-fonts">
                              {" "}
                              {driver?.car
                                ? `${driver?.car?.plateNumber} ${driver?.car?.plateText1} ${driver?.car?.plateText2} ${driver?.car?.plateText3}`
                                : "--"}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mt-3">
                          <div className="w-50">
                            <p className="grey-heading">License (Exp.)</p>
                            <p className="stats text-red">
                              {
                                driverDetail?.CitizenDLInfo?.licenseListList[1]
                                  ?.licssExpiryDateH?._text
                              }
                            </p>
                          </div>
                          <div>
                            <p className="grey-heading">Insurance (Exp.)</p>
                            <p className="stats"> --</p>
                          </div>
                        </div>
                        <div className="line-break"></div>
                        <div className=" d-flex align-items-center">
                          <div className="w-50">
                            <p className="grey-heading">Fuel type</p>
                            <p className="stats">--</p>
                          </div>
                          <div>
                            <p className="grey-heading">Seating capacity</p>
                            <p className="stats">--</p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mt-3">
                          <div className="w-50">
                            <p className="grey-heading">No. of cylinder</p>
                            <p className="stats text-red">--</p>
                          </div>
                          <div>
                            <p className="grey-heading">Displacement (cc)</p>
                            <p className="stats">--</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <Col xl={6} className="px-0 pl-0 pl-xl-2 mt-3 mt-xl-0">
                  <AppUsageChart activeRiderData={obj2} timeRange={timeRange} />
                </Col> */}
                </Row>

                {/* car deals section starting  */}
                {/* <Row
                  className="mt-2 driver-details bg-white px-0"
                  style={{
                    height: "auto",
                    padding: "24px",
                    borderRadius: "6PX",
                    marginRight: "-11px",
                    marginLeft: "-11px",
                  }}
                >
                  <Col xl={12}>
                    <h2 className="box-title mb-4">Car Deal Details</h2>

                    <Row>
                      <Col xl={6}>
                        <Doughnut
                          type="doughnut"
                          data={data}
                          plugins={plugins}
                          options={chartOptions}
                        />

                        
                        <div class="mt-24 d-flex align-items-baseline justify-content-around flex-wrap">
                          <div style={{ display: "flex" }}>
                            <div
                              className="green-box"
                              style={{ backgroundColor: "#00FF00" }}
                            ></div>
                            <div className="ml-2">
                              <div className="trips-title-label">Total paid</div>
                              <div
                                className="trips-count text-dark font-weight-bolder"
                                style={{ color: "rgb(0, 0, 0)" }}
                              >
                                SAR. 799
                              </div>
                            </div>
                          </div>

                          <div style={{ display: "flex" }}>
                            <div
                              className="green-box"
                              style={{ backgroundColor: "#000000" }}
                            ></div>
                            <div className="ml-2">
                              <div className="trips-title-label">
                                Total trips made
                              </div>
                              <div
                                className="trips-count text-dark font-weight-bolder"
                                style={{ color: "rgb(0, 0, 0)" }}
                              >
                                {" "}
                                SAR. 05
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col xl={6}>
                        <Doughnut
                          type="doughnut"
                          data={data}
                          plugins={plugins1}
                          options={chartOptions}
                        />

                        
                        <div class="mt-24 d-flex align-items-baseline justify-content-around flex-wrap">
                          <div style={{ display: "flex" }}>
                            <div
                              className="green-box"
                              style={{ backgroundColor: "#00FF00" }}
                            ></div>
                            <div className="ml-2">
                              <div className="trips-title-label">
                                Total trips made
                              </div>
                              <div
                                className="trips-count text-dark font-weight-bolder"
                                style={{ color: "rgb(0, 0, 0)" }}
                              >
                                05
                              </div>
                            </div>
                          </div>

                          <div style={{ display: "flex" }}>
                            <div
                              className="green-box"
                              style={{ backgroundColor: "#000000" }}
                            ></div>
                            <div className="ml-2">
                              <div className="trips-title-label">Trips left</div>
                              <div
                                className="trips-count text-dark font-weight-bolder"
                                style={{ color: "rgb(0, 0, 0)" }}
                              >
                                112
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row> */}

                {/* captains summary section started from here */}

                {/* <Row
                  className="mt-2 driver-details bg-white px-0"
                  style={{
                    height: "auto",
                    padding: "24px",
                    borderRadius: "6PX",
                    marginRight: "-11px",
                    marginLeft: "-11px",
                  }}
                >
                  <Col xl={12}>
                    <div className="d-flex align-items-center mb-5">
                      <div>
                        <h2 className="box-title mr-4">Captain summary</h2>
                      </div>
                      <div>
                        <div className="selectbox d-flex align-items-left">
                          <select className="select outline-none border-0 ">
                            <option value="day">Last 7 days</option>
                            <option value="week">By days</option>
                            <option value="month">By Week</option>
                            <option value="year">By Month</option>
                          </select>
                        </div>
                      </div>
                      <div style={{ marginLeft: "150px" }}>
                        <img src={cardIcon} alt="card icon" />
                      </div>
                    </div>

                    <div
                      className="my-5 ml-4"
                      style={{
                        position: "relative",
                        color: "#828282",
                        fontSize: "14px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          rotate: "-90deg",
                          top: "42%",
                          left: "-25px",
                        }}
                      >
                        SAR
                      </div>
                      <Line data={data1} />
                      <div className="d-flex justify-content-center align-items-center">
                        December 2022
                      </div>
                    </div>
                    <div
                      className="bg-light"
                      style={{
                        borderRadius: "6px",
                        padding: "16px",
                        marginTop: "30px",
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <div
                                class="green-box mr-3"
                                style={{ backgroundColor: "#13B542" }}
                              ></div>
                              <div class="trips-title-label mr-4">
                                Total Earning
                              </div>
                            </div>

                            <div
                              class="trips-count text-dark font-weight-bolder"
                              style={{ color: "rgb(0, 0, 0)" }}
                            >
                              13,568 SAR.
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <div
                                class="green-box mr-3"
                                style={{ backgroundColor: "#F1F1F1" }}
                              ></div>
                              <div class="trips-title-label mr-4">
                                Max Earning{" "}
                              </div>
                            </div>

                            <div
                              class="trips-count text-dark font-weight-bolder"
                              style={{ color: "rgb(0, 0, 0)" }}
                            >
                              3,568 SAR.
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <div
                                class="green-box mr-3"
                                style={{ backgroundColor: "#F1F1F1" }}
                              ></div>
                              <div class="trips-title-label mr-4">
                                Min Earning
                              </div>
                            </div>

                            <div
                              class="trips-count text-dark font-weight-bolder"
                              style={{ color: "rgb(0, 0, 0)" }}
                            >
                              1,568 SAR.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="bg-light"
                      style={{
                        borderRadius: "6px",
                        padding: "16px",
                        marginTop: "15px",
                        color: "#828282",
                      }}
                    >
                      Click on any date to see more info.
                    </div>
                  </Col>
                </Row> */}
                <Row
                  className="mt-2 driver-details bg-white px-0"
                  style={{
                    height: "auto",
                    padding: "24px",
                    borderRadius: "6PX",
                    marginRight: "-11px",
                    marginLeft: "-11px",
                    overflow: "hidden",
                  }}
                >
                  <Col xl={12}>
                    <div style={{ height: "500px" }}>
                      <TopupHistoryChart
                        activeRiderData={obj}
                        timeRange={timeRange}
                        riderId={driver?.externalId}
                      />
                    </div>
                  </Col>
                </Row>
                {/* <Row className="mt-3 driver-details px-1 row-flex">
                <Col xl={12} className="px-0 pl-0  mt-3 mt-xl-0">
                  <div style={{ height: "400px" }}>
                    <TopupHistoryChart
                      activeRiderData={obj}
                      timeRange={timeRange}
                    />
                  </div>
                </Col>
              </Row> */}

                <Row className="mt-2 driver-details px-1 row-flex">
                  <Col xl={12} className="px-0">
                    <Card className="py-3 h-100 card-121">
                      <DriverSubscriptionTransactionsTab
                        driverId={driverId}
                        dispatch={dispatch}
                      />
                    </Card>
                  </Col>
                </Row>

                <Row className="mt-2 driver-details px-1 row-flex">
                  <Col xl={12} className="px-0">
                    <Card className="py-3 h-100 card-121">
                      <DriverLog externalId={driver?.externalId} />
                    </Card>
                  </Col>
                </Row>

                {/* total trips */}
                {/* <Col xl={4} className="px-0 pl-0 pl-xl-2 mt-3 mt-xl-0">
          <TotalTrips
            activeDriverData={activeDriverData}
            captainData={rideViewData}
          />
        </Col> */}
              </Col>
              <Col xl={4}>
              <Row className="mt-2 driver-details px-1 row-flex">
                <Col xl={12} className="px-0">
                    <div className="d-box">
                      <div className="d-flex mb-3">
                        <h2 className="box-title"> Services </h2>
                        </div>
                        <div className="kyc-info">
                          <div className="d-flex align-items-center justify-content-between mt-3">
                          <div>
                          Own A Car
                          </div>
                          <div className="text-success font-weight-bold">Yes</div>
                          </div>

                          <div className="d-flex align-items-center justify-content-between mt-3">
                          <div>
                          Ride A Ride
                          </div>
                          <div className="text-dark font-weight-bold">No</div>
                          </div>

                          <div className="d-flex align-items-center justify-content-between mt-3">
                          <div>
                          Pickup
                          </div>
                          <div className="text-dark font-weight-bold">No</div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>  
                <Row className="mt-2 driver-details px-1 row-flex">
                  <Col xl={12} className="px-0">
                    <div className="d-box">
                      <div className="d-flex mb-3">
                        <h2 className="box-title"> KYC Details </h2>
                        <span className="ml-3">
                          {driver?.additionalInfo ? (
                            <span className="status-text">Completed</span>
                          ) : (
                            <span className="status-text-inactive">
                              Incompleted
                            </span>
                          )}
                        </span>
                      </div>
                      {driver?.additionalInfo ? (
                        <div className="kyc-info">
                          KYC details completed successfully.
                        </div>
                      ) : (
                        <div className="kyc-info">
                          KYC details not completed.
                        </div>
                      )}

                      {/* <div className="text-right mt-3">
                        {driver?.additionalInfo ? (
                          ""
                        ) : (
                          <Link className="text-link" to="#">
                            Initiate KYC
                          </Link>
                        )}
                      </div> */}
                      <div class="text-right mt-3">
                        {/* <a
                          class="text-link"
                          href="/driver-details/details/05874635-4942-4f19-9b89-bc50406fd72b"
                        >
                          Initiate KYC
                        </a> */}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2 driver-details px-1 row-flex">
                  <Col xl={12} className="px-0">
                    <div className="d-box">
                      <div className="d-flex mb-2">
                        <h2 className="box-title "> WASL Status </h2>
                      </div>
                      <div className="kyc-info d-flex align-items-center">
                        <div style={{ width: "50%" }}>
                          <p className="grey-heading">Status</p>
                          {isStatus === 1 ? (
                            <p className="stats">
                              {" "}
                              <span className="circle-indicator circle-green"></span>{" "}
                              Active
                            </p>
                          ) : isStatus === 0 ? (
                            <p className="stats">
                              {" "}
                              <span className="circle-indicator circle-red"></span>{" "}
                              Inactive
                            </p>
                          ) : (
                            <p className="stats">
                              {" "}
                              <span className="circle-indicator circle-red"></span>{" "}
                              Rejected
                            </p>
                          )}
                        </div>

                        <div>
                          <p className="grey-heading">Expiry Date</p>
                          <p className="stats">
                            {driver?.eligibilityExpiryDate}
                          </p>
                        </div>

                        <div></div>
                      </div>
                      {isStatus > 1 ? (
                        <div style={{ width: "50%" }}>
                          <p className="grey-heading">Reason</p>

                          <p className="stats">
                            {" "}
                            <span>{driver?.WASLRejectionReasons}</span>{" "}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="text-right mt-3">
                        {isStatus !== 1 ? (
                          <Link
                            className="text-link"
                            to="#"
                            onClick={onChangeStatus}
                          >
                            Activate By Admin
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="text-right mt-3">
                        {
                          <Link
                            className="text-link"
                            to="#"
                            onClick={onReinitializeStatus}
                          >
                            Reintial WASL
                          </Link>
                        }
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* <Row className="mt-2 driver-details px-1 row-flex">
                  <Col xl={12} className="px-0">
                    <div className="d-box">
                      <div className="d-flex mb-2">
                        <h2 className="box-title "> Contract Tajeer </h2>
                      </div>
                      <div className="kyc-info d-flex align-items-center">
                        <div style={{ width: "50%" }}>
                          <p className="grey-heading">Status</p>
                          <p className="stats">
                            {" "}
                            <span className="circle-indicator circle-pending"></span>{" "}
                            Pending
                          </p>

                          <p className="stats">
                              {" "}
                              <span className="circle-indicator circle-red"></span>{" "}
                              Inactive
                            </p>
                        </div>
                        <div>
                          <p className="grey-heading">Signed?</p>
                          <p className="stats">No</p>
                        </div>
                        <div></div>
                      </div>
                      <div className="text-right mt-3 font-weight-bold">
                        <Link className="text-link" to="#">
                          <button
                            className="btn btn-success text-white"
                            style={{ width: "100%" }}
                          >
                            
                            Download Contract
                          </button>

                          <p className="text-link">Initiate TAMM OTP</p>
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row> */}

                <Row className="mt-2 driver-details px-1 row-flex">
                  <Col xl={12} className="px-0">
                    <div className="d-box">
                      <div className="d-flex mb-3">
                        <h2 className="box-title"> Dakhli Details </h2>
                      </div>
                      <TabView
                        className="mt-2"
                        activeIndex={activeIndex}
                        onTabChange={(e) => setActiveIndex(e.index)}
                      >
                        <TabPanel header="GOSI">
                          <div className="tab-content mt-3 ">
                            <div
                              className="kyc-info tab-pane fade active show"
                              id="GOSI"
                              style={{ padding: "24px" }}
                            >
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ marginBottom: "24px" }}
                              >
                                <div>Basic wage</div>
                                <div className="text-dark font-weight-bold">
                                  {dakhliDetailGossi?.basicWage
                                    ? dakhliDetailGossi.basicWage
                                    : "-"}
                                </div>
                              </div>

                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ marginBottom: "24px" }}
                              >
                                <div>Housing Allowance</div>
                                <div className="text-dark font-weight-bold">
                                  {dakhliDetailGossi?.housingAllowance
                                    ? dakhliDetailGossi.housingAllowance
                                    : "-"}
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ marginBottom: "24px" }}
                              >
                                <div>Other Allowance</div>
                                <div className="text-dark font-weight-bold">
                                  {dakhliDetailGossi?.otherAllowance}
                                </div>
                              </div>
                              <div className="tab-content mt-3 ">
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ marginBottom: "24px" }}
                                >
                                  <div>Employer Name</div>
                                  <div className="text-dark font-weight-bold arabic-fonts">
                                    {dakhliDetailGossi?.employerName
                                      ? dakhliDetailGossi.employerName
                                      : "-"}
                                  </div>
                                </div>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ marginBottom: "24px" }}
                                >
                                  <div>Working Months</div>
                                  <div className="text-dark font-weight-bold">
                                    {dakhliDetailGossi?.workingMonths
                                      ? dakhliDetailGossi.workingMonths
                                      : "-"}
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                  <div>Employment Status</div>
                                  <div className="text-dark font-weight-bold">
                                    {dakhliDetailGossi?.employmentStatus
                                      ? dakhliDetailGossi.employmentStatus
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPanel>
                        <TabPanel header="GOVT">
                          <div className="tab-content mt-3 ">
                            <div
                              className="kyc-info tab-pane fade active show"
                              id="GOVT"
                              style={{ padding: "24px" }}
                            >
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ marginBottom: "24px" }}
                              >
                                <div>Employee Name</div>
                                <div className="text-dark font-weight-bold">
                                  {dakhliDetailGovt?.personalInfo
                                    ?.employeeNameEn
                                    ? dakhliDetailGovt.personalInfo
                                      .employeeNameEn
                                    : "-"}
                                </div>
                              </div>

                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ marginBottom: "24px" }}
                              >
                                <div>Agency Code</div>
                                <div className="text-dark font-weight-bold">
                                  {dakhliDetailGovt?.employerInfo?.agencyCode
                                    ? dakhliDetailGovt.employerInfo.agencyCode
                                    : "-"}
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ marginBottom: "24px" }}
                              >
                                <div>Agency Name</div>
                                <div className="text-dark font-weight-bold">
                                  {dakhliDetailGovt?.employerInfo?.agencyName
                                    ? dakhliDetailGovt.employerInfo.agencyName
                                    : "-"}
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ marginBottom: "24px" }}
                              >
                                <div>Account no.</div>
                                <div
                                  className="text-dark font-weight-bold"
                                  style={{
                                    wordBreak: "break-all",
                                    marginLeft: "50px",
                                  }}
                                >
                                  {dakhliDetailGovt?.bankInfo?.accountNumber
                                    ? dakhliDetailGovt.bankInfo.accountNumber
                                    : "-"}
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ marginBottom: "24px" }}
                              >
                                <div>Bank Code</div>
                                <div className="text-dark font-weight-bold">
                                  {dakhliDetailGovt?.bankInfo?.bankCode
                                    ? dakhliDetailGovt.bankInfo.bankCode
                                    : "-"}
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                <div>Bank Name</div>
                                <div className="text-dark font-weight-bold">
                                  {dakhliDetailGovt?.bankInfo?.bankName
                                    ? dakhliDetailGovt.bankInfo.bankName
                                    : "-"}
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ marginBottom: "24px" }}
                              >
                                <div>Employee Job No.</div>
                                <div className="text-dark font-weight-bold">
                                  {dakhliDetailGovt?.employmentInfo
                                    ?.employeeJobNumber
                                    ? dakhliDetailGovt.employmentInfo
                                      ?.employeeJobNumber
                                    : "-"}
                                </div>
                              </div>

                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ marginBottom: "24px" }}
                              >
                                <div>Employee Job Title</div>
                                <div className="text-dark font-weight-bold">
                                  {dakhliDetailGovt?.employmentInfo
                                    ?.employeeJobTitle
                                    ? dakhliDetailGovt.employmentInfo
                                      .employeeJobTitle
                                    : "-"}
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ marginBottom: "24px" }}
                              >
                                <div>Agency Emp. Date</div>
                                <div className="text-dark font-weight-bold">
                                  {dakhliDetailGovt?.employmentInfo
                                    ?.agencyEmploymentDate
                                    ? dakhliDetailGovt.employmentInfo
                                      .agencyEmploymentDate
                                    : "-"}
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ marginBottom: "24px" }}
                              >
                                <div>Pay Month</div>
                                <div className="text-dark font-weight-bold">
                                  {dakhliDetailGovt?.payslipInfo?.payMonth
                                    ? dakhliDetailGovt.payslipInfo.payMonth
                                    : "-"}
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ marginBottom: "24px" }}
                              >
                                <div>Basic Salary</div>
                                <div className="text-dark font-weight-bold">
                                  {dakhliDetailGovt?.payslipInfo?.basicSalary
                                    ? dakhliDetailGovt.payslipInfo.basicSalary
                                    : "-"}
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                <div>Total Allowance</div>
                                <div className="text-dark font-weight-bold">
                                  {dakhliDetailGovt?.payslipInfo?.totalAllownces
                                    ? dakhliDetailGovt.payslipInfo
                                      .totalAllownces
                                    : "-"}
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                <div>Total Deductions</div>
                                <div className="text-dark font-weight-bold">
                                  {dakhliDetailGovt?.payslipInfo
                                    ?.totalDeductions
                                    ? dakhliDetailGovt.payslipInfo
                                      .totalDeductions
                                    : "-"}
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                <div>Net Salary</div>
                                <div className="text-dark font-weight-bold">
                                  {dakhliDetailGovt?.payslipInfo?.netSalary
                                    ? dakhliDetailGovt.payslipInfo.netSalary
                                    : "-"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPanel>
                      </TabView>
                    </div>
                  </Col>

                  {/* dakhi section end  */}
                </Row>
                <Row
                  className="mt-2 driver-details px-1 row-flex"
                  style={{ height: "auto" }}
                >
                  <Col xl={12} className="px-0">
                    {driver?.totalTrips +
                      driver?.tripsCancelled +
                      driver?.tripsDeclined ===
                      0 ? (
                      <EmptyTotalTrips
                        tripsData={emptyTrips}
                        captainData={driver}
                        emptyTrips={emptyTrips}
                      />
                    ) : (
                      <TotalTrips
                        tripsData={totalTrips}
                        captainData={driver}
                        emptyTrips={emptyTrips}
                      />
                    )}
                  </Col>
                </Row>

                {/* customer care ticket  */}
                <Row
                  className="mt-2 driver-details px-1 row-flex"
                  style={{ height: "auto" }}
                >
                  {console.log('customer id kdkd ',driver?.externalId)}
                  <Col xl={12} className="px-0">
                    <CustomerCare customerId = {driver?.externalId}/>
                  </Col>
                </Row>

                {/* Driver cancelation reasons  */}
                <Row
                  className="mt-2 driver-details px-1 row-flex"
                  style={{ height: "auto" }}
                >
                  <Col xl={12} className="px-0">
                    {driver?.externalId&&
                    <CancelReason customerId={driver?.externalId}/>
                    }
                  </Col>
                </Row>


                <Row className="mt-2 driver-details px-1 row-flex">
                  <Col xl={12} className="px-0">
                    <div className=" h-100 d-box">
                      <h2 className="box-title mb-4">Live Location</h2>
                      {/* <h6 className="box-title mb-4">Last updated Location  </h6> */}
                      <h6 className="box-title mb-4">
                        {" "}
                        {moment(userStatus?.userLoc?.updatedAt).format(
                          "DD/MM/YYYY hh:mm:ss A"
                        )}{" "}
                      </h6>
                      <TrackDrivers
                        lat={driver?.latitude}
                        long={driver?.longitude}
                      />
                      {/* <div className="header-subcription">Saved Cards</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "84px",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <img
                        src={noCardLogo}
                        alt="ride"
                        style={{
                          width: "137px",
                          height: "104px",
                          // borderRadius: "50%",
                        }}
                      />
                    </div>

                    <div
                      style={{
                        color: "#828282",
                        fontFamily: "'Manrope', sans-serif !important",
                        fontSize: "16px",
                        marginTop: "30px",
                      }}
                    >
                      No saved cards
                    </div>
                  </div> */}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2 driver-details px-1 row-flex">
                  <Col xl={12} className="px-0">
                    <div className="d-box">
                      <div className="d-flex mb-3">
                        <h2 className="box-title "> Other Reasons </h2>
                      </div>
                      <div className="kyc-info">
                        <span className="pr-3">
                          {" "}
                          <i
                            className="fa fa-info-circle"
                            aria-hidden="true"
                          ></i>{" "}
                        </span>{" "}
                        NA
                      </div>
                      <div className="text-right mt-4"></div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* <Card className="card-custom profile-top mb-4">
            <Row>
              <Col md={6} xl={4} className="d-flex align-items-center">
                <div className="items-profile-wrap w-100">
                  <div className="item-img">
                    <img
                      src={
                        driver?.profileImage
                          ? driver.profileImage
                          : PlaceHoderImage
                      }
                      alt="profile"
                    />
                  </div>
                  <div className="item-right">
                    <div className="item-name">{driver.driverName}</div>
                    <Rating
                      fractions={2}
                      emptySymbol="fa fa-star-o fa-sm star"
                      fullSymbol="fa fa-star fa-sm star"
                      initialRating={driver.overallRating}
                      readonly
                    />
                    <div className="item-row">
                      <label className="item-label">Full Name</label>
                      <div className="item-value">{driver.driverName}</div>
                    </div>
                    <div className="item-row">
                      <label className="item-label">Mobile No.</label>
                      <div className="item-value">{driver.mobileNo}</div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} xl={4} className="d-flex align-items-center">
                <div className="items-pi-details">
                  <div className="item-row">
                    <label className="item-label">Address</label>
                    <div className="item-value">{driver.address1}</div>
                  </div>
                  <div className="item-row">
                    <label className="item-label">Email ID</label>
                    <div className="item-value">{driver.emailId}</div>
                  </div>
                  <div className="item-row">
                    <label className="item-label">DOB</label>
                    <div className="item-value">
                      {/* {moment(driver.dateOfBirth).format("DD-MM-YYYY")} */}
            {/* {driver?.dateOfBirth}
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                md={6}
                xl={4}
                className="pt-3 pt-xl-0 d-flex align-items-center"
              >
                <div className="">
                  <div className="d-flex align-items-center ">
                    <p className="mb-2 text-muted mr-2">Account Status :</p>
                    <p className="mb-2 text-success">
                      {driver.approved ? "Active" : "Inactive"}
                      {/* <span className="input-edit-icon pl-2" onClick={handleShow}>
                    <PencilSquare />
                  </span> */}
            {/* </p>
                  </div>
                  <div className="d-flex align-items-center ">
                    <p className="mb-2 text-muted mr-2">
                      Subscription Status :
                    </p>
                    <p className="mb-2 text-success">
                      {driver?.subscription?.status === 1
                        ? "Active"
                        : "Inactive"}
                    </p>
                  </div>
                  <div className="d-flex align-items-center ">
                    <p className="mb-2 text-muted mr-2">
                      {driver?.subscription?.status === 1
                        ? "Expiry Date :"
                        : "Expired Date :"}
                    </p>
                    <p className="mb-2 ">
                      {driver?.subscription?.dueDate
                        ? moment(driver?.subscription?.dueDate).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </p>
                  </div>
                  <div className="">
                    <Button
                      label="Primary"
                      className="p-button-raised"
                      onClick={handleShow}
                    >
                      {driver.approved ? "Block Driver" : "Unblock Driver"}
                    </Button>
                  </div>
        
          {/* {driver.approved ? (
              <div className="items-active">
                <div className="active-fnt">Active</div>
              </div>
            ) : (
              <div className="items-inactive">
                <div className="active-fnt">Inactive</div>
              </div>
            )} */}
            {/* </Col>
            </Row>
          </Card>
          <Row>
            <Col xl={8}>
              <Card className="card-driver-mode mb-4">
                <Row>
                  <Col>
                    <div className="item-icon-left">
                      <div className="item-icon">
                        <img src={driveractive} alt="group" />
                      </div>
                      <div className="item-title">Driver mode</div>
                      <div className="item-text">
                        {driver.driverModeSwitch
                          ? " Driver Mode is on. Driver can now accept ride and delivery requests."
                          : "Activating driver mode will let you accept ride requests from other users"}
                      </div>
                    </div>
                  </Col>
                  <Col sm="auto" className="pt-3 pt-sm-0">
                    <div className="item-active-text">
                      <div className="item-text">
                        {driver.driverModeSwitch ? "On" : "Off"}
                      </div>
                      <label className="switch">
                        <input
                          className="checkInput"
                          type="checkbox"
                          defaultChecked={driver.driverModeSwitch}
                          disabled
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </Col>
                </Row>
              </Card>
              <Card className="card-custom card-spec mb-4">
                <div className="spec-title">{driver.externalId}</div>
                <div className="spec-body">
                  <div className="items-spec">
                    <div className="item-label">Chassis Number</div>
                    <div className="item-value">
                      {driver.car?.chassisNumber
                        ? driver.car?.chassisNumber
                        : "-"}
                    </div>
                  </div>
                  <div className="items-spec">
                    <div className="item-label">Car Plate No.</div>
                    <div className="item-value">{driver.carPlateNo}</div>
                  </div>
                  <div className="items-spec">
                    <div className="item-label">Car License Type</div>
                    <div className="item-value">
                      {carLicenceTyps[driver.carLicenceType - 1]}
                    </div>
                  </div>
                  <div className="items-spec">
                    <div className="item-label">Car Sequence No.</div>
                    <div className="item-value">{driver.carSequenceNo}</div>
                  </div>
                  <div className="items-spec">
                    <div className="item-label">Car Category</div>
                    <div className="item-value">
                      {driver.cab?.name ? driver.cab?.name : "-"}
                    </div>
                  </div>

                  <div className="items-spec">
                    <div className="item-label">Driving Mode</div>
                    <div className="item-value">
                      {driver.drivingModes.length === 2 && "Both"}
                      {driver.drivingModes.length === 1 &&
                      driver.drivingModes[0]["drivingMode"] === 1
                        ? "Passenger Ride"
                        : "Delivery"}
                    </div>
                  </div>
                  <div className="items-spec">
                    <div className="item-label">Vehicle Maker</div>
                    <div className="item-value">
                      {driver.car?.vehicleMakerEnglish
                        ? driver.car?.vehicleMakerEnglish
                        : driver.car?.vehicleMaker
                        ? driver.car?.vehicleMaker
                        : "-"}
                    </div>
                  </div>
                  <div className="items-spec">
                    <div className="item-label">Vehicle Model</div>
                    <div className="item-value">
                      {driver.car?.vehicleModelEnglish
                        ? driver.car?.vehicleModelEnglish
                        : driver.car?.vehicleModel
                        ? driver.car?.vehicleModel
                        : "-"}
                    </div>
                  </div>
                  <div className="items-spec">
                    <div className="item-label">Model Year</div>
                    <div className="item-value">
                      {driver.car?.modelYear ? driver.car?.modelYear : "-"}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xl={4}>
              <Row>
                <Col md={6} xl={12}>
                  <Card className="card-custom card-trips mb-4">
                    <div className="trip-title">
                      Total Trips
                      <span>
                        {driver.totalTrips +
                          driver.tripsCancelled +
                          driver.tripsDeclined}
                      </span>
                    </div>
                    <div className="trip-body">
                      <div className="items-trips">
                        <div className="item-icon">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/icon-check.svg"
                            }
                            alt=""
                          />
                        </div>
                        <Row className="h-100 w-100 mx-0 justify-content-between">
                          <Col className="col pl-2 pr-0">Completed</Col>
                          <Col className="col-auto pl-2 pr-0 text-right">
                            <b>{driver.totalTrips}</b>
                          </Col>
                        </Row>
                      </div>
                      <div className="items-trips">
                        <div className="item-icon">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/icon-close.svg"
                            }
                            alt=""
                          />
                        </div>
                        <Row className="h-100 w-100 mx-0 justify-content-between">
                          <Col className="col pl-2 pr-0">Cancelled</Col>
                          <Col className="col-auto pl-2 pr-0 text-right">
                            <b>{driver.tripsCancelled}</b>
                          </Col>
                        </Row>
                      </div>
                      <div className="items-trips">
                        <div className="item-icon">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/icon-calendar.svg"
                            }
                            alt=""
                          />
                        </div>
                        <Row className="h-100 w-100 mx-0 justify-content-between">
                          <Col className="col pl-2 pr-0">Declined</Col>
                          <Col className="col-auto pl-2 pr-0 text-right">
                            <b>{driver.tripsDeclined}</b>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </Col>

                <Col md={6} xl={12}>
                  <Card className="card-custom card-rating">
                    <Row className="mx-n1 align-items-center">
                      <Col md={4} className="px-1">
                        <div className="heading-rating">
                          {driver?.overallRating
                            ? (driver?.overallRating).toFixed(1)
                            : "0"}
                        </div>
                        <Rating
                          fractions={2}
                          emptySymbol="fa fa-star-o fa-sm star"
                          fullSymbol="fa fa-star fa-sm star"
                          initialRating={driver.overallRating}
                          readonly
                        />
                        <div className="review-text">
                          {driver.overallReviews + " "}
                          reviews
                        </div>
                      </Col>

                      <Col md={8} className="px-1 mt-n4">
                        {driver.ratingCounts &&
                          Object.keys(driver.ratingCounts)
                            .reverse()
                            .map((rating) => {
                              return (
                                <Row className="mx-n1 mt-4">
                                  <Col md={6} className="px-1">
                                    <span>
                                      {" "}
                                      {rating} star (
                                      {driver.ratingCounts[rating]})
                                    </span>
                                  </Col>
                                  <Col md={6} className="px-1 progress-wrapper">
                                    <div className="progress">
                                      <div
                                        className="progress-bar progress-bar-success"
                                        role="progressbar"
                                        style={{
                                          width:
                                            (driver.ratingCounts[rating] *
                                              100) /
                                              driver.overallReviews +
                                            "%",
                                        }}
                                      ></div>
                                    </div>
                                  </Col>
                                </Row>
                              );
                            })}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Modal show={show} onHide={handleClose} centered>
            <Formik
              initialValues={{
                blockedReason: "",
              }}
              validationSchema={driver.approved ? formValidation : null}
              onSubmit={changeDriverStatusAPI}
            >
              {() => {
                return (
                  <Form>
                    <Modal.Header closeButton>
                      <Modal.Title className="title">
                        Are you sure you want to{" "}
                        {driver.approved ? "block" : "unblock"} this driver?
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="padding-header-footer">
                      {driver.approved ? (
                        <div className="form-group form-label-group mt-1">
                          <Field
                            id="blockedReason"
                            type="text"
                            name="blockedReason"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Enter Reason"
                          />
                          <label htmlFor="blockedReason">Enter Reason</label>
                          <ErrorMessage
                            name="blockedReason"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      ) : null}
                    </Modal.Body>
                    <Modal.Footer className="padding-header-footer mb-3">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isDisable}
                        className="save-button btn-save border-radius-12"
                      >
                        <span className="btn-text">
                          {driver.approved ? "Block" : "Unblock"}{" "}
                        </span>
                      </Button>
                    </Modal.Footer>
                  </Form>
                );
              }}
            </Formik>
          </Modal> */}
          </Col>{" "}
          {/* <Modal
            className="Create-Ticket"
            show={show}
            onHide={handleClose}
            centered
            backdrop="static"
            keyboard={false}
          >
            <Formik>
              {({ handleBlur, setFieldValue, values }) => {
                return (
                  <Form>
                    <Modal.Header>
                      <Modal.Title className="modal-title">
                        Create New Ticket
                      </Modal.Title>
                      <div className="divclose" onClick={handleClose}>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </Modal.Header>
                    <Modal.Body className="mt-24">
                      <Row>
                        <Col md={12}>
                          <div className="mb-3 form-label-group ">
                            <Select
                              className="height-50 br-10 bg-color-select"
                              placeholder="Ticket Type"
                              name="ticket-type"
                              styles={colourStyles}
                              onBlur={() => {
                                handleBlur({ target: { name: "ticket-type" } });
                              }}
                              options={ticketType}
                            />
                            <label>Ticket Type</label>
                            <ErrorMessage
                              name="ticket-type"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="form-group form-label-group">
                            <Field
                              id="content"
                              type="text"
                              as="textarea"
                              name="comment"
                              className="shadow-none form-control modal-input br-10"
                              placeholder="Name"
                            />
                            <label htmlFor="content">Comment</label>
                            <ErrorMessage
                              name="comment"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <Button
                            variant="primary"
                            type="submit"
                            className="btn-save btn-block"
                          >
                            Create Ticket
                          </Button>
                        </Col>
                      </Row>
                    </Modal.Body>
                  </Form>
                );
              }}
            </Formik>
          </Modal> */}
          <Modal
            className="Create-Ticket"
            show={showTicket}
            onHide={handleCloseTicket}
            centered
            backdrop="static"
            keyboard={false}
          >
            <Formik
              initialValues={{
                ticket_type: "",
                name: "",
                phone: "",
                comment: "",
                assign_type: "",
              }}
              onSubmit={async (values) => {
                console.log(values)
                isSubmitting(true);
                let dept_id = values.ticket_type;
                let description = values.comment;
                let name = `${driverDetail?.CitizenDLInfo?.englishFirstName?._text} ${driverDetail?.CitizenDLInfo?.englishSecondName?._text}  ${driverDetail?.CitizenDLInfo?.englishLastName?._text}`;
                let phone = driver?.mobileNo;
                let assign_type = ticketSelectionType.value;
                const requestOptions = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "Access-Control-Allow-Origin": "*",
                  },
                  body: JSON.stringify({
                    category_id: dept_id,
                    description: description,
                    secret_key: secret_key,
                    name: name,
                    contact_no: phone,
                    type: assign_type,
                    email: employeeEmail
                  }),
                };
                const response = await fetch(_saveTicketURL, requestOptions);
                let final_data = await response.json();
                if (final_data.success === true)
                  toast.success("Ticket created successfully!");
                else toast.error("Opsss! Ticket not created.");
                isSubmitting(false);
                handleCloseTicket();
              }}
            >
              {({ handleBlur, setFieldValue, values }) => {
                return (
                  <Form>
                    <Modal.Header>
                      <Modal.Title className="modal-title">
                        Create New Ticket
                      </Modal.Title>
                      <div className="divclose" onClick={handleCloseTicket}>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </Modal.Header>
                    <Modal.Body className="mt-24">
                      <Row>
                        <Col md={12}>
                          <div className="mb-3 form-label-group ">
                            <Select
                              className="height-50 br-10 bg-color-select"
                              placeholder="Ticket Type"
                              name="ticket_type"
                              styles={colourStyles}
                              onBlur={() => {
                                handleBlur({ target: { name: "ticket_type" } });
                              }}
                              onChange={async (selectedOption) =>
                                await setFieldValue(
                                  "ticket_type",
                                  selectedOption.value
                                )
                              }
                              options={ticketTypeOptions}
                            />
                            <label>Ticket Type</label>
                            <ErrorMessage
                              name="ticket_type"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3 form-label-group ">
                            <Select
                              className="height-50 br-10 bg-color-select"
                              placeholder="Ticket Type"
                              name="assign_type"
                              styles={colourStyles}
                              onBlur={() => {
                                handleBlur({ target: { name: "assign_type" } });
                              }}
                              // onChange={async (selectedOption) =>
                              //   await setFieldValue(
                              //     "assign_type",
                              //     selectedOption.value
                              //   )
                              // }s
                              menuIsOpen={false}
                              // options={assignType}
                              // setFieldValue()
                              value={ticketSelectionType}
                            />
                            <label>User Type</label>
                            <ErrorMessage
                              name="assign_type"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="form-group form-label-group">
                            <Field
                              id="content"
                              type="text"
                              name="name"
                              value={`${driverDetail?.CitizenDLInfo?.englishFirstName?._text} ${driverDetail?.CitizenDLInfo?.englishSecondName?._text}  ${driverDetail?.CitizenDLInfo?.englishLastName?._text}`}
                              disabled
                              className="shadow-none form-control modal-input br-10"
                              placeholder="Name"
                            />
                            <label htmlFor="content">Name</label>
                            <ErrorMessage
                              name="Name"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="form-group form-label-group">
                            <Field
                              id="content"
                              type="number"
                              name="phone"
                              value={driver?.mobileNo}
                              disabled
                              className="shadow-none form-control modal-input br-10"
                              placeholder="Phone"
                            />
                            <label htmlFor="content">Contact</label>
                            <ErrorMessage
                              name="Phone"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="form-group form-label-group">
                            <Field
                              id="content"
                              type="text"
                              as="textarea"
                              name="comment"
                              className="shadow-none form-control modal-input br-10"
                              placeholder="Name"
                            />
                            <label htmlFor="content">Comment</label>
                            <ErrorMessage
                              name="comment"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <Button
                            disabled={submitting}
                            variant="primary"
                            type="submit"
                            className="btn-save btn-block"
                          >
                            {submitting && (
                              <span className="spinner-grow spinner-grow-sm"></span>
                            )}
                            Create Ticket
                          </Button>
                        </Col>
                      </Row>
                    </Modal.Body>
                  </Form>
                );
              }}
            </Formik>
          </Modal>
          <Modal
            className="modal-promotion"
            show={showProfile}
            size="md"
            onHide={profileHandleClose}
            centered
            backdrop="static"
            keyboard={false}
          >
            <Formik
              initialValues={{ ...updateUserProfile }}
              // validationSchema={
              //   selectedOption.value === 1
              //     ? validationSchemaAmount
              //     : validationSchemaPercentage
              // }
              onSubmit={updateProfile}
            >
              {({ handleBlur, setFieldValue, values }) => {
                return (
                  <Form>
                    <Modal.Header className="flex-modal-header">
                      <Modal.Title className="modal-title">
                        Edit User Details
                      </Modal.Title>
                      <div class="divclose" onClick={profileHandleClose}>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </Modal.Header>
                    <Modal.Body className="mt-24">
                      <Row className="justify-content-center">
                        <Col md={12}>
                          <div className="form-group form-label-group mb-4">
                            <Field
                              id="email"
                              type="email"
                              name="email"
                              className="shadow-none form-control modal-input br-10"
                              placeholder="Email ID"
                              value={emailValue}
                              onChange={(e) => {
                                setEmailValue(e.target.value);
                              }}
                            />
                            <label htmlFor="email">Email ID</label>
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <PhoneInput
                            countryCallingCodeEditable={false}
                            className="d-flex"
                            international
                            defaultCountry="SA"
                            name="mobileNo"
                            required
                            value={mobleNo}
                            onChange={setMoblNo}
                          />

                          {validNumber ? (
                            ""
                          ) : (
                            <div className="invalid-feedback text-danger">
                              phone number is incomplete
                            </div>
                          )}
                        </Col>
                        <Col md={12} className="mt-3">
                          {validNumber | emailValidity ? (
                            <Button
                              variant="primary"
                              type="submit"
                              className="btn-save btn-block "
                            >
                              Update
                            </Button>
                          ) : (
                            <Button
                              disabled
                              variant="primary"
                              type="submit"
                              className="btn-save btn-block "
                            >
                              Update
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Modal.Body>
                  </Form>
                );
              }}
            </Formik>
          </Modal>
        </>
      ) : (
        <div className="loader">
          <i className="fa fa-spinner"></i>
        </div>
      )}
    </div>
  );
};

export default React.memo(DriverDetailsTab);
