import { Tab } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Tabs } from "react-bootstrap";
import SubscriptionsPackage from "../components/Subscriptions/SubscriptionsPackage";
import ExpiredSubscriptionsListingTable from "../components/Subscriptions/ExpiredSubscriptionsListingTable";
import DriverEarnings from "../components/accountings/DriverEarnings";
import Main from "../components/accountings/Main";
import DriverSubscription from "../components/accountings/DriverSubscription";
import Tax from "../components/accountings/Tax";
import Topups from "../components/accountings/Topups";
import Fees from "../components/accountings/Fees";
import NotificationPushPage from "./NotificationPushPage";
import NotificationSMSPage from "./NotificationSMSPage";
import NotificationEmailPage from "./NotificationEmailPage";
import TripsTabs from "./TripsTabs";
import LocationNav from "../components/Dashboard_2/LocationNav";
import Promotions from "../components/PromotionalCoupons/Promotions";

let tabsData = [{
  title: "Pushed",
  key: "notification-push",
  Component: NotificationPushPage,
}, {
  title: "Sms",
  key: "notification-sms",
  Component: NotificationSMSPage,
}, {
  title: "Email",
  key: "notification-emails",
  Component: NotificationEmailPage,
}
],
  tripTabs = ['notification-push', 'notification-sms', 'notification-emails']

let RideNotification = (props) => {
  let [tab, setTab] = useState("notification"),
    permissions = JSON.parse(atob(localStorage.getItem("access")));
  const refreshData = (refresh) => {

  }
  return (
    <>
      <div className="rider-listing">
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <span className="title">Communications</span>
          <LocationNav refreshData={refreshData} />
        </div>

        <Tabs
          id="controlled-tab-example"
          className="mt-30"
          activeKey={tab}
          onSelect={(tab) => setTab(tab)}
        >
          {/* {permissions.search("subscription-transaction-active-list") > -1 && (
            // <Tab eventKey="active" title="Active Subscriptions ">
            //   {tab === "active" && <ActiveSubscriptionsListingTable />}
            // </Tab>
          )} */}
          {/* {
            <Tab eventKey="rider" title="Rider">
              {tab === "rider" && <ExpiredSubscriptionsListingTable />}
            </Tab>
          } */}


          {/* {permissions.find(a => a.includes('/notification/notification-push')) && */}
          <Tab eventKey="notification" title="Notification">
            {tab === "notification" && <TripsTabs base='communication' tripTabs={tripTabs} tabsData={tabsData} type="notification" locationData={props} />}
          </Tab>
          <Tab eventKey="marketing" title="Marketing">
            {tab === "marketing" && <Promotions />}
          </Tab>
          {/* } */}
          {/* {permissions.find(a => a.includes('/notification/notification-sms')) &&
            <Tab eventKey="notification-sms" title="SMS">
              {tab === "notification-sms" && <NotificationSMSPage />}
            </Tab>
          }
          {permissions.find(a => a.includes('/notification/notification-emails')) &&
            <Tab eventKey="notification-emails" title="Email">
              {tab === "notification-emails" && <NotificationEmailPage />}
            </Tab>
          } */}

        </Tabs>
        
      </div>
    </>
  );
};

export default React.memo(RideNotification);
