import React, { useEffect, useState } from "react";
import Calendar from "./Calendar";
import APIService from "../../services/APIService";
import moment from "moment";
function TodayTripStatus(props) {
  const { activeDriversPercentage, waslApprovedCount, topCardDriver ,locationDataset, rideNamesData} = props;
  const [captainData, setCaptainData] = useState({})
  const [waslData, setWaslData] = useState({})
  const [applyFilter,setApplyFilter] = useState(false);
  const [ridesNames, setRidesNames] = useState([]);
  const [dailyTripData, setDailyTripsData] = useState({});
  const [selectedValue, setSelectedValue] = useState(ridesNames[0]?.name); // Set the default value to the id of the first item
  const [locationData,setLocationsData]=useState(null);
  const [startDate,setStartDate] = useState('');
  const [endDate,setEndDate] = useState('');
  const [selectedCountry, setSelectedCountry] = useState({
    id: "",
    name: ""
  });

  const [selectedCity, setSelectedCity] = useState({
    id: "",
    name: ""
  });

  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const getTimeRange=(range)=>{
    setStartDate(range.sDate);
    setEndDate(range.eDate);
  }
  useEffect(() => {
    const buildApiUrl = () => {
      // Start building the URL with common parameters
      const baseUrl = 'admin/dashboard/daily-trips-status';

const params = {
    fromDate: startDate ? `${startDate} 00:00:00.000000` :'',
    toDate: endDate ? `${endDate} 23:59:59.000000`:'',
    countryId: selectedCountry?.id, 
    cityId:selectedCity?.id, 
    cabId: selectedValue,
    type:(startDate && endDate) ? 'custom' : ''

};

const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value !== undefined && value !== null && value !== '')
);

const queryString = new URLSearchParams(filteredParams).toString();

return queryString ? `${baseUrl}?${queryString}` : baseUrl;
     
    };
    const fetchData = async () => {
      const url = buildApiUrl();

    if(url){
      try {
        setApplyFilter(true)

  
        const response = await APIService.get(url);
  
        if (response && response.status === 200) {
          const data = response.data?.data || "";
          setDailyTripsData(data[0]);
        }
      } catch (error) {
        console.error(error);
      }
    }
    };
  
    fetchData();
  }, [ endDate, selectedCountry, selectedCity,selectedValue]);


  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(()=>{
    if(rideNamesData){
      setRidesNames(rideNamesData)
    }
  },[JSON.stringify(rideNamesData)])

  useEffect(() =>{
    if(locationDataset){
      setLocationsData(locationDataset);
    }
  },[JSON.stringify(locationDataset)])

  useEffect(() => {
    // Extract country options from the data
    const countries = locationData?.map((country) => ({
      id: country.id,
      name: country.countryName
    }));
    setCountryOptions(countries);

    // Set the default selected country(
    if(countries?.length > 0){
      setSelectedCountry(countries[0]);

    }

    // Set the default selected city if available
    const defaultCity = locationData?.length > 0 && locationData[0]?.city[0];
    if (defaultCity) {
      setSelectedCity({ id: '', name: '' });
    }
  }, [JSON.stringify(locationData)]);

  useEffect(() => {
    // Extract city options based on the selected country
    const selectedCountryData = locationData?.find(
      (country) => country.countryName === selectedCountry.name
    );
    if (selectedCountryData) {
      const cities = selectedCountryData?.city?.map((city) => ({
        id: city.id,
        name: city.cityName
      }));
      setCityOptions(cities);

      // Set the default selected city
      setSelectedCity({});
    }
  }, [selectedCountry, JSON.stringify(locationData)]);

  const handleCountryChange = (e) => {
    const selectedCountryName = e.target.value;
    const selectedCountryData = countryOptions?.find(
      (country) => country.name === selectedCountryName
    );
    setSelectedCountry(selectedCountryData || {});
  };

  const handleCityChange = (e) => {
    const selectedCityName = e.target.value;
    const selectedCityData = cityOptions?.find(
      (city) => city.name === selectedCityName
    );
    setSelectedCity(selectedCityData || {});
  };


  return (
    <>
      <div className=" col-12 cards-section section-captain h-100">
        <div
          className="card-nav zoom-80"
          style={{ marginBottom: "15px", height: "auto" ,justifyContent:'start'}}
        >
          <div style={{paddingRight: '7rem'}}>
            <ul className="nav nav-pills subscription-tabs mb-1">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="pill" href="#captainsTrips">
                  Captains
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="pill" href="#pickupsTrips">
                  Pickups
                </a>
              </li>
            </ul>
          </div>
          <div>
            <div className="dropdown justify-content-start flex-wrap gap-1">
            <div className="selectbox">
        <select
          className="select w-100 outline-none border-0"
          style={{ minWidth:'fit-content', height: 35, borderRadius: 5 ,textTransform:'capitalize'}}
          value={selectedCountry.name}
          onChange={handleCountryChange}
        >
            <option key={''} value={''}>
              All
            </option>
          {countryOptions?.map((country, index) => (
            <option style={{textTransform:'capitalize'}} key={index} value={country.name}>
              {country.name}
            </option>
          ))}
        </select>
      </div>
      <div className="selectbox">
        <select
          className="select w-100 outline-none border-0"
          style={{ minWidth:'fit-content', height: 35, borderRadius: 5 ,textTransform:'capitalize', maxWidth:'115px'}}
          value={selectedCity.name}
          onChange={handleCityChange}
        >
          {cityOptions?.map((city, index) => (
            <option key={index} value={city.name}>
              {city.name}
            </option>
          ))}
          <option key={''} value={''}>
              All
            </option>
        </select>
      </div>

              <div className="selectbox">
                <select
                  className="select w-100 outline-none border-0 "
                  style={{ width: "fit-content" ,height:35}}
                  value={selectedValue} // Set the selected value
                  onChange={handleSelectChange} // Handle changes
                >
               {ridesNames.map((ride) => (
          <option key={ride.id} value={ride.id}>
            {ride.name}
          </option>
        ))}    
         <option key={''} value={''}>
           All
         </option>                          </select>
              </div>
              {/* <div className="selectbox">
                <select
                  className="select outline-none border-0 "
                  style={{ width: "fit-content" ,height:35}}
                >
                  <option value="day">Male</option>
                </select>
              </div> */}
              <Calendar handleTimeRange={getTimeRange} />
            </div>
          </div>
        </div>


        <section className="tab-pane fade active show" id="captainsTrips">
          <div className="row">
            <div className=" col-lg-12">
              <h2 className="box-title box-heading font-weight-bold" style={{marginBottom:10,fontSize:"24px"}}>Daily Trips Status : {Number(dailyTripData?.totalTrips) || 0} </h2>
              {/* <h2 className="box-title box-heading">Total Captains:{captainData?.count ? (captainData.count.active + captainData.count.inActive): topCardDriver?.count}</h2> */}
              {/* <div className="card bg-light p-5" style={{flexDirection:'row',justifyContent:'space-evenly'}}>
                <div className="card-content">
                  <div className="graphs-section">
                    <div id="circle-1">
                      <span>{Number(dailyTripData?.accepted) || 0}</span>

                      <div id="circle-5">{Number(dailyTripData?.cancelled) || 0}</div>
                      <div id="circle-7">{Number(dailyTripData?.arrived) || 0}</div>
                      <div id="circle-2">{Number(dailyTripData?.rejected) || 0}</div>

                    </div>
                  </div>

                  <div className="flex-cb mt-32 ">
                  <div className="d-flex align-items-center zoom-80">
                    <span className="fig-name fw-bold" style={{color:'#000000'}}>Driver: </span>
                  </div>
                  <div className="d-flex " style={{width:'50%',flexWrap:'wrap',gap:'10px'}}>    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig" ></span>
                      <span className="fig-name">Accepted</span>
                    </div>
                    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig " style={{background:'#A955F8'}}></span>
                      <span className="fig-name">Arrived</span>
                    </div>
                    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig "  style={{background:'#25C8C1'}} ></span>
                      <span className="fig-name">Cancelled</span>
                    </div>
                    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig " style={{background:'#EF4242'}}></span>
                      <span className="fig-name">Rejected</span>
                    </div></div>
                   
                  </div>

                </div>
                <div className="card-content">
                  <div className="graphs-section">
                    <div id="circle-8">
                      <span>{Number(dailyTripData?.ongoing) || 0}</span>

                      
                    </div>
                  </div>

                  <div className="flex-cb mt-32 ">
              
                    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig" style={{background:'#5342EF'}}></span>
                      <span className="fig-name">Ongoing Trips</span>
                    </div>
                  </div>

                </div>
                <div>
                  <div className="graphs-section">
                    <div id="circle-9">
                      <span>{Number(dailyTripData?.completed) || 0}</span> */}
                      {/* <span>{captainData?(captainData.Percentage?.activePercentage ? captainData.Percentage.activePercentage:0) : activeDriversPercentage?.Percentage?.activePercentage}</span> */}
                      {/* <div> */}
                        {/* (waslData?.approved===undefined ?  waslApprovedCount?.approved : waslData.approved) */}
                        {/* <div id="circle-14">{Number(dailyTripData?.unmatched) || 0}</div>

                        <div id="circle-10">{Number(dailyTripData?.pending) || 0}</div>
                        
                        <div id="circle-11">{Number(dailyTripData?.cancelledByRider) || 0}</div> */}

                        {/* <div id="circle-2">{captainData?(captainData.Percentage?.inActivePercentage ? captainData.Percentage.inActivePercentage:0)  : activeDriversPercentage?.Percentage?.inActivePercentage}</div> */}
                      {/* </div> */}
                      
                    {/* </div>
                  </div>
                  <div className="flex-cb mt-32">
                  <div className="d-flex align-items-center zoom-80">
                      <span className="fig-name fw-bold" style={{color:'#000000'}}>Trips: </span>
                    </div>
                    <div className="d-flex " style={{width:'70%',flexWrap:'wrap',gap:'10px'}}> 
                    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig" style={{background:'#42B9EF'}}></span>
                      <span className="fig-name">Completed</span>
                    </div>
                   <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig bg-yellow" style={{background:'#FFB065'}}></span>
                      <span className="fig-name">Pending</span>
                    </div>
                    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig " style={{background:'#F947EC'}}></span>
                      <span className="fig-name">Cancelled By Rider</span>
                    </div>
                    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig " style={{background:'#aea5d6'}}></span>
                      <span className="fig-name">Unmatched</span>
                    </div>
                    </div>
                  </div>
                </div>
             
              </div> */}






              <div className="card bg-light flex-col-set" style={{flexDirection:'row',justifyContent:'space-evenly'}}>
                <div className="card-content">
                  <div className="graphs-section">
                    <div id="circle-1">
                      <span>{Number(dailyTripData?.accepted) || 0}</span>

                      <div id="circle-5">{Number(dailyTripData?.cancelled) || 0}</div>
                      <div id="circle-7">{Number(dailyTripData?.arrived) || 0}</div>
                      <div id="circle-2">{Number(dailyTripData?.rejected) || 0}</div>

                    </div>
                  </div>

                  <div className="flex-cb mt-32 ">
                  <div className="d-flex align-items-center zoom-80">
                    <span className="fig-name fw-bold" style={{color:'#000000'}}>Driver: </span>
                  </div>
                  <div className="d-flex " style={{width:'50%',flexWrap:'wrap',gap:'10px'}}>    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig" ></span>
                      <span className="fig-name">Accepted</span>
                    </div>
                    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig " style={{background:'#A955F8'}}></span>
                      <span className="fig-name">Arrived</span>
                    </div>
                    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig "  style={{background:'#25C8C1'}} ></span>
                      <span className="fig-name">Cancelled</span>
                    </div>
                    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig " style={{background:'#EF4242'}}></span>
                      <span className="fig-name">Rejected</span>
                    </div></div>
                   
                  </div>

                </div>
                <div className="card-content">
                  <div className="graphs-section">
                    <div id="circle-8">
                      <span>{Number(dailyTripData?.ongoing) || 0}</span>

                      
                    </div>
                  </div>

                  <div className="flex-cb mt-32 ">
              
                    <div className="d-flex align-items-center zoom-80">
                      <span className="circle-fig" style={{background:'#5342EF'}}></span>
                      <span className="fig-name">Ongoing Trips</span>
                    </div>
                  </div>

                </div>
                <div className="cardContent">
                  <div className="graphs-section">
                    <div id="circle-9">
                      <span>{Number(dailyTripData?.completed) || 0}</span>
                      <div>
                        <div id="circle-14">{Number(dailyTripData?.unmatched) || 0}</div>
                        <div id="circle-10">{Number(dailyTripData?.pending) || 0}</div>
                        <div id="circle-11">{Number(dailyTripData?.cancelledByRider) || 0}</div>
                      </div>
                      
                    </div>
                  </div>
                  <div className="flex-cb mt-32">
                    <div className="d-flex align-items-center zoom-80">
                      <span className="fig-name fw-bold" style={{color:'#000000'}}>Trips: </span>
                    </div>
                    <div className="d-flex d-flexSet" style={{width:'70%',flexWrap:'wrap',gap:'10px'}}> 
                      <div className="d-flex align-items-center zoom-80">
                        <span className="circle-fig" style={{background:'#42B9EF'}}></span>
                        <span className="fig-name">Completed</span>
                      </div>
                      <div className="d-flex align-items-center zoom-80">
                        <span className="circle-fig bg-yellow" style={{background:'#FFB065'}}></span>
                        <span className="fig-name">Pending</span>
                      </div>
                      <div className="d-flex align-items-center zoom-80">
                        <span className="circle-fig " style={{background:'#F947EC'}}></span>
                        <span className="fig-name">Cancelled By Rider</span>
                      </div>
                      <div className="d-flex align-items-center zoom-80">
                        <span className="circle-fig " style={{background:'#aea5d6'}}></span>
                        <span className="fig-name">Unmatched</span>
                      </div>
                    </div>
                  </div>
                </div>
             
              </div>




            </div>
          </div>
        </section>

        <div className="tab-pane fade coming-soon" id="pickupsTrips">
          Coming soon
        </div>
      </div>
    </>
  );
}

export default TodayTripStatus;
