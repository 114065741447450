import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import Registration from "../components/adjust/registration";
import Transaction from "../components/adjust/transaction";
import TripRequest from "../components/adjust/tripRequest";
import MarketingSubscription from "../components/adjust/marketingSubscription";

const AdjustTabLayout = (props) => {
  const [selectTab, setSelectedTab] = useState("captains");
  const [subSelectedTab, setSubSelectedTab] = useState("onboarding-login");

  useEffect(() => {
    setSelectedTab(props.match.params.tab || "captains");
    props.history.push("/adjust/registration/");
  }, []);

  useEffect(()=>{
    
  },[]);

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
    props.history.push(`/adjust/${tab}/`);
    if(tab=='rider'){
      setSubSelectedTab('registration-login');
    }
    else if(tab=='captains'){
      setSubSelectedTab('onboarding-login');
    }
  };
  const handleSubTabSelect = (tab) => {
    setSubSelectedTab(tab);
    // props.history.push(`/adjust/${tab}/`);
  };
  const tabsData = [
    {
      key: "captains",
      title: "Captains",
      component: (
        <Tabs
          className="mt-40"
          style={{
            background: "#FFFFFF",
            borderRadius: 6,
            color: "#828282",
            margin: 30,
          }}
          id="captains-tabs"
          activeKey={subSelectedTab}
          onSelect={handleSubTabSelect}
        >
          <Tab eventKey="onboarding-login" title="Onboarding  & Login">
            <Registration dispatch={props?.dispatch} />
          </Tab>
          <Tab eventKey="trips" title="Trips">
            <Transaction dispatch={props?.dispatch} />
          </Tab>
          <Tab eventKey="others" title="Others">
            <TripRequest dispatch={props?.dispatch} />
          </Tab>
          {/* <Tab
            eventKey="marketing-subscription"
            title="Marketing & subscription"
          >
            <MarketingSubscription dispatch={props?.dispatch} />
          </Tab> */}
        </Tabs>
      ),
    },
    {
      key: "rider",
      title: "Rider",
      component: (
        <Tabs
        className="mt-40"
        style={{
          background: "#FFFFFF",
          borderRadius: 6,
          color: "#828282",
          margin: 30,
        }}
        id="captains-tabs"
        activeKey={subSelectedTab}
        onSelect={handleSubTabSelect}
      >         <Tab eventKey="registration-login" title="Registration & Login">
            <Transaction dispatch={props?.dispatch} />
          </Tab>
          <Tab eventKey="trips" title="Trips">
            <Transaction dispatch={props?.dispatch} />
          </Tab>
          <Tab eventKey="payments" title="Payments">
            <Transaction dispatch={props?.dispatch} />
          </Tab>
          <Tab eventKey="riderOthers" title="Others">
            <Transaction dispatch={props?.dispatch} />
          </Tab>
        </Tabs>
      ),
    },
    {
      key: "chat",
      title: "Chat",
      component: (
        // <Tabs
        // className="mt-40"
        // style={{
        //   background: "#FFFFFF",
        //   borderRadius: 6,
        //   color: "#828282",
        //   margin: 30,
        // }}
        // id="captains-tabs"
        // activeKey={subSelectedTab}
        // onSelect={handleSubTabSelect}
      // >           <Tab eventKey="trips-request" title="Trips Request">
            <TripRequest dispatch={props?.dispatch} />
          // </Tab>
        // </Tabs>
      ),
    },
  ];

  return (
    <>
      <div className="rider-listing">
        <span className="title ml-2">Adjust</span>
      </div>

      <Tabs
        // key={item.key}
        // id={`${item.key}-tabs`}
        className="mt-40"
        style={{
          background: "#FFFFFF",
          borderRadius: 6,
          color: "#828282",
          margin: 30,
        }}
        activeKey={selectTab}
        onSelect={handleTabSelect}
      >
        {tabsData?.map((item, idx) => (
          <Tab eventKey={item.key} title={item.title}>
            {item.component ? item.component : <></>}
          </Tab>
        ))}
      </Tabs>
    </>
  );
};

export default React.memo(connect()(AdjustTabLayout));
