import React, { useEffect, useState } from "react";
import PromotionsDatatable from "./PromotionsDatatable";
import { Button, Modal } from "react-bootstrap";
import { Card, Col, Row } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select, { StylesConfig } from "react-select";
import APIService from '../../services/APIService';
import { toast } from 'react-toastify';
import * as Yup from "yup";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? "#DCDCDC" : isSelected ? "#ffffff"
        : isFocused
        ? "white"
        : undefined,
      color: isDisabled ? "white" : isSelected ? "#28a745" : "#000000",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },
};

const PromotionsMasterControl = () => {
  const [show, setShow] = useState(false),
    [promotionsuccess, setPromotionSuccess] = useState(false),
    [promotionCategory, setPromotionCategory] = useState({
      value: "TOPUP_CASHBACK",
      label: "Topup Cashback",
    }),
    [selectedValue, setSelectedValue] = useState("TOPUP_CASHBACK");
    let countryOptions = [
      { value: 1, label: "Rider" },
      { value: 2, label: "Driver" },
    ],
    countryOptions1 = [
      { value: 1, label: "Rider" },
      { value: 2, label: "Driver", isDisabled: true },
    ],
    countryOptions2 = [
      { value: 1, label: "Rider", isDisabled: true },
      { value: 2, label: "Driver" },
    ],
    promotionsOptions = [
      { value: "TOPUP_CASHBACK", label: "Topup Cashback" },
      { value: "TRIP_CASHBACK", label: "Trip Payment Cashback" },
      { value: "REGISTRATION_CASHBACK", label: "Fixed Amount" },
      { value: "DOUBLE_EARN", label: "Double Earn" },
      { value: "AUTO_PROMO_CODE", label: "Discount" },
      { value: "REFERRAL", label: "Referral" },
    ];
  const optionsLists = {
      TOPUP_CASHBACK: [
        "Applicable For RO",
        "Cash or Percentage", // AmountType
        "Max Discount Amount",
        "Amount",             // Amount
        "Currency",           // Country
        "Cashback Value",     // Min Amount In Cart
        "Duration",           // Start Date & End Date
        "Repeating Times",    // Max Usage Per User
        "Total Budget",
        "Max Total Usage",    // Max Total Usage
      ],
      TRIP_CASHBACK: [
        "Applicable For RO",
        "Cash or Percentage", // AmountType
        "Amount",             // Amount
        // "No of trips",     // Required Trips
        "Required Trips",     // Required Trips
        "Currency",           // Country
        "Duration",           // Start Date & End Date
        "Repeating Times",    // Max Usage Per User
        "Total Budget",       // Total Budget
        "Max Total Usage",    // Max Total Usage
      ],
      REGISTRATION_CASHBACK: [
        "Applicable For",
        // "No of trips",     // Required Trips
        "Amount",             // Amount
        "Required Trips",     // Required Trips
        "Currency",           // Country
        "Duration",           // Start Date & End Date
        "Repeating Times",    // Max Usage Per User
        "Total Budget",       // Total Budget
        "Max Total Usage",    // Max Total Usage
      ],
      DOUBLE_EARN: [
        "Applicable For DO",
        // "No of trips",     // Required Trips
        "Required Trips",     // Required Trips
        "Currency",           // Country
        "Duration",           // Start Date & End Date
        "Repeating Times",    // Max Usage Per User
        "Max Discount Amount",// Max Discount Amount
        "Total Budget",       // Total Budget
        "Max Total Usage",    // Max Total Usage
      ],
      AUTO_PROMO_CODE: [
        "Applicable For RO",
        // "No of trips",     // Required Trips
        "Required Trips",     // Required Trips
        "Cash or Percentage", // AmountType
        "Amount",             // Amount
        "Currency",           // Country
        "Duration",           // Start Date & End Date
        "Max Discount Amount",// Max Discount Amount
        "Repeating Times",    // Max Usage Per User
        "Total Budget",       // Total Budget
        "Max Total Usage",    // Max Total Usage
      ],
      REFERRAL: [
        "Applicable For",
        // "No of trips",     // Required Trips 
        "Required Trips",     // Required Trips 
        "Amount",             // Amount
        "Currency",           // Country
        "Duration",           // Start Date & End Date
        "Max Discount Amount",// Max Discount Amount
        "Repeating Times",    // Max Usage Per User
        "Total Budget",       // Total Budget
        "Max Total Usage",    // Max Total Usage
      ],
    },
    handleShow = () => setShow(!show);

  let createPromotions = (formData) => {
    console.warn(formData)
    // promotionInfo.status = Number(selectedStatus.value);
    // let formData = new FormData();
    // Object.keys(promotionInfo).forEach((key) => {
    //   formData.append(key, promotionInfo[key]);
    // });
    // var startAt = new Date();
    // var endAt = new Date(startAt);
    // endAt.setDate(startAt.getDate() + formData.duration);
    // function addDaysToDate(startAt, daysToAdd) {
    //   console.warn(startAt)
    //   const date = new Date(startAt); // Create a new Date object based on the input date
    //   console.warn(date.getDate())
    //   date.setDate(date.getDate() + daysToAdd); // Add the specified number of days
    //   return date.toISOString(); // Convert the result back to an ISO string
    // }
    const startAt = new Date();
    // console.warn(startAt)
    const daysToAdd = formData.duration;
    // const endAt = addDaysToDate(startAt, daysToAdd);
    const endAt = new Date(new Date().getTime()+(daysToAdd*24*60*60*1000));
    // console.warn(endAt)
    
    let amountType = formData.maximumDiscountAmount;

    if (formData.amountType == 1) {
      amountType = formData.amount;
    }

    const promotionCategoryMap = {
      "TOPUP_CASHBACK": 1,
      "TRIP_CASHBACK": 2,
      "REGISTRATION_CASHBACK": 3,
      "DOUBLE_EARN": 4,
      "AUTO_PROMO_CODE": 5,
      "REFERRAL": 6,
    };
    
    let promotionCategoryVal = promotionCategoryMap[formData.promotionCategory] || 1;    

    APIService.post("admin/promotion", {
      "promotionCategory": promotionCategoryVal??1,
      "message": "Saif Testing New",
      "applicableFor": formData.applicableFor??1,
      "country": ("" + (formData.country??"country")),
      "amountType": formData.amountType??2,
      "userUsage": ("" + (formData.userUsage??"0")),
      "amount": ("" + (formData.amount??"0")),
      "totalBudget": ("" + (formData.totalBudget??"0")),
      "totalUtilised": ("" + (formData.totalUtilised??"0")),
      "maximumDiscountAmount": ("" + (amountType??"0")),
      "maximumTotalUsage": ("" + (formData.maximumTotalUsage??"0")),
      "maximumUsagePerUser": ("" + (formData.maximumUsagePerUser??"0")),
      "startAt": startAt??"2023-10-10 00:00:00.000000",
      "endAt": endAt??"2023-10-10 00:00:00.000000",
      "status": formData.status??false,
      "deletedAt": formData.deletedAt??"-"
    }).then((response) => {
      if (response.data.statusCode === 200) {
        console.log("updated data ", response.data);
    getPromoList();
      }
      console.log(response.data.message)
      handleShow();
    }).catch((error) => {
      // props.resetForm();
      console.log(error);
    });
  };

  let [applicableFor, setApplicableFor] = useState({ value: 1, label: "Rider" }),
      [noOfTrips, setNoOfTrips] = useState(0),
      [amount, setAmount] = useState(0),
      [amountType, setAmountType] = useState(0),
      [maximumDiscountAmount, setMaximumDiscountAmount] = useState(0),
      [totalBudget, setTotalBudget] = useState(0),
      [selectedCountry, setSelectedCountry] = useState('SAR.'),
      [maximumTotalUsage, setMaximumTotalUsage] = useState(0),
      [maximumUsagePerUser, setMaximumUsagePerUser] = useState(0);

  let curruncySelected = 'SAR.';
  function changeCurrency(val) {
    curruncySelected = val;
  }
  let [promotionList, setPromotionList] = useState([]);
  let getPromoList = async () => {
    APIService.get(`admin/all-promotion`)
    .then((response) => {
      if (response.data && response.data.statusCode === 200) {
        const logData1 = response.data.data;
        console.log(logData1)

        setPromotionList(logData1)
        // setLazyParams({ ...lazyParams, totalRecords: logData1.length });
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };

  useEffect(()=>{
    getPromoList();
  }, [])

  return (
    <>
      <PromotionsDatatable promotionList={promotionList} handleShow={handleShow} />
      <Modal show={show} onHide={handleShow} centered size="lg">
        <Formik
          initialValues={{
            applicableFor: 1, // Initialize your form field values here
            promotionCategory: "TOPUP_CASHBACK", // Corrected field name
            // reasonType:reasonType?.DRIVER_CANCEL,
            // status: '', // Initialize the status field
          }}
          validationSchema={Yup.object({
            applicableFor: Yup.string().required("Target User is required!"),
            amount: Yup.number().when('promotionCategory', {
              is: 'DOUBLE_EARN', // Only apply this rule when promotionCategory is 'DOUBLE_EARN'
              then: Yup.number().notRequired(), // Make amount not required
              otherwise: Yup.number().min(1, 'Amount cannot be smaller than 1!').required('Please enter an amount'),
            }),
            maxDiscountAmount: Yup.number().when('promotionCategory', {
              is: (promotionCategory) => ['TOPUP_CASHBACK', 'TRIP_CASHBACK', 'REGISTERATION_CASHBACK'].includes(promotionCategory),
              then: Yup.number().notRequired(), // Make maxDiscountAmount optional
              otherwise: Yup.number().required('Max Discount Amount is required'),
            }),
            requiredTrips: Yup.string().when('promotionCategory', {
              is: (promotionCategory) => promotionCategory !== 'TOPUP_CASHBACK',
              then: Yup.string().required('Required Trips is required!').test('greaterThanOrEqual', 'Required Trips must be greater than or equal to 0.', (value) => parseInt(value, 10) >= 0),
              otherwise: Yup.string().notRequired(),
            }),
            totalBudget: Yup.number().optional("Please enter Total Budget!").min(Yup.ref("amount"), "Total Budget must be greater than Amount"),
            maximumTotalUsage: Yup.number().min(1, "Max Total Usage cannot be smaller than 1!").required("Max Total Usage is required!"),
            amountType: Yup.string().required("Amount Type is required!"),
            country: Yup.string().optional("Please pick a currency!"),
            minimumAmountInCart: Yup.number().min(1, "Cashback Value cannot be smaller than 1!").required("Cashback Value is required!"),
            duration: Yup.number().required("Duration is required!"),
            maximumUsagePerUser: Yup.number().min(1, "Repeating Time cannot be smaller than 1!").required("Repeating Times is required!"),
          })}
          onSubmit={(values) => {
            createPromotions(values); 
          }}
        >
        {(formik) => (
            <Form>
              <Modal.Header>
                <Modal.Title className="modal-title">
                  Add New Promotion
                </Modal.Title>
                <div className="divclose" onClick={handleShow}>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </Modal.Header>
              <Modal.Body className="mt-24">
                <Row className="row">

                  {/* Applicable For - Any */}
                  {optionsLists[selectedValue].includes("Applicable For") && (
                    <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Select User"
                          name="applicableFor"
                          styles={colourStyles}
                          id="applicableFor"
                          onBlur={() => {
                            formik.handleBlur({ target: { name: "applicableFor" } });
                          }}
                          onChange={(e) => {
                              if (e) {
                                formik.setFieldValue("applicableFor", e.value);
                                setApplicableFor(e);
                              }
                          }}
                          value={applicableFor}
                          options={countryOptions}
                        />
                        <label htmlFor="applicableFor">Target User</label>
                        {(!selectedCountry || !selectedCountry?.value) && (
                            <ErrorMessage
                              name="applicableFor"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )}
                      </div>
                    </Col>
                  )}
                  {/* Applicable For - Rider Only */}
                  {optionsLists[selectedValue].includes("Applicable For RO") && (
                    <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          classNamePrefix="custom-select"
                          placeholder="Select User"
                          name="applicableFor"
                          styles={colourStyles}
                          id="applicableFor"
                          onBlur={() => {
                            formik.handleBlur({ target: { name: "applicableFor" } });
                          }}
                          onChange={(e) => {
                              if (e) {
                                formik.setFieldValue("applicableFor", e.value);
                                setApplicableFor(e);
                              }
                          }}
                          value={applicableFor}
                          options={countryOptions1}
                        />
                        <label htmlFor="applicableFor">Target User</label>
                        {(!selectedCountry || !selectedCountry?.value) && (
                            <ErrorMessage
                              name="applicableFor"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )}
                      </div>
                    </Col>
                  )}
                  {/* Applicable For - Driver Onlyl */}
                  {optionsLists[selectedValue].includes("Applicable For DO") && (
                    <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          classNamePrefix="custom-select"
                          placeholder="Select User"
                          name="applicableFor"
                          styles={colourStyles}
                          id="applicableFor"
                          onBlur={() => {
                            formik.handleBlur({ target: { name: "applicableFor" } });
                          }}
                          onChange={(e) => {
                              if (e) {
                                formik.setFieldValue("applicableFor", e.value);
                                setApplicableFor(e);
                              }
                          }}
                          value={applicableFor}
                          options={countryOptions2}
                        />
                        <label htmlFor="applicableFor">Target User</label>
                        {(!selectedCountry || !selectedCountry?.value) && (
                            <ErrorMessage
                              name="applicableFor"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )}
                      </div>
                    </Col>
                  )}

                  <Col md={6}>
                    <div className="mb-4 form-label-group">
                      <Select
                        className="height-50 br-10 bg-color-select"
                        placeholder="Select Category"
                        name="promotionCategory"
                        styles={colourStyles}
                        id="promotionCategory"
                        onBlur={() => {
                          formik.handleBlur({
                            target: { name: "promotionCategory" },
                          });
                        }}
                        onChange={(e) => {
                          if (e) {
                            formik.setFieldValue("promotionCategory", e.value);
                            setSelectedValue(e.value);
                            setPromotionCategory(e);
                          }
                        }}
                        value={promotionCategory}
                        options={promotionsOptions}
                      />
                      <label htmlFor="">Promotions Category</label>
                      {(!selectedCountry || !selectedCountry?.value) && (
                          <ErrorMessage
                            name="promotionCategory"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        )}
                    </div>
                  </Col>

                  {optionsLists[selectedValue].includes("Amount") && (
                    <Col md={6}>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          id="amount"
                          type="Number"
                          name="amount"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="amount"
                        />
                        <label htmlFor="amount">Amount</label>
                        <span
                          className="position-absolute top-0 end-0 p-2 rounded font-weight-bold"
                          style={{
                            marginTop: "10px",
                            color: "#13B542",
                            background: "#E3F6E8",
                            marginRight: "10px",
                          }}
                        >
                          {amountType.value != 2 ? (selectedCountry.label == 'PKR' ? 'PKR.' : 'SAR.') : '%'}
                        </span>
                        <ErrorMessage
                          name="amount"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                  )}

                  {optionsLists[selectedValue].includes("Max Discount Amount") && (
                    amountType.value != 1 && (
                      <Col md={6}>
                        <div className="form-group form-label-group position-relative">
                          <Field
                            id="maximumDiscountAmount"
                            type="Number"
                            name="maximumDiscountAmount"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="maximumDiscountAmount"
                            // isDisabled={Formik.values.amountType == '2'}
                          />
                          <label htmlFor="maximumDiscountAmount">Max Discount Amount</label>
                          <span
                            className="position-absolute top-0 end-0 p-2 rounded font-weight-bold"
                            style={{
                              marginTop: "10px",
                              color: "#13B542",
                              background: "#E3F6E8",
                              marginRight: "10px",
                            }}
                          >
                            %
                          </span>
                          <ErrorMessage
                            name="maximumDiscountAmount"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                    )
                    ||
                    amountType.value == 1 && (
                      <Col md={6}>
                        <div className="form-group form-label-group position-relative">
                          {console.log(amountType.value)}
                          <Field
                            id="maximumDiscountAmount"
                            type="Number"
                            name="maximumDiscountAmount"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="maximumDiscountAmount"
                            disabled={true}
                            value={formik.values.amount}
                            // isDisabled={Formik.values.amountType == '2'}
                          />
                          <label htmlFor="maximumDiscountAmount">Max Discount Amount</label>
                          <span
                            className="position-absolute top-0 end-0 p-2 rounded font-weight-bold"
                            style={{
                              marginTop: "10px",
                              color: "#13B542",
                              background: "#E3F6E8",
                              marginRight: "10px",
                            }}
                          >
                            {selectedCountry.label == 'PKR' ? 'PKR.' : 'SAR.'}
                          </span>
                          <ErrorMessage
                            name="maximumDiscountAmount"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                    )
                  )}
                  {optionsLists[selectedValue].includes("Required Trips") && (
                    <Col md={6}>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          id="requiredTrips"
                          type="number"
                          name="requiredTrips"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="name"
                          onChange={(e) => {
                              formik.setFieldValue("requiredTrips", e.target?.value)
                          }}
                        />
                        <label htmlFor="requiredTrips">Required Trips</label>
                        {/* <span
                          className="position-absolute top-0 end-0 p-2 rounded font-weight-bold"
                          style={{
                            marginTop: "10px",
                            color: "#13B542",
                            background: "#E3F6E8",
                            marginRight: "10px",
                          }}
                        >
                          %
                        </span> */}
                        <ErrorMessage
                          name="requiredTrips"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                  )}
                  {optionsLists[selectedValue].includes("Total Budget") && (
                    <Col md={6}>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          id="totalBudget"
                          type="Number"
                          name="totalBudget"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="name"
                          // value={totalBudget}
                          onChange={(e) => {
                              // setTotalBudget(e.target.value)
                              formik.setFieldValue("totalBudget", e.target?.value)
                          }}
                        />
                        <label htmlFor="totalBudget">Total Budget</label>
                        <span
                          className="position-absolute top-0 end-0 p-2 rounded font-weight-bold"
                          style={{
                            marginTop: "10px",
                            color: "#13B542",
                            background: "#E3F6E8",
                            marginRight: "10px",
                          }}
                        >
                          {selectedCountry.label == 'PKR' ? 'PKR.' : 'SAR.'}
                        </span>
                        <ErrorMessage
                          name="totalBudget"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                  )}
                  {optionsLists[selectedValue].includes("Max Total Usage") && (
                    <Col md={6}>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          id="maximumTotalUsage"
                          type="number"
                          name="maximumTotalUsage"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="name"
                          onChange={(e) => {
                              formik.setFieldValue("maximumTotalUsage", e.target?.value)
                          }}
                        />
                        <label htmlFor="maximumTotalUsage">Max Total Usage</label>
                        {/* <span
                          className="position-absolute top-0 end-0 p-2 rounded font-weight-bold"
                          style={{
                            marginTop: "10px",
                            color: "#13B542",
                            background: "#E3F6E8",
                            marginRight: "10px",
                          }}
                        >
                          %
                        </span> */}
                        <ErrorMessage
                          name="maximumTotalUsage"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                  )}
                  {optionsLists[selectedValue].includes("Cash or Percentage") && (
                    <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Amount Type"
                          name="amountType"
                          styles={colourStyles}
                          id="amountType"
                          onChange={(e) => {
                              if (e) {
                                formik.setFieldValue("amountType", e.value);
                                setAmountType(e);
                              }
                          }}
                          value={amountType}
                          options={[
                            { value: 1, label: "Cash" },
                            { value: 2, label: "Percentage" },
                          ]}
                        />
                        <label htmlFor="">Cash or Percentage</label>
                        {(!amountType || !amountType?.value) && (
                          <ErrorMessage
                            name="amountType"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        )}
                      </div>
                    </Col>
                  )}
                  {optionsLists[selectedValue].includes("Currency") && (
                    <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Currency"
                          name="country"
                          styles={colourStyles}
                          id="country"
                          // onBlur={() => {
                          //   handleBlur({ target: { name: "country" } });
                          // }}
                          onChange={(e) => {
                            if (e) {
                              formik.setFieldValue("country", e.value);
                              setSelectedCountry(e);
                              changeCurrency(e.label);
                            }
                          }}
                          value={selectedCountry}
                          options={[
                            { value: "kasfdjhoiwuerhksjdhfc", label: "SAR" },
                            { value: "kasfdjhoiwuerhksjdhfc", label: "PKR" },
                          ]}
                        />
                        <label htmlFor="country">Currency</label>
                        {(!selectedCountry || !selectedCountry?.value) && (
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        )}
                      </div>
                    </Col>
                  )}
                  {optionsLists[selectedValue].includes("Cashback Value") && (
                    <Col md={6}>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          id="minimumAmountInCart"
                          type="Number"
                          name="minimumAmountInCart"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="minimumAmountInCart"
                          onChange={(e) => {
                              formik.setFieldValue("minimumAmountInCart", e.target?.value)
                          }}
                        />
                        <label htmlFor="minimumAmountInCart">Cashback Value</label>
                        <span
                          className="position-absolute top-0 end-0 p-2 rounded font-weight-bold"
                          style={{
                            marginTop: "10px",
                            color: "#13B542",
                            background: "#E3F6E8",
                            marginRight: "10px",
                          }}
                        >
                        {selectedCountry.label == 'PKR' ? 'PKR.' : 'SAR.'}
                        </span>

                        <ErrorMessage
                          name="minimumAmountInCart"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                  )}
                  {optionsLists[selectedValue].includes("Duration") && (
                    <Col md={6}>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          id="duration"
                          type="Number"
                          name="duration"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="duration"
                          onChange={(e) => {
                              formik.setFieldValue("duration", e.target?.value)
                          }}
                        />
                        <label htmlFor="duration">Duration</label>
                        <span
                          className="position-absolute top-0 end-0 p-2 rounded font-weight-bold"
                          style={{
                            marginTop: "10px",
                            color: "#13B542",
                            background: "#E3F6E8",
                            marginRight: "10px",
                          }}
                        >
                          DAYS
                        </span>

                        <ErrorMessage
                          name="fullName"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                  )}
                  {optionsLists[selectedValue].includes("Repeating Times") && (
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="maximumUsagePerUser"
                          type="Number"
                          name="maximumUsagePerUser"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="maximumUsagePerUser"
                          // value={maximumUsagePerUser}
                          onChange={(e) => {
                              // setMaximumUsagePerUser(e.target.value)
                              formik.setFieldValue("maximumUsagePerUser", e.target?.value)
                          }}
                        />
                        <label htmlFor="maximumUsagePerUser">Repeating Times</label>
                        <ErrorMessage
                          name="maximumUsagePerUser"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                  )}
                  <Col md={12} className="d-flex justify-content-center">
                    <Col md={6}>
                      <Button
                        variant="success"
                        className="btn-save save-button mb-3 btn  btn-block"
                        type="submit"
                        // onClick={createOrUpdateAdmin}
                      >
                        Create
                      </Button>
                    </Col>
                  </Col>
                </Row>
              </Modal.Body>
            </Form>
          )
        }
        </Formik>
      </Modal>
      <Modal
        show={promotionsuccess}
        size="md"
        onHide={() => setPromotionSuccess(false)}
        centered
        backdrop="static"
        keyboard={false}
        className="modal-height modal-vc"
      >
        <Formik
        // initialValues={{
        //   oldPassword: "",
        //   newPassword: "",
        //   confirmPassword: "",
        // }}
        // validationSchema={validationPasswordSchema}
        // onSubmit={changePassword}
        >
          {() => {
            return (
              <Form>
                <Modal.Header>
                  <Modal.Title className="modal-title"> </Modal.Title>
                  <div
                    className="divclose"
                    onClick={() => setPromotionSuccess(false)}
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Modal.Header>

                <Modal.Body
                  className="customheight"
                  style={{
                    height: "492px !important",
                    minHeight: "492px !important",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Row>
                    <Col xl={12} className="text-center my-auto custom-success">
                      <svg
                        className="custom-svg"
                        viewBox="0 0 26 26"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          stroke="currentColor"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            className="circle"
                            d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"
                          />
                          <path
                            className="tick"
                            d="M6.5 13.5L10 17 l8.808621-8.308621"
                          />
                        </g>
                      </svg>
                      <h2 className="success-message mt-24">
                        Add New Promotion
                        <br />
                        Confirmed
                      </h2>
                    </Col>
                  </Row>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default PromotionsMasterControl;
