export const driverInitialArray = [
  {
    "category": "Registration",
    "zeroToTwoHours": "0",
    "TwoToFourHours": "0",
    "FourToTenHours": "0",
    "aboveTenHours": "0"
  },
  {
    "category": "Passenger No Show",
    "zeroToTwoHours": "0",
    "TwoToFourHours": "0",
    "FourToTenHours": "0",
    "aboveTenHours": "0"
  },
  {
    "category": "Vehicle Document Renewal",
    "zeroToTwoHours": "0",
    "TwoToFourHours": "0",
    "FourToTenHours": "0",
    "aboveTenHours": "0"
  },
  {
    "category": "Trip",
    "zeroToTwoHours": "0",
    "TwoToFourHours": "0",
    "FourToTenHours": "0",
    "aboveTenHours": "0"
  },
  {
    "category": "Payout",
    "zeroToTwoHours": "0",
    "TwoToFourHours": "0",
    "FourToTenHours": "0",
    "aboveTenHours": "0"
  },
  {
    "category": "Other",
    "zeroToTwoHours": "0",
    "TwoToFourHours": "0",
    "FourToTenHours": "0",
    "aboveTenHours": "0"
  }
],
  riderInitialArray = [
    {
      "category": "Registration",
      "zeroToTwoHours": "0",
      "TwoToFourHours": "0",
      "FourToTenHours": "0",
      "aboveTenHours": "0"
    },
    {
      "category": "Balance",
      "zeroToTwoHours": "0",
      "TwoToFourHours": "0",
      "FourToTenHours": "0",
      "aboveTenHours": "0"
    },
    {
      "category": "Different Car",
      "zeroToTwoHours": "0",
      "TwoToFourHours": "0",
      "FourToTenHours": "0",
      "aboveTenHours": "0"
    },
    {
      "category": "Trip",
      "zeroToTwoHours": "0",
      "TwoToFourHours": "0",
      "FourToTenHours": "0",
      "aboveTenHours": "0"
    },
    {
      "category": "Refund",
      "zeroToTwoHours": "0",
      "TwoToFourHours": "0",
      "FourToTenHours": "0",
      "aboveTenHours": "0"
    },
    {
      "category": "Other",
      "zeroToTwoHours": "0",
      "TwoToFourHours": "0",
      "FourToTenHours": "0",
      "aboveTenHours": "0"
    }
  ]