import React, { useState, useEffect } from "react";
import APIService from "../../services/APIService";
import { Card, Col, Row, Modal, Button } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as PrimeButton } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";

let windowWidth = window.innerWidth,
  TripSettingDetailPage = (props) => {
    let tripSettings = [
      "RIDE-A-RIDE-STATUS",
      "DRIVER_DECLINE_TIME_LIMIT",
      "DRIVER_END_TRIP_RANGE",
      "EHSAN_DONATION_FEE",
      "DRIVER_WAITING_TIME_LIMIT",
      "NOTIFY_TIME_SCHEDULED_TRIP",
      "RECENT_ADDRESSES_LIMIT",
      "RIDER_CANCEL_TIME_LIMIT",
      "SCHEDULE_TRIP_MAX_DAYS",
      "SCHEDULE_TRIP_TIME_RANGE",
      "SCHEDULED_TRIP_MIN_RANGE",
      "TRIP_DRIVER_SEARCH_LIMIT",
      "TRIP_DRIVER_SEARCH_RADIUS",
      "TRIP_TAX_PERCENTAGE",
      "CHANGE_DESTINATION_LIMIT_FOR_RIDER",
      "RIDER_TRIPS_LIMIT_WITHOUT_KYC",
      "ADMIN_RIDE_NOTIFICATION_EMAIL",
      "ADMIN_TRANSACTION_NOTIFY_EMAIL",
      "ADMIN_RIDE_NOTIFICATION_EMAIL",
      "DRIVER_DECLINE_TIME_LIMIT",
      "EMAIL_NOTIFICATION_FROM_EMAIL",
      "EMAIL_NOTIFICATION_FROM_NAME",
      "RECENT_ADDRESSES_LIMIT",
      "SCHEDULED_TRIP_MIN_TIME",
      "SUPPORT_TOLL_FREE_NO",
      "MAX_TRIP_REQUESTS_LIMIT_FOR_CANCELLED_TRIP",
      "MAX_DRIVERS_REQUEST_LIMIT_FOR_CANCELLED_TRIP",
      "MAX_TRIP_REQUESTS_LIMIT_FOR_REJECTED_TRIP",
      "MOT_AMOUNT",
    ]
    // const { tripSettings } = props
    let [tripSettingData, setTripSettingData] = React.useState([]),
      [updateData, setUpdateData] = useState(null),
      [changeValue, setChangeValue] = useState(""),
      [show, setShow] = useState(false),
      [startTime, setStartTime] = useState(null),
      [endTime, setEndTime] = useState(null),
      [isMultiField, setIsMultiField] = useState(false),
      permissions = JSON.parse(atob(localStorage.getItem("access"))),
      currentRoutePermissions = permissions.filter(a => a.includes('/fare-configuration/setting/')),
      handleClose = () => setShow(false),
      handleShow = (rowData , toggle = "") => {
        if (rowData.name === "SCHEDULE_TRIP_TIME_RANGE") {
          setIsMultiField(true);
          let value = JSON.parse(rowData.value);
          setStartTime(moment(value.start, "HH: mm").toDate());
          setEndTime(moment(value.end, "HH: mm").toDate());
        } else {
          setIsMultiField(false);
        }
        setUpdateData(rowData);
        setChangeValue(rowData.value);
        setShow(true);
      };

    useEffect(() => {
      getSettings();
    }, []);

    let getSettings = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.get("admin/settings")
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            let settingData = response.data.data;
            setTripSettingData(
              settingData
                .filter((item) => {
                  return tripSettings.indexOf(item.name) > -1;
                })
                .map((setting, index) => {
                  setting.order = index;
                  setting.title = setting.name.split("_").join(" ").replace(/\bNO\b/g,'number');
                  return setting;
                })
            );
            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
      updateSetting = () => {
        if (
          isMultiField &&
          (moment(startTime).isAfter(moment(endTime)) ||
            moment(startTime).isSame(moment(endTime)))
        ) {
          return;
        }
        let details = isMultiField
          ? {
            value: JSON.stringify({
              start: moment(startTime).format("HH:mm"),
              end: moment(endTime).format("HH:mm"),
            }),
          }
          : {
            value: changeValue,
          };
        APIService.patch("admin/setting/" + updateData?.name, details).then(
          (response) => {
            if (response.data && response.data.statusCode === 200) {
              handleClose();
              toast.success("Setting Updated Successfully");
              getSettings();
            }
          }
        );
      },
      renderValueTemplate = (rowData) => {
        return (
          <>
            {rowData.name === "SCHEDULE_TRIP_TIME_RANGE" ? (
              <>
                {JSON.parse(rowData.value).start} to{" "}
                {JSON.parse(rowData.value).end}
              </>
            ) : (
              <>{rowData.value}</>
            )}
          </>
        );
      },
      settingTitle = (setting) => {
        return (
          setting.title?.slice(0, 1) +
          "" +
          setting.title?.toLowerCase().slice(1, setting.title.length)
        );
      },
      settingDesc = (setting) => {
        return (
          setting.description?.slice(0, 1) +
          "" +
          setting.description?.toLowerCase().slice(1, setting.description.length)
        );
      },
      onChangeValue = (event) =>{
          setChangeValue(event.target.value)
        },
      actionTemplate = (rowData) => {
        return (
          <div className="button p-table-button">
            <PrimeButton
              tooltip="Edit"
              tooltipOptions={{ position: "bottom" }}
              onClick={() => handleShow(rowData)}
            >
              <svg
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.914 14L14.056 3.85796L12.642 2.44396L2.5 12.586V14H3.914ZM4.743 16H0.5V11.757L11.935 0.321961C12.1225 0.13449 12.3768 0.0291748 12.642 0.0291748C12.9072 0.0291748 13.1615 0.13449 13.349 0.321961L16.178 3.15096C16.3655 3.33849 16.4708 3.5928 16.4708 3.85796C16.4708 4.12313 16.3655 4.37743 16.178 4.56496L4.743 16ZM0.5 18H18.5V20H0.5V18Z"
                  fill="#03053D"
                />
              </svg>
            </PrimeButton>
          </div>
        );
      };
      let activeActionTemplate = (rowData) => {
        return (
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`customSwitch${rowData.id}`}
              defaultChecked={rowData.value === "1" ? true : false}
              onChange={(e) => {
                let value = e.target.checked ? "1" : "0"
                APIService.patch("admin/setting/" + rowData?.name, {value : value}).then(
                  (response) => {
                    if (response.data && response.data.statusCode === 200) {
                      getSettings();
                      toast.success("Setting Updated Successfully");
                    }
                  }
                );
              }}
              // disabled={!updatePermission}
            />
            <label
              className="custom-control-label"
              style={{ cursor: "pointer" }}
              htmlFor={`customSwitch${rowData.id}`}
            ></label>
          </div>
        );
      };
    return (
      <>
        {/* <div className="rider-listing">
          <span className="title">Trip Settings</span>
          <Card className="mt-3">
            <DataTable
              value={tripSettingData}
              scrollable
              scrollHeight="70vh"
              loading={useSelector((state) => state.isGridLoader)}
              loadingIcon="fa fa-spinner"
            >
              <Column
                style={{
                  width: windowWidth < 768 ? "200px" : "",
                }}
                field="title"
                header="Title"
                body={(setting) =>
                  setting.title?.slice(0, 1) +
                  "" +
                  setting.title?.toLowerCase().slice(1, setting.title.length)
                }
              ></Column>
              <Column
                field="description"
                header="Description"
                style={{ width: windowWidth < 768 ? "350px" : "" }}
              ></Column>
              <Column
                className="text-center"
                style={{
                  width: windowWidth < 768 ? "150px" : "",
                }}
                body={renderValueTemplate}
                header="Value"
              ></Column>
              <Column
                className="text-center"
                style={{ width: "100px" }}
                header="Action"
                headerClassName="text-center"
                body={actionTemplate}
                filterPlaceholder="Minimum"
              />
            </DataTable>
          </Card>
        </div> */}
        <div className="rider-listing px-0">
          <div className="d-box">
            <div className="row tabs-custom">
              { tripSettingData.map((itemCard) => (
                <div className="col-lg-4">
                  <div className="fields">
                    <h5 className="field-heading">{settingTitle(itemCard)}</h5>
                    <p className="field-text">{settingDesc(itemCard)}</p>
                    <div
                      className="input-fields"
                      style={{
                        width: "95%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "8px",
                      }}
                    >
                      {!(itemCard.name==="RIDE-A-RIDE-STATUS") ?
                      <span className="text-dark font-weight-bold">
                        {itemCard.value ? itemCard.value: <span style={{color:'#808080',opacity:'0.8',fontStyle:'italic'}}>Click on edit button to add value</span>}
                      </span> : <span className="text-dark font-weight-bold">
                        {itemCard.value === "1" ? "Enabled": "Disabled"}
                      </span>
                      }
                      <span
                        className="text-success   cursor-pointer"
                      // onClick={() => setButtonText(!buttonText)}
                      >

                        <div className="button p-table-button">
                          {/* <PrimeButton
                            tooltip="Delete"
                            label="Delete"
                            className="del-button"
                            tooltipOptions={{ position: "bottom" }}
                            onClick={() => handleShow(itemCard)}
                          > 
                          </PrimeButton> */}
                          
                          {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'update') && (itemCard.name === "RIDE-A-RIDE-STATUS" ? activeActionTemplate(itemCard) : <PrimeButton
                            tooltip="Edit"
                            label="Edit"
                            className="edit-button"
                            tooltipOptions={{ position: "bottom" }}
                            onClick={() => handleShow(itemCard)}
                          >
                          </PrimeButton>)}
                          {}
                          
                        </div>
                        {/* {buttonText ? "Edit" : "Save"} */}
                      </span>
                    </div>
                    {/* <input type="text" name={itemCard.name} value={itemCard.value} onChange={InputEventCall} className='p-2' style={{width:'95%',border:'1px solid #E2E2E2'}}/> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>




        <Modal show={show}
          className="modal-promotion" size="" onHide={handleClose} centered backdrop="static"
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title className="modal-title">
              Recent Address Limit
              <p className="field-text mt-2 pr-5">
                {updateData?.description}
              </p>
            </Modal.Title>
            {/* <div class="divclose" onClick={handleClose}>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
              </div> */}

          </Modal.Header>
          <Modal.Body className="px-1 mt-3">
            <form>
              {/* <div className="form-group form-label-group">
                <input
                  id="couponTitle"
                  type="text"
                  name="couponTitle"
                  className="shadow-none form-control modal-input br-10"
                  placeholder="Trip Title"
                  value={updateData && updateData.name.split("_").join(" ")}
                  readOnly
                  style={{ background: "#BABBC0" }}
                />
              </div>
              <div className="form-group form-label-group">
                <textarea
                  id="description"
                  type="text"
                  style={{ height: "unset" }}
                  name="description"
                  className="shadow-none form-control modal-input br-10"
                  disabled
                  readOnly
                >
                  {updateData?.description}
                </textarea>
              </div> */}
              {isMultiField ? (
                <Row>
                  <Col>
                    <Calendar
                      appendTo={null}
                      id="time12"
                      placeholder="Start time"
                      value={startTime}
                      onChange={(e) => setStartTime(e.value)}
                      timeOnly
                      hourFormat="24"
                    />
                    {!startTime ? (
                      <div className="text-danger">Please enter start time</div>
                    ) : null}
                  </Col>
                  <Col>
                    <Calendar
                      appendTo={null}
                      id="time12"
                      placeholder="End time"
                      value={endTime}
                      onChange={(e) => setEndTime(e.value)}
                      timeOnly
                      hourFormat="24"
                    />
                    {!endTime ? (
                      <div className="text-danger">Please enter end time</div>
                    ) : startTime &&
                      endTime &&
                      moment(startTime).isBefore(moment(endTime)) ? null : (
                      <div className="text-danger">
                        End-time should be after Start-time
                      </div>
                    )}
                  </Col>
                </Row>
              ) : (
                <div className="form-group form-label-group">
                  <InputText
                    keyfilter={/^(?:[1-9][0-9]*|0)$/}
                    id="tripValue"
                    type="number"
                    name="tripValue"
                    onChange={(e) => onChangeValue(e)}
                    className="shadow-none form-control modal-input br-10"
                    value={changeValue}
                  // placeholder={updateData?.title}
                  />
                  <label htmlFor="tripValue">Trip Value</label>
                  {!changeValue ? (
                    <div className="text-danger">Please enter value</div>
                  ) : null}
                  <label htmlFor="tripValue">{updateData?.title}</label>
                </div>
              )}

            </form>
          </Modal.Body>
          <Modal.Footer className="  px-0">
            <Button
              variant="primary"
              className="btn-save save-button mt-3 border-radius-15"
              onClick={updateSetting}
            >
              Update
            </Button>

            <Button
              variant="light"
              className="btn-cancel save-button mt-3 border-radius-15"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

export default React.memo(connect()(TripSettingDetailPage));

