import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { connect, useSelector } from "react-redux";
import riderAvtSec from "../../assest/images/placeHoderImage.png";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { Card, Col, Row } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select, { StylesConfig } from "react-select";
import APIService from "../../services/APIService";
import moment from "moment/moment";
import * as Yup from "yup";
import { toast } from "react-toastify";



let ManagementListingTable = (props) => {

  const statusOptions = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#ffffff"
          : isFocused
          ? "white"
          : undefined,
        color: isDisabled ? "white" : isSelected ? "#28a745" : "#000000",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : "white"
            : undefined,
        },
      };
    },
  };

  const [managements, setManagements] = useState(null);
  const [managementsData, setManagementsData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [showDepartmentModal, setShowDepartmentModal] = useState(false);
  const [showSubDepartmentModal,setShowSubDepartmentModal] = useState(false);
  const [createDepartmentModal,setCreateDepartmentModal] = useState(false)
  const [departments,setDepartments] = useState(null);
  const [subDepartments,setSubDepartments] = useState(null);
  const dt = useRef(null);
  const [windowWidth] = useState(useSelector((state) => state.windowSize));
  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState("management");
  const countryOptions = [
    { value: 1, label: "Saudia" },
    { value: 2, label: "Pakistan" },
  ];
  const cityName = [
    { value: "Saudia", label: "Riyad" },
    { value: "Pakistan", label: " islamabad" },
  ];
  const Departments = [
    { value: 1, label: "Developer" },
    { value: 2, label: "QA" },
  ];
  const subDepartment = [
    { value: 1, label: "UI" },
    { value: 1, label: "Backend" },
  ];
  const Postions = [
    { value: 1, label: "CEO" },
    { value: 2, label: "CTO" },
  ];
  const reports = [
    { value: 1, label: "report 1" },
    { value: 2, label: "report 2" },
  ];

  const [city, setCity] = useState(
    { value: "Saudia", label: "Riyad" },
    { value: "Pakistan", label: "islamabad" }
  );

  let [lazyParams, setLazyParams] = useState({
    filters: {
      userType: 1,
    },
    sort: {
      field: "createdAt",
      order: 1,
    },
    take: 5,
    skip: 0,
    keyword: "",
    totalRecords: 0,
  });

  const manageUsers = (event) => {
    const newLazyParams = { ...lazyParams };
    if (event.sortField) {
      newLazyParams.sort = {
        field: event.sortField,
        order: event.sortOrder,
      };
    } else if (event.first || event.first === 0) {
      newLazyParams.take = event.rows ? event.rows : newLazyParams.take;
      newLazyParams.skip = event.first;
    } else if (event.filters && Object.keys(event.filters).length) {
      newLazyParams.take = event.rows ? event.rows : newLazyParams.take;
      newLazyParams.skip = 0;
      newLazyParams.filters = {
        ...newLazyParams.filters,
        ...event.filters,
      };
    } else if (!event.filters || !Object.keys(event.filters).length) {
      newLazyParams.filters = {};
    }

    newLazyParams.filters.userType = 1;

    // Apply sorting and filtering on the complete dataset (managementsData)
    const sortedData = applySorting(
      newLazyParams.sort,
      lazyParams.keyword !== "" ? managements : managementsData
    );
    const filteredData = applyFiltering(newLazyParams.filters, sortedData);
    const currentPageData = filteredData.slice(
      newLazyParams.skip,
      newLazyParams.skip + newLazyParams.take
    );
    setManagements(currentPageData);
    newLazyParams.totalRecords = filteredData.length;
    setLazyParams(newLazyParams);
  };

  const applySorting = (sort, data) => {
    // Implement sorting logic here
    return data;
  };

  const applyFiltering = (filters, data) => {
    const { keyword } = filters;
    if (!keyword) {
      return data;
    }

    // Filter the data array based on the keyword
    const filteredData = data.filter((item) => {
      return item.name.toLowerCase().includes(keyword.toLowerCase());
    });

    return filteredData;
  };

  const searchFilter = (keyword, originalData) => {

    if (!keyword) {
      lazyParams.totalRecords = originalData.length; // Set total records to original data length
      setManagements(originalData); // Return the original data if no keyword is provided
    } else {
      // Filter the data array based on the keyword
      const filteredData = originalData.filter((item) => {
        // Customize this logic based on your data structure and the property you want to filter by
        return item?.fullName?.toLowerCase().includes(keyword.toLowerCase());
      });

      lazyParams.totalRecords = filteredData.length; // Set total records to filtered data length
      setManagements(filteredData);
    }
  };

  const reportsHandleClose = () => setShowReports(false);
  const reportsHandleShow = () => {
    setShowReports(true);
  };

  const handleShow = () => setShow(!show);
  const rules = [
    "Dashboard",
    "Accounting Management",
    "checked",
    "Adjust",
    "Master Control",
    "Customer Care",
    "User Management",
    "Communications",
  ];
  const renderHeader = () => {
    return (
      <>
        <div className="table-header">
          <span className="toggle-filter">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
              showFilter ? (
                <>
                  <img src="/images/filter-icon.svg" alt="" />
                  <span className="span-filter mx-12">Filters</span>
                  <i className="fa fa-caret-up"></i>
                </>
              ) : (
                <>
                  <img src="/images/filter-icon.svg" alt="" />
                  <span className="span-filter mx-12">Filters</span>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                      fill="#828282"
                    />
                  </svg>
                </>
              )}
            </button>
          </span>

          <span className="p-input-icon-left d-flex align-items-center">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search..."
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 5,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                searchFilter(lazyParams.keyword, managementsData);
              }}
            />
          </span>

          {/* {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'export') &&  */}
          <button
            className="btn btn-primary float-right mb-1 ml-12 text-nowrap"
            onClick={reportsHandleShow}
          >
            Export Report
          </button>
          <button className="btn btn-primary float-right mb-1 ml-12 text-nowrap" onClick={()=>setShowDepartmentModal(true)}>
            Create Department
          </button>
          <button
            className="btn btn-primary float-right mb-1 ml-12 text-nowrap"
            onClick={() => handleShow()}
          >
            {windowWidth ? <i className="fa fa-plus" /> : "+ Add "}
          </button>
        </div>
      </>
    );
  };

  const header = renderHeader();
  const toggleFilter = () => {
    setShowFilter(!showFilter);
    setLazyParams({
      filters: {
        userType: 1,
      },
      sort: {
        field: "creationDate",
        order: -1,
      },
      take: 5,
      skip: 0,
      keyword: "",
      totalRecords: 0,
      userType: 1,
    });
  };

  const renderActions = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <button className="btn btn-success">Edit</button>
          <button
            className="btn btn-danger ml-2"
            onClick={() => deleteUser(rowData)}
          >
            Delete
          </button>
          <button
            className="btn btn-warning ml-2"
            style={{ background: "#FFB065" }}
            onClick={() => updateUserStatus(rowData)}
          >
            {rowData?.status == true ? " Block" : "Unblock"}
          </button>
        </div>
      </>
    );
  };

  const renderStatus = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-center">
          <div
            className={`text-center text-white ${
              rowData.status === true ? "bg-success" : "bg-danger"
            } rounded`}
            style={{ width: "90px" }}
          >
            {rowData?.status == true ? "Active" : "InActive"}
          </div>
        </div>
      </>
    );
  };

  const renderID = (rowData) => {
    const userId = rowData.id.replace(/-/g, "").toUpperCase();
    const userID = userId.slice(0, 12);
    return (
      <>
        <div className=" d-flex align-items-center ">
          <img
            src={riderAvtSec}
            alt="person image"
            style={{ width: "48px", height: "48px", borderRadius: "100%" }}
          />
          <Link to={`/management-details/${rowData.id}`}>
            <div className=" text-success font-weight-bold ml-2">{userID}</div>
          </Link>
        </div>
      </>
    );
  };

  const renderAccessTime = (rowData) => {
    const originalDate = rowData.lastAccessedAt;
    const formattedDate = moment(originalDate).format("DD/MM/YYYY - hh:mm A");
    return (
      <>
        <div className=" text-dark font-weight-bold text-center">
          {rowData.lastAccessedAt ? formattedDate : "--"}
        </div>
      </>
    );
  };

  // api callig
  const deleteUser = async (user) => {
    try {
      const response = await APIService.delete(`admin/subadmin/${user?.id}`);
      getOnlineStatus();
    } catch (error) {
      console.error("Error fetching online status:", error);
    }
  };

  // update user status
  const updateUserStatus = async (user) => {
    try {
      const response = await APIService.patch(`admin/subadmin/${user?.id}`, {
        status: user?.status,
      });
      getOnlineStatus();
    } catch (error) {
      console.log("Failed to update user status", error);
    }
  };

  // get online status
  const getOnlineStatus = async () => {
    props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
    try {
      const response = await APIService.post("admin/subadmin/list");

      if (response?.data?.statusCode === 200) {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        const UpdateUserStatus = response?.data?.data?.results;
        lazyParams.totalRecords = response.data.data.total;
        setLazyParams(lazyParams);
        setManagements(UpdateUserStatus);
        setManagementsData(UpdateUserStatus);
      }
    } catch (error) {
      console.error("Error fetching online status:", error);
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
    } finally {
    }
  };

  useEffect(() => {
    getOnlineStatus();
  }, []);


  // department modals code 

  const getDepartments = async ()=>{
    try {
      const response = await APIService.get(`admin/get-departments-without-relations`);
  
      if (response.data.statusCode === 200) {
        const data = response?.data?.data;
        const transformedDepartments = data?.map((department) => ({
          value: department.id,
          label: department.name
        }));
        
        setDepartments(transformedDepartments);

      }
    } catch (error) {
      console.log('error', error);

    }

  }

  const getSubDepartments = async (subdepart)=>{
    try {
      const response = await APIService.get(`admin/get-departments-with-relations-m2`);
  ;
      if (response.data.statusCode === 200) {
        const data = response?.data?.data;
        if(subdepart){

          const department = data[0]?.filter((department)=>department?.id==subdepart.value);
          
          const subdepartment = department?.length > 0 ? department.map((subdepart) => {
            const subdepartments = subdepart?.subDepartment.map((subdep)=>({
              value: subdep.id,
              label: subdep.name
            }))
            return subdepartments
          }) : [];
          setSubDepartments(subdepartment[0]);
        }

      }
    } catch (error) {
      console.log('error', error);

    }

  }

  const CreateDepartment =async (values)=>{
    try {
      const response = await APIService.post(`admin/create-department-only`, {
        name:values?.name,
        status:values?.status
      });
  
      if (response.data.statusCode === 200) {
        toast.success('department added successfully')
        setShowSubDepartmentModal(true)
      }
    } catch (error) {
      console.log('error', error);
      toast.error('something went wrong')

    }
    getDepartments()

    setShowDepartmentModal(false)
  }
  const CreateSubDepartment =async (values)=>{
    try {
      const response = await APIService.post(`admin/create-subdepartment`, {
        name:values?.name,
        status:values?.status,
        departmentId:values?.departmentId
      });
  
      if (response.data.statusCode === 200) {
        toast.success('sub department added successfully')
        setCreateDepartmentModal(true)
      }
    } catch (error) {
      console.log('error', error);
      toast.error('something went wrong')

    }
    setShowSubDepartmentModal(false)
   }
   const DepartmentDesignation =async (values)=>{
    setCreateDepartmentModal(false);
    try {
      const response = await APIService.post(`admin/create-role-with-relations`, {
        title:values?.name,
        status:values?.status,
        subDepartmentId :values?.subDepartment
      });
  
      if (response.data.statusCode === 200) {
        toast.success('sub department added successfully')
      }
    } catch (error) {
      console.log('error', error);
      toast.error('something went wrong')

    }
   }

  return (
    <>
      <div className="datatable-doc-demo mt-3">
        <div className="card">
          <DataTable
            ref={dt}
            value={managements}
            header={header}
            className="p-datatable-customers"
            dataKey="id"
            rowHover
            scrollable
            scrollHeight="470px"
            paginator
            emptyMessage="No Request Found"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
            rowsPerPageOptions={[5, 10, 25, 50]}
            totalRecords={lazyParams.totalRecords}
            lazy={true}
            first={lazyParams.skip}
            rows={lazyParams.take}
            sortField={lazyParams.sort.field}
            sortOrder={lazyParams.sort.order}
            onPage={manageUsers}
            onSort={manageUsers}
            onFilter={manageUsers}
            loading={useSelector((state) => state.isGridLoader)}
            loadingIcon="fa fa-spinner"
          >
            <Column
              className="text-center font-weight-bold"
              style={{ fontSize: "12px", width: "230px" }}
              header="ID"
              field="id"
              body={renderID}
            />
            <Column
              className="text-center font-weight-bold"
              style={{ fontSize: "12px", width: "170px" }}
              header="Name"
              field="fullName"
            />
            <Column
              className="text-center font-weight-bold"
              style={{ fontSize: "12px", width: "170px" }}
              header="Mobile"
              field="mobile"
              body={(row) => {
                return <div>---</div>;
              }}
            />
            <Column
              className="text-center font-weight-bold"
              style={{ fontSize: "12px", width: "200px" }}
              header="Email"
              field="email"
            />
            <Column
              className="text-center font-weight-bold"
              style={{ fontSize: "12px", width: "170px" }}
              header="City"
              field="city"
              body={(row) => {
                return <div>---</div>;
              }}
            />
            <Column
              className="text-center font-weight-bold text-nowrap"
              style={{ fontSize: "12px", width: "200px" }}
              header="Last Access"
              field="lastAccessedAt"
              body={renderAccessTime}
            />
            <Column
              className="text-center font-weight-bold"
              style={{ fontSize: "12px", width: "190px" }}
              header="Status"
              field="status"
              body={renderStatus}
            />
            <Column
              className="text-center font-weight-bold"
              style={{ fontSize: "12px", width: "250px" }}
              header="Action"
              body={renderActions}
            />
          </DataTable>
        </div>
      </div>

      <Modal show={show} onHide={handleShow} centered size="lg">
        <Formik
        // initialValues={{ ...updateData }}
        // validationSchema={validationSchema}
        // onSubmit={createOrUpdateAdmin}
        >
          {({ handleBlur, setFieldValue }) => {
            return (
              <Form>
                <Modal.Header>
                  <Modal.Title className="modal-title">
                    Add Management
                  </Modal.Title>
                  <div className="divclose" onClick={handleShow}>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body className="mt-24">
                  <Row className="row">
                    <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Select User"
                          // isClearable
                          name="targetuser"
                          styles={colourStyles}
                          id="targetuser"
                          onBlur={() => {
                            handleBlur({ target: { name: "targetuser" } });
                          }}
                          onChange={(e) => {
                            //   if (e) {
                            //     setFieldValue("country", e.value);
                            //     setSelectedCountry(e);
                            //   }
                          }}
                          // value={selectedCountry}
                          options={countryOptions}
                        />
                        <div className="d-flex justify-content-end mt-1 text-success font-weight-bolder pointer">
                          Add Another Country
                        </div>
                        <label htmlFor="">Country</label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Select Category"
                          // isClearable
                          name="promotionCategory"
                          styles={colourStyles}
                          id="promotionCategory"
                          onBlur={() => {
                            handleBlur({
                              target: { name: "promotionCategory" },
                            });
                          }}
                          onChange={(e) => {
                            if (e) {
                              // setFieldValue("country", e.value);
                              setSelectedValue(e.value);
                              setCity(e);
                            }
                          }}
                          // value={cityName}
                          options={cityName}
                        />
                        <label htmlFor="">City</label>
                        {/* {(!selectedCountry || !selectedCountry?.value) && (
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )} */}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="fullName"
                          type="text"
                          name="fullName"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="name"
                          // value={adminName}
                          onChange={(e) => {
                            //   setAdminName(e.target.value)
                            //   setFieldValue("fullName", e.target?.value)
                          }}
                        />
                        <label htmlFor="fullName">Name</label>
                        <ErrorMessage
                          name="fullName"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group position-relative">
                        <Field
                          id="email"
                          type="text"
                          name="email"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Email"
                          // value={adminName}
                          onChange={(e) => {
                            //   setAdminName(e.target.value)
                            //   setFieldValue("fullName", e.target?.value)
                          }}
                        />
                        <label htmlFor="fullName">Email</label>
                        <span
                          className="position-absolute top-0 end-0 p-2 rounded font-weight-bold"
                          style={{
                            marginTop: "10px",
                            color: "#13B542",
                            background: "#E3F6E8",
                            marginRight: "10px",
                          }}
                        >
                          @ride.sa
                        </span>
                        <ErrorMessage
                          name="fullName"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="mobileNo"
                          type="number"
                          name="mobileNo"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Mobile No"
                          // value={adminName}
                          onChange={(e) => {
                            //   setAdminName(e.target.value)
                            //   setFieldValue("fullName", e.target?.value)
                          }}
                        />
                        <label htmlFor="fullName">Mobile No</label>
                        <ErrorMessage
                          name="fullName"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Postions"
                          // isClearable
                          name="postions"
                          styles={colourStyles}
                          id="postion"
                          onBlur={() => {
                            handleBlur({ target: { name: "targetuser" } });
                          }}
                          onChange={(e) => {
                            //   if (e) {
                            //     setFieldValue("country", e.value);
                            //     setSelectedCountry(e);
                            //   }
                          }}
                          // value={selectedCountry}
                          options={Postions}
                        />
                        <label htmlFor="">Postion</label>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Select User"
                          // isClearable
                          name="dpartments"
                          styles={colourStyles}
                          id="departments"
                          onBlur={() => {
                            handleBlur({ target: { name: "departments" } });
                          }}
                          onChange={(e) => {
                            //   if (e) {
                            //     setFieldValue("country", e.value);
                            //     setSelectedCountry(e);
                            //   }
                          }}
                          // value={selectedCountry}
                          options={Departments}
                        />
                        <label htmlFor="">Department</label>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Select Category"
                          // isClearable
                          name="subDepartment"
                          styles={colourStyles}
                          id="subDepartment"
                          onBlur={() => {
                            handleBlur({ target: { name: "subDepartment" } });
                          }}
                          onChange={(e) => {
                            if (e) {
                              // setFieldValue("country", e.value);
                              setSelectedValue(e.value);
                            }
                          }}
                          // value={cityName}
                          options={cityName}
                        />
                        <label htmlFor="">Sub Department</label>
                        {/* {(!selectedCountry || !selectedCountry?.value) && (
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          )} */}
                      </div>
                    </Col>

                    <div className=" text-dark font-weight-bold my-2">
                      Select Rules
                    </div>
                    <Row className="row">
                      {rules.map((rule) => {
                        return (
                          <>
                            <Col md={6}>
                              {rule === "checked" ? (
                                <>
                                  <div className=" d-flex align-items-center gap-5 mt-3">
                                    <div class="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked
                                      />
                                      <label>Rule 1</label>
                                    </div>
                                    <div class="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked
                                      />
                                      <label>Rule 2</label>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="d-flex align-items-center justify-content-between mt-3">
                                    <div className="text-dark font-weight-bold">
                                      {rule}
                                    </div>
                                    <div
                                      className="d-flex flex-grow-1 mx-1"
                                      style={{ border: "1px solid #F4F4F4" }}
                                    ></div>
                                    <div
                                      className=" text-success font-weight-bolder d-flex align-items-center justify-content-center"
                                      style={{
                                        fontSize: "24px",
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "50%",
                                        background: "rgba(6, 176, 4, 0.3)",
                                      }}
                                    >
                                      {rule == "Dashboard" ? "-" : "+"}
                                    </div>
                                  </div>
                                </>
                              )}
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                    <Row className="row">
                      <Col md={6}>
                        <div className="d-flex align-items-center justify-content-between mt-3">
                          <div className="text-dark font-weight-bold">
                            Trips Management
                          </div>
                          <div
                            className="d-flex flex-grow-1 mx-1"
                            style={{ border: "1px solid #F4F4F4" }}
                          ></div>
                          <div
                            className=" text-success font-weight-bolder d-flex align-items-center justify-content-center"
                            style={{
                              fontSize: "24px",
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              background: "rgba(6, 176, 4, 0.3)",
                            }}
                          >
                            +
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="row">
                      <Col md={6}>
                        <div className="d-flex align-items-center justify-content-between mt-3">
                          <div className="text-dark font-weight-bold">
                            Subscription Management
                          </div>
                          <div
                            className="d-flex flex-grow-1 mx-1"
                            style={{ border: "1px solid #F4F4F4" }}
                          ></div>
                          <div
                            className=" text-success font-weight-bolder d-flex align-items-center justify-content-center"
                            style={{
                              fontSize: "24px",
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              background: "rgba(6, 176, 4, 0.3)",
                            }}
                          >
                            +
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Col md={12} className="d-flex justify-content-center my-3">
                      <Col md={6}>
                        <Button
                          variant="success"
                          className="btn-save save-button mb-3 btn  btn-block"
                          // type="submit"
                          // onClick={createOrUpdateAdmin}
                        >
                          Add
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      <Modal
        className="modal-promotion"
        show={showReports}
        size="md"
        onHide={reportsHandleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Formik
        // validationSchema={
        //   selectedOption.value === 1
        //     ? validationSchemaAmount
        //     : validationSchemaPercentage
        // }
        // validationSchema ={validateSchema}
        // onSubmit={}
        >
          {({ handleBlur, setFieldValue, values }) => {
            return (
              <Form>
                <Modal.Header className="flex-modal-header">
                  <Modal.Title className="modal-title">
                    Export Report
                  </Modal.Title>
                  <div class="divclose" onClick={reportsHandleClose}>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body className="mt-24">
                  <Row className="justify-content-center">
                    <Col md={12}>
                      <div className="form-group form-label-group mb-3">
                        <Field
                          id="email"
                          type="email"
                          name="email"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Email ID"
                          value="@ride.sa"
                          // onChange={(e) => {
                          //   setEmailValue(e.target.value);
                          // }}
                        />
                        <label htmlFor="email">Email ID</label>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                      <div className="text-success font-weight-bold d-flex justify-content-end pointer">
                        Add New Email
                      </div>
                    </Col>

                    <Col md={12} className="mt-3">
                      <div className="form-label-group">
                        <Select
                          className="height-50 br-10 bg-color-select"
                          placeholder="Reports"
                          // isClearable
                          name="reports"
                          styles={colourStyles}
                          id="reports"
                          onBlur={() => {
                            handleBlur({ target: { name: "reports" } });
                          }}
                          onChange={(e) => {}}
                          options={reports}
                        />
                        <label htmlFor="">Reports (optional)</label>
                      </div>
                    </Col>

                    <Col md={12} className="mt-2">
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn-save btn-block "
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>


      {/* create Department modals  */}
      <Modal
          show={showDepartmentModal}
          onHide={() => setShowDepartmentModal(false)}
          size="lg"
          centered
        >
          <Modal.Header>
            <Modal.Title className="modal-title mb-4">
              Create Department
            </Modal.Title>
            <div className="divclose" onClick={()=>setShowDepartmentModal(false)}>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                name: '', // Initialize your form field values here
                status: '', // Initialize the status field
                // id: '',
              }}
              validationSchema={Yup.object({

                name: Yup.string().trim().required('Name is required'),
                status: Yup.string().required("Status is required"), // Add validation for the status field
              })}
              onSubmit={(values) => {

                CreateDepartment(values);
              }}
            >
              {(formik) => (
                <Form>
                  <Row>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="userid"
                          type="text"
                          name="name"
                          className="shadow-none form-control modal-input br-10"
                        />
                        <label htmlFor="userid">Name</label>
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <label htmlFor="status">Status</label>
                        <Select
                          id="status"
                          name="status"
                          className="height-50 br-10 bg-color-select"
                          options={statusOptions}
                          placeholder="Select a status"
                          onChange={(selectedOption) => {
                            formik.setFieldValue(
                              "status",
                              selectedOption.value
                            );
                          }}
                        />
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    </Row>

                  <Row>
                  

            <Col md={12}>
              <Button
                variant="success"
                className="btn-save save-button btn btn-block text-white  text-center d-flex align-items-center  justify-content-center fw-bold"
                style={{ height: '58px' }}
                type="submit"
              >
                Create
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  </Modal.Body>
</Modal>

{/* sub-department modal  */}
        <Modal
          show={showSubDepartmentModal}
          onHide={() => setShowSubDepartmentModal(false)}
          size="lg"
          centered
        >
          <Modal.Header>
            <Modal.Title className="modal-title mb-4">
              Create Sub Department
            </Modal.Title>
            <div className="divclose" onClick={()=>setShowSubDepartmentModal(false)}>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                name: '', // Initialize your form field values here
                departmentId:'',
                status: '', // Initialize the status field
              }}
              validationSchema={Yup.object({
                name: Yup.string().trim().required("Name is required"),
                departmentId:Yup.string().required('Department is required'),
                status: Yup.string().required("Status is required"), // Add validation for the status field
              })}
              onSubmit={(values) => {

                CreateSubDepartment(values);
              }}
            >
              {(formik) => (
                <Form>
                  <Row>
                  <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <label htmlFor="status">Department</label>
                        <Select
                          id="departmentId"
                          name="departmentId"
                          className="height-50 br-10 bg-color-select"
                          options={departments}
                          placeholder="Select a Department"
                          onChange={(selectedOption) => {
                            formik.setFieldValue(
                              "departmentId",
                              selectedOption.value
                            );
                          }}
                        />
                        <ErrorMessage
                          name="departmentId"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <label htmlFor="status">Status</label>
                        <Select
                          id="status"
                          name="status"
                          className="height-50 br-10 bg-color-select"
                          options={statusOptions}
                          placeholder="Select a status"
                          onChange={(selectedOption) => {
                            formik.setFieldValue(
                              "status",
                              selectedOption.value
                            );
                          }}
                        />
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    </Row>

                  <Row>
                  
                  <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="userid"
                          type="text"
                          name="name"
                          className="shadow-none form-control modal-input br-10"
                        />
                        <label htmlFor="userid">Name</label>
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
            <Col md={6}>
              <Button
                variant="success"
                className="btn-save save-button text-white  btn btn-block text-center d-flex align-items-center  justify-content-center fw-bold"
                style={{ height: '58px' }}
                type="submit"
              >
                Create
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  </Modal.Body>
</Modal>

{/* Department designation modal  */}
<Modal
          show={createDepartmentModal}
          onHide={() => setCreateDepartmentModal(false)}
          size="lg"
          centered
        >
          <Modal.Header>
            <Modal.Title className="modal-title mb-4">
              Create Department Role
            </Modal.Title>
            <div className="divclose" onClick={()=>setCreateDepartmentModal(false)}>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                name: '', // Initialize your form field values here
                department: '', // Initialize the status field
                subDepartment:'',
                status:'',
              }}
              validationSchema={Yup.object({
                name: Yup.string().trim().required("Name is required"),
                subDepartment: Yup.string().required("Sub Department is required"),
                status: Yup.string().required("status is required"),
              })}
              onSubmit={(values) => {

                DepartmentDesignation(values);
              }}
            >
              {(formik) => (
                <Form>
                  <Row>
                    <Col md={6}>
                      <div className="form-group form-label-group">
                        <Field
                          id="userid"
                          type="text"
                          name="name"
                          className="shadow-none form-control modal-input br-10"
                        />
                        <label htmlFor="userid">Name</label>
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                  <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <label htmlFor="status">Department</label>
                        <Select
                          id="department"
                          name="department"
                          className="height-50 br-10 bg-color-select"
                          options={departments}
                          placeholder="Select a Department"
                          onChange={(selectedOption) => getSubDepartments(selectedOption)}
                        />
                        <ErrorMessage
                          name="department"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    </Row>

                  <Row>
                  

                  <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <label htmlFor="status">Sub Department</label>
                        <Select
                          id="subDepartment"
                          name="subDepartment"
                          className="height-50 br-10 bg-color-select"
                          options={subDepartments}
                          placeholder="Select a Sub Department"
                          onChange={(selectedOption) => {
                            formik.setFieldValue(
                              "subDepartment",
                              selectedOption.value
                            );
                          }}
                        />
                        <ErrorMessage
                          name="subDepartment"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-4 form-label-group">
                        <label htmlFor="status">Status</label>
                        <Select
                          id="status"
                          name="status"
                          className="height-50 br-10 bg-color-select"
                          options={statusOptions}
                          placeholder="Select a status"
                          onChange={(selectedOption) => {
                            formik.setFieldValue(
                              "status",
                              selectedOption.value
                            );
                          }}
                        />
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
          </Row>
          <Row>
          <Col md={12}>
              <Button
                variant="success"
                className="btn-save save-button btn btn-block text-center d-flex align-items-center text-white justify-content-center fw-bold"
                style={{ height: '58px' }}
                type="submit"
              >
                Create
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  </Modal.Body>
</Modal>
    </>
  );
};

export default React.memo(connect()(ManagementListingTable));
