import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import APIService from "../../services/APIService";
let UserLogs = (props) => {
  let externalId = props.externalId;

  let demoLogs = [
    {
      ipAddress: '192.0.1.2',
      location: 'Riyadh, Saudi Arabia',
      loginTime: '12/12/2023T12:12:12:000Z',
      logoutTime: '12/12/2023T12:24:12:000Z',
      timeDifference: '12'
    }
  ]
  let [Logs, setLogs] = useState([]),
    dt = useRef(null),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 5,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    logData,
    getUserLogs = () => {
      APIService.get('admin/get-my-logs?userId=' + externalId + '&take=10&skip=0')
      .then((response) => {
        if (response.data && response.data.statusCode === 200) {
          logData = response.data.data.result;
          console.log(response)
          
          // setUserLogs(logData)
          setLazyParams({ ...lazyParams, totalRecords: logData.length });
        }
      })
      .catch((error) => {
        console.log(error);
      });
      setLogs(logData??demoLogs)
    };

  useEffect(() => {
    getUserLogs();
    // console.log(Logs)
  }, []);


  return (
    <div className="datatable-doc-demo ">
      <h2 className="box-title">User Logs </h2>
      <div className="card mt-3">
        <DataTable
          ref={dt}
          value={Logs??demoLogs}
          // header={header}
          className="p-datatable-customers"
          dataKey="id"
          //   globalFilter={globalFilter}
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Subscription(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[5, 10, 25]}
          totalRecords={Logs?.length}
          //   lazy={true}
          //   first={lazyParams.skip}
          rows={lazyParams.take}
          //   onPage={manageActiveSubscription}
          //   onSort={manageActiveSubscription}
          //   onFilter={manageActiveSubscription}
          //   sortField={lazyParams.sort.field}
          //   sortOrder={lazyParams.sort.order}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Device IP"
            field="ipAddress"
            body={(value) =>{
              return <span>{value?.ipAddress ? value?.ipAddress : '-'}</span>
            }}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Location"
            field="location"
            body={(value) =>{
              return <span>{value?.location ? value?.location : '-'}</span>
            }}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Date"
            field="loginTime"
            body={(value) =>{
              return <span>{value?.loginTime ? value?.loginTime.slice(0, 10) : '-'}</span>
            }}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Login Time"
            field="loginTime"
            body={(value) =>{
              return <span>{value?.loginTime ? value?.loginTime.slice(11, 19) : '-'}</span>
            }}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Logout Time"
            field="logoutTime"
            body={(value) =>{
              return <span>{value?.logoutTime ? value?.logoutTime.slice(11, 19) : '-'}</span>
            }}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Duration"
            field="timeDifference"
            body={(value) =>{
              return <span>{value?.timeDifference ? value?.timeDifference : '-'}</span>
            }}
          />


        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(UserLogs));
