import React, { useState } from 'react'
import { TabView, TabPanel } from 'primereact/tabview';
import { secret_key, _baseURL } from '../../../services/APIService';
import Calendar from '../../Dashboard_2/Calendar';
// import DoughnutCMS from './Doughnut';
import BarChart from './BarChart';
import IssueResolvingTime from './IssueResolvingTime';
import { useEffect } from 'react';
import { driverInitialArray, riderInitialArray } from './data';

const IsssueResolvingComponent = (props) => {
  let isuueResolvingApi = `${_baseURL}/api/v1/ticket/${props.apiVar}`;

  const [activeIndex, setActiveIndex] = useState(0);
  const [driverData, setDriverData] = useState([]);
  const [riderData, setRiderData] = useState([]);

  function mergeArrays(initialArray, updatedArray) {
    // Create a map of category in the updated array to quickly find and update values
    const categoryMap = {};
    updatedArray.forEach((item) => {
      categoryMap[item.category] = item;
    });

    // Merge the arrays, updating values if the cagory exists in the updated array
    const mergedArray = initialArray.map((item) => {
      const updatedItem = categoryMap[item.category];
      if (updatedItem) {
        return {
          category: item.category,
          ...updatedItem,
        };
      }
      return item;
    });

    return mergedArray;
  }

  const fetchIssueResolvingApi = async (updatedApi = null) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
          // "Timezone":timeUtc
        },
        body: JSON.stringify({
          secret_key: secret_key,
        }),
      };

      let result = await fetch(updatedApi ?? isuueResolvingApi, requestOptions);
      let response = await result.json();
      if (response?.success) {
        setDriverData(mergeArrays(driverInitialArray, response.data?.driverResolvingTimeIssues ?? []))
        setRiderData(mergeArrays(riderInitialArray, response.data?.riderResolvingTimeIssues ?? []))
      }
    } catch (e) {
      throw new Error(e);
    }
  }
  const getTimeRange = (range) => {
    let updatedApi = `${_baseURL}/api/v1/ticket/${props.apiVar}?fromDate=${range.sDate}&toDate=${range.eDate}`;
    fetchIssueResolvingApi(updatedApi);
  }
  console.log('driverd',driverData)
  useEffect(() => {
    fetchIssueResolvingApi();
  }, [])
  return (<div>
    <div className="section-rider cards-section">
      <div className="d-flex justify-content-between text-center align-items-center position-relative">
        <h2 className="box-title">{props.name}</h2>
        <Calendar handleTimeRange={getTimeRange} />
      </div>
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="Captain">
          <IssueResolvingTime comp={props?.name} data={driverData} />
        </TabPanel>
        <TabPanel header="Rider">
          <IssueResolvingTime comp={props?.name} data={riderData} />
        </TabPanel>
      </TabView>
    </div>
  </div>
  )
}

export default IsssueResolvingComponent