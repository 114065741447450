import React, { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import moment from "moment";
import { CSVLink } from "react-csv";

let EscalationsDatatable = (props) => {
  let  [downlaodCsv, setDownloadCsv] = useState([]),
    CSVdownloadRef = useRef(null),
    [showFilter, setShowFilter] = useState(false),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    currentRoutePermissions = permissions.filter(a => a.includes('/admin/user/')),
    dt = useRef(null),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    dummyData = [
        {
          escalationId: 6644123,
          managementUser: "John Jacob",
          mobileNo: "+96612345678",
          city: "Riyadh",
          country: "Saudi Arabia",
          noOfEscalations: 5
        },
        {
          escalationId: 1278915,
          managementUser: "John Jacob",
          mobileNo: "+96687654321",
          city: "Riyadh",
          country: "United Arab Emirates",
          noOfEscalations: 7
        },
        {
          escalationId: 9765432,
          managementUser: "John Jacob",
          mobileNo: "+96678990987",
          city: "Jeddah",
          country: "Bahrain",
          noOfEscalations: 3
        },
        {
          escalationId: 2387654,
          managementUser: "John Jacob",
          mobileNo: "+96676544567",
          city: "Dammam",
          country: "Qatar",
          noOfEscalations: 9
        },
        {
          escalationId: 8907234,
          managementUser: "John Jacob",
          mobileNo: "+96611223344",
          city: "Mecca",
          country: "Oman",
          noOfEscalations: 2
        },
        {
          escalationId: 6514321,
          managementUser: "John Jacob",
          mobileNo: "+96688997755",
          city: "Medina",
          country: "Kuwait",
          noOfEscalations: 6
        },
      ],
    headers = [
      { label: "Full Name", key: "fullName" },
      { label: "Role", key: "roleName" },
      { label: "Email", key: "email" },
      { label: "Last Access", key: "date" },
      { label: "Status", key: "status" },
    ],
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="toggle-filter" >
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
                showFilter ? (
                  <>
                    <img src="/images/filter-icon.svg" alt="" />
                    <span className="span-filter mx-12">Filters</span>
                    <i className="fa fa-caret-up "></i>
                  </>
                ) : (
                  <>
                    <img src="/images/filter-icon.svg" alt="" />
                    <span className="span-filter mx-12">Filters</span>
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                        fill="#828282"
                      />
                    </svg>
                  </>
                )}
            </button>
          </span>
          <span className="p-input-icon-left d-flex align-items-center ml-2" style={{height:48}}>
            <i className="pi pi-search " style={{marginTop:'-10px'}} />
            <InputText
              type="search"
              placeholder="Search"
              // onInput={(e) => setGlobalFilter(e.target.value)}
            />
          </span>
          <CSVLink
            data={downlaodCsv}
            headers={headers}
            filename="Sub Admin.csv"
            target="_blank"
            ref={CSVdownloadRef}
          />
          <button
            className="btn btn-primary mb-1 ml-12"
            style={{width:"150px"}}
            // onClick={() => downloadCsvFile()}
          >
            Export Report
          </button>
          {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'add') && <button
            className="btn btn-primary mb-1 ml-12"
            style={{width:"70px"}}
            onClick={props.handleShow}
          >
            {windowWidth ? <i className="fa fa-plus" /> : "+ Add"}
          </button>}
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      dt.current.reset();
      setShowFilter(!showFilter);
    },
    actionTemplate = (rowData) => {
      return (
        <>
        <Button label="Edit" onClick={props.handleEditShow} className="mr-2" style={{background:"#13B542"}}/>
        </>
      );
    },
    filterDate = (value, filter) => {
      return value &&
        filter &&
        moment(value).format("DD/MM/YYYY") ===
        moment(filter).format("DD/MM/YYYY")
        ? true
        : false;
    };

  // get subAdmins data into csv file
  const downloadCsvFile = () => {
    setDownloadCsv(props.admins);
    setTimeout(() => {
      CSVdownloadRef.current.link.click();
    }, 500);
  };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={dummyData}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          paginator
          rows={25}
          loading={props.isLoading}
          loadingIcon="fa fa-spinner"
          emptyMessage="No Promotions found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          scrollable
          scrollHeight="470px"
        >
          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "120px",color:"#13B542" }}
            sortField="fullName"
            filterField="fullName"
            header="Escalation ID"
            field="escalationId"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by ID"
            // body={(rowData) => {
            //   return (
            //     <span
            //       style={{
            //         color: "rgb(19, 181, 66)",
            //         textDecoration: " underline",
            //         cursor: "pointer",
            //         textTransfor: " capitalize",
            //       }}
            //     >
            //       {" "}
            //       {rowData.fullName}
            //     </span>
            //   );
            // }}
          />

          <Column
            headerClassName="text-center"
            className="text-center"
            style={{ fontSize: "12px", width: "150px" }}
            field="managementUser"
            header="Management User"
            sortable
            filter={showFilter}
          />
          <Column
            headerClassName="text-left"
            className="text-left"
            style={{ fontSize: "12px", width: "120px" }}
            field="mobileNo"
            header="Mobile No."
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by Issue Date"
          />
                    <Column
            headerClassName="text-center"
            className="text-left text-center"
            style={{ fontSize: "12px", width: "120px" }}
            field="city"
            header="City"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by Issued By"
          />
                              <Column
            headerClassName="text-left"
            className="text-left"
            style={{ fontSize: "12px", width: "100px" }}
            field="country"
            header="Country"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by Issued By"
          />
                              <Column
            headerClassName="text-left"
            className="text-left"
            style={{ fontSize: "12px", width: "150px" }}
            field="noOfEscalations"
            header="No of Escalations"
            sortable
            filter={showFilter}
            filterMatchMode="contains"
            filterPlaceholder="Search by Issued By"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "100px" }}
            header="Action"
            headerClassName="text-center"
            body={actionTemplate}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(EscalationsDatatable);
