import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { classNames } from "react-select/dist/index-ea9e225d.cjs.prod";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import { _baseURL, secret_key } from "../../services/APIService";
import Calendar from "../Dashboard_2/Calendar";
import APIService from "../../services/APIService";
function CustomerCare({customerId}) {
  const [riderTickets, setRiderTickets] = useState(null);
 const currentDate =  moment().format('YYYY-MM-DD');
 const [ticketStatus,setTicketStatus] = useState(null);


  const [driverTickets, setDriverTickets] = useState(null);
  const [chartData ,setChartData] = useState(true);
  const [riderDataset,setRiderDataset] =useState( {
    datasets: [
      {
        data: [ticketStatus?.open,ticketStatus?.closed,ticketStatus?.progress],
        backgroundColor: ["#EB3D3D", "#13B542", "#FFB065"],
        hoverBackgroundColor: ["#EB3D3D", "#13B542", "#FFB065"],
        borderWidth: 0,
      },
    ],
  });
  const [driverDataset,setDriverDataset] =useState( {
    datasets: [
      {
        data: [22,18,47],
        backgroundColor: ["#EB3D3D", "#13B542", "#FFB065"],
        hoverBackgroundColor: ["#EB3D3D", "#13B542", "#FFB065"],
        borderWidth: 0,
      },
    ],
  });

  const getCustomorCounts = async () => {
    const apiUrl = 'https://cmsride.xintdev.com/api/v1/ticket/ticket-status-by-id';
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
          // "Timezone":timeUtc
        },
        body: JSON.stringify({
          secret_key: secret_key,
        //  secret_key="xBJbW0S1OIFJl9bsjsdZ",
        //  fromDate:"2022-06-12 16:35:50",
        //  toDate:"2023-08-18 16;36:03",
         ride_customer_id:customerId??customerId,
        })
      };

      let result = await fetch(apiUrl, requestOptions);
      let response = await result.json();
      console.log('Rider.. response',response.data?.ticketStatus)
      setTicketStatus(response?.data?.ticketStatus);
      
    } catch (e) {
      throw new Error(e);
    }
  }

  useEffect(()=>{

    getCustomorCounts()
  },[])


  const getTimeRange= async (range)=>{
    const {sDate , eDate } = range;

    const startDate = moment(sDate , "DD/MM/YYYY").format('YYYY-MM-DD');
    const endDate = moment(eDate , "DD/MM/YYYY").format('YYYY-MM-DD');
       console.log('data ',sDate,eDate)

       try {
    const apiUrl = 'https://cmsride.xintdev.com/api/v1/ticket/ticket-status-by-id';
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            "Access-Control-Allow-Origin": "*",
            // "Timezone":timeUtc
          },
          body: JSON.stringify({
            secret_key: secret_key,
          //  secret_key="xBJbW0S1OIFJl9bsjsdZ",
           fromDate:sDate,
           toDate:eDate,
           ride_customer_id:customerId??customerId
          })
        };
  
        let result = await fetch(apiUrl, requestOptions);
        let response = await result.json();
        console.log('customer response',response.data?.ticketStatus)
        setTicketStatus(response?.data?.ticketStatus);
        
      } catch (e) {
        throw new Error(e);
      }
  }


  const  windowWidth = window.innerWidth,
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    type: "doughnut",
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: windowWidth > 992 ? 95 : 105,
    pointStyle: "circle",

    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
        position: windowWidth > 992 ? "bottom" : "bottom",
        labels: {
          boxWidth: 100,
          usePointStyle: true,
          fontSize: 60,
        },
      },
    },
  };
  let emptyTrips = {
    datasets: [
      {
        data: [10],
        backgroundColor: ["rgba(241, 241, 241, 1)"],
        hoverBackgroundColor: ["rgba(241, 241, 241, 1)"],
        borderWidth: 0,
      },
    ],
  };
  
  

  const options = {
    plugins: {
      tooltip: {
        displayColors: false,
        backgroundColor: "#fff",
        fontWeight: "bold",
        bodyFontSize: "30",
        borderWidth: 1,
        padding: 12,
        yAlign: "bottom",

        callbacks: {
          label: function () {
            var label = "101";
            return label;
          },

          beforeLabel: function () {
            var beforelabel = "Open Tickets";
            return beforelabel;
          },
          afterLabel: function () {
            var afterlabel = "View All";
            return afterlabel;
          },
          labelTextColor: function (context) {
            return "#828282";
          },
        },
      },
    },
  };

  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 380).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = `Tickets: ${ticketStatus?.tickets?ticketStatus.tickets:0}`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillStyle = "#828282";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

 
  useEffect(() => {
    setRiderDataset( {
      datasets: [
        {
          data: [ticketStatus?.open,ticketStatus?.closed,ticketStatus?.progress],
          backgroundColor: ["#EB3D3D", "#13B542", "#FFB065"],
          hoverBackgroundColor: ["#EB3D3D", "#13B542", "#FFB065"],
          borderWidth: 0,
        },
      ],
    })
    setDriverDataset( {
      datasets: [
        {
          data: [55,14,29],
          backgroundColor: ["#EB3D3D", "#13B542", "#FFB065"],
          hoverBackgroundColor: ["#EB3D3D", "#13B542", "#FFB065"],
          borderWidth: 0,
        },
      ],
    })
  }, [riderTickets,driverTickets]);

  return (
    <>
      <div className="section-rider col-lg-12 section-captain h-100">
        <div className="card-nav mb-16">
          <h2 className="box-title text-wrap">Customer Care Tickets</h2>
          <div>
            <div className="dropdown">
              <Calendar handleTimeRange={getTimeRange} />
            </div>
          </div>
        </div>
        

        {(ticketStatus?.open=='0%' || ticketStatus?.closed=='0%' || ticketStatus?.progress=='0%') ? (
              <Doughnut
                data={emptyTrips}
                width={null}
                height={windowWidth > 992 ? 150 : null}
                options={chartOptions}
                plugins={plugins}
              />
            ) : (
              <Doughnut
                type="doughnut"
                width={null}
                height={windowWidth > 992 ? 150 : null}
                data={riderDataset}
                plugins={plugins}
                options={chartOptions}
              />
            )}

            <div className="info-section">
              <div className="row">
                <div className="container-fluid">
                  <div className="main-percent line-main">
                    <div className="percentage cc-percent">
                      <div className="d-flex">
                        <div className="green"></div>
                        <div className="percentext flex-grow-1">Open</div>
                      </div>
                      <div>
                        <div className="percenPoint">
                                    {ticketStatus?.open}
                        </div>
                      </div>
                    </div>

                    <div className="percentage cc-percent">
                      <div className="d-flex">
                        <div className="red"></div>
                        <div className="percentext flex-grow-1">Closed</div>
                      </div>
                      <div>
                        <div className="percenPoint">
                        {ticketStatus?.closed}
                        </div>
                      </div>
                    </div>

                    <div className="percentage cc-percent">
                      <div className="d-flex">
                        <div
                          className="green"
                          style={{ background: "#FFB065" }}
                        ></div>
                        <div className="percentext flex-grow-1">Progress</div>
                      </div>
                      <div>
                        <div className="percenPoint">
                        {ticketStatus?.progress}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
             
            </div>

            <div className="main-percent rPercen justify-content-center"></div>
    
      </div>
    </>
  );
}

export default CustomerCare;
