import React, { useState, useEffect } from "react";
import cardIcon from "./images/cardIcon.svg";
import { Line } from "react-chartjs-2";
import droparrow from "./images/droparrow.svg";
import APIService from "../../services/APIService";
import { TabView, TabPanel } from "primereact/tabview";
import CancelledTrips from "./CancelledTrips";
import Timezone from "./Timezone";
import Calendar from "./Calendar";
import moment from 'moment';
import { barChartOptions, convertDataBasedOnRequirement } from "./linechartdata/linechartdata";

function Linechart(props) {
  const { topCardTrips ,locationDataset,rideNamesData} = props;
  const [activeIndex, setActiveIndex] = useState(0);
  let [graphCountList, setgraphCountList] = useState(null),
    [lineGraphData, setLineGraphData] = useState({}),
    [updatedData,setUpdatedData]=useState(null),
    [timeRange, setTimeRange] = useState("week")
  const [dateRange, setDateRange] = useState(null);
  const [statusCounts,setStatusCounts]=useState({});
  const [applyFilter,setApplyFilter] = useState(false);
  const [locationData,setLocationsData]=useState(null);
  const [startDate,setStartDate] = useState('');
  const [endDate,setEndDate] = useState('');
  const [ridesNames, setRidesNames] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    id: "",
    name: ""
  });

  const [selectedCity, setSelectedCity] = useState({
    id: "",
    name: ""
  });
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  }
  const getTimeRange=(range)=>{
    setStartDate(range.sDate);
    setEndDate(range.eDate);
  }

  useEffect(()=>{
    if(rideNamesData){
      setRidesNames(rideNamesData)
    }
  },[JSON.stringify(rideNamesData)])
  
  useEffect(() => {
    const buildApiUrl = () => {
      // Start building the URL with common parameters
      const baseUrl = 'admin/dashboard/trip-stats';

const params = {
    fromDate: startDate ? `${startDate} 00:00:00.000000` :'',
    toDate: endDate ? `${endDate} 23:59:59.000000`:'',
    countryId: selectedCountry?.id, 
    cityId:selectedCity?.id, 
    cabId: selectedValue,
    type:(startDate && endDate) ? 'custom' : ''
};

const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value !== undefined && value !== null && value !== '')
);

const queryString = new URLSearchParams(filteredParams).toString();

return queryString ? `${baseUrl}?${queryString}` : baseUrl;
     
    };
    
    const fetchData = async () => {

      try {
        const url = buildApiUrl();

        if(url){
          setApplyFilter(true)

  
        const response = await APIService.get(url);
  
        if (response.data.statusCode === 200) {
          setUpdatedData(response?.data?.data)
          setDateRange(response?.data?.data?.dateRange)
            if(response?.data?.data?.graphList){
              const convertToFormat = convertDataBasedOnRequirement(response?.data?.data?.graphList);
              setLineGraphData(convertToFormat);
            }
            setStatusCounts(response?.data?.data?.statusCounts);
          setgraphCountList(
            response.data &&
            response.data.data &&
            response.data.data.percentaged
          )
        }
        }
      } catch (error) {
        console.error(error);
      }
    };
       fetchData();
    
  
  }, [ endDate, selectedCountry, selectedCity,selectedValue]);  

  useEffect(() =>{
    if(locationDataset){
      setLocationsData(locationDataset);
    }
  },[JSON.stringify(locationDataset)])

  useEffect(() => {
    // Extract country options from the data
    const countries = locationData?.map((country) => ({
      id: country.id,
      name: country.countryName
    }));
    setCountryOptions(countries);

    // Set the default selected country(
    if(countries?.length > 0){
      setSelectedCountry(countries[0]);

    }

    // Set the default selected city if available
    const defaultCity = locationData?.length > 0 && locationData[0]?.city[0];
    if (defaultCity) {
      setSelectedCity({ id: '', name: '' });
    }
  }, [JSON.stringify(locationData)]);

  useEffect(() => {
    // Extract city options based on the selected country
    const selectedCountryData = locationData?.find(
      (country) => country.countryName === selectedCountry.name
    );
    if (selectedCountryData) {
      const cities = selectedCountryData?.city?.map((city) => ({
        id: city.id,
        name: city.cityName
      }));
      setCityOptions(cities);

      // Set the default selected city
      setSelectedCity({});
    }
  }, [selectedCountry, JSON.stringify(locationData)]);

  const handleCountryChange = (e) => {
    const selectedCountryName = e.target.value;
    const selectedCountryData = countryOptions?.find(
      (country) => country.name === selectedCountryName
    );
    
  setSelectedCountry(selectedCountryData || {});
  };

  const handleCityChange = (e) => {
    const selectedCityName = e.target.value;
    const selectedCityData = cityOptions?.find(
      (city) => city.name === selectedCityName
    );
    setSelectedCity(selectedCityData || {});
  };


  useEffect(() => {
    let dataGraphList = props?.sendgraphTripData?.graphList
    if (dataGraphList !== undefined) {
      const convertedData = convertDataBasedOnRequirement(dataGraphList);
      setLineGraphData(convertedData);
    }
  }, [props.sendgraphTripData])

  useEffect(() => {
    setTimeRange(props.timeRange);
  }, [props.timeRange]);

 
  useEffect(()=>{
    setStatusCounts(graphCountList
      ? graphCountList
      : props?.sendgraphTripData?.statusCounts)
  },[props?.sendgraphTripData?.statusCounts])


  return (
    <div className=" containre-fluid cards-section line-section h-100">
      <div className="card-nav card-nv2 mb-16 zoom-80">
        <h2 className="box-title" style={{fontSize:"24px"}}>Total Trips: {(statusCounts?.completed + statusCounts?.unmatched + statusCounts?.cancelled) || 0}</h2>
        <div>
          <div className="dropdown">
          <div className="selectbox">
        <select
          className="select w-100 outline-none border-0"
          style={{ minWidth:'fit-content', height: 35, borderRadius: 5 ,textTransform:'capitalize'}}
          value={selectedCountry.name}
          onChange={handleCountryChange}
        >
           <option key={''} value={''}>
              All
            </option>
          {countryOptions?.map((country, index) => (
            <option style={{textTransform:'capitalize'}} key={index} value={country.name}>
              {country.name}
            </option>
          ))}
        </select>
      </div>
      <div className="selectbox">
        <select
          className="select w-100 outline-none border-0"
          style={{ minWidth:'fit-content', height: 35, borderRadius: 5 ,textTransform:'capitalize', maxWidth:'115px'}}
          value={selectedCity.name}
          onChange={handleCityChange}
        >
           <option key={''} value={''}>
              All
            </option>
          {cityOptions?.map((city, index) => (
            <option key={index} value={city.name}>
              {city.name}
            </option>
          ))}
         
        </select>
      </div>

      <div className="selectbox">
                <select
                  className="select w-100 outline-none border-0 "
                  style={{ width: "fit-content" ,height:35}}
                  value={selectedValue} // Set the selected value
                  onChange={handleSelectChange} // Handle changes
                >
                  <option key={''} value={''}>
        All
      </option> 
               {ridesNames.map((ride) => (
          <option key={ride.id} value={ride.id}>
            {ride.name}
          </option>
        ))}                              </select>
              </div>
            {/* <div className="selectbox align-items-center d-inline-block float-right">
              <select
                style={{ width: "fit-content" }}
                value={timeRange}
                className="select outline-none border-0 "
                onChange={manageTimeRange}
              >
                <option value="day">Today</option>
                <option value="week">Last 7 Days</option>
                <option value="month">By Week</option>
                <option value="year">By Month</option>
              </select>
            </div> */}


            <Calendar handleTimeRange={getTimeRange} />
          </div>
        </div>
      </div>

      {/* section heading */}

      <TabView
        className="mt-2"
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="All">
          {" "}
          <div className="tab-pane fade active show" id="All">
            <div className="col-12">
              <h6 className=" mb-2 graph-label left-text rounded text-capitalize" style={{left:-20}}>
                Trips
              </h6>
              {Object.keys(lineGraphData).length > 0 && (
                <Line data={lineGraphData} options={barChartOptions} />
              )}
              <h6 className=" graph-label-2 left-text rounded text-capitalize">
                {dateRange && `${moment(dateRange?.startDate).format('DD MMM YYYY')} - ${moment(dateRange?.endDate).format('DD MMM YYYY')}`}
              </h6>
            </div>
{/* <div style={{textAlign:'center'}}>September 2022</div> */}
            <div className="col-12 graph-menu g-menu">
              <div className="main-percent line-main">
                <div className="percentage">
                  <div className="green mt-0"></div>
                  <div className="percentext">Completed:</div>
                  <div className="percenPoint">{statusCounts?.completed || 0}</div>
                </div>

                <div className="percentage">
                  <div className="red mt-0"></div>
                  <div className="percentext">Cancelled :</div>
                  <div className="percenPoint">{statusCounts?.cancelled || 0}</div>
                </div>

                <div className="percentage">
                  <div
                    className="green mt-0"
                    style={{ background: "#13A5B5" }}
                  ></div>
                  <div className="percentext">No Captain Found:</div>
                  <div className="percenPoint">{statusCounts?.unmatched || 0}</div>
                </div>
                <div className="percentage">
                  <div
                    className="green mt-0"
                    style={{ background: "#FFB065" }}
                  ></div>
                  <div className="percentext">Ignored:</div>
                  <div className="percenPoint">{statusCounts?.declined || 0}</div>
                </div>
              </div>
            </div>
            <div className="graph-menu"><div className="main-percent line-main mt-2">Click on any date to see more info.</div></div>


          </div>
        </TabPanel>
        <TabPanel header="Cancelled">
          <CancelledTrips
            cancelledTripData={topCardTrips}
            sendgraphTripData={updatedData ?? props.sendgraphTripData}
            timeRange={props?.timeRange}
          />
        </TabPanel>
        <TabPanel header="Time Zone">
          <Timezone
            cancelledTripData={topCardTrips}
            sendgraphTripData={updatedData ?? props.sendgraphTripData}
            timeRange={props?.timeRange}
          />
        </TabPanel>

      </TabView>
    {activeIndex == 1 &&  <div className="text-success font-weight-bold text-underline " style={{position:'absolute',top:75,right:65,textDecoration:'underline'}}>View by reasons</div>}
    </div>
  );
}

export default Linechart;
