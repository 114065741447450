import { createStore } from "redux";
import LayoutReducer from "./store/LayoutReducer";
import { googleMapApiKey } from "../src/services/APIService"
let initialState = {
  isLoader: false,
  isGridLoader: false,
  user: {
    fullName: localStorage.getItem("fullName"),
    profileImage: localStorage.getItem("profileImage"),
  },
  windowSize: window.innerWidth < 992,
  polygon: null,
  apiKey: googleMapApiKey,
  location: {
    time: "",
    date: "",
    country: "",
    city: "",
  }
},
  store = createStore(LayoutReducer, initialState);
export default store;
