import React, { useState, useEffect } from "react";
import Analytics from "../components/Dashboard_2/Analytics";
import Cards from "../components/Dashboard_2/cards";
import Cashflow from "../components/Dashboard_2/cashFlow";
import CustomerCare from "../components/Dashboard_2/customerCare";
import APIService from "../services/APIService";
import Linechart from "../components/Dashboard_2/linechart";
import Navbar from "../components/Dashboard_2/navbarr";
import PromoCode from "../components/Dashboard_2/promoCode";
import Raiting from "../components/Dashboard_2/Raiting";
import Subsciption from "../components/Dashboard_2/Subsciption";
import TotalCaptains from "../components/Dashboard_2/totalCaptains";
import TotalRider from "../components/Dashboard_2/totalRider";
import { toast } from "react-toastify";
import CancelationReasonCard from "../components/Dashboard_2/cancelationReason";
import PaymentCard from "../components/Dashboard_2/payment";
import EhsanDonationCard from "../components/Dashboard_2/ehsanDonation";
import TodayTripStatus from "../components/Dashboard_2/TodayTripStatus";
import { Col, Row } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import Select, { StylesConfig } from "react-select";
import { _baseURL } from "../services/APIService";
import Admins from "./Admins";
import moment from "moment";





function Dashboard2(props) {
  const secret_key = process.env.REACT_APP_SECRET_KEY;
  const _saveEmployee = _baseURL + "/api/v1/employee/save?secret_key=" + secret_key;
  let [sendgraphTripList, setSendGraphTripList] = useState({}),
    [topCard, setTopCard] = useState(null),
    [timeRange, setTimeRange] = useState("year"),
    [genderCard, setGenderCard] = useState(null),
    [activeRiderCard, setActiveRiderCard] = useState(null),
    [activeDriverCard, setActiveDriverCard] = useState(null),
    [driverEarningCard, setDriverEarningCard] = useState(null),
    [cancelSummaryCard, setCancelSummaryCard] = useState(null),
    [usersData, setUsersData] = useState(null),
    [subscriptionEarningsCard, setSubscriptionEarningsCard] = useState(null),
    [activeDriversPercentage, setActiveDriversPercentage] = useState(null),
    [activeRidersPercentage, setActiveRidersPercentage] = useState(null),
    [cashFlow, setCashFlow] = useState(null),
    [waslApprovedCount, setWaslApprovedCount] = useState(null),
    [RiderReviewsStats, setRiderReviewsStats] = useState(null),
    [DriverReviewsStats, setDriverReviewsStats] = useState(null),
    [riderPromoStats, setRiderPromoStats] = useState(null),
    [driverPromoStats, setDriverPromoStats] = useState(null),
    [locationData,setLocationsData]=useState(null),
    [rideNames,setRideNames] = useState(null),
    [driverTickets,setDriverTickets]=useState(null),
    [riderTickets,setRiderTickets]=useState(null),
    [riderSubscriptionStats, setRiderSubscriptionStats] = useState(null),
    [driverSubscriptionStats, setDriverSubscriptionStats] = useState(null),
    [isRefreshData, setIsRefreshData] = useState(false),
    currentDate = moment().format('YYYY-MM-DD'),
    
    topCardDashboard = () => {
      // props.dispatch({ type: "TOGGLE_LOADER", payload: true });
      APIService.get("admin/dashboard?type=year")
        .then((response) => {
          if (response.data.statusCode === 200) {
            let topCard =
              response.data &&
              response.data.data &&
              response.data.data.topStats;
            let tripChart =
              response.data &&
              response.data.data &&
              response.data.data.tripStats;
            // let genderData =
            //   response.data &&
            //   response.data.data &&
            //   response.data.data.genderStats;
            let activeRiderData =
              response.data &&
              response.data.data &&
              response.data.data.activeRiders;
            let activeDriverData =
              response.data &&
              response.data.data &&
              response.data.data.activeDrivers;

            // let driverEarningData =
            //   response.data &&
            //   response.data.data &&
            //   response.data.data.driverEarnings;
            // let cancelSummaryData =
            //   response.data &&
            //   response.data.data &&
            //   response.data.data.cancelSummary;
            // let subscriptionEarningsData =
            //   response.data &&
            //   response.data.data &&
            //   response.data.data.subscriptionEarnings;
            let activeRiderPercentage =
              response.data &&
              response.data.data &&
              response.data.data.activeRidersPercentage;
            let waslApprovedCount = 
              response.data &&
              response.data.data &&
              response.data.data.waslApprovedCount;
            let activeDriverPercentage =
              response.data &&
              response.data.data &&
              response.data.data.activeDriversPercentage;
            let cashFlowData =
              response.data &&
              response.data.data &&
              response.data.data.cashFlow;
            let waslApprovedData =
              response.data &&
              response.data.data &&
              response.data.data.waslApprovedCount;
            let RiderReviewsStatsData =
              response.data &&
              response.data.data &&
              response.data.data.RiderReviewsStats;
            let DriverReviewsStatsData =
              response.data &&
              response.data.data &&
              response.data.data.DriverReviewsStats;
            let RiderPromoStatsData =
              response.data &&
              response.data.data &&
              response.data.data.riderPromoStats;
            let DriverPromoStatsData =
              response.data &&
              response.data.data &&
              response.data.data.captainPromoStats;
            let RiderSubscriptionStatsData =
              response.data &&
              response.data.data &&
              response.data.data.riderSubscriptionStats;
            let DriverSubscriptionStatsData =
              response.data &&
              response.data.data &&
              response.data.data.captainSubscriptionStats;
            setDriverSubscriptionStats(DriverSubscriptionStatsData);
            setRiderSubscriptionStats(RiderSubscriptionStatsData);
            setDriverPromoStats(DriverPromoStatsData);
            setRiderPromoStats(RiderPromoStatsData);
            setTopCard(topCard);
            setSendGraphTripList(tripChart);
            // setGenderCard(genderData);
            setActiveRiderCard(activeRiderData);
            setActiveDriverCard(activeDriverData);
            setActiveDriversPercentage(activeDriverPercentage);
            setActiveRidersPercentage({activeRiderPercentage,waslApprovedCount});
            setCashFlow(cashFlowData);
            setWaslApprovedCount(waslApprovedData);
            setRiderReviewsStats(RiderReviewsStatsData);
            setDriverReviewsStats(DriverReviewsStatsData);
            // setDriverEarningCard(driverEarningData);
            // setCancelSummaryCard(cancelSummaryData);
            // setSubscriptionEarningsCard(subscriptionEarningsData);

            // props.dispatch({ type: "TOGGLE_LOADER", payload: false });
          }
        })
        .catch((error) => {
          // props.dispatch({ type: "TOGGLE_LOADER", payload: false });
        });
    };
    const getRidesNames = () => {
      try {
        APIService.get(`/admin/cab-type/all`)
          .then(response => {
            if (response.status == 200) {
              let data = response?.data?.data
              // setCaptainData(data)
              setRideNames(data)
            }
          })
      } catch (e) { console.log(e) }
    }
    const getGeoLocations = () => {
      try {
        APIService.get(`admin/get-geo-locations`)
          .then((res) => {
            if (res) {
              if (res.status == 200) {
                let data = res?.data?.data ? res.data.data : "";
                setLocationsData(data[0]);
              }
            }
          })
          .catch((e) => console.log(e));
      } catch (e) {
        console.log(e);
      }
    };

  const refreshData = (isRefresh) => {
    if (isRefresh) {
      toast.success("Data Refresh Successfully");
      topCardDashboard();
    }
  };

  const _getAllTicketsDriverURL = _baseURL + `/api/v1/ticket/get-status-wise-tickets?secret_key=${secret_key}&from_date=2022-10-29&to_date=${currentDate}&type=2`;
  let _getAllTicketsDriver = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },

    };
    try {
      const response = await fetch(_getAllTicketsDriverURL, requestOptions);
      let final_data = await response.json();
      console.log('final_data',final_data)
      if(final_data?.data){
        setDriverTickets(final_data?.data);
      }
    } catch (error) {
      console.log(error)
    }

  };

  const _getAllTicketsRiderURL = _baseURL + `/api/v1/ticket/get-status-wise-tickets?secret_key=${secret_key}&from_date=2022-10-29&to_date=${currentDate}&type=1`;
  let _getAllTicketsRider = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },

    };



    try {
      const response = await fetch(_getAllTicketsRiderURL, requestOptions);
      let final_data = await response.json();
      if(final_data?.data){
        setRiderTickets(final_data.data);
      }
    } catch (error) {
      console.log(error)
    }

  };


  console.log('driverTickets',driverTickets);
console.log('rider',riderTickets);
  useEffect(() => {
    topCardDashboard();
    getGeoLocations();
    getRidesNames();
    _getAllTicketsDriver();
    _getAllTicketsRider();
  }, []);

  return (
    <>
      {/* <h1>This is my dash boad 2</h1> */}

      <div className="main__screen container-fluid">
        <div className="col-12">
          <div className="row">
            <Navbar refreshData={refreshData} />
          </div>
          <div className="col-12 bottomSpacer">
            <div className="row">
              <Cards topCard={topCard} />
            </div>
          </div>
          <Admins />

          {/* Rider sections */}
          <div className="bottomSpacer">
            <div className="row">
              <div className=" cards col-xl-4 mb-lg-0 mb-4">
                <TotalRider
                  locationDataset={locationData}
                  activeRidersPercentage={activeRidersPercentage}
                  topCardRider={topCard?.rider}
                  rideNamesData={rideNames}
                />
              </div>
              <div className="cards col-xl-8">
                <TotalCaptains
                  locationDataset={locationData}
                  activeDriversPercentage={activeDriversPercentage}
                  waslApprovedCount={waslApprovedCount}
                  topCardDriver={topCard?.driver}
                  rideNamesData={rideNames}

                />
              </div>
            </div>
          </div>
{/*daily trips status*/}
<div className="bottomSpacer">
            <div className="row">
<div className="cards col-12">
                <TodayTripStatus
                  activeDriversPercentage={activeDriversPercentage}
                  waslApprovedCount={waslApprovedCount}
                  topCardDriver={topCard?.driver}
                  locationDataset={locationData}
                  rideNamesData={rideNames}
                />
              </div>
              </div>
          </div>
          {/* section line charts */}
          <div className="bottomSpacer">
            <div className="row">
              <div className="col-xl-8 col-lg-12 mb-lg-0 mb-4">
                <Linechart
                  locationDataset={locationData}
                  sendgraphTripData={sendgraphTripList}
                  topCardTrips={topCard?.trip}
                  timeRange={timeRange}
                  rideNamesData={rideNames}
                />
              </div>
              <div className="col-xl-4 col-lg-12">
                <CustomerCare ticketData={driverTickets} ticketDataRider={riderTickets} />
              </div>
            </div>
          </div>
   {/* section other charts */}
   <div className="bottomSpacer">
            <div className="row">
              <div className="col-xl-4 col-lg-12">
                <CancelationReasonCard />
              </div>
              <div className="col-xl-4 col-lg-12">
                <PaymentCard />
              </div>
              <div className="col-xl-4 col-lg-12">
                <EhsanDonationCard />
              </div>
            </div>
          </div>
          {/* section cashflow */}
          <div className=" bottomSpacer">
            <div className="row">
              <div className="col-xl-8 mb-lg-0 mb-4">
                <Cashflow cashFlow={cashFlow} />
              </div>
              <div className="col-xl-4 col-12">
                <Raiting
                  RiderReviewsStats={RiderReviewsStats}
                  DriverReviewsStats={DriverReviewsStats}
                />
              </div>
            </div>
          </div>

          {/* section PromoCode */}
          <div className="bottomSpacer">
            <div className="row">
              <div className="container-fluid">
                <PromoCode
                  riderPromoStats={riderPromoStats}
                  driverPromoStats={driverPromoStats}
                />
              </div>
            </div>
          </div>
          {/* Subscription seciton  */}
          {/* <div className="bottomSpacer">
            <div className="row">
              <div className="container-fluid">
                <Subsciption
                  riderSubscriptionStats={riderSubscriptionStats}
                  driverSubscriptionStats={driverSubscriptionStats}
                />
              </div>
            </div>
          </div> */}

          {/* Analytics section      */}
          {/* <div className="bottomSpacer">
            <div className="row">
              <div className="col-lg-12">
                <Analytics />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Dashboard2;
