import React, { useState, useCallback } from "react";
import { Button, Modal, Col, Row } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Select, { StylesConfig } from "react-select";
import { connect, useSelector } from "react-redux";
import DeleteModal from "../components/Common/DeleteModal";
import VehicleMakerListing from "../components/VehicleMakerList/VehicleMakerListing";
import APIService from "../services/APIService";
// import { InputText } from "primereact/inputtext";

const colourStyles: StylesConfig<ColourOption, true> = {
  // control: (styles) => ({ ...styles, backgroundColor: 'black' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#212529"
        : isFocused
        ? "white"
        : undefined,
      color: isDisabled ? "white" : isSelected ? "#28a745" : "#28a745",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "white"
          : undefined,
      },
    };
  },
};
let statusOptions = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

let VehicleListingPage = (props) => {
  const [windowWidth] = useState(useSelector((state) => state.windowSize));
  let [vehicleMakers, setVehicleMakers] = React.useState([]),
  [vehicleTypePhoto, setVehicleTypePhoto] = useState(null),
    [vehicleTypePhotoName, setVehicleTypePhotoName] = useState(null),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    [previewImageData, setPreviewImageData] = useState(null),
    [showModal, setShowModal] = useState(false),
    [showModalUpdate, setShowModalUpdate] = useState(false),
    [showDelete, setShowDelete] = useState(false),
    [selectedStatus, setSelectedStatus] = useState({
      value: true,
      label: "Active",
    }),
    [updateData, setUpdateData] = useState({
      maker: "",
      makerEnglish: "",
      status: 1,
    }),
    handleClose = () => {
      setShowModal(false);

      setVehicleTypePhoto(null);
      setVehicleTypePhotoName(null);
      setPreviewImageData(null);
    },
    handleShow = () => {
      setSelectedStatus({ value: 1, label: "Active" });
      setUpdateData({
        maker: "",
        makerEnglish: "",
        status: 1,
      });
      setShowModal(true);
    },
    handleCloseUpdate = () => {
      setShowModalUpdate(false);

      setVehicleTypePhoto(null);
      setVehicleTypePhotoName(null);
      setPreviewImageData(null);
    },
    handleShowUpdate = () => setShowModalUpdate(true),
    handleCloseDelete = () => setShowDelete(false),
    handleShowDelete = (rowData) => {
      setUpdateData(rowData);
      setShowDelete(true);
    },
    [saveNmore, setSaveNmore] = useState(false),
    updateTableData = (rowData) => {
      let vehicleDeatils = { ...rowData };
      setUpdateData(vehicleDeatils);
      setSelectedStatus(
        statusOptions.find((sts) => {
          return sts.value === vehicleDeatils.status;
        })
      );
    },
    getVehicleMakers = useCallback(() => {
      // let filterParams = JSON.parse(JSON.stringify(lazyParams));
      // filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.get("admin/vehicle-maker/all")
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
           
            setVehicleMakers(response.data.data);
            props.dispatch({
              type: "TOGGLE_GRID_LOADER",
              payload: false,
            });
          }
        })
        .catch((error) => {
          props.dispatch({
            type: "TOGGLE_GRID_LOADER",
            payload: false,
          });
        });
    }, [props]),
    removeVehicleMaker = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      APIService.delete("admin/vehicle-maker/" + updateData.id)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            getVehicleMakers();
            toast.success("Vehicle Maker deleted successfully");
          } else {
            toast.error("Failed to delete Vehicle Maker");
          }
          setShowDelete(false);
        })
        .catch((error) => {
          setShowDelete(false);
        });
    },
    createOrUpdateVehicleMaker = (vehicleMakerInfo, props) => {
      vehicleMakerInfo.status = Number(selectedStatus.value);
      let formData = new FormData();
      Object.keys(vehicleMakerInfo).forEach((key) => {
        formData.append(key, vehicleMakerInfo[key]);
      });
      if (vehicleTypePhoto) {
        formData.append("makerIcon", vehicleTypePhoto);
      }
 
      if (!updateData || !updateData.id) {
        APIService.post("admin/cab-type/vehicle-maker",formData)
          .then((response) => {
            if (response.data.statusCode === 200) {
              toast.success("Vehicle Maker created successfully");
              setVehicleTypePhoto(null);
             setVehicleTypePhotoName(null);
              getVehicleMakers();
            } else {
              toast.error("Failed to create Vehicle Maker");
            }
            setVehicleTypePhoto(null);
            setVehicleTypePhotoName(null);
            props.resetForm();

            setSelectedStatus({ value: true, label: "Active" });
            if (!saveNmore) {
              handleClose();
            }
          })
          .catch((error) => {
            props.resetForm();
            setVehicleTypePhoto(null);
            setVehicleTypePhotoName(null);
            setSelectedStatus({ value: true, label: "Active" });
            if (!saveNmore) {
              handleClose();
            }
            console.log(error);
          });
      } else {
        if (!vehicleTypePhoto) {
          formData.delete("makerIcon");
        }
        
        APIService.patch(
          "admin/vehicle-maker-update",formData
        )
          .then((response) => {
            if (response.data.statusCode === 200) {
              toast.success("Vehicle Maker updated successfully");
              getVehicleMakers();
              setVehicleTypePhoto(null);
            setVehicleTypePhotoName(null);
            } else {
              toast.error("Failed to update Vehicle Maker");
            }
            handleCloseUpdate();
          })
          .catch((error) => {
            handleCloseUpdate();
            console.log(error);
          });
      }
    },
    updateVehicleMakerStatus = (vehicleMakerInfo) => {
      APIService.patch(
        "admin/vehicle-maker/" + vehicleMakerInfo.id,
        vehicleMakerInfo
      )
        .then((response) => {
          if (response.data.statusCode === 200) {
            toast.success("Vehicle Maker updated successfully");
            // getVehicleMakers();
          } else {
            toast.error("Failed to update Vehicle Maker");
          }
          handleCloseUpdate();
        })
        .catch((error) => {
          handleCloseUpdate();
          console.log(error);
        });
    },
   
    validationRules = {
      makerEnglish: Yup.string().required("Please enter Vehicle Maker Name"),
      maker: Yup.string().required("Please enter Vehicle Maker Arabic"),
      status: Yup.string().required("Select Status"),
    },
    validationSchema = Yup.object().shape(validationRules);
  React.useEffect(() => {
    getVehicleMakers();
  }, [getVehicleMakers]);

  return (
    <>
     
        
       
        <VehicleMakerListing
          vehicleMakers={vehicleMakers}
          handleShowUpdate={handleShowUpdate}
          updateTableData={updateTableData}
          updateVehicleMakerStatus={updateVehicleMakerStatus}
          handleShowDelete={handleShowDelete}
          handleShow={handleShow}
          isLoading={useSelector((state) => state.isGridLoader)}
        />
     

      <Modal show={showModal} onHide={handleClose} centered size="lg">
        <Formik
          initialValues={updateData}
          validationSchema={validationSchema}
          onSubmit={createOrUpdateVehicleMaker}
        >
          {({ handleBlur, setFieldValue }) => {
            return (
              <Form>
               <Modal.Header>
                    <Modal.Title className="modal-title" style ={{paddingBottom: "20px"}}>
                    Vehicle Makers
                    </Modal.Title>
                    <div className="divclose" onClick={handleClose}>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleClose}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </Modal.Header>
                <Modal.Body className="mt-24">
                <div className="form-group mb-2">
                    <div className="form-file-upload form-label-group mb-2">
                      <div className="form-file-name">
                        {vehicleTypePhotoName}
                      </div>
                      <div className="form-file-icon">
                        <span style={{ color: "#13B542", textDecoration: "underline", cursor: "pointer", fontWeight: "500" }}>Upload</span>  
                   
                      </div>
                      <input
                        id="fileupload"
                        className="form-control modal-input br-10"
                        placeholder="name"
                        type="file"
                        accept="image/*"
                        name="fileupload"
                        onChange={(e) => {
                          if (e.target.files) {
                            setVehicleTypePhoto(e.target.files[0]);
                            setVehicleTypePhotoName(e.target.files[0]?.name);
                          }
                        }}
                      />
                      <label htmlFor="fileupload">Vehicle Maker Photo</label>
                      <ErrorMessage
                        name="fileupload"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                  </div>
                  <div className="info__text" style ={{marginBottom: "30px !important"}}>
                    <p className="mb-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="#13b542"
                        className="bi bi-info-circle mr-2 mb-0"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 118 1a7 7 0 010 14zm0 1A8 8 0 108 0a8 8 0 000 16z"></path>
                        <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 11-2 0 1 1 0 012 0z"></path>
                      </svg>
                      Image dimension should be 390 &#10006; 216.
                    </p>
                  </div>

                 
                  <Row style ={{paddingTop: "20px"}}>
                    <Col md={6}><div className="form-group form-label-group">
                    <Field
                      id="name"
                      type="text"
                      name="makerEnglish"
                      className="shadow-none form-control modal-input br-10"
                      placeholder="Vehicle Maker Name"
                      maxLength="20"
                    />
                    <label htmlFor="name">Vehicle Maker Name (English)</label>
                    <ErrorMessage
                      name="makerEnglish"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div></Col>
                    <Col md={6}>
                       <div className="form-group form-label-group arabic-fonts">
                    <Field
                      id="nameArabic"
                      type="text"
                      name="maker"
                      className="shadow-none form-control modal-input br-10 arabic-fonts"
                      placeholder="Vehicle Maker Name (Arabic)"
                      maxLength="20"
                    />
                    <label htmlFor="nameArabic">
                      Vehicle Maker Name (Arabic)
                    </label>
                    <ErrorMessage
                      name="maker"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div>
                    </Col>
                    <Col md={6}><div className="mb-4">
                    <Select
                      className="height-50"
                      placeholder="Select Status"
                      // isClearable
                      name="status"
                      styles={colourStyles}
                      value={selectedStatus}
                      onBlur={() => {
                        handleBlur({
                          target: {
                            name: "status",
                          },
                        });
                      }}
                      onChange={(e) => {
                        if (e) {
                          setFieldValue("status", e.value);
                          setSelectedStatus(e);
                        }
                      }}
                      options={statusOptions}
                    />
                    <ErrorMessage
                      name="status"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div></Col>
                  </Row>
                  

                 
                  
                </Modal.Body>

                <Modal.Footer className="padding-header-footer">
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn-save save-button mb_20 border-radius-15"
                    onClick={() => setSaveNmore(false)}
                  >
                    Add
                  </Button>
                  <Button
                    variant="secondary"
                    type="submit"
                    className="btn-save-add save-button mb_20 border-radius-15"
                    onClick={() => setSaveNmore(true)}
                  >
                    Save & Add more
                  </Button>
                  <Button
                    variant="light"
                    className="btn-cancel save-button mb_20 border-radius-15"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      <Modal show={showModalUpdate} onHide={handleCloseUpdate} centered size="lg">
        <Formik
          initialValues={{ ...updateData }}
          validationSchema={Yup.object().shape({
            ...validationRules,
          })}
          onSubmit={createOrUpdateVehicleMaker}
        >
          {({ handleBlur, setFieldValue }) => {
            return (
              <Form>
                <Modal.Header closeButton>
                <Modal.Title className="modal-title" style ={{paddingBottom: "20px !important"}}>
                    Update Vehicle Maker
                  </Modal.Title>
                  <div className="divclose" onClick={handleCloseUpdate}>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleClose}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                </Modal.Header>
                <Modal.Body className="mt-24">
                <div className="preview__img__main d-flex justify-content-center pb-4">
                    <img
                      src={
                        previewImageData
                          ? previewImageData
                          : updateData.makerIconUrl
                          ? updateData.makerIconUrl
                          : "showcase/demo/images/flag_placeholder.png"
                      }
                      onError={(e) =>
                        (e.target.src = previewImageData
                          ? previewImageData
                          : updateData.makerIconUrl
                          ? updateData.makerIconUrl
                          : process.env.PUBLIC_URL + `/images/car.png`)
                      }
                      alt={updateData.name}
                      className=""
                      style={{
                        width: "auto",
                        height: "100px",
                        // borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="form-group mb-2" >
                    <div className="form-file-upload form-label-group mb-2" style={{marginBottom:"30px !important"}}>
                      <div className="form-file-name">
                        {vehicleTypePhotoName}
                      </div>
                      <div className="form-file-icon">
                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2 16H18V9H20V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9H2V16ZM11 6V13H9V6H4L10 0L16 6H11Z"
                            fill="#03053D"
                          />
                        </svg>
                      </div>
                      <input
                        id="fileupload"
                        className="form-control modal-input br-10"
                        placeholder="name"
                        type="file"
                        accept="image/*"
                        name="fileupload"
                        onChange={(e) => {
                          if (e.target.files) {
                            var reader = new FileReader();
                            reader.onloadend = function (e) {
                              setPreviewImageData(e.target.result);
                            };
                            reader.readAsDataURL(e.target.files[0]);
                            setVehicleTypePhoto(e.target.files[0]);
                            setVehicleTypePhotoName(e.target.files[0]?.name);
                          }
                        }}
                      />
                      <label htmlFor="fileupload">Vehicle Maker Photo</label>
                      <ErrorMessage
                        name="fileupload"
                        component="div"
                        className="invalid-feedback text-danger"
                      />
                    </div>
                  </div>
                  <Row style ={{marginTop: "40px"}}>
                    <Col md={6}> <div className="form-group form-label-group">
                    <Field
                      id="name"
                      type="text"
                      name="makerEnglish"
                      className="shadow-none form-control modal-input br-10"
                      placeholder="Vehicle Maker Name"
                    />
                    <label htmlFor="name">Vehicle Maker Name (English)</label>
                    <ErrorMessage
                      name="makerEnglish"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div></Col>
                    <Col md={6}><div className="form-group form-label-group">
                    <Field
                      id="nameArabic"
                      type="text"
                      name="maker"
                      className="shadow-none form-control modal-input br-10"
                      placeholder="Vehicle Maker Name"
                    />
                    <label htmlFor="nameArabic">
                      Vehicle Maker Name (Arabic)
                    </label>
                    <ErrorMessage
                      name="maker"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div></Col>
                    <Col md={6}> <div className="mb-4">
                    <Select
                      className="height-50"
                      placeholder="Select Status"
                      // isClearable
                      name="status"
                      styles={colourStyles}
                      value={selectedStatus}
                      onBlur={() => {
                        handleBlur({
                          target: {
                            name: "status",
                          },
                        });
                      }}
                      onChange={(e) => {
                        if (e) {
                          setFieldValue("status", e.value);
                          setSelectedStatus(e);
                        }
                      }}
                      options={statusOptions}
                    />
                    <ErrorMessage
                      name="status"
                      component="div"
                      className="invalid-feedback text-danger"
                    />
                  </div></Col>
                   

                  
                 
                  </Row>
                  
                </Modal.Body>

                <Modal.Footer className="padding-header-footer">
                  <Button
                    type="submit"
                    variant="primary"
                    className="btn-save save-button mb-3 border-radius-15"
                  >
                    Update
                  </Button>
                  <Button
                    variant="light"
                    className="btn-cancel save-button mb-3 border-radius-15"
                    onClick={handleCloseUpdate}
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      <DeleteModal
        clickYes={removeVehicleMaker}
        showDelete={showDelete}
        handleCloseDelete={handleCloseDelete}
      />
    </>
  );
};
export default React.memo(connect()(VehicleListingPage));
