import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import Select from "react-select";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import * as Yup from "yup";
import { connect, useSelector } from "react-redux";
import APIService from "../../services/APIService";
import riderAvtSec from "../../assest/images/carimage.png";
import {
  ModalData,
  Manufacturer,
  Modalyear,
  TotalCars,
  ActiveInactive,
  BodyColour,
  initialModal,
} from "./Data";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { value } from "lodash/seq";
const baseURL = process.env.REACT_APP_RIDE_A_RIDE_URL;
const secretKey = process.env.REACT_APP_RIDE_A_RIDE_SECRET_KEY;
const getInventryListURL = `${baseURL}/api/ride/inventory/list`;
const createInventory = `${baseURL}/api/ride/inventory/add`;
const categoryList = `${baseURL}/api/ride/category/list`;
const updateInventory = `${baseURL}/api/ride/inventory/update`
let Ridearide = (props) => {
  let [selectedStatus, setSelectedStatus] = useState(null),
  [tooltipStates, setTooltipStates] = useState({}),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [showFilter, setShowFilter] = useState(false),
    [fillRider, setFillRider] = useState([]),
    [subStatus, setSubStatus] = useState(null),
    [showActivationModal, setShowActivationModal] = useState(false),
    [currImage, setCurrImage] = useState(""),
    [carCategories, setCarCategories] = useState([]),
    [makerCategories, setMakerCategories] = useState([]),
    [modelCategories, setModelCategories] = useState([]),
    [addCar, setAddCar] = useState(false),
    [carInfo, setCarInfo] = useState({}),
    [sequenceModal, setSequenceModal] = useState(false),
    [uploadCsvModal, setUploadCsvModal] = useState(false),
    [addCarManually, setAddCarManually] = useState(false),
    [selectedFile, setSelectedFile] = useState(null),
    dt = useRef(null),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    [createdAt, setCreatedAt] = useState([]),
    [inventry, setInventry] = useState({}),
    [inventryList, setInventryList] = useState([]),
    [modalValues, setModalValues] = useState(initialModal),
    getInventry = async () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      let callApi = await fetch(getInventryListURL, {
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
          token: secretKey,
        },
      });
      let response = await callApi.json();
      if (response.status) {
        setInventry(response?.data);
        setInventryList(response?.data?.data);
      }
      else {
        setInventry({})
        setInventryList([])
      }
      getCategories()
      getVehicalMaker()
      getVehicalModel()
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
    }, getCategories = async () => {
      let callApi = await fetch(categoryList, {
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
          token: secretKey,
        },
      });
      let response = await callApi.json();
      let categories = response?.data.map((item) => {
        return ({
          value: item.id,
          label: item.name
        }
        )
      })
      setCarCategories(categories);
    },
    getVehicalMaker = async () => {
      APIService.get(`/admin/vehicle-maker/all`)
        .then((res) => {
          let response = res?.data?.data
          let categories = response?.map((item) => {
            return ({
              value: item.makerEnglish ?? "" + "  " + item.maker ?? "",
              label: item.makerEnglish ?? "" + "  " + item.maker ?? ""
            }
            )
          })
          setMakerCategories(categories)
        })
    },
    getVehicalModel = async () => {
      APIService.get(`/admin/vehicle-model/all`)
        .then((res) => {
          let response = res?.data?.data
          let categories = response?.models?.map((item) => {
            return ({
              value: item?.modelEnglish ?? "" + "  " + item?.model ?? "",
              label: item?.modelEnglish ?? "" + "  " + item?.model ?? ""
            }
            )
          })

          setModelCategories(categories)
        })//vehicle-model/all
    },
    getCarInfo = (values) => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      setSequenceModal(false)
      setAddCar(false)
      APIService.post(`/admin/car-info-by-sequence`, values)
        .then((res) => {
          console.log("Res", res)
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          if (res === undefined) {
            toast.error("car info doesn't exist")
          }
          else {
            let response = res?.data?.data
            setCarInfo(response)
            setModalValues({
              ...initialModal,
              sequence_no: response?.carSequenceNo ?? "",
              model_year: response?.modelYear ?? ""
            })
            setAddCarManually(true)
          }
        })
    }
    , convertDatatoFormData = (values) => {
      const {
        body_color,
        category_id,
        chassis_no,
        engine_no,
        manufacturer,
        model_year,
        model,
        registration_no,
        rental_amount,
        seating_capacity,
        sequence_no,
        uploadimage,
        isUpdate,
      } = values;

      let formdata = new FormData();
      formdata.append("body_color", body_color)
      formdata.append("category_id", category_id)
      formdata.append("chassis_no", chassis_no)
      formdata.append("engine_no", engine_no)
      formdata.append("manufacturer", manufacturer)
      formdata.append("model_year", model_year)
      formdata.append("model", model)
      formdata.append("registration_no", registration_no)
      formdata.append("rental_amount", rental_amount)
      formdata.append("seating_capacity", seating_capacity)
      formdata.append("sequence_no", sequence_no)
      uploadimage !== "" && formdata.append("icon", uploadimage)
      !isUpdate && formdata.append("car_info", JSON.stringify(carInfo))
      return formdata
    }
    ,
    addInventory = async (values) => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let formData = convertDatatoFormData(values)
      const requestOptions = {
        method: "POST",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
          token: secretKey,
        },
        body: formData
      };
      try {
        const addInventory = await fetch(values.isUpdate ? `${updateInventory}/${values?.id}` : createInventory, requestOptions);
        const response = await addInventory.json();
        if (response.status) {
          setAddCarManually(false);
          setSequenceModal(false)
          // setModalValues(initialModal)
          setAddCar(false);
          getInventry();
          toast.success("Inventory Created Successfully");
        } else {
          setSequenceModal(false)
          setAddCarManually(false);
          // setModalValues(initialModal)
          setAddCar(false);
          toast.warn("Something Went Wrong");
        }
        setSelectedFile(null);
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
      } catch (e) {
        throw new Error(e)
      }
    },
    manageDrivers = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            if (filter === "totalTrips") {
              lazyParams.filters[filter] = Number(event.filters[filter].value);
            } else {
              lazyParams.filters[filter] = event.filters[filter].value;
            }
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getInventry();
    },
    statusOptions = [
      { label: "Active", value: 1 },
      { label: "Inactive", value: 2 },
    ],
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="toggle-filter">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
                showFilter ? (
                  <>
                    <img src="/images/filter-icon.svg" alt="" srcSet="" />
                    <span className="span-filter mx-12">Filters</span>
                    <i className="fa fa-caret-up  "></i>
                  </>
                ) : (
                  <>
                    <img src="/images/filter-icon.svg" alt="" srcSet="" />
                    <span className="span-filter mx-12">Filters</span>
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                        fill="#828282"
                      />
                    </svg>
                  </>
                )}
            </button>
          </span>
          <span className="p-input-icon-left p-captain d-flex align-items-center">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                // getDrivers();
              }}
            />
          </span>
          <button
            className="btn btn-primary float-right mb-1 ml-12"
            onClick={() => { }}
          >
            Export
          </button>
          <button
            className="btn btn-primary float-right mb-1 ml-12"
            onClick={() => setAddCar(true)}
          >
            {windowWidth ? <i className="fa fa-plus" /> :<div style={{width:"50px"}}>+ Add</div>}
          </button>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      setCreatedAt([]);
      setSubStatus(null);
      getInventry();
    },
    validateSchema = Yup.object().shape({
      ...(!modalValues.isUpdate) && { uploadimage: Yup.mixed().required("Please upload image") },
      manufacturer: Yup.string().required("Please enter manufacturer"),
      category_id: Yup.string().required("Please enter Category Id").nullable(),
      model_year: Yup.string().required("Please enter modal year"),
      model: Yup.string().required("Please enter model of car"),
      body_color: Yup.string().required("Please enter body_color"),
      engine_no: Yup.string().required("Please enter engine no"),
      registration_no: Yup.string().required("Please enter registration no"),
      chassis_no: Yup.string().required("Please enter chassis no"),
      sequence_no: Yup.string().required("Please enter a sequence no"),
      seating_capacity: Yup.string().required("Please enter seating capacity"),
      rental_amount: Yup.string().required("Please enter rental amount"),
    }),
    onHide = () => {
      setModalValues(initialModal)
      setAddCarManually(false);
      setSelectedFile(null)
      setCurrImage("");
    },
    handleActiveInactive = (rowData) => {
      let currentImage = rowData?.image?.url.split("/").slice(-1)[0];
      setCurrImage(currentImage);
      setModalValues({ ...rowData, uploadimage: "", isUpdate: true })
      setSelectedFile(rowData?.image)
      setAddCarManually(true)
    };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  useEffect(() => {
    getInventry();
  }, []);
  const handleMouseOver = (index) => {
    console.log(index)
    setTooltipStates((prevStates) => ({
      ...prevStates,
      [index]: true,
    }));
  };

  const handleMouseOut = (index) => {
    setTooltipStates((prevStates) => ({
      ...prevStates,
      [index]: false,
    }));
  };

  return (
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <DataTable
          ref={dt}
          value={inventryList}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Data found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          totalRecords={inventry.total}
          lazy={true}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageDrivers}
          onSort={manageDrivers}
          onFilter={manageDrivers}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-left"
            style={{ fontSize: "12px", width: "200px" }}
            header="Car Image"
            field="carImage"
            body={(driver) => {
              return (
                <Link
                  to={"/car-inventory/" + driver.id}
                  style={{ color: "#13B542" }}
                >
                  <img
                    src={baseURL + driver?.image?.url ?? riderAvtSec}
                    alt="ride"
                    style={{
                      marginRight: "15px",
                      width: "70px",
                      height: "70px",
                      objectFit: "cover",
                    }}
                  />
                </Link>
              );
            }}
            sortable
            filter={showFilter}
            filterMatchMode="startsWith"
            filterPlaceholder="Search by id"
          />

          <Column
            className="text-left font-weight-bold"
            headerClassName="text-left"
            style={{ fontSize: "12px", width: "140px" }}
            header="Date"
            field="date"
            sortable
            body={rowData => rowData?.created_at?.split("T")[0].split("-").reverse().join("/")}
            filter={showFilter}
            filterMatchMode="custom"
          // filterFunction={customFunction}
          // filterPlaceholder="Search by name"
          />

          <Column
            className="text-left font-weight-bold"
            style={{ fontSize: "12px", width: "180px" }}
            header="Manufacturer"
            field="manufacturer"
            sortable
            filter={showFilter}
          // filterPlaceholder="Search by Services"
          />
          <Column
            className="text-left font-weight-bold"
            style={{ fontSize: "12px", width: "180px" }}
            header="Model & Year"
            field="model_year"
            sortable
            filter={showFilter}
          // filterPlaceholder="Search by Services"
          />

          <Column
            className="text-left font-weight-bold"
            style={{ fontSize: "12px", width: "180px" }}
            header="Category"
            field="category.name"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Loyalty points"
          />

          <Column
            className="text-left font-weight-bold"
            style={{ fontSize: "12px", width: "135px" }}
            header="Seating"
            field="seating_capacity"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by mobile"
          />

          <Column
            className="text-left font-weight-bold"
            style={{ fontSize: "12px", width: "135px" }}
            header="Body Color"
            field="body_color"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Body Color"
          />
          <Column
            className="text-left font-weight-bold"
            style={{ fontSize: "12px", width: "160px" }}
            header="Chassis No"
            field="chassis_no"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by ChassisNo"
          />
          <Column
            className="text-left font-weight-bold"
            style={{ fontSize: "12px", width: "170px" }}
            header="Registration No"
            field="registration_no"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Registration No"
          />
          <Column
            className="text-left font-weight-bold"
            style={{ fontSize: "12px", width: "170px" }}
            header="Rental Amount"
            field="rental_amount"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Amount"
          />
          {/* <Column
            className="text-left font-weight-bold"
            style={{ fontSize: "12px", width: "220px" }}
            header="Registered Cars"
            field="registered_cars"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by trips"
          />
          <Column
            className="text-left font-weight-bold"
            style={{ fontSize: "12px", width: "135px" }}
            header="Available"
            field="available_cars"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by trips"
          /> */}

          <Column
            className="text-left font-weight-bold"
            style={{ fontSize: "12px", width: "145px" }}
            field="userStatus"
            header="status"
            body={(car) => {
              // console.log(car)
              // return <Dropdown
              //   value={selectedStatus}
              //   options={[]}
              //   onChange={(event) => {
              //     setSelectedStatus(event.value);
              //     if (event.value === 1) {
              //       lazyParams.filters["approved"] = true;
              //     } else if (event.value === 2) {
              //       lazyParams.filters["approved"] = false;
              //     } else {
              //       delete lazyParams.filters["approved"];
              //     }
              //     setLazyParams(lazyParams);
              //     setTimeout(() => {
              //       getInventry();
              //     }, 500);
              //   }}
              //   showClear
              //   placeholder="Select Status"
              //   className="p-column-filter"
              // />
              return car.status ? (
                <span className="status-text">Active</span>
              ) : (
                <span className="status-text-inactive">InActive</span>
              );
            }}
            sortable
            filter={showFilter}
            filterElement={
              <Dropdown
                value={selectedStatus}
                options={statusOptions}
                onChange={(event) => {
                  setSelectedStatus(event.value);
                  if (event.value === 1) {
                    lazyParams.filters["approved"] = true;
                  } else if (event.value === 2) {
                    lazyParams.filters["approved"] = false;
                  } else {
                    delete lazyParams.filters["approved"];
                  }
                  setLazyParams(lazyParams);
                  setTimeout(() => {
                    getInventry();
                  }, 500);
                }}
                showClear
                placeholder="Select Status"
                className="p-column-filter"
              />
            }
          />
          <Column
            className="text-left font-weight-bold"
            style={{ fontSize: "12px", width: "200px" }}
            header="Action"
            headerClassName="text-left"
            // body={actionTemplate}
            body={(rowData) => {
              console.log(rowData)
              return (
                <span
                  className="status-text p-2 position-relative activeInactive"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleActiveInactive(rowData)}
                  onMouseOver={() => handleMouseOver(rowData.id)}
                  onMouseOut={() => handleMouseOut(rowData.id)}
                >
                  Active/InActive
                  <span className="car-activeInactive">This car is registered with one or more than one captains so you can't inactivate this record.</span>
                  {/* {tooltipStates[rowData.id] && (
        <div className="position-absolute end-0 p-2 bg-white text-dark" style={{zIndex:"5" , width:"250px"}}>
          This car is registered with one or more captains, so you can't inactivate this record.
        </div>
      )} */}
                </span>
              );
            }}
          />
        </DataTable>
        <Modal
          className="modal-promotion"
          show={addCar}
          size="md"
          onHide={() => setAddCar(false)}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Formik
          // initialValues={{ ...updateUserProfile }}
          // validationSchema={
          //   selectedOption.value === 1
          //     ? validationSchemaAmount
          //     : validationSchemaPercentage
          // }
          // validationSchema ={validateSchema}
          // onSubmit={updateProfile}
          >
            {({ handleBlur, setFieldValue, values }) => {
              return (
                <Form>
                  <Modal.Header className="flex-modal-header">
                    <Modal.Title className="modal-title">
                      Add New Car
                    </Modal.Title>
                    <div class="divclose" onClick={() => setAddCar(false)}>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="mt-24">
                    <Row
                      className="justify-content-center"
                      style={{ border: "#E2E2E2 !important" }}
                    >
                      <Col md={12}>
                        <button
                          type="button"
                          className="w-100 bg-white"
                          onClick={() => setSequenceModal(true)}
                        >
                          <div
                            className="d-flex justify-content-between p-3 "
                            style={{ border: "#E2E2E2 !important" }}
                          >
                            <span className="font-weight-bold">Add Manually</span>
                            <span className="text-success fs-4">{">"}</span>
                          </div>
                        </button>
                      </Col>
                      <Col md={12}>
                        <button
                          type="button"
                          className="w-100 bg-white mb-3 border-top-0"
                          onClick={() => {
                            setUploadCsvModal(true);
                            setAddCar(false);
                          }}
                        >
                          <div className="d-flex justify-content-between p-3">
                            <span className="font-weight-bold">Upload CSV File</span>
                            <span className="text-success fs-4">{">"}</span>
                          </div>
                        </button>
                      </Col>
                    </Row>
                  </Modal.Body>
                </Form>
              );
            }}
          </Formik>
        </Modal>
        <Modal
          className="modal-promotion"
          show={uploadCsvModal}
          size="md"
          onHide={() => setUploadCsvModal(false)}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Formik
          // initialValues={{ ...updateUserProfile }}
          // validationSchema={
          //   selectedOption.value === 1
          //     ? validationSchemaAmount
          //     : validationSchemaPercentage
          // }
          // validationSchema ={validateSchema}
          // onSubmit={updateProfile}
          >
            {({ handleBlur, setFieldValue, values }) => {
              return (
                <Form>
                  <Modal.Header className="flex-modal-header">
                    <Modal.Title className="modal-title">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="18"
                        viewBox="0 0 9 14"
                        fill="none"
                        onClick={() => {
                          setUploadCsvModal(false);
                          setAddCar(true);
                        }}
                      >
                        <path
                          d="M8.50586 11.7552L3.80007 6.99713L8.50586 2.23906L6.9559 0.671875L0.700148 6.99713L6.9559 13.3224L8.50586 11.7552Z"
                          fill="black"
                        />
                      </svg>
                      <span className="ml-2 fw-bold"> Add New Car</span>
                    </Modal.Title>
                    <div
                      class="divclose"
                      onClick={() => setUploadCsvModal(false)}
                    >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="mt-24">
                    <Row className="justify-content-center">
                      <Col md={12} className="position-relative">
                        <div className="mb-4 form-label-group">
                          <InputText
                            className="height-50 br-10 bg-color-select w-100"
                            style={{ height: "50px" }}
                            name="uploadcsv"
                            // type="text"
                            readOnly
                            placeholder=""
                            // options={ModalData}
                            value={selectedFile ? selectedFile.name : ""}
                          // onChange={handleSelectChange}
                          />
                          <label htmlFor="uploadcsv">Upload Csv File</label>
                          <ErrorMessage
                            name="modalyear"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                        {!selectedFile ? (
                          <div>
                            <label
                              htmlFor="file-upload"
                              className="custom-file-upload position-absolute top-0 right-0 end-0 mr-4 bg-white border-0 text-decoration-underline text-success"
                              style={{ marginTop: "8px" }}
                            >
                              Upload
                            </label>
                            <InputText
                              id="file-upload"
                              type="file"
                              accept=".csv"
                              onChange={handleFileChange}
                            />
                          </div>
                        ) : (
                          <div>
                            <Button
                              htmlFor="file-upload"
                              className="custom-file-upload position-absolute top-0 right-0 end-0 mr-4 bg-white border-0 text-decoration-underline text-success"
                              style={{ marginTop: "8px" }}
                              onClick={() => {
                                setSelectedFile(null);
                              }}
                            >
                              Remove
                            </Button>
                          </div>
                        )}
                      </Col>
                      <Col md={12}>
                        <Button
                          variant="success"
                          className="btn-save save-button btn  btn-block"
                          style={{ height: "45px" }}
                        // type="submit"
                        // onClick={createOrUpdateAdmin}
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                  </Modal.Body>
                </Form>
              );
            }}
          </Formik>
        </Modal>

        <Modal
          className="modal-promotion"
          show={sequenceModal}
          size="md"
          onHide={() => setSequenceModal(false)}
          centered
          backdrop="static"
        >
          <Formik
            initialValues={{
              'userid': "",
              'sequenceNumber': ""
            }}
            onSubmit={getCarInfo}
          >
            {({ handleBlur, setFieldValue, values }) => {
              return (
                <Form>
                  <Modal.Header className="flex-modal-header">
                    <Modal.Title className="modal-title">
                      User Info
                    </Modal.Title>
                    <div class="divclose" onClick={() => setSequenceModal(false)}>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="mt-24">
                    <Row
                      className="justify-content-center"
                      style={{ border: "#E2E2E2 !important" }}
                    >
                      <Col md={12}>
                        <div className="form-group form-label-group">
                          <Field
                            id="userid"
                            type="text"
                            name="userid"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Vehical Owner ID"
                            onChange={(e) => {
                              // console.log(e.target.value)
                              setFieldValue("userid", e.target.value)
                            }}
                          />
                          <label htmlFor="userid">Vehical Owner ID</label>
                          <ErrorMessage
                            name="userid"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-group form-label-group">
                          <Field
                            id="sequenceNumber"
                            type="text"
                            name="sequenceNumber"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="sequenceNumber"
                            onChange={(e) => {
                              // console.log(e.target.value)
                              setFieldValue("sequenceNumber", e.target.value)
                            }}
                          />
                          <label htmlFor="sequenceNumber">Sequence Number</label>
                          <ErrorMessage
                            name="sequenceNumber"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <Button
                          variant="success"
                          className="btn-save save-button btn  btn-block"
                          style={{ height: "58px" }}
                          type="submit"
                        >
                          Get Info
                        </Button>
                      </Col>
                    </Row>
                  </Modal.Body>
                </Form>
              );
            }}
          </Formik>
        </Modal>
        {/* ADD Car Manually */}

        <Modal
          show={addCarManually}
          onHide={onHide}
          centered
          size="lg"
        >
          <Formik
            initialValues={modalValues}
            validationSchema={validateSchema}
            onSubmit={addInventory}
          >
            {({ handleBlur, setFieldValue, isSubmitting, values }) => {
              return (
                <Form>
                  <Modal.Header>
                    <Modal.Title className="modal-title">
                      Add New Car
                    </Modal.Title>
                    <div
                      className="divclose"
                      onClick={onHide}
                    >
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="mt-24">
                    <Row className="row">
                      <Col md={6} className="position-relative">
                        <div className="mb-4 form-label-group">
                          <InputText
                            className="height-50 br-10 bg-color-select w-100"
                            style={{ height: "60px" }}
                            name="uploadimage"
                            readOnly
                            placeholder="Select a file..."
                            value={!currImage ? selectedFile ? selectedFile?.name : "" : currImage}
                          />
                          <label htmlFor="uploadimage">Car Image</label>
                          <ErrorMessage
                            name="uploadimage"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                        {!selectedFile ? (
                          <div>
                            <label
                              htmlFor="file-upload"
                              className="custom-file-upload position-absolute top-0 right-0 end-0 mr-3 bg-white border-0 text-decoration-underline text-success"
                              style={{ marginTop: "10px" }}
                            >
                              Upload
                            </label>
                            <InputText
                              id="file-upload"
                              type="file"
                              accept=".png,.jpg"
                              onChange={(e) => {
                                setFieldValue("uploadimage", e.target.files[0]);
                                handleFileChange(e);
                                // setCurrImage("");
                              }}
                            />
                          </div>
                        ) : (
                          <div>
                            <Button
                              htmlFor="file-upload"
                              className="custom-file-upload position-absolute top-0 right-0 end-0 mr-3 bg-white border-0 text-decoration-underline text-success"
                              style={{ marginTop: "10px" }}
                              onClick={() => {
                                setFieldValue("uploadimage", null);
                                setSelectedFile(null);
                                setCurrImage("");
                              }}
                            >
                              Remove
                            </Button>
                          </div>
                        )}
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            name="manufacturer"
                            options={makerCategories}
                            // value={selectedOption}
                            value={makerCategories.find(a => a.value === values.manufacturer)}
                            onChange={(e) =>
                              setFieldValue("manufacturer", e.value)
                            }
                          />
                          <label htmlFor="manufacturer">Manufacturer</label>
                          <ErrorMessage
                            name="manufacturer"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4 form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            name="category_id"
                            options={carCategories}
                            value={carCategories.find(a => a.value === values.category_id)}
                            onChange={(e) => setFieldValue("category_id", e.value)}
                          />
                          <label htmlFor="category_id">Category</label>
                          <ErrorMessage
                            name="category_id"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="model_year"
                            type="text"
                            name="model_year"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Model Year"
                            disabled={true}
                            onChange={(e) => {
                              // console.log(e.target.value)
                              setFieldValue("model_year", e.target.value)
                            }}
                          />
                          <label htmlFor="model_year">Model Year</label>
                          <ErrorMessage
                            name="model_year"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className=" form-label-group">
                          <Select
                            className="height-50 br-10 bg-color-select"
                            name="model"
                            options={modelCategories}
                            // value={selectedOption}
                            value={modelCategories.find(a => a.value === values.model)}

                            onChange={(e) =>
                              setFieldValue("model", e.value)
                            }
                          />
                          {/* <Field
                            id="model"
                            type="text"
                            name="model"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Engine No"
                            onChange={(e) => {
                              // console.log(e.target.value)
                              setFieldValue("model", e.target.value)
                            }}
                          /> */}
                          <label htmlFor="model">Model</label>
                          <ErrorMessage
                            name="model"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="body_color"
                            type="text"
                            name="body_color"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Engine No"
                            onChange={(e) => {
                              // console.log(e.target.value)
                              setFieldValue("body_color", e.target.value)
                            }}
                          />
                          <label htmlFor="body_color">Body Color</label>
                          <ErrorMessage
                            name="body_color"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="content"
                            type="text"
                            name="engine_no"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Engine No"
                            onChange={(e) => {
                              // console.log(e.target.value)
                              setFieldValue("engine_no", e.target.value)
                            }}
                          />
                          <label htmlFor="engine_no">Engine No</label>
                          <ErrorMessage
                            name="engine_no"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="content"
                            type="text"
                            name="registration_no"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Registration No"
                            onChange={(e) => {
                              // console.log(e.target.value)
                              setFieldValue("registration_no", e.target.value)
                            }}
                          />
                          <label htmlFor="registration_no">Registration No</label>
                          <ErrorMessage
                            name="registration_no"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="content"
                            type="text"
                            name="chassis_no"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Chassis No"
                            onChange={(e) => {
                              // console.log(e.target.value)
                              setFieldValue("chassis_no", e.target.value)
                            }}
                          />
                          <label htmlFor="chassis_no">Chassis No</label>
                          <ErrorMessage
                            name="chassis_no"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="content"
                            type="text"
                            name="seating_capacity"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Seating Capacity"
                            onChange={(e) => {
                              // console.log(e.target.value)
                              setFieldValue("seating_capacity", e.target.value)
                            }}
                          />
                          <label htmlFor="content">Seating Capacity</label>
                          <ErrorMessage
                            name="seating_capacity"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="content"
                            type="text"
                            name="sequence_no"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Sequence No"
                            disabled={true}
                            onChange={(e) => {
                              // console.log(e.target.value)
                              setFieldValue("sequence_no", e.target.value)
                            }}
                          />
                          <label htmlFor="sequence_no">Sequence No</label>
                          <ErrorMessage
                            name="sequence_no"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group form-label-group">
                          <Field
                            id="content"
                            type="text"
                            name="rental_amount"
                            className="shadow-none form-control modal-input br-10"
                            placeholder="Rental Amount"
                            onChange={(e) => {
                              // console.log(e.target.value)
                              setFieldValue("rental_amount", e.target.value)
                            }}
                          />
                          <label htmlFor="rental_amount">Rental Amount</label>
                          <ErrorMessage
                            name="rental_amount"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <Button
                          variant="success"
                          className="btn-save save-button btn  btn-block"
                          style={{ height: "58px" }}
                          type="submit"
                        // onClick={createOrUpdateAdmin}
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                  </Modal.Body>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </div>
    </div >
  );
};

export default React.memo(connect()(withRouter(Ridearide)));
