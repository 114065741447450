import "./saas/cards.css";
import React, { useState } from "react";
import "./saas/dashBoard2.css";
import SingalCard from "./singalCard";
import graph from "./images/graph.svg";
import arrow from "./images/arrow.svg";
import downArrow from "./images/downArrow.svg";
import person from "./images/person.svg";
import Car from "./images/car.svg";
import van from "./images/van.svg";
import locationIcon from "./images/location.svg";
import redgraph from "./images/redgraph.svg";
import Calendar from "./Calendar";
import APIService from "../../services/APIService";
import moment from "moment";
function Cards(props) {
  const { topCard } = props;
  const [topCardData, setTopCardData] = useState({});
  const [gender, setGender] = useState("A");
  const [type, setType] = useState("day");
  const [timeRange, setTimeRange] = useState({ sDate: null, eDate: null });
  const getTimeRange = (range) => {
    try {
      setTimeRange({ sDate: range.sDate, eDate: range.eDate })
      setType("custom")
      APIService.get(`admin/dashboard/stats?type=custom&entity=rider&gender=${gender}&fromDate=${range.sDate} 00:00:00.000000&toDate=${range.eDate} 23:59:59.000000`)
        .then((res) => {
          if (res.status == 200) {
            let data = res?.data?.data ? res.data.data : "";
            setTopCardData(data);
          }
        })
        .catch((e) => {
          console.log(e)
        });
    } catch (e) {
      console.log(e);
    }
  },
    handleGender = (e) => {
      let value = e.target.value;
      setGender(value)
      try {
        APIService.get(`admin/dashboard/stats?type=${type}&entity=rider&gender=${value}${(timeRange.sDate && timeRange.eDate) ? `&fromDate=${timeRange.sDate} 23:59:59.000000&toDate=${timeRange.eDate} 23:59:59.000000` : ''}`)
          .then((res) => {
            if (res.status == 200) {
              let data = res?.data?.data ? res.data.data : "";
              setTopCardData(data);
            }
          })
          .catch((e) => {
            console.log(e)
          });
      } catch (e) {
        console.log(e);
      }
    }
    console.log('topCardData',topCard)

  return (
    <>
      <div className=" cards-section col-12 zoom-90">
        <div className="card-nav" style={{ marginBottom: "20px" }}>
          <h2 className="box-title" style={{fontWeight:'bold'}}>Daily Report</h2>
          <div>
            <div className="dropdown">
            
              <Calendar handleTimeRange={getTimeRange} />
            </div>
          </div>
        </div>

        <div className="row">
          <SingalCard
            profile={person}
            profilebgColor="#9293DB"
            arrow={arrow}
            ProfileName="Total Riders"
            Points="1,629"
            percentage="135"
            showPercenatge={false}
graph={graph}
            percentTextColor="#13B542"
            percentBgColor="rgba(19, 181, 66, 0.1)"
            // data={topCard?.rider}
            data={topCardData?.rider ? {...topCardData?.riderTotal,status:''} : {...topCard?.riderTotal,status:''} }
          />
          <SingalCard
            profile={Car}
            profilebgColor="#8099ED"
            ProfileName="Total Captains"
            arrow={downArrow}
            Points="2,392"
            percentage="35"
            showPercenatge={false}
graph={redgraph}
            percentTextColor="#EF4242"
            percentBgColor="rgba(239, 66, 66, 0.12)"
            // data={topCard?.driver}
            data={topCardData?.driver ? {...topCardData?.driverTotal,status:''} : {...topCard?.driverTotal,status:''} }
          />

          <SingalCard
            profile={locationIcon}
            profilebgColor="#87CBB9"
            arrow={arrow}
            ProfileName="Total Trips"
            Points="2,392"
            percentage="100"
            showPercenatge={false}
              graph={graph}
            percentTextColor="#13B542"
            percentBgColor="rgba(19, 181, 66, 0.1)"
            data={topCardData?.driver ? {...topCardData?.tripTotal,status:''} : {...topCard?.tripTotal,status:''} }

          />
          <SingalCard
            profile={person}
            profilebgColor="#9293DB"
            arrow={arrow}
            ProfileName="Today's Riders"
            Points="1,629"
            percentage="135"
            showPercentage={true}
            graph={graph}
            percentTextColor="#13B542"
            percentBgColor="rgba(19, 181, 66, 0.1)"
            // data={topCard?.rider}
            data={topCardData?.rider ? topCardData.rider : topCard?.rider}
          />
          <SingalCard
            profile={Car}
            profilebgColor="#8099ED"
            ProfileName="Today's Captains"
            arrow={downArrow}
            Points="2,392"
            percentage="35"
            graph={redgraph}
            showPercentage={true}
            percentTextColor="#EF4242"
            percentBgColor="rgba(239, 66, 66, 0.12)"
            // data={topCard?.driver}
            data={topCardData?.driver ? topCardData.driver : topCard?.driver}
          />

          <SingalCard
            profile={locationIcon}
            profilebgColor="#87CBB9"
            arrow={arrow}
            ProfileName="Today's Trips"
            Points="2,392"
            showPercentage={true}
            percentage="100"
            graph={graph}
            percentTextColor="#13B542"
            percentBgColor="rgba(19, 181, 66, 0.1)"
            data={topCardData?.trip ? topCardData.trip : topCard?.trip}

          />
        </div>
      </div>
    </>
  );
}

export default Cards;
