import React, { useEffect, useState } from 'react'
import { TabView, TabPanel } from 'primereact/tabview';
import Calendar from '../../Dashboard_2/Calendar';
import DoughnutCMS from './Doughnut';
import { _baseURL } from '../../../services/APIService';
import { secret_key } from '../../../services/APIService';

const DoughnutComponent = (props) => {

  let isuueTypeApi = `${_baseURL}/api/v1/ticket/${props.apiVar}`;

  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState(null);
  const [timeUtc, setTimeUtc] = useState(null);
  const getTimeRange = (range) => {
    let updatedApi = `${_baseURL}/api/v1/ticket/${props.apiVar}?fromDate=${range.sDate}&toDate=${range.eDate}`;
    fetchIssueTypeApi(updatedApi);
  }
  const getUtcOffset = () => {
    const now = new Date();
    const utcOffsetMinutes = now.getTimezoneOffset();
    const hours = Math.floor(Math.abs(utcOffsetMinutes) / 60);
    const minutes = Math.abs(utcOffsetMinutes) % 60;
    const formattedUtcOffset = `${utcOffsetMinutes >= 0 ? "-" : "+"}${hours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    setTimeUtc(formattedUtcOffset)
  };
  const categoriesToInclude = [
    "Registration",
    "Trip",
    "Passenger No Show",
    "Payout",
    "Vehicle Document Renewal",
    "Other"
  ];
  
  async function fetchIssueTypeApi(updatedApi = null) {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
          "Timezone": timeUtc
        },
        body: JSON.stringify({
          secret_key: secret_key,
          userType: 2
        }),
      };
      
      let result = await fetch(updatedApi ?? isuueTypeApi, requestOptions);
      let response = await result.json();
      setData(response.data)
    } catch (e) {
      throw new Error(e);
    }
  }
  

  useEffect(async () => {
    getUtcOffset();
    fetchIssueTypeApi();

  }, [])
  console.log("ioioio", props.apiVar, data?.driverIssueTypes
   
      )
  return (
    <div className="section-rider cards-section">
      <div className="d-flex justify-content-between text-center align-items-center position-relative">
        <h2 className="box-title">{props.name}</h2>
        <Calendar handleTimeRange={getTimeRange} />
      </div>
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="Captain">
          {data && <DoughnutCMS IssueTypeData={
            data?.driverIssueTypes
              ? data.driverIssueTypes
              : data?.driverRatings
                ? data.driverRatings[0]
                : data?.driverIssueTypes
                  ? data.driverIssueTypes
                  : data.driverIssueChannels
          } />}
        </TabPanel>
        <TabPanel header="Rider">
          {data && <DoughnutCMS IssueTypeData={
            data?.riderIssueTypes
              ? data.riderIssueTypes
              : data?.riderRatings
                ? data.riderRatings[0]
                : data?.riderIssueTypes
                  ? data.riderIssueTypes
                  : data.riderIssueChannels
          } />}
        </TabPanel>
        <TabPanel header="Internal">
          Coming Soon
        </TabPanel>
      </TabView>
    </div>
  )
}

export default DoughnutComponent