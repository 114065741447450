import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { connect, useSelector } from "react-redux";
import * as Yup from "yup";
import CountryInput from "../Riders/InputFeild";
import { Button, Card, Col, Row, Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import riderAvtSec from "../../assest/images/placeHoderImage.png";


let ProcessingListingTable = (props) => {
     const [requests,setRequests]= useState(null);
    const [showFilter, setShowFilter] = useState(false);
    const [otp, setotp] = useState("");
    const [moblNo, setMoblNo] = useState("966");
    const [OTPValidation, setOTPValidation] = useState(null);
    const [tillid, setTillId] = useState("");
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [enableOTP, setEnableOTP] = useState(false);
    const [sendotp, setSendotp] = useState(true);
    const [showRider, setAddRider] = useState(false);
    const [showMessage, setMessage] = useState(false);
    const dt = useRef(null);
   const [windowWidth] = useState(useSelector((state) => state.windowSize));
   const [selectedProducts, setSelectedProducts] = useState(null);
   const [rowClick, setRowClick] = useState(true);

   
    let [lazyParams, setLazyParams] = useState({
      filters: {
        userType: 1,
      },
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 5,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    });

    let addRiderHandleClose = () => {
      setAddRider(false)
      setEnableOTP(false)
    };
      let addRiderHandleShow = () => {
        setAddRider(true);
      };
     let handleCloseMessage = () => {
        setMessage(false);
        setMinutes(0);
        setSeconds(0);
      };
     let handleShowMessage = () => {
        setMessage(true);
      };
      const resendOTP = () => {
        sendOTP();
        setMinutes(1);
        setSeconds(0);
      };
      function getOTP() {
        sendOTP()
        setEnableOTP(true)
      };

      function VerifingOTP(tillId) {
        let text = moblNo.toString();
        text = text.replace("+", "");
        let MobileNo = text;
        const VerifyOTP = {
          mobileNo: MobileNo,
          tId: tillId,
          otp: otp,
          // userId: idNo,
          // dateOfBirth: dat,
        };
      }
      const sendOTP = () => {
        setMinutes(1);
        setSeconds(0);
    
        let text = moblNo.toString();
        text = text.replace("+", "");
    
        let MobileNo = text;
        const DataFilter = {
          mobileNo: MobileNo,
          reason: 4,
        };
    
        // APIService.post("/sendotp", DataFilter)
        //   .then((res) => setTillId(res.data.data.tId))
    
        //   .catch((err) => {
        //     console.log(err);
        //   });
    
    
      };


      const signUpSchma = Yup.object({
        idNumber: Yup.number().required("Please Enter your ID number"),
        dob: Yup.date().required("Please select date"),
        otp: Yup.string().required("Please enter your OTP number"),
      });


      // otp setting interval for time
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });


   const  renderHeader = () => {
      return (
        <>
          <div className="table-header">
            <span className="toggle-filter">
              <button
                className="btn btn-primary show_filter_btn"
                onClick={() => toggleFilter()}
              >
                {windowWidth ? (
                  showFilter ? (
                    <span>
                      <i className="fa fa-filter"></i>
                      <i className="fa fa-caret-up ml-10"></i>
                    </span>
                  ) : (
                    <span>
                      <i className="fa fa-filter"></i>
                      <i className="fa fa-caret-down ml-10"></i>
                    </span>
                  )
                ) : // <i className="fa fa-filter"></i>
                  showFilter ? (
                    <>
                      <img src="/images/filter-icon.svg" alt="" />
                      <span className="span-filter mx-12">Filters</span>
                      <i className="fa fa-caret-up"></i>
                    </>
                  ) : (
                    <>
                      <img src="/images/filter-icon.svg" alt="" />
                      <span className="span-filter mx-12">Filters</span>
                      <svg
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                          fill="#828282"
                        />
                      </svg>
                    </>
                  )}
              </button>
            </span>

            <span className="p-input-icon-left d-flex align-items-center">
              <i className="pi pi-search" />
              <InputText
                type="search"
                placeholder="Search..."
                value={lazyParams.keyword}
                onInput={(e) => {
                  lazyParams = {
                    ...lazyParams,
                    skip: 0,
                    take: 25,
                    keyword: e.target.value,
                  };
                  setLazyParams(lazyParams);
                }}
              />
            </span>
            
            {/* {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'export') &&  */}
            <button
              className="btn btn-primary float-right mb-1 ml-12 text-nowrap"
           
            >
              Export Report
            </button>
            <button
              className="btn btn-primary float-right mb-1 ml-12 text-nowrap"
              onClick={() => {
                setAddRider(true);
                setMinutes(0);
                setotp("");
                setSeconds(0);
                setSendotp(true);
                setMoblNo("");
              }}
            >
            Process Selected
            </button>
          </div>
        </>
      );
    };


   const header = renderHeader();
    const toggleFilter = () => {
      setShowFilter(!showFilter);
      setLazyParams({
        filters: {
          userType: 1,
        },
        sort: {
          field: "creationDate",
          order: -1,
        },
        take: 5,
        skip: 0,
        keyword: "",
        totalRecords: 0,
        userType: 1,
      });
    };


    let ProRequests = [
        {
        request_id:1146290172,
        requested_by:'john Jacob',
        request_type:'Type',
        transactions:'12',
        request_message:'Request message will go here'
        },
        {
            request_id:1146290172,
            requested_by:'john Jacob',
            request_type:'Type',
            transactions:'12',
            request_message:'Request message will go here'
            },
            {
                request_id:1146290172,
                requested_by:'john Jacob',
                request_type:'Type',
                transactions:'12',
                request_message:'Request message will go here'
                },
                {
                    request_id:1146290172,
                    requested_by:'john Jacob',
                    request_type:'Type',
                    transactions:'12',
                    request_message:'Request message will go here'
                    },
                    {
                        request_id:1146290172,
                        requested_by:'john Jacob',
                        request_type:'Type',
                        transactions:'12',
                        request_message:'Request message will go here'
                        },
                        {
                            request_id:1146290172,
                            requested_by:'john Jacob',
                            request_type:'Type',
                            transactions:'12',
                            request_message:'Request message will go here'
                            },
                            {
                                request_id:1146290172,
                                requested_by:'john Jacob',
                                request_type:'Type',
                                transactions:'12',
                                request_message:'Request message will go here'
                                },
                                                                                            
        ]
  

        useEffect(()=>{
          setRequests(ProRequests)
        },[])

  return (
    <>
      <div className="datatable-doc-demo mt-3">
        <div className="card">
          {/* <Tooltip target=".export-buttons>button" position="bottom" /> */}
          <DataTable
            ref={dt}
            value={requests}
            header={header}
            className="p-datatable-customers"
            dataKey="id"
            rowHover
            scrollable
            scrollHeight="470px"
            paginator
            emptyMessage="No Request Found"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
            rowsPerPageOptions={[5, 10, 25, 50]}
            totalRecords={lazyParams.totalRecords}
            lazy={true}
            first={lazyParams.skip}
            rows={lazyParams.take}
            // sortField={lazyParams.sort.field}
            // sortOrder={lazyParams.sort.order}
            loading={useSelector((state) => state.isGridLoader)}
            loadingIcon="fa fa-spinner"
            selectionMode={rowClick ? null : 'checkbox'} 
            selection={selectedProducts} 
            onSelectionChange={(e) => setSelectedProducts(e.value)}
          >
            <Column 
            className="text-center"
            selectionMode="multiple" 
            headerStyle={{ width: '20px' }}
            />
            <Column
              className="text-center text-success font-weight-bold pointer"
              style={{ fontSize: "12px", width: "170px" }}
              header="Request ID."
              field="request_id"
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="Request By."
              field="requested_by"
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="Type."
              field="request_type"
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="No of Transactions"
              field="transactions"
            />
            <Column
              className="text-center text-dark font-weight-bold"
              style={{ fontSize: "12px", width: "170px" }}
              header="Request Message"
              field="request_message"
            />

            
          </DataTable>
        </div>
      </div>
      <Modal
        className="modal-promotion"
        show={showRider}
        size="md"
        onHide={addRiderHandleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Formik validationSchema={signUpSchma}>
          {({ handleBlur, setFieldValue, values }) => {
            return (
              <Form>
                <Modal.Header className="flex-modal-header">
                  <Modal.Title className="modal-title">
                  Enter OTP
                  </Modal.Title>
                  <div class="divclose" onClick={addRiderHandleClose}>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body className="mt-24">
                  <Row className="justify-content-center">
                    <Col md={12}>
                      <div className="mb-4">
                        <CountryInput
                          setMoblNo={setMoblNo}
                          setOTPValidation={setOTPValidation}
                        />
                      </div>
                    </Col>
                    {enableOTP ? <Col md={12}>
                      <div className="form-group form-label-group mb-4">
                        <Field
                          id="otp"
                          type="text"
                          name="otp"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Enter OTP"
                          required

                          value={otp}
                          onChange={(e) => setotp(e.target.value)}
                        />
                        <label htmlFor="otp">Enter OTP</label>
                        <ErrorMessage
                          name="otp"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col> : <Col md={12}>
                      <div className="form-group form-label-group mb-4">
                        <Field
                          id="otp"
                          type="text"
                          name="otp"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Enter OTP"
                          required
                          disabled
                          value={otp}
                          onChange={(e) => setotp(e.target.value)}
                        />
                        <label htmlFor="otp">Enter OTP</label>
                        <ErrorMessage
                          name="otp"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    }

                    <Col md={12}>
                      {seconds > 0 || minutes > 0 ? (
                        <p>
                          Didn't recieve code?
                          {seconds > 0 || minutes > 0 ? (
                            <span className="ml-2 font-weight-bold text-dark">
                              {minutes < 10 ? `0${minutes}` : minutes}:
                              {seconds < 10 ? `0${seconds}` : seconds}
                              {setSendotp(false)}
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                      ) : (
                        <p>
                          <span>
                            {sendotp ? (
                              OTPValidation ? (
                                <p className="resend" style={{ fontSize: '20px' }} onClick={() => getOTP()}>
                                  send OTP
                                </p>
                              ) : (
                                <p disabled className="resend font-weight-bolder" style={{ color: 'gray', fontSize: '20px' }}>
                                  send OTP
                                </p>
                              )
                            ) : !otp ? (
                              <p>
                                Didn't recieve code?
                                <span
                                  className="resend ml-2"
                                  onClick={resendOTP}
                                  style={{ fontSize: '20px' }}
                                >
                                  {" "}
                                  Resend OTP
                                </span>
                              </p>
                            ) : (
                              <p className="resend" onClick={resendOTP} style={{ fontSize: '20px' }}>
                                send OTP
                              </p>
                            )}
                          </span>
                        </p>
                      )}
                    </Col>
                    <Col md={12} className="mt-3">
                      {otp.length > 3 ? (
                        <Button
                          variant="primary"
                          //type="submit"
                          onClick={() => {
                            VerifingOTP(tillid);
                            setEnableOTP(false);
                          }}
                          className="btn-save btn-block "
                        >
                         Verify
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          //type="submit"
                          className="btn-save btn-block "
                          disabled
                        >
                          Verify
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>
      <Modal
        show={showMessage}
        onHide={handleCloseMessage}
        centered
        backdrop="static"
        keyboard={false}
        size="md"
        className="modal-height modal-vc "
      >
        <Formik>
          {() => {
            return (
              <Form>
                <Modal.Header>
                  <Modal.Title className="modal-title"> </Modal.Title>
                  <div class="divclose" onClick={handleCloseMessage}>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Modal.Header>

                <Modal.Body>
                  <Row
                    className="customheight"
                    style={{
                      height: "440px !important",
                      minHeight: "440px !important",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Col xl={12} className="text-center my-auto custom-success">
                      <svg
                        class="custom-svg"
                        viewBox="0 0 26 26"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          stroke="currentColor"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            class="circle"
                            d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"
                          />
                          <path
                            class="tick"
                            d="M6.5 13.5L10 17 l8.808621-8.308621"
                          />
                        </g>
                      </svg>

                      <h2 className="success-message mt-24">
                        Selected Requests Has Been <br/> Processed
                      </h2>
                    </Col>
                  </Row>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>

     
     
    </>
  );
};
export default React.memo(connect()(ProcessingListingTable));
