import React, { useEffect, useState } from "react";
import { Tab } from "bootstrap";
import { Tabs } from "react-bootstrap";
import TripsTabs from "./TripsTabs";
import HighDemandZone from "./HighDemandZone";
import { tripTabs, HeatMapTabs, HeatMapData, tabsData, FareCalculationData, tripSettingsData } from "../components/TripSetting/TripManagementData";
import LocationNav from "../components/Dashboard_2/LocationNav";




const TripsManagement = (props) => {

  let topTab = props.location.pathname.split("/")[2] ?? null;
  let [tab, setTab] = useState(topTab),
    getActiveTab = () => {
      if (props.location.pathname) {
        setTab(topTab);
      } else {
        setTab("trips");
      }
    },
    permissions = JSON.parse(atob(localStorage.getItem("access")));
  useEffect(() => {
    getActiveTab();
  }, []);


  return (
    <>
      <div className="rider-listing ">
        <div className="mb-4">
        <span className="title">TRIPS MANAGEMENT</span>
        <span className="float-right">
        <LocationNav refreshData={()=>console.log("Refresh")}/>
        </span>
        </div>
        <Tabs
          id="controlled-tab-example"
          className="mt-30"
          activeKey={tab}
          onSelect={(tab) => {
            setTab(tab);
            props.history.push(`/trips/${tab}`);
          }}
        >
          {permissions.find(a => a.includes('/master-control/vehicle-makers')) &&
            <Tab eventKey="trips" title="Trips">
              {tab === "trips" && <TripsTabs base='trips' tripTabs={tripTabs} tabsData={tabsData} type="trips" />}
            </Tab>
          }
          {permissions.find(a => a.includes("/master-control/vehicle-makers")) &&
            <Tab eventKey="heat-map" title="Heat Map">
              {tab === "heat-map" && <TripsTabs base='trips' tripTabs={HeatMapTabs} tabsData={HeatMapData} type="heat-map" />}
            </Tab>
          }
          {permissions.find(a => a.includes("/master-control/vehicle-makers")) &&
            <Tab eventKey="fare-calculation" title="Fare Calculation">
              {tab === "fare-calculation" && <TripsTabs base='trips' tripTabs={tripTabs} tabsData={FareCalculationData} type="fare-calculation" />}
            </Tab>
          }
          {permissions.find(a => a.includes("/master-control/vehicle-makers")) &&
            <Tab eventKey="trip-settings" title="Trip Settings">
              {tab === "trip-settings" && < TripsTabs base='trips' tripTabs={tripTabs} tabsData={tripSettingsData} type="trip-settings" />}
            </Tab>
          }
          {permissions.find(a => a.includes("/master-control/vehicle-makers")) &&
            <Tab eventKey="high-zone" title="High Zone">
              {tab === "high-zone" && <HighDemandZone />}
            </Tab>
          }
        </Tabs>
      </div>
    </>
  )
}

export default React.memo(TripsManagement);