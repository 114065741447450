import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { _baseURL } from "../../services/APIService";
import Calendar from "./Calendar";

function EhsanDonationCard() {
  const [activeIndex, setActiveIndex] = useState(0);
  const getTimeRange=(range)=>{}

  return (
    <>
      <div className="section-rider col-lg-12 section-captain h-100">
        <div className="card-nav mb-16">
          <h2 className="box-title text-wrap font-weight-bold">Ehsan Donation</h2>
          <div>
            <div className="dropdown">
              <Calendar handleTimeRange={getTimeRange} />
            </div>
          </div>
        </div>
        <TabView
          className="mt-2"
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="Riders">
          <div className="d-flex justify-content-center align-content-center fw-bold fs-4" style={{marginTop:"220px",color:"#212529"}}>
          Coming soon
        </div>
          </TabPanel>
          <TabPanel header=" Captains">
          <div className="d-flex justify-content-center align-content-center fw-bold fs-4" style={{marginTop:"220px",color:"#212529"}}>
          Coming soon
        </div>
          </TabPanel>
        </TabView>
      </div>
    </>
  );
}

export default EhsanDonationCard;
