import React from 'react'
import { Row, Col } from 'react-bootstrap'
const InfoCard = ({ info }) => {
  const length = parseInt(12 / info.length)
  return (
    <div className='my-3'>
      <Row>
        {info.map(data =>
          <Col xl={length} >
            <div className=' d-flex align-items-center justify-content-between p-2 bg-white' style={{ border: '1px solid gray', borderRadius: '5px', height: '55px', outline: '1px solid green' }}>
              <div className=' font-weight-bold text-secondary'>{data.title}</div>
              <div className=' font-weight-bold text-success'>{data.value}</div>
            </div>
          </Col>
        )}
      </Row>
    </div >
  )
}

export default InfoCard
