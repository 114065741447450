import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import APIService from "../../services/APIService";
let UserLogs = (props) => {
  const externalId = props.externalId;
 
  let [userLogs, setUserLogs] = useState([]),
    dt = useRef(null),
    [lazyParams, setLazyParams] = useState({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 5,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      }),

    getUserLogs = async () => {
      APIService.get(`admin/get-my-logs?userId=${externalId}&take=10&skip=0`)
      .then((response) => {
        if (response.data && response.data.statusCode === 200) {
          const logData = response.data.data.result;
          console.log(response)
          
          setUserLogs(logData)
          setLazyParams({ ...lazyParams, totalRecords: logData.length });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //  const logData = [
    //     {
    //     device_ip:'192.0.1.2',
    //     location:'Riyadh, Saudi Arabia',
    //     date:'28/09/2023',
    //     login_time:'12:34 AM',
    //     logout_time:'12:45 AM',
    //     duration:'9 Mins'
    //  },
    //  {
    //     device_ip:'192.0.1.2',
    //     location:'Riyadh, Saudi Arabia',
    //     date:'28/09/2023',
    //     login_time:'12:34 AM',
    //     logout_time:'12:45 AM',
    //     duration:'9 Mins'
    //  },
    //  {
    //     device_ip:'192.0.1.2',
    //     location:'Riyadh, Saudi Arabia',
    //     date:'28/09/2023',
    //     login_time:'12:34 AM',
    //     logout_time:'12:45 AM',
    //     duration:'9 Mins'
    //  },
    //  {
    //     device_ip:'192.0.1.2',
    //     location:'Riyadh, Saudi Arabia',
    //     date:'28/09/2023',
    //     login_time:'12:34 AM',
    //     logout_time:'12:45 AM',
    //     duration:'9 Mins'
    //  },
    //  {
    //     device_ip:'192.0.1.2',
    //     location:'Riyadh, Saudi Arabia',
    //     date:'28/09/2023',
    //     login_time:'12:34 AM',
    //     logout_time:'12:45 AM',
    //     duration:'9 Mins'
    //  },
    //  {
    //     device_ip:'192.0.1.2',
    //     location:'Riyadh, Saudi Arabia',
    //     date:'28/09/2023',
    //     login_time:'12:34 AM',
    //     logout_time:'12:45 AM',
    //     duration:'9 Mins'
    //  },
    //  {
    //     device_ip:'192.0.1.2',
    //     location:'Riyadh, Saudi Arabia',
    //     date:'28/09/2023',
    //     login_time:'12:34 AM',
    //     logout_time:'12:45 AM',
    //     duration:'9 Mins'
    //  }
    
    
    // ]
    };

  useEffect(() => {
    getUserLogs();
  }, []);
  

  return (
    <div className="datatable-doc-demo ">
      <h2 className="box-title">User Logs </h2>
      <div className="card mt-3">
        <DataTable
          ref={dt}
          value={userLogs}
          // header={header}
          className="p-datatable-customers"
          dataKey="id"
        //   globalFilter={globalFilter}
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No User Log(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[5, 10, 25]}
          totalRecords={userLogs.length}
        //   lazy={true}
        //   first={lazyParams.skip}
          rows={lazyParams.take}
        //   onPage={manageActiveSubscription}
        //   onSort={manageActiveSubscription}
        //   onFilter={manageActiveSubscription}
        //   sortField={lazyParams.sort.field}
        //   sortOrder={lazyParams.sort.order}
          loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Device IP"
            field="deviceIp"
            body={(value) =>{
              return <span>{value.deviceIp ? value.deviceIp : '-'}</span>
            }}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Location"
            field="location"
            body={(value) =>{
              return <span>{value.location ? value.location : '-'}</span>
            }}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Date"
            field="createdAt"
            body={(value) =>{
              return <span>{value.createdAt ? value.createdAt.slice(0, 10) : '-'}</span>
            }}
          />
           <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Login Time"
            field="createdAt"
            body={(value) =>{
              return <span>{value.createdAt ? value.createdAt.slice(11, 19) : '-'}</span>
            }}
          />
        <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Logout Time"
            field="logoutTime"
            body={(value) =>{
              return <span>{value.logoutTime ? value.logoutTime.slice(11, 19) : '-'}</span>
            }}
          />
          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "200px" }}
            header="Duration"
            field="timeDifference"
            body={(value) =>{
              return <span>{value.timeDifference ? value.timeDifference : '-'}</span>
            }}
          />
          

        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(connect()(UserLogs));
