import React, { useEffect, useState }from "react";
import { useHistory , useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Tab } from "bootstrap";
import { Tabs } from "react-bootstrap";
import Rides from "./Rides";

const TripsTabs = ({tripTabs , base, tabsData , type, ...props})=>{
    let
    location = useLocation(), 
    history = useHistory(),
    // currentTab = tripTabs.includes(location.pathname.split("/").pop()) ? location.pathname.split("/").pop() : null,
    [tab, setTab] = useState( tripTabs[0]),
    permissions = JSON.parse(atob(localStorage.getItem("access")));
    // useEffect(()=>{
    //     history.push(`/${base}/${type}/${tab}`)
    // },[tab])
    return (
          <Tabs
            id="controlled-tab-example"
            className="mt-30"
            activeKey={tab}
            onSelect={(tab) => setTab(tab)}
          >
            {tabsData.map((item)=>{
              const {Component} = item;
              console.log('items',Component);
              return(
                // {permissions.find(a => a.includes('/master-control/vehicle-makers')) &&
                  <Tab eventKey={`${item.key}`} title={`${item.title}`}>
                    { tab === `${item.key}` && item?.type ? <Component type={item.type} {...props} /> : <Component {...props}/> }
                  </Tab>
                // }
              )
            })}
          </Tabs>
    )
}

export default React.memo(TripsTabs);