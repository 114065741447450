import React , {useState} from "react";
import { Tab, Tabs } from "react-bootstrap";
import Ridearide from "../components/Inventory/Ridearide";
import OwnaCar from "../components/Inventory/OwnaCar";

let Inventory = () => {
  let [selectTab, setSelectedTab] = useState("RideaRide");
  return (
    <>
      <div className="mt-4">
      {/* <div className="d-flex justify-content-between">
          <span className="title">Car Details</span>
        </div> */}
        <Tabs
          id="controlled-tab-example"
          className="mt-30 position-relative"
          activeKey={selectTab}
          onSelect={(tab) => {
            setSelectedTab(tab);
          }}
        >
          
          <Tab eventKey="RideaRide" title="Ride a Ride">
            {selectTab === "RideaRide" && (
            <Ridearide/>
            )}
          </Tab>
          <Tab eventKey="OwnaCar" title="Own A Car">
            {selectTab === "OwnaCar" && (
              <OwnaCar/>
            )}
          </Tab>
          <Tab eventKey="pickup" title="Pickup">
            {selectTab === "pickup" && (
              "Coming soon"
            )}
          </Tab>
          </Tabs>
      </div>
    </>
  );
};

export default React.memo(Inventory);