import React from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import Settings from "../pages/Settings";
import Login from "../pages/Login";
import RideNotification from "../pages/RideNotification";
import BasePage from "../BasePage";
import Base from "../Base";
import { CSSTransition, TransitionGroup } from "react-transition-group";
// import Dashboard from "../pages/Dashboard";
import RiderListing from "../pages/RiderListing";
import RiderListingTableDetailPage from "../pages/RiderListingTableDetailPage";
import TripSettingPage from "../components/TripSetting/TripSettingDetailPage";
import RadiusSettingPage from "../components/TripSetting/RadiusSettingDetailPage";
import OtherSettingPage from "../components/TripSetting/OtherSettingDetailPage";
import DriverListing from "../pages/DriverListing";
import DriverListingTableDetailPage from "../pages/DriverListingTableDetailPage";
// import VehicleListingPage from "../pages/VehicleListingPage";
import VehicleEditPage from "../pages/VehicleEditPage";
import VehicleTypeListingPage from "../pages/VehicleTypeListingPage";
import VehicleMakerListingPage from "../pages/VehicleMakerListingPage";
import VehicleModelListingPage from "../pages/VehicleModelListingPage";
import Profile from "../pages/Profile";
// import Admin from "../pages/Admin";
import Rides from "../pages/Rides";
import PromotionalCoupon from "../pages/PromotionalCoupon";
import ForgotPassword from "../pages/ForgotPassword";
import NewPassword from "../pages/NewPassword";

import RatingRiders from "../pages/RatingRiders";
import RatingDrivers from "../pages/RatingDrivers";
import RolesPage from "../pages/RolesPage";

import RidesDetails from "../pages/RidesDetailsPage";
import CancelRideReasonRiderPage from "../pages/CancelRideReasonRiderPage";
import DeclineRequestReasonPage from "../pages/DeclineRequestReasonPage";
import Notifications from "../pages/Notifications";
import SubscriptionsPackage from "../pages/SubscriptionsPackage";
import Subscriptions from "../pages/Subscriptions";
import DriverRegistrationPage from "../pages/DriverRegistrationPage";
import CancelRideReasonDriverPage from "../pages/CancelRideReasonDriverPage";
import CityPage from "../pages/CityPage";
import CountryPage from "../pages/CountryPage";
import ReportsRiderPage from "../pages/ReportsRiderPage";
import ReportsDriverPage from "../pages/ReportsDriverPage";
import ReportsTransactionsPage from "../pages/ReportsTransactionsPage";
import ReportsDriverEarning from "../pages/ReportsDriverEarning";
import ReportsTripCancelledSummary from "../pages/ReportsTripCancelledSummary";
import ReportsTripsPage from "../pages/ReportsTripsPage";
import ReportsTripCancelledByRider from "../pages/ReportsTripCancelledByRider";
import ReportsDeclineRequestByCaptain from "../pages/ReportsDeclineRequestByCaptain";
import AuditLogPage from "../pages/AuditLogPage";
import RolePage from "../pages/RolePage";
// import NotificationPushPage from "../pages/NotificationPushPage";
// import NotificationSMSPage from "../pages/NotificationSMSPage";
// import NotificationEmailPage from "../pages/NotificationEmailPage";
import NotificationPushEditPage from "../pages/NotificationPushEditPage";
import NotificationSMSEditPage from "../pages/NotificationSMSEditPage";
import NotificationEmailEditPage from "../pages/NotificationEmailEditPage";
import ReportsVehicleRegistrationPage from "../pages/ReportsVehicleRegistrationPage";
import VehicleRegistration from "../pages/VehicleRegistration";
import TrackDrivers from "../pages/TrackDrivers";
import DispatchListing from "../pages/DispatchListing";
import DispatchTripListing from "../pages/DispatchTripListing";
import IncompleteTripReq from "../pages/IncompleteTripReq";
import EmergencyRequestPage from "../pages/EmergencyRequestPage";
import EmergencyTeamsPage from "../pages/EmergencyTeamsPage";
import DispatchDetailTabsLayout from "../pages/DispatchDetailTabsLayout";
import EmergencyRequestDetail from "../components/Emergency/EmergencyRequestDetail";
import EmergencyTeamDetailTabLayout from "../pages/EmergencyTeamDetailTabLayout";
import CreateTripUI from "../components/Dispatch/CreateTripUI";
import NoAccess from "../pages/NoAccess";
import FareConfigationListingPage from "../pages/FareConfigationListingPage";
import FareConfigationDetailsTab from "../components/FareConfigation/FareConfigationDetailsTab";
import Users from "../pages/Users";
import ChatSetting from "../pages/ChatSetting";
import User from "../pages/User";
import ChatAuditLog from "../pages/ChatAuditLog";
import SLAConfig from "../pages/SLAConfig";
import Promotions from "../components/PromotionalCoupons/Promotions";
import Accounting from "../pages/Accounting";
import InvoiceData from "../components/invoice/InvoiceData";
import InvoiceArabicData from "../components/invoice/invoiceArabicData";
import HeatMap from "../pages/HeatMap";
import TicketListing from "../components/Tickets/TicketListing";
import TicketDetail from "../components/Tickets/TicketDetail";
import Dashboard2 from "../pages/Dashboard2";
import OtpLogs from "../pages/OtpLogs";
import adjust from "../pages/adjust";
import UnifonicTables from "../components/adjust/unifonic-otp";
import promoCodes from "../components/adjust/promoCodes";
import PromoCodesDetail from "../components/adjust/promoCodesDetail";
import subscriptionPackages from "../components/adjust/subscriptionPackages";
import subscriptionPackagesDetail from "../components/adjust/subscriptionPackagesDetail";
import InvoiceBoth from "../components/invoice/invoiceBoth";
import UserProfile from "../pages/UserProfile";
import CategoyPermissionsListing from "../pages/CategoryPermissionListing";
import Reconciliation from "../pages/Reconciliation/Reconciliation";
import PickupsListingTableDetailPage from "../pages/PickupsListingTableDetailPage";
import PickupsListing from "../pages/PickupsListing";
import Inventory from "../pages/Inventory";
import RidearideDetails from "../components/Inventory/RidearideDetails";
import PromoDetail from "../components/PromotionalCoupons/PromoDetail";
import NotificationList from "../pages/NotificationList";
import Success from "../pages/Tabbypages/Success";
import Failure from "../pages/Tabbypages/Failure";
import Cancel from "../pages/Tabbypages/Cancel";
import userManagement from "../pages/userManagement";
import PickupDetailsTab from "../components/Pickups/PickupDetailsTab";
import TripsManagement from "../pages/TripsManagement";
import TripsTabs from "../pages/TripsTabs";
import Tickets from "../components/Tickets/Tickets";
import ManagementListingTableDetailPage from "../pages/ManagementListingTableDetailPage";
import SubscrptionDetail from "../components/Subscriptions/SubscrptionDetail";

let nonAuthenticatedPages = [
  "login",
  "forgot-password",
  "reset-password",
  "new-password",
],
  isFirstLogin = localStorage.getItem("isFirstLogin"),
  isFirstLogin_allow = (isFirstLogin || isFirstLogin == 'true') ? true : false,
  PreLoginRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: props.location },
            }}
          />
        ) : !isFirstLogin_allow && props.location.pathname.split('/')[1] === 'new-password' ? (
          <Redirect to={{
            pathname: '/login'
          }} />
        ) :
          (
            <Component {...props} />
          )
      }
    />
  );
let publicPages = ["trip-invoice", "trip-arabic-invoice", "trip-both-invoices", "checkout"],
  PublicRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => <Component {...props} />} />
  );

let Routes = (props) => {
  let currentKey = props.location.pathname.split("/")[1] || "/";
  let timeout = { enter: 500, exit: 500 };
  let animationName = "rag-fadeIn";

  if (
    nonAuthenticatedPages.indexOf(props.location.pathname.split("/")[1]) > -1
  ) {
    return (
      <BasePage>
        <Switch location={props.location}>
          <PreLoginRoute path="/login" component={Login} />
          <PreLoginRoute path="/forgot-password" component={ForgotPassword} />
          <PreLoginRoute
            path="/reset-password/:token"
            component={NewPassword}
          />
          <PreLoginRoute path="/new-password" component={NewPassword} />
        </Switch>
      </BasePage>
    );
  }

  else if (publicPages.indexOf(props.location.pathname.split("/")[1]) > -1) {
    return (
      <Switch location={props.location}>
        <PublicRoute path="/trip-invoice/:id" component={InvoiceData} />
        <PublicRoute path="/trip-arabic-invoice/:id" component={InvoiceArabicData} />
        <PublicRoute path="/trip-both-invoices/:id" component={InvoiceBoth} />
        <PublicRoute path="/checkout/tabby/success" component={Success} />
        <PublicRoute path="/checkout/tabby/failure" component={Failure} />
        <PublicRoute path="/checkout/tabby/cancel" component={Cancel} />
        <PublicRoute path="/checkout/tamara/success" component={Success} />
        <PublicRoute path="/checkout/tamara/failure" component={Failure} />
        <PublicRoute path="/checkout/tamara/cancel" component={Cancel} />
        {/* <PublicRoute path="/unauthorised" component={NoAccess} /> */}
      </Switch>
    )
  }
  // else if (publicPages.indexOf(props.location.pathname.split("/")[2]) > -1) {
  //   return <PublicRoute path="/trip-arabic-invoice/:id" component={InvoiceArabicData}/>
  // }

  else {
    return (
      <Base>
        <TransitionGroup>
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames={animationName}
            exit={false}
          >
            <>
              <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
              />
              {props.isLoader && (
                <div className="loader">
                  <i className="fa fa-spinner"></i>
                </div>
              )}
              <Switch location={props.location}>
                <PrivateRoute path="/" exact component={Dashboard2} />
                <PrivateRoute path="/dashboard" component={Dashboard2} />
                <PrivateRoute path="/dashboard" component={Dashboard2} />
                <PrivateRoute path="/logs" component={OtpLogs} />
                <PrivateRoute path="/adjust" component={adjust} />
                <PrivateRoute path="/unifonic-otp" component={UnifonicTables} />
                <PrivateRoute path="/promocodes" component={promoCodes} />
                <PrivateRoute path="/promocodes-detail/:id" component={PromoCodesDetail} />
                <PrivateRoute path="/subscription-packages" component={subscriptionPackages} />
                <PrivateRoute path="/subscription-packages-detail/:id" component={SubscrptionDetail} />
                <PrivateRoute path="/user-management" component={userManagement} />
                <PrivateRoute path="/riders" component={RiderListing} />
                <PrivateRoute path="/pickups" component={PickupsListing} />
                <PrivateRoute path="/tickets/:tab" component={Tickets} />
                <PrivateRoute
                  path="/ticket-detail/:id"
                  component={TicketDetail}
                />
                <PrivateRoute
                  path="/rider-details/:tab/:id"
                  component={RiderListingTableDetailPage}
                />
                <PrivateRoute
                  path="/driver-pickups/:id"
                  component={PickupDetailsTab}
                />
                <PrivateRoute 
                path="/management-details/:id"
                component={ManagementListingTableDetailPage}
                />
                <PrivateRoute
                  path="/pickup-details/:tab/:id"
                  component={PickupsListingTableDetailPage}
                />
                <PrivateRoute path="/drivers" component={DriverListing} />
                <PrivateRoute
                  path="/driver-details/:tab/:driver"
                  component={DriverListingTableDetailPage}
                />
                {/* <PrivateRoute path="/vehicles" component={VehicleListingPage} /> */}
                <PrivateRoute path="/vehicle/:id" component={VehicleEditPage} />
                <PrivateRoute
                  path="/vehicle-types"
                  component={VehicleTypeListingPage}
                />
                <PrivateRoute
                  path="/vehicle-makers"
                  component={VehicleMakerListingPage}
                />
                <PrivateRoute
                  path="/vehicle-models"
                  component={VehicleModelListingPage}
                />
                <PrivateRoute path="/rating-riders" component={RatingRiders} />
                <PrivateRoute
                  path="/rating-drivers"
                  component={RatingDrivers}
                />
                <PrivateRoute path="/profile" component={Profile} />
                <PrivateRoute path="/admin/:tab" component={UserProfile} />
                <PrivateRoute path="/category-permissions/:id" component={CategoyPermissionsListing} />
                <PrivateRoute path="/roles" component={RolesPage} />
                <PrivateRoute path="/role/:id" component={RolePage} />
                <PrivateRoute path="/role" component={RolePage} />
                <PrivateRoute path="/city" component={CityPage} />
                <PrivateRoute path="/country" component={CountryPage} />
                {/* <PrivateRoute path="/tripss" component={Rides} /> */}
                <PrivateRoute
                  path="/trip-details/:tripId"
                  component={RidesDetails}
                />
                <PrivateRoute
                  path="/driver-registration"
                  component={DriverRegistrationPage}
                />

                <PrivateRoute
                  path="/emergency-request"
                  component={EmergencyRequestPage}
                />
                <PrivateRoute
                  path="/emergency-request-detail/:id"
                  component={EmergencyRequestDetail}
                />

                <PrivateRoute
                  path="/emergency-team"
                  component={EmergencyTeamsPage}
                />
                <PrivateRoute
                  path="/emergency-team-detail/:tab/:id"
                  component={EmergencyTeamDetailTabLayout}
                />

                <PrivateRoute
                  path="/subscriptions/:tab"
                  component={Subscriptions}
                />
                <PrivateRoute path="/accounting/:tab" component={Accounting} />
                <PrivateRoute path="/reconciliation" component={Reconciliation} />
                <PrivateRoute
                  path="/master-control/:tab"
                  component={Settings}
                />
                <PrivateRoute path="/trips/:tab" component={TripsManagement} />
                {/* <PrivateRoute path="/trips/:tab/:subtab" component={TripsTabs}/> */}
                <PrivateRoute
                  path="/fare-configuration/:tab"
                  component={FareConfigationListingPage}
                />
                <PrivateRoute path="/markeeting/promo-detail/:id" component={PromoDetail} />
                <PrivateRoute path="/markeeting/:tab" component={Promotions} />
                <PrivateRoute path="/heat-map/:tab" component={HeatMap} />
                <PrivateRoute path="/car-details" component={Inventory} />
                <PrivateRoute path="/car-inventory/:id" component={RidearideDetails} />
                <PrivateRoute
                  path="/subscriptions-package"
                  component={SubscriptionsPackage}
                />

                <PrivateRoute
                  path="/promotional-coupon"
                  component={PromotionalCoupon}
                />
                <PrivateRoute
                  path="/cancel-ride-reason-rider"
                  component={CancelRideReasonRiderPage}
                />
                <PrivateRoute
                  path="/decline-ride-reason"
                  component={DeclineRequestReasonPage}
                />
                <PrivateRoute
                  path="/cancel-ride-reason-driver"
                  component={CancelRideReasonDriverPage}
                />
                <PrivateRoute path="/notification-detail/:tab" component={NotificationList} />
                <PrivateRoute path="/notifications" component={Notifications} />
                <PrivateRoute
                  path="/reports-riders"
                  component={ReportsRiderPage}
                />
                <PrivateRoute
                  path="/reports-drivers"
                  component={ReportsDriverPage}
                />
                <PrivateRoute
                  path="/reports-trips"
                  component={ReportsTripsPage}
                />
                <PrivateRoute
                  path="/reports-transactions"
                  component={ReportsTransactionsPage}
                />
                <PrivateRoute
                  path="/reports-driver-earning"
                  component={ReportsDriverEarning}
                />
                <PrivateRoute
                  path="/reports-trip-cancelled-summary"
                  component={ReportsTripCancelledSummary}
                />

                <PrivateRoute
                  path="/reports-trip-cancelled-rider"
                  component={ReportsTripCancelledByRider}
                />
                <PrivateRoute
                  path="/reports-decline-request-captain"
                  component={ReportsDeclineRequestByCaptain}
                />
                <PrivateRoute
                  path="/reports-vehicle-registration"
                  component={ReportsVehicleRegistrationPage}
                />
                <PrivateRoute
                  path="/registered-vehicle/:id"
                  component={VehicleRegistration}
                />

                <PrivateRoute
                  path="/fare-configuration"
                  component={FareConfigationListingPage}
                />
                <PrivateRoute
                  path="/fare-conf/:tab/:id"
                  component={FareConfigationDetailsTab}
                />
                <PrivateRoute
                  path="/communication/:tab"
                  component={RideNotification}
                />

                {/* <PrivateRoute
                  path="/notification-push"
                  component={NotificationPushPage}
                /> */}
                <PrivateRoute
                  path="/notification-push-edit/:id"
                  component={NotificationPushEditPage}
                />
                {/* <PrivateRoute
                  path="/notification-sms"
                  component={NotificationSMSPage}
                /> */}
                <PrivateRoute
                  path="/notification-sms-edit/:id"
                  component={NotificationSMSEditPage}
                />
                {/* <PrivateRoute
                  path="/notification-emails"
                  component={NotificationEmailPage}
                /> */}
                <PrivateRoute
                  path="/notification-email-edit/:id"
                  component={NotificationEmailEditPage}
                />

                <PrivateRoute
                  path="/dispatchers-details/:id"
                  component={DispatchDetailTabsLayout}
                />

                <PrivateRoute path="/dispatchers" component={DispatchListing} />
                <PrivateRoute
                  path="/dispatchers-trip"
                  component={DispatchTripListing}
                />
                <PrivateRoute
                  path="/incomplete-trip"
                  component={IncompleteTripReq}
                />
                <PrivateRoute path="/create-trip" component={CreateTripUI} />
                <PrivateRoute
                  path="/trip-setting"
                  component={TripSettingPage}
                />
                <PrivateRoute
                  path="/radius-setting"
                  component={RadiusSettingPage}
                />
                <PrivateRoute
                  path="/other-setting"
                  component={OtherSettingPage}
                />
                <PrivateRoute path="/audit-log" component={AuditLogPage} />
                <PrivateRoute path="/track-captains" component={TrackDrivers} />
                <PrivateRoute path="/unauthorised" component={NoAccess} />
                <PrivateRoute path="/chat-users" component={Users} />
                <PrivateRoute path="/chat-audit-log" component={ChatAuditLog} />
                <PrivateRoute path="/user/:id" component={User} exact />
                <PrivateRoute path="/user/:id/:tab" component={User} exact />
                <PrivateRoute path="/chat-setting" component={ChatSetting} />
                <PrivateRoute path="/sla-config" component={SLAConfig} />
                <Redirect to="/login" />
              </Switch>
            </>
          </CSSTransition>
        </TransitionGroup>
      </Base>
    );
  }
};

export default connect((state) => {
  return {
    isLoader: state.isLoader,
  };
})(withRouter(Routes));
