import { Tab } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Tabs } from "react-bootstrap";
import SubscriptionsPackage from "../components/Subscriptions/SubscriptionsPackage";
import ExpiredSubscriptionsListingTable from "../components/Subscriptions/ExpiredSubscriptionsListingTable";
import DriverEarnings from "../components/accountings/DriverEarnings";
import Main from "../components/accountings/Main";
import DriverSubscription from "../components/accountings/DriverSubscription";
import Tax from "../components/accountings/Tax";
import Topups from "../components/accountings/Topups";
import Fees from "../components/accountings/Fees";
import APIService from "../services/APIService";
import { useDispatch } from 'react-redux';
import Ehsaan from "../components/accountings/Ehsaan";
import All from "../components/accountings/All";
import Refunds from "../components/accountings/Refunds";
import Tabby from "./tabby";
import Tamara from "./tamara";
let Accounting = (props) => {
  const dispatch = useDispatch(),
    removeLast = (str) => str.substring(0, str.length - 1),
    activeTab = props?.match?.params?.tab,
    pathLength = window.location.pathname.split('/')
  let [tab, setTab] = useState("bank-accounts"),
    [subTab, setSubTab] = useState('all'),
    getActiveTab = () => {
      if (activeTab) {
        setTab(activeTab);
        pathLength.length === 4
          ? setSubTab(activeTab === 'bank-accounts'
            ? pathLength[3]
            : pathLength[3] + '2')
          : setSubTab(activeTab === 'bank-accounts' ? 'all' : 'all2')
        props.history.push(pathLength.length === 4 ? pathLength[3] : 'all')
      } else {
        setTab("bank-accounts");
      }
    },
    permissions = JSON.parse(atob(localStorage.getItem("access")));

  useEffect(() => {
    getActiveTab()
  }, [])
  let all = 'All'
  let RiDETopup = `RiDE TopUp`
  let RiderTopup = `Rider-TopUp`
  let vatTitle = `VAT`
  let feeTitle = `Fees`
  let topupTitle = `Driver withdrawal`
  let subscriptionTitle = `Subscriptions`
  let hasSubTab = ['bank-accounts', 'reconciliation']
  return (
    <>
      <div className="rider-listing">
        <span className="title">Accounting Management</span>

        <Tabs
          id="controlled-tab-example"
          className="mt-30"
          activeKey={tab}
          onSelect={(tab) => {
            setTab(tab);
            setSubTab(tab === 'bank-accounts' ? 'all' : 'all2')
            hasSubTab.includes(tab) ? props.history.push(`/accounting/${tab}/all`) : props.history.push(`/accounting/${tab}`)
          }}
        >
          {permissions.find(a => a.includes('/accounting/bank-accounts')) &&
            <Tab eventKey="bank-accounts" title={'Bank Accounts'}>
              <Tabs
                id="controlled-tab-example"
                className="mt-30"
                activeKey={subTab}
                onSelect={(tab) => {
                  setSubTab(tab);
                  props.history.push(`/accounting/bank-accounts/${tab}`);
                }}
              >
                {permissions.find(a => a.includes('/accounting/bank-accounts/all')) &&
                  <Tab eventKey="all" title={all}>
                    {subTab === "all" && <All />}
                    {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
                  </Tab>
                }
                {permissions.find(a => a.includes('/accounting/bank-accounts')) &&
                  <Tab eventKey="accounting" title={RiDETopup}>
                    {subTab === "accounting" && <DriverEarnings />}
                    {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
                  </Tab>
                }
                {permissions.find(a => a.includes('/accounting/bank-accounts')) &&
                  <Tab eventKey="earnings" title={RiderTopup}>
                                        {subTab === "earnings" && <Topups />}

                    {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
                  </Tab>
                }
                {permissions.find(a => a.includes('/accounting/bank-accounts')) &&
                  <Tab eventKey="vat" title={vatTitle}>
                    {subTab === "vat" && <Tax />}
                  </Tab>
                }
                {permissions.find(a => a.includes('/accounting/bank-accounts')) &&
                  <Tab eventKey="fees" title={feeTitle}>
                    {subTab === "fees" && <Fees />}
                  </Tab>
                }
                {permissions.find(a => a.includes('/accounting/bank-accounts')) &&
                  <Tab eventKey="accountancy" title={topupTitle}>
                    {subTab === "accountancy" && <Main />}
                    {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
                  </Tab>
                }
                {permissions.find(a => a.includes('/accounting/bank-accounts')) &&
                  <Tab eventKey="subscription" title={subscriptionTitle}>
                    {subTab === "subscription" && <DriverSubscription />}
                    {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
                  </Tab>
                }
                {/* {permissions.find(a => a.includes('/accounting/ehsaan')) &&
                  <Tab eventKey="ehsaan" title="Ehsaan">
                    {tab === "ehsaan" && <Ehsaan />}
                  </Tab>
                } */}
              </Tabs>
            </Tab>
          }
          {permissions.find(a => a.includes('/accounting/reconciliation')) &&
            <Tab eventKey="reconciliation" title={'Reconciliation'}>
              <Tabs
                id="controlled-tab-example"
                className="mt-30"
                activeKey={subTab}
                onSelect={(tab) => {
                  setSubTab(tab);
                  props.history.push(`/accounting/reconciliation/${removeLast(tab)}`);
                }}
              >
                {permissions.find(a => a.includes('/accounting/reconciliation/all')) &&
                  <Tab eventKey="all2" title={all}>
                    {subTab === "all2" && <All />}
                    {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
                  </Tab>
                }
                {permissions.find(a => a.includes('/accounting/reconciliation')) &&
                  <Tab eventKey="accounting2" title={RiDETopup}>
                    {subTab === "accounting2" && <Topups />}
                    {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
                  </Tab>
                }
                {permissions.find(a => a.includes('/accounting/reconciliation')) &&
                  <Tab eventKey="earnings2" title={RiderTopup}>
                    {subTab === "earnings2" && <DriverEarnings />}
                    {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
                  </Tab>
                }
                {permissions.find(a => a.includes('/accounting/reconciliation')) &&
                  <Tab eventKey="vat2" title={vatTitle}>
                    {subTab === "vat2" && <Tax />}
                  </Tab>
                }
                {permissions.find(a => a.includes('/accounting/reconciliation')) &&
                  <Tab eventKey="fees2" title={feeTitle}>
                    {subTab === "fees2" && <Fees />}
                  </Tab>
                }
                {permissions.find(a => a.includes('/accounting/reconciliation')) &&
                  <Tab eventKey="accountancy2" title={topupTitle}>
                    {subTab === "accountancy2" && <Main />}
                    {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
                  </Tab>
                }
                {permissions.find(a => a.includes('/accounting/reconciliation')) &&
                  <Tab eventKey="subscription2" title={subscriptionTitle}>
                    {subTab === "subscription2" && <DriverSubscription />}
                    {/* {tab === "expired" && <ExpiredSubscriptionsListingTable />} */}
                  </Tab>
                }
                {/* {permissions.find(a => a.includes('/accounting/ehsaan')) &&
                  <Tab eventKey="ehsaan" title="Ehsaan">
                    {tab === "ehsaan" && <Ehsaan />}
                  </Tab>
                } */}
              </Tabs>
            </Tab>
          }

          <Tab eventKey="tabby" title={'Tabby'}>
           {tab=='tabby' && <Tabby />} 
          </Tab>
          <Tab eventKey="tamara" title={'Tamara'}>
          {tab=='tamara' && <Tamara/>} 

          </Tab>
          <Tab eventKey="refunds" title={'Refunds'}>
            {tab === 'refunds' && <Refunds />}
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default React.memo(Accounting);
