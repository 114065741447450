// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case "TOGGLE_LOADER":
      if (action.payload) {
        document.querySelector("body").classList.add("o-hidden");
      } else {
        document.querySelector("body").classList.remove("o-hidden");
      }
      return (state = {
        ...state,
        isLoader: action.payload,
      });
    case "TOGGLE_GRID_LOADER":
      return (state = {
        ...state,
        isGridLoader: action.payload,
      });
    case "USER_PROFILE":
      return (state = {
        ...state,
        user: { ...action.payload },
      });
    case "WINDOW_SIZE":
      return (state = {
        ...state,
        windowSize: action.payload,
      });
    case "POLYGON":
      return (state = {
        ...state,
        polygon: action.payload,
      });
    case "LOCATION":
      return (state = {
        ...state,
        location: action.payload,
      });
    default:
      return state;
  }
};
