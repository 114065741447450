import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import Select from "react-select";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import * as Yup from "yup";
import { connect, useSelector } from "react-redux";
import APIService from "../../services/APIService";
import riderAvtSec from "../../assest/images/carimage.png";
import { Button } from 'primereact/button';
import {  Col, Modal, Row } from "react-bootstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";




function RiderCancel(props) {
    

  const reasonType =  {
    DRIVER_REJECT: 1,
    DRIVER_CANCEL: 2,
    RIDER_CANCEL : 3
  }
    const [cancelRide,setCancelRide]=useState();
    const [windowWidth] = useState(useSelector((state) => state.windowSize));
    const [showFilter, setShowFilter] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [formValue, setFormValues] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [apiCallInProgress, setApiCallInProgress] = useState(false);

    const statusOptions = [
      { value: true, label: "Active" },
      { value: false, label: "Inactive" },
    ];
    const [lazyParams, setLazyParams] = useState({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
    const dt = useRef(null);
    const manageDrivers = (event) => {
        if (event.sortField) {
          lazyParams.sort = {
            field: event.sortField,
            order: event.sortOrder,
          };
        } else if (event.first || event.first === 0) {
          lazyParams.take = event.rows ? event.rows : lazyParams.take;
          lazyParams.skip = event.first;
        } else if (event.filters && Object.keys(event.filters).length) {
          lazyParams.take = event.rows ? event.rows : lazyParams.take;
          lazyParams.skip = 0;
          let filters = { ...lazyParams.filters };
          for (let filter in event.filters) {
            if (event.filters[filter].value) {
              if (filter === "totalTrips") {
                lazyParams.filters[filter] = Number(event.filters[filter].value);
              } else {
                lazyParams.filters[filter] = event.filters[filter].value;
              }
            }
          }
          lazyParams.filters = { ...filters, ...lazyParams.filters };
        } else if (!event.filters || !Object.keys(event.filters).length) {
          lazyParams.filters = {};
        }
        setLazyParams(lazyParams);
        getCancelledRide();
      };

        // fetching data from api 
       const getCancelledRide = async ()=>{
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true })
          
        APIService.get(`admin/rejected-reason/type/${reasonType.RIDER_CANCEL}`)
    .then((response) => {
      if (response.data && response.data.statusCode === 200) {
        props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        const rides = response.data.data;
        
        setCancelRide(rides);
        setLazyParams({...lazyParams,totalRecords:rides.length})


        

      }
    })
    .catch((error) => {
      console.log(error);
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
    });
          
       }
      useEffect(()=>{
           getCancelledRide();
      },[]);
     
  const renderHeader = () => {
        return (
          <div className="table-header">
            <span className="toggle-filter">
              <button
                className="btn btn-primary show_filter_btn"
                onClick={() => toggleFilter()}
              >
                {windowWidth ? (
                  showFilter ? (
                    <span>
                      <i className="fa fa-filter"></i>
                      <i className="fa fa-caret-up ml-10"></i>
                    </span>
                  ) : (
                    <span>
                      <i className="fa fa-filter"></i>
                      <i className="fa fa-caret-down ml-10"></i>
                    </span>
                  )
                ) : // <i className="fa fa-filter"></i>
                  showFilter ? (
                    <>
                      <img src="/images/filter-icon.svg" alt="" srcSet="" />
                      <span className="span-filter mx-12">Filters</span>
                      <i className="fa fa-caret-up  "></i>
                    </>
                  ) : (
                    <>
                      <img src="/images/filter-icon.svg" alt="" srcSet="" />
                      <span className="span-filter mx-12">Filters</span>
                      <svg
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                          fill="#828282"
                        />
                      </svg>
                    </>
                  )}
              </button>
            </span>
            <span className="p-input-icon-left p-captain d-flex align-items-center">
              <i className="pi pi-search" />
              <InputText
                type="search"
                placeholder="Search"
                value={lazyParams.keyword}
                onInput={(e) => {
                  lazyParams = {
                    ...lazyParams,
                    skip: 0,
                    take: 25,
                    keyword: e.target.value,
                  };
                  setLazyParams(lazyParams);
                  // getDrivers();
                }}
              />
            </span>
            <button
              className="btn btn-primary float-right mb-1 ml-12"
              onClick={() => { }}
            >
              Export
            </button>
            <button
              className="btn btn-primary float-right mb-1 ml-12"
              onClick={()=>setShowAddModal(true)}
            >
              {windowWidth ? <i className="fa fa-plus" /> :<div style={{width:"50px"}}>+ Add</div>}
            </button>
          </div>
        );
      };

     const header = renderHeader();
   const toggleFilter = () => {
      setShowFilter(!showFilter);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      getCancelledRide();
    };

    const renderID = (rowData) => {
      const idWithoutDashes = rowData.id.replace(/-/g, ''); // Remove dashes from the ID
      const minimizedId = idWithoutDashes.substr(0, 12).toUpperCase(); // Get the first 12 characters and convert to uppercase
      return (
        <span className="text-success fw-bold" style={{ cursor: 'pointer' }}>
          {minimizedId}
        </span>
      );
    };

    const renderCreatedAt = (rowData) => {
      const formattedDate = moment(rowData.createdAt).format('MM/DD/YYYY hh:mm A');
      return <span>{formattedDate}</span>;
    };

    const renderReasonType = (rowData) => {
      const { reasonType } = rowData;
      let type;
    
      if (reasonType === 1) {
        type = 'Driver Reject';
      } else if (reasonType === 2) {
        type = 'Driver Cancel';
      } else if (reasonType === 3) {
        type = 'Rider Cancel';
      }
    
      return (
        <div className={`p-button-${type.toLowerCase().replace(' ', '-')}`} >{type}</div>
      );
    };


    console.log('form initial value ',formValue)


    const renderUpdated = (rowData) => {
      const handleUpdateClick = () => {
        setFormValues({
          reason: rowData.reason || '',
          arabicReason: rowData.reasonArabic || '',
          status: rowData.status?true:false,
          id:rowData.id
        });
        setShowModal(true);
      };
    
      return (
        <div className="d-flex align-items-center gap-2 ">
        <button className="btn btn-success" onClick={handleUpdateClick }>
              Update
            </button>
            <button className="btn btn-danger" onClick={()=>handleDelete(rowData.id)}>
              Delete
            </button>
        </div>
      );
    };

    const handleUpdate = async (values) => {
         
      APIService.patch(`admin/rejected-reason/${values.id}`, {
        reason:values.reason,
        reasonArabic:values.arabicReason,
        status:values.status,
      })
        .then((response) => {
          if (response.data.statusCode === 200) {
            console.log('updated data ',response.data)
          }});
          getCancelledRide();

      setShowModal(false);
    };

    const handleDelete = async (id)=>{
      APIService.delete(`admin/rejected-reason/${id}`)
        .then((response) => {
        console.log('delete response: ' , response)  
        }).catch((error) => {
            console.log('error', error)
          });
          getCancelledRide();
    }

 // add the reasons 
 const handleAdd = async (values) => {
  // Disable the button while the API call is in progress
  setApiCallInProgress(true);

  try {
    const response = await APIService.post(`admin/rejected-reason`, {
      reason: values?.reason,
      reasonArabic: values?.arabicReason,
      reasonType: values?.reasonType,
      status: values?.status,
    });

    if (response.data.statusCode === 200) {
      console.log('add reason data ', response.data);
    }
  } catch (error) {
    console.log('error', error);
  } finally {
    setApiCallInProgress(false);
    setShowAddModal(false);
    getCancelledRide();
  }
};
    
        const onHide = () => {
          // setModalValues(initialModal)
          setShowModal(false)
          setShowAddModal(false)
        }



   const renderBody =(rowData)=>{
    return (
      <div className={`${rowData.status==true?'text-success ':'text-danger '} fw-bold `}>{rowData.status==true?'Active':'InActive'}</div>
  )
   }

  return (
    <div className="datatable-doc-demo mt-3">
    <div className="card">
    <DataTable
        ref={dt}
        value={cancelRide}
        header={header}
        className="p-datatable-customers"
        dataKey="id"
        rowHover
        scrollable
        scrollHeight="470px"
        paginator
        emptyMessage="No Data found"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
        rowsPerPageOptions={[10, 25, 50]}
        totalRecords={lazyParams.totalRecords}
        lazy={true}
        first={lazyParams.skip}
        rows={lazyParams.take}
        sortField={lazyParams.sort.field}
        sortOrder={lazyParams.sort.order}
        // onPage={manageDrivers}
        // onSort={manageDrivers}
        // onFilter={manageDrivers}
        loading={useSelector((state) => state.isGridLoader)}
        loadingIcon="fa fa-spinner"
      >
    <Column
    className="text-center"
    style={{ fontSize: "12px", width: "200px" }}
    header="ID"
    field="id"
    body={renderID}
    sortable
    filter={showFilter}
    filterMatchMode="startsWith"
    filterPlaceholder="Search by id"
  />
  
  {/* Add more columns here */}
  <Column
    className="text-center"
    style={{ fontSize: "12px", width: "200px" }}
    header="Reason Type"
    field="reason"
  />

  <Column
    className="text-center"
    style={{ fontSize: "12px", width: "200px" }}
    header="Reason Arabic"
    field="reasonArabic"
    sortable
    // filter={showFilter}
    // filterMatchMode="startsWith"
    // filterPlaceholder="Search by Arabic reason"
  />

  <Column
    className="text-center"
    style={{ fontSize: "12px", width: "200px" }}
    header="Reason"
    field="reason"
    body={renderReasonType}
    sortable
    filter={showFilter}
    filterMatchMode="startsWith"
    filterPlaceholder="Search by reason type"
  />

<Column
  className="text-center"
  style={{ fontSize: "12px", width: "200px" }}
  header="Created At"
  body={renderCreatedAt}
  sortable
/>

  {/* <Column
    className="text-center"
    style={{ fontSize: "12px", width: "200px" }}
    header="Modified By"
    field="modifiedBy"
    sortable
    // filter={showFilter}
    // filterMatchMode="startsWith"
    // filterPlaceholder="Search by modified by"
  /> */}

  {/* <Column
    className="text-center"
    style={{ fontSize: "12px", width: "200px" }}
    header="Is Deleted"
    field="isDeleted"
    body={(value)=>{
        return (
            <div className={`${value?'text-success ':'text-danger '} fw-bold `}>{value?'Yes':'No'}</div>
        )
    }}
    sortable
    // filter={showFilter}
    // filterMatchMode="startsWith"
    // filterPlaceholder="Search by is deleted"
  /> */}

  <Column
    className="text-center"
    style={{ fontSize: "12px", width: "200px" }}
    header="Status"
    field="status"
    sortable
    body={renderBody}
    // filter={showFilter}
    // filterMatchMode="startsWith"
    // filterPlaceholder="Search by status"
  />


<Column
    className="text-center"
    style={{ fontSize: "12px", width: "200px"}}
    header="Action"
    field="Action"
    body={renderUpdated}
  />

  </DataTable>
  <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered>
  <Modal.Header>
    <Modal.Title className="modal-title mb-4">Update Reason</Modal.Title>
    <div className="divclose" onClick={onHide}>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </Modal.Header>
  <Modal.Body>
    <Formik
      initialValues={{
        reason: formValue?.reason, // Initialize your form field values here
        arabicReason: formValue?.arabicReason, // Corrected field name
        status: formValue?.status, // Initialize the status field
        id: formValue?.id, //
      }}
      validationSchema={Yup.object({
        reason: Yup.string().required("Reason is required"),
        arabicReason: Yup.string().required("Arabic Reason is required"), // Corrected field name
        status: Yup.string().required("Status is required"), // Add validation for the status field
      })}
      onSubmit={(values) => {
       
        console.log('form values ', values);

        handleUpdate(values); 
      }}
    >
      {(formik) => (
        <Form>
          <Row>
            <Col md={6}>
            <div className="form-group form-label-group">
                          <Field
                            id="userid"
                            type="text"
                            name="reason"
                            className="shadow-none form-control modal-input br-10"
                            
                          />
                          <label htmlFor="userid">Reason</label>
                          <ErrorMessage
                            name="userid"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
            </Col>

            <Col md={6}>
              <div className="form-group form-label-group">
                <Field
                  id="arabicReason"
                  type="text"
                  name="arabicReason" // Corrected field name
                  className="shadow-none form-control modal-input br-10"
                />
                <label htmlFor="arabicReason">Arabic Reason</label>
                <ErrorMessage
                  name="arabicReason"
                  component="div"
                  className="invalid-feedback text-danger"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="mb-4 form-label-group">
                <label htmlFor="status">Status</label>
                <Select
                  id="status"
                  name="status"
                  className="height-50 br-10 bg-color-select"
                  options={statusOptions}
                  placeholder="Select a status"
                  onChange={(selectedOption) => {
                    formik.setFieldValue('status', selectedOption.value);
                  }}
                />
                <ErrorMessage
                  name="status"
                  component="div"
                  className="invalid-feedback text-danger"
                />
              </div>
            </Col>

            <Col md={6}>
              <Button
                variant="success"
                className="btn-save save-button btn btn-block text-center d-flex justify-content-center fw-bold"
                style={{ height: '58px' }}
                type="submit"
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  </Modal.Body>
</Modal>



{/* add reason modal  */}
<Modal show={showAddModal} onHide={() => setShowAddModal(false)} size="lg" centered>
  <Modal.Header>
    <Modal.Title className="modal-title mb-4">Add Reason</Modal.Title>
    <div className="divclose" onClick={onHide}>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </Modal.Header>
  <Modal.Body>
    <Formik
      initialValues={{
        reason: '', // Initialize your form field values here
        arabicReason:'', // Corrected field name
        reasonType:reasonType?.RIDER_CANCEL,
        status: '', // Initialize the status field
      }}
      validationSchema={Yup.object({
        reason: Yup.string().required("Reason is required"),
        arabicReason: Yup.string().required("Arabic Reason is required"), // Corrected field name
        status: Yup.string().required("Status is required"), // Add validation for the status field
      })}
      onSubmit={(values) => {
       
        console.log('add form values ', values);

        handleAdd(values); 
      }}
    >
      {(formik) => (
        <Form>
          <Row>
            <Col md={6}>
            <div className="form-group form-label-group">
                          <Field
                            id="userid"
                            type="text"
                            name="reason"
                            className="shadow-none form-control modal-input br-10"
                            
                          />
                          <label htmlFor="userid">Reason</label>
                          <ErrorMessage
                            name="reason"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
            </Col>

            <Col md={6}>
              <div className="form-group form-label-group">
                <Field
                  id="arabicReason"
                  type="text"
                  name="arabicReason" // Corrected field name
                  className="shadow-none form-control modal-input br-10"
                />
                <label htmlFor="arabicReason">Arabic Reason</label>
                <ErrorMessage
                  name="arabicReason"
                  component="div"
                  className="invalid-feedback text-danger"
                />
              </div>
            </Col>
          </Row>

          <Row>

            <Col md={6}>
              <div className="mb-4 form-label-group">
                <label htmlFor="status">Status</label>
                <Select
                  id="status"
                  name="status"
                  className="height-50 br-10 bg-color-select"
                  options={statusOptions}
                  placeholder="Select a status"
                  onChange={(selectedOption) => {
                    formik.setFieldValue('status', selectedOption.value);
                  }}
                />
                <ErrorMessage
                  name="status"
                  component="div"
                  className="invalid-feedback text-danger"
                />
              </div>
            </Col>
            <Col md={6}>
            <Button
        variant="success"
        className="btn-save save-button btn btn-block text-center d-flex justify-content-center fw-bold"
        style={{ height: '58px' }}
        type="submit"
        disabled={apiCallInProgress} // Disable the button when the API call is in progress
      >
        {apiCallInProgress ? "Adding Reason..." : "Add Reason"}
      </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  </Modal.Body>
</Modal>



  
      </div>
      </div>
  )
}

export default React.memo(connect()(withRouter(RiderCancel)));
