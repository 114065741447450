import React, { useState, useEffect, useRef } from "react";
import { CalendarRange } from "react-bootstrap-icons";
import { Button, Card, Col, Row, Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import * as Yup from "yup";
import { Calendar } from "primereact/calendar";
import Rating from "react-rating";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import APIService from "../../services/APIService";
import whatsappSource from "../../assest/images/ride-app-source.svg";
import riderAvtSec from "../../assest/images/placeHoderImage.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const PickupListingTable = () => {
  const [status, setStatus] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const dt = useRef(null);
  const [windowWidth] = useState(useSelector((state) => state.windowSize));

  let [lazyParams, setLazyParams] = useState({
    filters: {},
    sort: {
      field: "createdAt",
      order: -1,
    },
    take: 25,
    skip: 0,
    keyword: "",
    totalRecords: 0,
  });

  const managePickups = (event) => {
    if (event.sortField) {
      lazyParams.sort = {
        field: event.sortField,
        order: event.sortOrder,
      };
    } else if (event.first || event.first === 0) {
      lazyParams.take = event.rows ? event.rows : lazyParams.take;
      lazyParams.skip = event.first;
    } else if (event.filters && Object.keys(event.filters).length) {
      lazyParams.take = event.rows ? event.rows : lazyParams.take;
      lazyParams.skip = 0;
      let filters = { ...lazyParams.filters };
      for (let filter in event.filters) {
        lazyParams.filters[filter] = event.filters[filter].value;
      }
      lazyParams.filters = { ...filters, ...lazyParams.filters };
    } else if (!event.filters || !Object.keys(event.filters).length) {
      lazyParams.filters = {};
    }
    setLazyParams(lazyParams);
    // getPickups();
  };

  const Pickups = [
    {
      ID: "3791062042",
      full_name: "Josie",
      service: "Hayselden",
      loyality_point: 1,
      rating: "Female",
      Mobile_No: "584-828-5373",
      "date&time": "3/16/2023",
      total_trips: 1,
      kyc: "SK",
      status: "GA",
      source: "Tom",
    },
    {
      ID: "2685544860",
      full_name: "Tamar",
      service: "Mocker",
      loyality_point: 2,
      rating: "Female",
      Mobile_No: "951-380-7410",
      "date&time": "7/23/2022",
      total_trips: 2,
      kyc: "US",
      status: "OM",
      source: "Eward",
    },
    {
      ID: "2327066182",
      full_name: "Juieta",
      service: "Bazoche",
      loyality_point: 3,
      rating: "Female",
      Mobile_No: "845-847-7371",
      "date&time": "12/12/2022",
      total_trips: 3,
      kyc: "US",
      status: "IL",
      source: "Winslow",
    },
    {
      ID: "1594557411",
      full_name: "Ambrosius",
      service: "Frissell",
      loyality_point: 4,
      rating: "Male",
      Mobile_No: "698-863-3850",
      "date&time": "10/5/2022",
      total_trips: 4,
      kyc: "BR",
      status: "CZ",
      source: "Tynan",
    },
    {
      ID: "1548359653",
      full_name: "Corabella",
      service: "Benton",
      loyality_point: 5,
      rating: "Female",
      Mobile_No: "101-875-7266",
      "date&time": "11/23/2022",
      total_trips: 5,
      kyc: "FJ",
      status: "PL",
      source: "Stavros",
    },
    {
      ID: "7779532127",
      full_name: "Colby",
      service: "Ferroni",
      loyality_point: 6,
      rating: "Male",
      Mobile_No: "646-469-7627",
      "date&time": "1/21/2023",
      total_trips: 6,
      kyc: "BR",
      status: "CA",
      source: "Bo",
    }
  ];

  const renderHeader = () => {
    return (
      <>
        <div className="table-header">
          <span className="toggle-filter">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
              showFilter ? (
                <>
                  <img src="/images/filter-icon.svg" alt="" />
                  <span className="span-filter mx-12">Filters</span>
                  <i className="fa fa-caret-up"></i>
                </>
              ) : (
                <>
                  <img src="/images/filter-icon.svg" alt="" />
                  <span className="span-filter mx-12">Filters</span>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                      fill="#828282"
                    />
                  </svg>
                </>
              )}
            </button>
          </span>

          <span className="p-input-icon-left d-flex align-items-center">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search..."
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                // getPickups();
              }}
            />
          </span>

          <button className="btn btn-primary float-right mb-1 ml-12">
            Export
          </button>
        </div>
      </>
    );
  };

  const header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setLazyParams({
        filters: {},
        sort: {
          field: "creationDate",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      setStatus(null);
      // getPickups();
    };

   const redirectIdTemplate = (rowData) => {
      return (
        <div className="text-left">
          {rowData?.profileImage ? (
            <img
              src={`${rowData?.profileImage}`}
              alt="ride"
              style={{
                marginRight: "15px",
                width: "48px",
                height: "48px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          ) : (
            <img
              src={riderAvtSec}
              alt="ride"
              style={{
                marginRight: "15px",
                width: "48px",
                height: "48px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          )}
          <Link
              to={"/driver-pickups/" + rowData.ID}
              className="dataFnt-size"
              style={{ color: "rgba(19, 181, 66, 1)" }}
            >
            
              <span className="dataFnt-size" style={{ color: "#13B542" }}>
                {rowData.ID}
              </span>
            </Link>
        </div>
      );
    };

  return (
    <>
      <div className="datatable-doc-demo mt-3">
        <div className="card">
          <DataTable
            ref={dt}
            value={Pickups}
            header={header}
            className="p-datatable-customers"
            dataKey="id"
            rowHover
            scrollable
            scrollHeight="470px"
            paginator
            emptyMessage="No Rider(s) found"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
            rowsPerPageOptions={[5, 10, 25, 50]}
            totalRecords={lazyParams.totalRecords}
            lazy={true}
            first={lazyParams.skip}
            rows={lazyParams.take}
            sortField={lazyParams.sort.field}
            sortOrder={lazyParams.sort.order}
            onPage={managePickups}
            onSort={managePickups}
            onFilter={managePickups}
            loading={useSelector((state) => state.isGridLoader)}
            loadingIcon="fa fa-spinner"
          >
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "250px" }}
              field="ID"
              header="ID"
              body={redirectIdTemplate}
              sortable
              filter={showFilter}
              filterField="Id"
              filterMatchMode="contains"
              filterPlaceholder="Search by id"
            />
            <Column
              className="text-left"
              headerClassName="text-left"
              style={{ fontSize: "12px", width: "190px" }}
              field="full_name"
              header="Full name"
              sortable
              filter={showFilter}
              filterPlaceholder="Search by name"
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="Service"
              field="service"
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="Rating"
              field="rating"
              sortable
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="loyality points"
              field="loyality_point"
              sortable
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="Mobile no."
              field="Mobile_No"
              sortable
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="Date & Time"
              field="date&time"
              sortable
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="Total Trips"
              field="total_trips"
              sortable
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="KYC"
              field="kyc"
              sortable
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="Status"
              field="status"
              sortable
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="source"
              field="source"
              sortable
            />
          </DataTable>
        </div>
      </div>
    </>
  );
};
export default PickupListingTable;
